import React, { Component } from "react";
import "./CreateQuote.scss";
import Vector from  "../../../assets/images/Vector.svg";
import quotearrow from  "../../../assets/images/quotearrow.svg";
import VectorQuote from "../../../assets/images/VectorQuote.svg";
import { userService } from '../../Services/CommonService';
import { constants } from '../../../constants/constants';
import Check from '../../Services/Check';
import HireRectangleBlue from  "../../../assets/images/HireRectangleBlue.svg";
import HireRectangle from  "../../../assets/images/HireRectangle.svg";
import Image from "../../common-components/Image";
import Delete from "../../../assets/images/delete.svg"
import uploadFile from '../../../assets/images/uploadFile.svg';
import CustomModal from '../../common-components/CustomModal/CustomModal';


interface qutoeprops {
    result: any;
    error: any;
    builderId: any;
    projectName:any;
    isEditQuoteCheck: boolean;
    isPublicCheck: boolean;

}

interface qutoestate {
    price: string,
    durationInMonth: string,
    description: string,
    projectName: string,
    quoteDetails: any,
    closeCreateQuote: any,
    attachedFile: string,
    selectedFile: any,
    errors: {
        file:string,
        description: string,
        price: string,
        durationInMonth: string,
        name :  string,
        email : string,
        password : string,
        usertype : string,
        provision: string,
    }
    newUserChecked: boolean, 
    customerType: string,
    showUpload: boolean,
    body: string, 
    header: string, 
    provision: string,
}
const Regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
class Quote extends React.Component<qutoeprops & any , qutoestate>{
    constructor(props: any) {
        super(props);
        const initialState = {
            price: '',
            durationInMonth: '',
            description: '',
            projectName: this.props.projectName,
            quoteDetails: this.props.quoteDetailsData,
            closeCreateQuote: true,
            attachedFile: '',
            selectedFile: '',
            errors: {
                file: '',
                description: '',
                price: '',
                durationInMonth: '',
                name : '',
                email : '',
                password : '',
                usertype : '',
                provision: '',
            },
            newUserChecked: false,
            customerType: '',
            showUpload: false,
            header: '',
            body: '',
            provision: '',
            // projectId: this.props.id

        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount(){
        let {quoteDetails}:any = this.state;
        if( this.props.isEditQuoteCheck == true){   
        this.setState({
            durationInMonth: this.props.quoteDetailsData.durationInMonths,
            price: this.props.quoteDetailsData.price,
            description: this.props.quoteDetailsData.description,
            attachedFile: this.props.quoteDetailsData.attachmentPath,
            provision: this.props.quoteDetailsData.provisionType,
        }) 
    }
    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'price':
                errors.price = value.length == 0 ? 'Please enter price': '';
            break;
            case 'durationInMonth':
                errors.durationInMonth = value.length == 0 ? 'Please enter value': '';
            break;
            case 'description':
                    errors.description = value.length == 1024 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'name':
                    errors.name = value.length == 0 ? 'Please enter valid Name': '';
                break;
            case 'email':
                    errors.email = Regex.test(value)? '': 'Please enter valid Email';
                break;
            case 'password':
                    errors.password = value.length < 8 ? 'Password must be 8 characters long': '';
                break;
            case 'usertype':
                    errors.usertype = value.length == 0 ? 'Please select valid option': '';
                    break;
            case 'provision':
                        errors.provision = value.length == 0 ? 'Please select provision': '';
                    break;
            default:
                break;    
        }
        this.setState(Object.assign(this.state, {errors, [name]: value }));
    };

    changeCheck = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState(
            Object.assign(this.state, { [name]: value })
        );
        this.setState({
            newUserChecked: true
        })
  
    };

    closeQuoteModal = () =>{
        const {closeCreateQuote}:any = this.state  
        this.clearValues();
        this.clearErrorMsg();
        this.props.result(closeCreateQuote);   
    }

    clearErrorMsg = () =>{
        let errors = this.state.errors;
        errors.description = '';
        errors.price = '';
        errors.durationInMonth = '';
        errors.file = '';

    }
    sendQuotes = ()=>{
        const {price,durationInMonth, description, attachedFile,  name, email, password, usertype, newUserChecked, provision}:any = this.state 
        let  builderId = [];
        builderId.push(localStorage.getItem("id"));
        let userId:any;
        let quoteId:any;
        {(localStorage.userType == "Builder" || localStorage.userType == "Architect")?
            userId = builderId
            :
            userId = this.props.builderId ? this.props.builderId: ""
        }  
        let projectId = this.props.id;
        let validity = true;
        Object.values(this.state.errors).forEach(
          (val) => val.length > 0 && (validity = false)
        );
    if(validity == true ){
     if( this.props.isEditQuoteCheck == true){
        Check.checkToken()
        .then(() => {
            const options = {
                url: constants.createQuote,
                data: {
                     "quoteId": this.props.quoteDetailsData.id,
                     "projectId": projectId,
                     "userId": userId,
                     "userType":  (localStorage.userType),
                     "description": description || this.props.quoteDetailsData.description,
                     "price": price || this.props.quoteDetailsData.price,
                     "durationInMonths": durationInMonth || this.props.quoteDetailsData.durationInMonths,
                     "attachmentPath": attachedFile,
                     "provisionType": provision,
                     "active": true,
                }
            };
           
            userService
            .updateApiRequest(options)
            .then((response) => {
                this.clearValues();
                this.props.result("Quote Sent Successfully");
            })
            .catch((error) => {
                this.props.error("User Does not Exist");
            });

        })
     }else{
        Check.checkToken()
        .then(() => {
            const options = {
                url: constants.createQuote,
                data: {
                     
                     "projectId": projectId,
                     "userId": userId,
                     "userType":  (localStorage.userType),
                     "description": description,
                     "price": price,
                     "durationInMonths": durationInMonth,
                     "attachmentPath": attachedFile,
                     "provisionType": provision,
                     "active": true,
                     "fullName": name,
                     "userName": email,
                     "password": password,
                     "signUpUserType": usertype,
                     "email": email,
                     "socialUser": false,
                     "newUser": newUserChecked
                }
            };
           
            userService
            .postApiRequest(options)
            .then((response) => {
                this.clearValues();
                this.props.result("Quote Sent Successfully.");
            })
            .catch((error) => {
                this.props.error("Can not Sent Quote.");
            });

        })
     }
    }else{

    }
       
    }

    clearValues(){
        this.setState({price:'', durationInMonth:'', description: '', attachedFile: ''});
        }

        onFileChange = async (event: any) => {
            let userId = localStorage.getItem("id");
            let id = event?.target.id;
            let file = event.target.files[0];
            const formData = new FormData();
            if(file && file.type != 'application/pdf' && file && file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && file && file.type != 'text/csv' ){
                let errors = this.state.errors;
                errors.file = 'Please upload only PDF/XLS/CSV file.';
                this.setState({errors: errors});
            }else if(file && file.size > 4000000){
                let errors = this.state.errors;
                errors.file = 'Please upload file below 4MB.';
                this.setState({errors: errors});
            }else{
                let errors = this.state.errors;
                errors.file = '';
                if(file){
                    formData.append('id', userId?userId:'');
                    formData.append('type', "Profile");
                    formData.append('file', file);   
                    const options = {
                        url: constants.uploadFilesAPI,
                        data: formData
                    }
                    Check.checkToken();
                    await userService.postFileApiRequest(options)
                    .then((response)=>{
                        if(response){
                            this.setState({attachedFile: response});
                            this.openFileUpload("Success", "File Uploaded SuccessFully");
                        }else{
                            this.setState({attachedFile: ''});
                            
                        }
                    })
                    .catch((error) =>{
                        this.openFileUpload("Failed", "Failed to Upload File");
                    })
                       
                }else{
                    // let errors = this.state.errors;
                    // errors.file1 = 'Please Select File to Upload';
                    // this.setState({errors: errors});
                }
               
            }
 
        };    

        removeAttachedFile = async(event:any) => {
            let id = event?.target.id;
            this.setState({attachedFile:''})
        }
        openFileUpload = async(header:any, body: any) => {
            this.setState({showUpload:true, header: header, body: body});
        }
        onHideFileUpload = async(event:any) => {
            this.setState({showUpload:false});
        }


    
    render(){
        const {price,durationInMonth, projectName, description, quoteDetails, attachedFile, errors, newUserChecked,  name, email, password, usertype, customerType, showUpload, body, header,provision }:any = this.state
        let isEnabled = true;
        if(localStorage.userType && localStorage.userType == "Customer"){
            if( description !== ""
            ){
                isEnabled = false
            }
        }else{
            if(provision !== "" && durationInMonth !== "" &&
            price !== "" && description !== ""
            ){
                isEnabled = false
            }
        }
        
        return (
            <>
                {(localStorage.userType && localStorage.userType == "Customer")?
                    <div className = "container-fluid quote-container">
                            <>
                                <div className = "quote-header">
                                    <p>Ask a Quote</p>
                                    <img src = {Vector}  onClick={this.closeQuoteModal}/>
                                </div>
                                <div style= {{marginBottom : "10px"}}>
                                    <img src = {HireRectangle}/>
                                    <img style = {{marginLeft : "5px"}} src = {HireRectangleBlue}/>
                                </div>
                                <div className = "quote-content">
                                    <p>Add a message and send your quote.</p>
                                </div>
                                <div className = "moreInfo-content">
                                    <p>Message</p>
                                    <textarea 
                                    placeholder = "Add additional info"
                                    name= "description" 
                                    value = {description}
                                    onChange={this.handleChange}
                                    maxLength = {1024}
                                    >
                                    </textarea> 
                                    {errors.description.length > 0 &&  <p style={{color: "red"}}>{errors.description}</p>}
                                </div>
                            </>
                        <button className= {isEnabled == true ? "disabled-button" : ""} disabled = {isEnabled} onClick={()=>this.sendQuotes()}>Ask Quotes <img src = {quotearrow}/></button>
                    </div>
                :
                <div className = "container-fluid quote-container scrollableContent">
                        <>
                            <div className = "quote-header">
                                <p>Send a Quote</p>
                                <img src = {Vector}  onClick={this.closeQuoteModal}/>
                            </div>
                            <br/>
                                <div className = "quote-content">
                                    <p>Share a quote for the project "{this.props.projectName}" with the owner.</p>
                                    <div className = "moreInfo-content">
                                        <p>Provision</p>
                                        <select  
                                            name= "provision" 
                                            value = {provision}
                                            onChange={this.handleChange}
                                            >
                                                 <option>Select</option>
                                                <option value = "Only Construction">Only Construction</option>
                                                <option value = "Land/Construction">Construction/Land</option>
                                        </select> 
                                        {errors.provision.length > 0 &&  <p style={{color: "red"}}>{errors.provision}</p>}
                                    </div>
                                    <p>Duration: 
                                    <input 
                                            type = "number"
                                            onChange={this.handleChange}
                                            value= {durationInMonth}
                                            name="durationInMonth"
                                        />
                                        <span>Months</span>
                                    </p>
                                    {errors.durationInMonth.length > 0 &&  <p style={{color: "red"}}>{errors.durationInMonth}</p>}
                                </div>
                                <div className="row new-rate-container">
                                    <div className = "col-sm-4">
                                        <p>Give new Rate:</p>
                                    </div>
                                    <div className = "col-sm-8" style = {{marginRight: "-54px", marginLeft: "-40px"}}> 
                                        <input 
                                        type = "number"
                                        onChange={this.handleChange}
                                        value= {price}
                                        name="price"
                                        /><span>AUD</span>
                                        <p className= "price-message"> <img src = {VectorQuote}/>The new rate will not be shown to any other customer or project. It is specific to this project.</p> 
                                        {errors.price.length > 0 &&  <p style={{color: "red"}}>{errors.price}</p>}   
                                    </div>
                                    <div className = "col-sm-2">
                                        
                                    </div>
                                </div>
                            <div className = "moreInfo-content">
                                <p>Message</p>
                                <textarea  
                                    placeholder = "Add additional info" 
                                    name= "description" 
                                    value = {description}
                                    onChange={this.handleChange}
                                    maxLength= {1024}
                                    >
                                </textarea> 
                                {errors.description.length > 0 &&  <p style={{color: "red"}}>{errors.description}</p>}
                            </div>
                            <div className = "moreInfo-content">
                                <p>Attach Files</p>
                                {attachedFile && 
                                    <>
                                        <div className="profilecard">
                                                <span>
                                                    {attachedFile.split("/")[3]}
                                                </span>
                                                <span> <Image className="close tab-select" id="logo" src={Delete} onClick={(event)=>{this.removeAttachedFile(event)}} /></span>
                                        </div>
                                    </>
                                || 
                                    <>
                                        <div className = "quote-attachment">
                                            <span>
                                                <label htmlFor="logo">
                                                    <span className=" cursor"><h6 data-toggle="tooltip" data-html="true" title="File must be less than 4MB and file types must be PDF/XLS/CSV."><img style = {{marginRight: "5px"}} src= {uploadFile}></img>Upload Files</h6></span>
                                                </label>
                                                <input accept="application/pdf,text/csv, .csv,.xls,.xlsx " id="logo" type="file" style={{display:"none"}}  onChange={(event)=>this.onFileChange(event)} width="100%" height="300px"/>
                                            </span>
                                        </div>
                                        {errors.file.length > 0 && <span style={{ color: 'red' }}>{errors.file}</span>}
                                    </>
                                }
                            </div>
                                {(this.props.isPublicCheck == false)
                                //     ?<div className = "quote-content">
                                //         <p>Customer Types</p>
                                //         <select name = "customerType" onChange={this.changeCheck} value={customerType}  className=" px-3 py-1">
                                //             <option value="">--SELECT--</option>
                                //             <option value = "newUser">New user</option>
                                //             {/* <option value = "existingUser">Existing user</option> */}
                                //         </select>
                                //     </div>
                                // :
                                // ""
                                }
                            
                                {(customerType == "newUser")
                                // ?<>
                                //     <div>
                                //         <div className='fullName'>
                                //             <label className="mt-2">Name</label>
                                //             <input type="text" name="name" className="w-100 px-3" placeholder="Name" onChange={this.handleChange}  value={name}  required/>
                                //             {errors.name.length > 0 &&  <span style={{color: "red"}}>{errors.name}</span>}
                                //         </div>
                                //         <div className='email'>
                                //             <label className="mt-2">Email</label>
                                //             <input type='email' name='email' className="w-100 px-3" placeholder="Email" onChange={this.handleChange} value={email} required/>
                                //             {errors.email.length > 0 &&  <span style={{color: "red"}}>{errors.email}</span>}
                                //         </div> 
                                //         <div className='password'>
                                //             <label className="mt-2">Password</label>
                                //             <input type='password' name='password' className="w-100 px-3" placeholder="Password" onChange={this.handleChange} value={password} required/>
                                //             {errors.password.length > 0 &&  <span style={{color: "red"}}>{errors.password}</span>}
                                //         </div> 
                                        
                                //         <div className='usertype'>
                                //             <label  className="mt-2">What are you?</label>
                                //             <select name="usertype" id="usertype"  className="w-100 px-3 py-1" onChange={this.handleChange} value={usertype} required>
                                //                 <option value="">--SELECT--</option>
                                //                 <option value="Customer">Customer</option>
                                //             </select>
                                //             {errors.usertype.length > 0 &&  <span style={{color: "red"}}>{errors.usertype}</span>}
                                //         </div>
                                //     </div>
                                // </>
                                // :
                                // ""
                                }
                        </>
                        <br/><br/>    
                        <button className= {isEnabled == true ? "disabled-button" : ""} disabled = {isEnabled} onClick={()=>this.sendQuotes()}>Send Quotes <img src = {quotearrow}/></button>
                    </div>    
                }
                <CustomModal 
                    isAlert={true} 
                    show={showUpload} 
                    header={header} 
                    body={body} 
                    onHide={(event: any)=>this.onHideFileUpload(event)}
                />
             </>
        )
        
    }

}

export default Quote;