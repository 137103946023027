import React, { Component } from 'react';
import axios from 'axios';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import { constants } from '../../../../constants/constants';
import Modal from 'react-awesome-modal';
import ruler from '../../../../assets/images/ruler.svg';
import Twitterwithround from '../../../../assets/images/Twitterwithround.svg';
import Rating from '@material-ui/lab/Rating';
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg';
import Image from "../../../common-components/Image";
import Upload from "../../../../assets/images/upload.svg";
import { blobService } from '../../../common-components/Blob';
import {Card } from "react-bootstrap";
import rightarrow from '../../../../assets/images/rightarrow.svg';
import { Link } from 'react-router-dom';
import Loader from '../../../common-components/Loader';
import FBwithround from '../../../../assets/images/FBwithround.svg';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import Linkedinwithround from '../../../../assets/images/Linkedinwithround.svg';

interface ViewRawMaterialSupplierProps{
    location?:any
      name?: any;
    value?: any;
    project?: any;
    isView?: boolean;
}
interface ViewRawMaterialSupplierState{
    error:boolean;
    ErrMsg:any;
    isLoading: boolean;
    rawMaterialSupplier: any;
    materialList: any;
    profileImage: string,
    showProject: boolean,
    showUpload: boolean,
    header: string,
    body: string,
    supplierName:string,
    city: string,
    twitterLink: string,
    facebookLink: string,
    instagramLink: string,
    description: string,
    showModal: boolean,
    addressLine1: string, 
    addressLine2: string,
    state: string, 
    country: string, 
    pincode: number,
 
}
class ViewRawMaterialSupplier extends Component <ViewRawMaterialSupplierProps,ViewRawMaterialSupplierState>{
   constructor(props:ViewRawMaterialSupplierProps) {
       super(props)
   
       this.state = {
        isLoading: false,
        rawMaterialSupplier: {},
            city: '',
            addressLine1: "",
            addressLine2: "", 
            state: "", 
            country: "",
            pincode: 0,
            materialList: [],
            profileImage:'',
            showUpload: false,
            showProject: false,
            header: '',
            body: '',
            supplierName: '',
            twitterLink: '',
            facebookLink: '',
            description: '',
            showModal: false,
            error:false,
            ErrMsg:"",
            instagramLink: ""
          
       }
   }
   
    componentDidMount(){
        this.init();
    }

    init=()=>{
        this.fetchRawMaterialSupplier((this.props.location.state.data.userId)); 
    }
    

    fetchRawMaterialSupplier=(id:any)=>{
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
        if(user){
            token = 'Bearer ' + user;
        
        }
        const key = constants.subscriptionKey;
        const url=constants.fetchRawMaterialSupplierAPI+id;
        axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
        .then((response) => {
            let supplierData = response.data;
            if( supplierData.address.addressLine1 !== null || supplierData.address.addressLine2 !== null || supplierData.address.state !== null ||  supplierData.address.country !== null ||  supplierData.address.city !== null ){
                this.setState({
                city:  supplierData.address.city , 
                addressLine1:  supplierData.address.addressLine1, 
                addressLine2:  supplierData.address.addressLine2 , 
                state:  supplierData.address.state, 
                country: supplierData.address.country ,
                // pincode: a.address.pincode,
                })
            }

            this.setState({ 
                rawMaterialSupplier: supplierData, 
                profileImage: supplierData.profileImage, 
                supplierName: supplierData.supplierName, 
                twitterLink: supplierData.socialLinks.twitterLink,
                facebookLink: supplierData.socialLinks.facebookLink,
                description:supplierData.description,
                instagramLink: supplierData.socialLinks.instagramLink,
                materialList: supplierData.suppplierRawMaterialMapping,
            });
        })
        .catch((error) => {
            this.setState({ ErrMsg: error, error:true });
        });
    };

    onShowModal(event:any){
        this.setState({ showModal: true, header: "Contact Details" });
    }

    onHideModal(event:any){
        this.setState({ showModal: false });
    }

    async loadImages(path:string){
        return await blobService.fetch(path);
    };


    render() {

        const { isLoading, rawMaterialSupplier, profileImage, header,supplierName, city, twitterLink, facebookLink, instagramLink, description,  showModal, addressLine1, addressLine2, state, country, materialList } = this.state;

        let address:any;
        if(addressLine1 == "" || addressLine1 == null){
            address = []
        }else{
            address =  addressLine1+","+addressLine2 +", "+city+", "+state+", "+country;
        }

        let selectedValues = materialList.map((items: any)=>{
            let cityData = items.city.map((data: any)=>{
                return(
                    <p style = {{margin:"0px"}}>{data? data + "," : "" }</p>
                )
            });
            let stateData = items.state.map((data: any)=>{
                return(
                    <p style = {{margin:"0px"}}>{data? data + "," : "" }</p>
                )
            });
            return(
                    <>
                        <div className="container ">
                            <div className="row ">
                                <div className="col-md-2 ">
                                    <p>{items.materialName? items.materialName : "" }</p>
                                </div>
                                <div className="col-md-2 ">
                                    <p>{items.brand? items.brand : ""}</p>
                                </div>
                                <div className="col-md-2 "> 
                                    <p>{items.price? items.price : ""}</p>
                                </div>
                                <div className="col-md-2">
                                    <p>{items.description? items.description : ""}</p>
                                </div>
                                <div  className="col-md-2">
                                          {cityData ? cityData: ""}{stateData? stateData: ""}
                                </div>
                            </div>
                        </div>
                    </>
            )
    })
    

        return isLoading ? (<Loader/>): 
        (
            <>
            <Header/>
            <div className="bg-img">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <h1 style={{fontWeight:600}}>{supplierName}</h1>
                                <p className="my-3">Raw Material Supplier / {supplierName}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="aboutArchitectProfile">
                    <div className="container py-3">
                        <div className="row mt-3">
                            <div className="col-md-4">
                                    <div className="profilecard">
                                        <Image id="logo" src={blobService.createUrl(profileImage)} errorImg={Upload} alt="" width="100%" height="300px"  />
                                    </div>
                            </div>
                            <div className="col-md-8">
                                <div className="projectInfo">
                                    <span>
                                        <h3 className="mt-3">{supplierName? supplierName : '' }</h3>
                                        <p>{city? city : ""}</p>    
                                     </span> 
                                        {/* <p className="d-flex align-items-center">
                                            <Rating name="read-only" value={3} readOnly className="rating" />
                                            (3 Reviews)
                                        </p> */}
                                        <div>
                                            {(twitterLink == null || twitterLink == "") ?
                                                <a  target="_blank">
                                                    <img  src={Twitterwithround} alt="" />
                                                </a>
                                            :
                                                <a href={ rawMaterialSupplier.socialLinks.twitterLink} target="_blank">
                                                    <img  src={Twitterwithround} alt="" />
                                                </a>
                                             }
                                            {(facebookLink == null || facebookLink == "") ?
                                            <a  target="_blank" className="left-buffer">
                                                <img  src={FBwithround} alt="" />
                                            </a>
                                                :
                                                <a href={ rawMaterialSupplier.socialLinks.facebookLink} target="_blank" className="left-buffer">
                                                    <img  src={FBwithround} alt="" />
                                                </a>
                                            }
                                             {(instagramLink == null || instagramLink ==  "") ?
                                            <a  target="_blank" className="left-buffer">
                                                <img  src={Linkedinwithround} alt="" />
                                            </a>
                                            :
                                            <a href={ rawMaterialSupplier.socialLinks.instagramLink} target="_blank" className="left-buffer">
                                                <img  src={Linkedinwithround} alt="" />
                                            </a>
                                        }
                                               
                                        </div>
                                        <div className="mt-4">
                                            <button className="button1 contact" onClick={(event:any)=>this.onShowModal(event)} >Contact <img src={RightArrow} style={{height:"12px",width:"20px",marginLeft:"0.25rem"}}/></button>    
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-5">
                            <h4>description</h4>
                            <p style={{ lineHeight: '30px' }}>{description?  description : ""}</p>
                        </div>
                            <div className="row">
                                <div className="col-md-12" >
                                    <h4>Raw Materials Available</h4>
                                    {(materialList.length !== 0)?
                                         <div> 
                                         <div className="container ">
                                             <div className="row ">
                                                 <div className="col-md-2 ">
                                                     <h5>Name</h5>
                                                 </div>
                                                 <div className="col-md-2 ">
                                                     <h5>Brand</h5>
                                                 </div>
                                                 <div className="col-md-2 ">
                                                     <h5>Price</h5>  
                                                 </div>
                                                 <div className="col-md-2">
                                                     <h5>Description</h5>
                                                 </div>
                                                 <div className="col-md-2">
                                                     <h5>Location</h5>
                                                 </div>
                                             </div>
                                         </div>
                                                 {selectedValues}
                                     </div>
                                     :
                                     ""
                                    }
                                       
                                </div>
                            </div>
                    </div>
                        <CustomModal isAlert={true} show={showModal} header={header} 
                        body=
                        { 
                        (address.length == 0  && rawMaterialSupplier.mobile == null ) 
                        ? <div><p>Address & Contact Details Are Not Available. </p><p>Email : {rawMaterialSupplier.email}</p></div>  : 
                        <div>{( address.length == 0 || address == null) ? "Address Is Not Available."
                        : <p>Address : {address}</p> }
                      
                        {( rawMaterialSupplier.mobile == null || rawMaterialSupplier.mobile == 'Not available') ?"Contact Details Is Not Available." 
                        : <p>Call on : {rawMaterialSupplier.mobile}</p> }{( rawMaterialSupplier.email == null) ?"Email Id Is Not Available." : <p>Email : {rawMaterialSupplier.email}</p> }</div>
                     }
                       
                           onHide={(event:any)=>this.onHideModal(event)}/>
             </div>
            <Footer/>
            </>
        )
    }
}
export default ViewRawMaterialSupplier;