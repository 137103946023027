import React, { Component } from 'react';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import Footer from '../../../common-components/Footer/Footer';
import Header from '../../../common-components/Header/Header';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import { constants } from '../../../../constants/constants';
import axios from 'axios';

interface VerifyEmailProps {
    name?: any;
    value?: any;
}
interface VerifyEmailState {
    errors: {};
    showUpload: boolean,
    header: string,
    body: string,
}

export default class VerifyEmail extends React.Component<VerifyEmailProps & RouteComponentProps, VerifyEmailState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            errors: {},
            showUpload: false,
            header: '',
            body: '',
        };
        this.state = initialState;
    }
    componentDidMount(){
        let params = queryString.parse(this.props.location.search);
        this.verifyEmail(params.token);
    }
    async verifyEmail(token: any): Promise<any> {
        // const options = {
        //     url: constants.verifyEmailAPI + "?token="+token,
        // };
        // userService
        //     .getApiRequest(options)
        const key = constants.subscriptionKey;
        const url =  constants.verifyEmailAPI + "?token="+token;
        axios.get(url,  { headers: { "Ocp-Apim-Subscription-Key" :key} })
            .then((response: any) => {
                this.openUpload("Congratulations","Your account has been activated and email is verified!");
            })
            .catch((error) => {
                this.openUpload("Failed","Unable to activate your account. Please try again");
            });
    };

    openUpload = async(header:any, body: any) => {
        this.setState({showUpload:true, header: header, body: body});
    }
    onHideUpload = async(event:any) => {
        this.setState({showUpload:false});
        this.props.history.push('/')
    }
    render() {
        const { errors, showUpload, header, body } = this.state;
        return (
            <>
            <Header/>
                <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any)=>this.onHideUpload(event)}/>
            <Footer />
            </>
        )
    }
}
