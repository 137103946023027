import React from 'react';
import Footer from '../../../common-components/Footer/Footer';
import Header from '../../../common-components/Header/Header';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loader from '../../../common-components/Loader';
import Edit from '../../../../assets/images/edit.svg';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import Image from "../../../common-components/Image";
import Delete from "../../../../assets/images/delete.svg"
import { blobService } from '../../../common-components/Blob';
import Upload from "../../../../assets/images/upload.svg"
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import ToggleSwitch from '../../../common-components/ToggleSwitch/ToggleSwitch';
import Check from '../../../Services/Check';
import passwordLock from '../../../../assets/images/passwordLock.svg';



interface ProfileProps {
    name?: any;
    value?: any;
}
interface ProfileState {
    error: boolean;
    ErrMsg: any;
    checked: boolean;
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    isLoading: boolean;
    isEdit: boolean;
    isPublic: boolean;
    projectName: string;
    type: string;
    project: any;
    gender: string;
    houseHoldIncome: string;
    builderType: string,
    measurementUnit: string,
    adLine1: string;
    adLine2: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    errors: {
        oldPassword: string,
        newPassword: boolean,
        confirmNewPassword: string,
        description: string,
        mobile: string,
        adLine1: string,
        adLine2: string,
        customerName: string,
        houseHoldIncome: string,
        file: string,
        newupperCase: boolean,
        newlowerCase: boolean,
        newdigit: boolean,
        newspecialChar: boolean,
        newminLength: boolean,
    };
    cityList: any,
    stateList: any,
    pincodeList: any,
    customer: any;
    customerName: string;
    description: string;
    address: string;
    mobile: string;
    email: string;
    profileImage: string,
    showUpload: boolean,
    header: string,
    body: string,
    newsletter: boolean,
    id: string,
    pageNo: any,
    user: any,
    formAddressData: any,
    formOtherData: any,
    isEnablePassword: boolean,
    showOldPassword: boolean,
    showNewPassword: boolean,
    showConfirmPassword: boolean
}
const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);

export default class Profile extends React.Component<ProfileProps, ProfileState> {

    constructor(props: ProfileProps) {
        super(props);

        const initialState = {
            error: false,
            ErrMsg: "",
            checked: true,
            profileTab: true,
            settingTab: false,
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            isLoading: true,
            isEdit: false,
            isPublic: false,
            projectName: '',
            type: 'text',
            gender: '',
            houseHoldIncome: "",
            project: {},
            projectModal: false,
            projectHeader: '',
            projectBody: '',
            builderType: '',
            measurementUnit: '',
            adLine1: '',
            adLine2: '',
            city: '',
            state: '',
            country: '',
            pincode: '',
            errors: {
                oldPassword: '',
                newPassword: false,
                confirmNewPassword: '',
                description: '',
                mobile: '',
                adLine1: '',
                adLine2: '',
                customerName: '',
                houseHoldIncome: '',
                file: '',
                newupperCase: false,
                newlowerCase: false,
                newdigit: false,
                newspecialChar: false,
                newminLength: false,
            },
            customer: {},
            customerName: '',
            description: '',
            address: '',
            mobile: '',
            email: '',
            profileImage: '',
            showUpload: false,
            header: '',
            body: '',
            newsletter: false,
            id: '',
            pageNo: 0,
            pincodeList: [],
            stateList: [],
            cityList: [],
            user: {},
            formAddressData: [],
            formOtherData: [],
            isEnablePassword: true,
            showOldPassword: false,
            showNewPassword: false,
            showConfirmPassword: false
        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        const urlRegEx = RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        let errors = this.state.errors;
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        switch (name) {
            case 'oldPassword':
                errors.oldPassword = value.length > 0 ? '' : "Please Enter Password";
                break;
            case 'newPassword':
                errors.newupperCase = uppercaseRegExp.test(value) ? true : false;
                errors.newlowerCase = lowercaseRegExp.test(value) ? true : false;
                errors.newdigit = digitsRegExp.test(value) ? true : false;
                errors.newspecialChar = specialCharRegExp.test(value) ? true : false;
                errors.newminLength = minLengthRegExp.test(value) ? true : false;
                break;
            case 'confirmNewPassword':
                errors.confirmNewPassword = this.state.newPassword != value ? 'Make Sure Your Password is Same as New Password' : '';
                break;
            case 'state':
                this.fetchCityList(value);
                break;
            case 'city':
                this.fetchPicodeList(value);
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
        if ((errors.newupperCase &&
            errors.newlowerCase && errors.newdigit &&
            errors.newspecialChar && errors.newminLength &&
            (this.state.confirmNewPassword == this.state.newPassword)
        ) == true && this.state.oldPassword !== '') {
            this.setState({
                isEnablePassword: false
            })
        } else {
            this.setState({
                isEnablePassword: true
            })
        }
    };

    componentDidMount() {
        this.init();
    }

    init() {
        let userId = localStorage.getItem("id");
        this.viewProfile(userId);
        this.viewUser(userId);
        this.fetchStateList();
    }
    async viewUser(id: any): Promise<any> {
        Check.checkToken()
            .then(() => {
                const options = {
                    url: constants.fetchUserAPI + id,
                };
                userService
                    .getApiRequest(options)
                    .then((response) => {
                        let u = response.data;
                        this.setState({
                            user: u,
                            email: u.detail.email,
                            mobile: u.detail.phone,
                        });
                    })
                    .catch((error) => {
                    });
            })
    }
    async fetchStateList(): Promise<any> {
        Check.checkToken()
            .then(() => {
                const options = {
                    url: constants.fetchStatesListAPI,
                };
                userService
                    .getApiRequest(options)
                    .then((response) => {
                        this.setState({ stateList: response.data })

                    })
                    .catch((error) => {
                        this.setState({ ErrMsg: error, error: true });
                    });
            })
    }
    async fetchCityList(stateNm: any): Promise<any> {
        if (stateNm) {
            const options = {
                url: constants.fetchCitiesListAPI + stateNm,
            };
            userService
                .getApiRequest(options)
                .then((response) => {
                    let dataArr: any = [];
                    response.data.map((item: any) => (
                        dataArr.push(item.city)

                    ),
                    )

                    const dataArrWithSet = Array.from(new Set(dataArr));
                    const resultArr = [...dataArrWithSet];
                    this.setState({ cityList: resultArr })
                })
                .catch((error) => {
                    this.setState({ cityList: [] })
                    this.setState({ ErrMsg: error, error: true });
                });
        } else {
            this.setState({ cityList: [] })
        }


    }
    async fetchPicodeList(cityNm: any): Promise<any> {
        const options = {
            url: constants.fetchPincodesListAPI + cityNm,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ pincodeList: response.data })

            })
            .catch((error) => {
                // this.setState({ ErrMsg: error, error:true });
            });

    }

    async viewProfile(id: any): Promise<any> {
        Check.checkToken()
            .then(() => {
                const options = {
                    url: constants.fetchCustomerAPI + id,
                };
                userService
                    .getApiRequest(options)
                    .then((response) => {
                        let b = response.data;
                        this.setState({
                            customer: b,
                            isLoading: false,
                            customerName: b.customerName,
                            description: b.description,
                            adLine1: b.address.addressLine1,
                            adLine2: b.address.addressLine2,
                            city: b.address.city,
                            state: b.address.state,
                            country: b.address.country,
                            pincode: b.address.pincode,
                            profileImage: b.profileImage,
                            houseHoldIncome: b.houseHoldIncome == 0 ? "": b.houseHoldIncome ,
                            formAddressData: b.address,
                            formOtherData: b,
                            gender: b.gender
                        });
                        if (b.address.state !== null && b.address.city !== null) {
                            this.fetchCityList(b.address.state);
                            this.fetchPicodeList(b.address.city);
                        }

                    })
                    .catch((error) => {
                        this.setState({ ErrMsg: error, error: true });
                    });
            })
    }
    updateProfile = async () => {
        Check.checkToken()
            .then(
                async () => {
                    let validity = true;
                    Object.values(this.state.errors).forEach((val: any) => val.length > 0 && (validity = false));
                    if (validity == true) {
                        var b = this.state.customer;
                        b.customerName = this.state.customerName;
                        const options = {
                            url: constants.updateCustomerAPI,
                            data: {
                                "customerId": b.id,
                                "userId": b.userId,
                                "customerName": this.state.customerName,
                                "address": {
                                    "id": b.address.id,
                                    "addressLine1": this.state.adLine1,
                                    "addressLine2": this.state.adLine2,
                                    "city": this.state.city,
                                    "state": this.state.state,
                                    "country": "Australia",
                                    "pincode": this.state.pincode,
                                    "latitude": b.address.latitude,
                                    "longitude": b.address.longitude,
                                    "active": b.address.active
                                },
                                "gender": this.state.gender,
                                "houseHoldIncome": this.state.houseHoldIncome,
                                "profileImage": this.state.profileImage,
                                "portfolioImages": b.portfolioImages,
                                "bannerImages": b.bannerImages,
                                "registrationNo": b.registrationNo,
                                "registeredOn": b.registeredOn,
                                "apn": b.apn,
                                "description": this.state.description,
                                "executiveName": b.executiveName,
                                "executiveProfile": b.executiveProfile,
                                "executiveRole": b.executiveRole,
                                "mobile": this.state.mobile,
                                "office": b.office,
                                "email": b.email,
                                "website": b.website,
                                "socialLinks": {
                                    "id": b.socialLinks.id,
                                    "facebookLink": "",
                                    "instagramLink": "",
                                    "twitterLink": "",
                                    "youtubeLink": "",
                                },
                                "minBudget": 0,
                                "maxBudget": 0,
                                "staffCount": 0,
                                "membership": 0,
                                "createdDate": b.createdDate,
                                "active": b.active
                            }
                        };
                        var response = await userService.updateApiRequest(options);
                        if (response) {
                            const options = {
                                url: constants.updateUserAPI + b.userId,
                                data: {
                                    "fullName": b.fullName,
                                    "userName": b.userName,
                                    "email": this.state.email || b.email,
                                    "phone": this.state.mobile || b.phone,
                                    "emailModified": (this.state.email === b.email) ? false : true,
                                    "phoneModified": (this.state.mobile === b.phone) ? false : true,
                                }
                            };
                            var response = await userService.postApiRequest(options);
                            if (response) {
                                this.openUpload('Success', 'Profile Updated Successfully');
                                this.init();
                                this.edit();
                            } else {
                                this.openUpload('Failed', 'Failed to Update Profile');
                            }

                        } else {
                            this.openUpload('Failed', 'Failed to Update Profile');
                        }
                    } else {
                    }
                }
            )
    };

    hasChangedSinceInitialState = () => {
        const { formAddressData, formOtherData } = this.state;
        return (formAddressData.city === this.state.city &&
            formAddressData.state === this.state.state &&
            formAddressData.pincode == this.state.pincode &&
            formAddressData.addressLine1 === this.state.adLine1 &&
            formAddressData.addressLine2 === this.state.adLine2 &&
            formOtherData.customerName === this.state.customerName &&
            formOtherData.description === this.state.description &&
            formOtherData.houseHoldIncome == this.state.houseHoldIncome &&
            formOtherData.mobile == this.state.mobile &&
            formOtherData.profileImage == this.state.profileImage &&
            formOtherData.gender == this.state.gender
        );
    }

    cancel() {
        this.clearErrorMsg();
        this.init();
        this.edit();

    }
    clearErrorMsg = () => {
        let errors = this.state.errors;
        errors.description = '';
        errors.mobile = '';
        errors.adLine1 = '';
        errors.adLine2 = '';
        errors.customerName = '';
        errors.houseHoldIncome = '';

    }
    edit = () => {
        this.setState({
            isEdit: !this.state.isEdit,
        });
    };

    handleChangeProfile = (event: any) => {

        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'name':
                //  errors.name = value.length == 0 ? 'Please enter valid Name': '';
                break;
            case 'description':
                errors.description = (value.length < 251 || value.length == 0) ? '' : 'You Have Reached Your maximum Limit Of Characters Allowed';
                break;
            case 'houseHoldIncome':
                errors.houseHoldIncome = (value.length > 8  && value.length > 0) ? 'You Have Reached Your maximum Limit Of Characters Allowed' : '';
                break;
            case 'mobile':
                errors.mobile = (Regex.test(value) && value.length > 0) ? '' : 'Please Enter A Valid Number';
                break;
            case 'adLine1':
                errors.adLine1 = value.length > 100 ? 'You Have Reached Your maximum Limit Of Characters Allowed' : '';
                break;
            case 'adLine2':
                errors.adLine2 = value.length > 150 ? 'You Have Reached Your maximum Limit Of Characters Allowed' : '';
                break;
            case 'customerName':
                errors.customerName = value.length > 70 ? 'You Have Reached Your maximum Limit Of Characters Allowed' : '';
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    };
    removeImage = async (event: any) => {
        let id = event?.target.id;
        this.setState({ profileImage: '' })
    }

    openUpload = async (header: any, body: any) => {
        this.setState({ showUpload: true, header: header, body: body });
    }
    onHideUpload = async (event: any) => {
        this.setState({ showUpload: false });
    }

    onFileChange = async (event: any) => {
        let userId = localStorage.getItem("id");
        let id = event?.target.id;
        let file = event.target.files[0];
        const formData = new FormData();
        let errors = this.state.errors;
        if (file) {
            if (file && file.type != 'image/gif' && file && file.type != 'image/jpeg' && file && file.type != 'image/png') {
                errors.file = 'Please upload only proper file type';
                this.setState({ errors: errors });
            } else if (file && file.size > 2000000) {
                errors.file = 'Please upload file below 2MB';
                this.setState({ errors: errors });
            } else {
                errors.file = '';
                formData.append('file', file);
            }
            formData.append('id', userId ? userId : '');
            formData.append('type', "Profile");
            formData.append('file', file);
            const options = {
                url: constants.uploadFilesAPI,
                data: formData
            }
            Check.checkToken();
            const response = await userService.postFileApiRequest(options);
            if (response) {

                this.setState({ profileImage: response });
                this.openUpload("Success", "File Uploaded SuccessFully");
            } else {
                this.setState({ profileImage: '' });
                this.openUpload("Failed", "Failed to Upload File");
            }
        } else {
            // let errors = this.state.errors;
            // errors.file1 = 'Please Select File to Upload';
            // this.setState({errors: errors});
        }
    };
    onNewsletterChange(event: any) {
        this.setState({ newsletter: !this.state.newsletter });
    }

    async changePassword(event: any) {
        event.preventDefault();
        Check.checkToken()
            .then(() => {
                let userId = localStorage.getItem("id");
                let validity = true;
                Object.values(this.state.errors).forEach((val: any) => val.length > 0 && (validity = false));

                if (!this.state.oldPassword || !this.state.newPassword) {
                    return;
                }
                if (validity == true) {
                    const options = {
                        url: constants.resetPasswordAPI + userId,
                        data: {
                            "oldPassword": this.state.oldPassword,
                            "newPassword": this.state.newPassword,
                        }
                    };
                    userService.updateApiRequest(options)
                        .then(response => {
                            this.clearValues();
                            this.openUpload('Success', 'Password Changed Successfully');
                            this.init();
                        })
                        .catch(error => {
                            this.openUpload('Failed to Change Password', error.message);
                        });
                }
            }
            )
    }

    clearValues() {
        this.setState({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        });
    }


    changeTab(index: any) {
        this.setState({
            pageNo: index,
        })
    }

    clickShowPassword = (type: any) => {
        if (type == "showOldPassword") {
            this.setState({
                showOldPassword: !this.state.showOldPassword
            })
        } else if (type == "showNewPassword") {
            this.setState({
                showNewPassword: !this.state.showNewPassword
            })
        } else {
            this.setState({
                showConfirmPassword: !this.state.showConfirmPassword
            })
        }

    }

    render() {
        let {
            errors,
            isEdit,
            isLoading,
            description,
            adLine1,
            adLine2,
            state,
            city,
            country,
            pincode,
            gender,
            houseHoldIncome,
            customer,
            customerName,
            address,
            mobile,
            email,
            profileImage,
            showUpload,
            header,
            body,
            newsletter,
            pageNo,
            pincodeList,
            stateList,
            cityList,
            error, ErrMsg,
            oldPassword,
            newPassword,
            confirmNewPassword,
            isEnablePassword,
            showOldPassword,
            showNewPassword,
            showConfirmPassword
        } = this.state;
        address = adLine1 + ", " + adLine2 + ", " + city + ", " + state + ", " + country + ", " + pincode;
        let isEnabled = true;
        if (this.hasChangedSinceInitialState() == false && customerName !== "" &&
            city !== "" && state !== "" && adLine1 !== "" && adLine2 !== "" &&
            mobile !== "" && mobile !== null  && pincode !== "" && houseHoldIncome !== "" 
        ) {
            isEnabled = false
        }

        return isLoading ? (
            <Loader />
        ) : (
            <>
                <Header />
                <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 profile-offset-md-2  col-12">
                                    <h1>Profile</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(pageNo == 0) &&
                        <>
                            {!isEdit ? (
                                <div className="col-md">
                                    <button
                                        className="button3 contact"
                                        onClick={() => {
                                            this.edit();
                                        }}
                                    >
                                        Edit Profile
                                        <img src={Edit} className="ml-1" />
                                    </button>
                                </div>
                            ) : (
                                <div className="col-md">
                                    <button
                                        className={isEnabled == true ? "button3 contact save-btn disabled-button" : "button3 contact save-btn"}
                                        onClick={() => {
                                            this.updateProfile();
                                        }} disabled={isEnabled} >
                                        Save
                                    </button>
                                    <button
                                        className="button3 contact cancel-btn"
                                        onClick={() => {
                                            this.cancel();
                                        }}>
                                        Cancel
                                    </button>
                                </div>
                            )}
                        </>
                    }
                </div>

                <Tabs className="profile-container" defaultIndex={0} onSelect={(index: any) => this.changeTab(index)} selectedTabClassName="TabProfileTrue">
                    <TabList>
                        <div className="container">
                            <div className="row">
                                <div className="heading">
                                    <Tab
                                        id="profile"
                                        className="title tab-select"
                                    >
                                        Profile
                                    </Tab>
                                    <Tab
                                        id="setting"
                                        className="title left-tab tab-select"
                                    >
                                        Settings
                                    </Tab>
                                </div>
                            </div>
                        </div>
                    </TabList>

                    <TabPanel>
                        <div className="aboutProject">
                            <div className="container ">

                                <div className="row ">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* */}
                                                {isEdit &&
                                                    <>
                                                        {profileImage &&
                                                            <>
                                                                <div className="profilecard">
                                                                    <span>
                                                                        <Image id="logo" src={blobService.createUrl(profileImage)} errorImg={Upload} alt="" width="100%" height="300px" />
                                                                        <Image className="close x-icon tab-select" id="logo" src={Delete} onClick={(event) => { this.removeImage(event) }} width="100%" height="20px" />
                                                                    </span>
                                                                </div>
                                                            </>
                                                            ||
                                                            <>
                                                                <div className="profilecard content-center">
                                                                    <span>
                                                                        <label htmlFor="logo">
                                                                            <span className='cursor'><h6 data-toggle="tooltip" data-html="true" title="Image must be less than 2MB and file types must be png, jpeg,jpg or gif. "><img src={Upload} />Photo</h6></span>
                                                                        </label>
                                                                        <input accept="image/gif, image/jpeg, image/png," id="logo" type="file" style={{ display: "none" }} onChange={(event) => this.onFileChange(event)} width="100%" height="auto" />
                                                                    </span>
                                                                </div>
                                                                {errors.file.length > 0 && <span style={{ color: 'red' }}>{errors.file}</span>}
                                                            </>
                                                        }
                                                    </>
                                                    ||
                                                    <div className="profilecard">
                                                        <span>
                                                            <Image id="logo" src={blobService.createUrl(profileImage)} errorImg={Upload} alt="" width="100%" height="300px" />                                                    </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="projectInfo profileInfo">
                                            {(isEdit === true && (
                                                <>
                                                    <input
                                                        name="customerName"
                                                        type={this.state.type}
                                                        value={customerName}
                                                        autoFocus
                                                        placeholder="Name*"
                                                        onChange={this.handleChangeProfile}
                                                    />
                                                    {errors.customerName.length > 0 && (
                                                        <p style={{ color: 'red' }}>{errors.customerName}</p>
                                                    )}
                                                </>
                                            )) || (
                                                    <span>
                                                        <h4 className="profile-name">{customerName}</h4>
                                                    </span>
                                                )}
                                            <div style={{ marginTop: "15px" }}>
                                                <p className="city-name">{city}</p>
                                            </div>
                                            <div>
                                                {isEdit ? (
                                                    <div className="aboutProject">
                                                        <textarea
                                                            id="about"
                                                            name="description"
                                                            className="px-3"
                                                            placeholder="About Me"
                                                            rows={8}
                                                            cols={50}
                                                            onChange={this.handleChangeProfile}
                                                            value={description}
                                                            required
                                                        />
                                                        {errors.description.length > 0 && (
                                                            <span style={{ color: 'red' }}>{errors.description}</span>
                                                        )}
                                                    </div>

                                                ) : (
                                                    <p className="profile-description">
                                                        {description}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><br />
                        <div className="container profile-about">
                            <h4 className="title">About</h4>
                            <div className="row">
                                <div className="col-md-4" >
                                    <h6>Address</h6>
                                    {(isEdit && (
                                        <div>
                                            <div className="address" >
                                                <input
                                                    type="text"
                                                    name="adLine1"
                                                    className="px-3"
                                                    placeholder="Door No*"
                                                    onChange={this.handleChangeProfile}
                                                    value={adLine1}
                                                    required
                                                />
                                                {errors.adLine1.length > 0 && (
                                                    <p style={{ color: 'red', width: '56%' }}>{errors.adLine1}</p>
                                                )}
                                            </div>
                                            <br />
                                            <div className="adLine2" >
                                                <input
                                                    type="text"
                                                    name="adLine2"
                                                    className="px-3"
                                                    placeholder="Street*"
                                                    onChange={this.handleChangeProfile}
                                                    value={adLine2}
                                                    required
                                                />
                                                {errors.adLine2.length > 0 && (
                                                    <p style={{ color: 'red', width: '56%' }}>{errors.adLine2}</p>
                                                )}
                                            </div>
                                            <br />
                                            <div className="city" >
                                                <div className="state" style={{ display: 'flex' }}>
                                                    <select name="state" id="state" className="py-1" onChange={this.handleChange} value={state} required>
                                                        <option value="">State*</option>
                                                        {stateList.map((item: any) => (
                                                            <option value={item.name} key={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <br />
                                                <select name="city" id="city" className="py-1" onChange={this.handleChange} value={city} required style={{ width: "60%" }}>
                                                    <option value="">City*</option>
                                                    {cityList.map((item: any) => (
                                                        <option value={item} key={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <br />

                                            <div className="pincode" style={{ display: 'flex' }}>
                                                <select name="pincode" id="pincode" className="py-1" onChange={this.handleChange} value={pincode} required>
                                                    <option value="">Postcode*</option>
                                                    {pincodeList.map((item: any) => (
                                                        <option value={item.pincode} key={item.pincode}>
                                                            {item.pincode}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <br />
                                        </div>
                                    )) || (
                                            <div>
                                                <div className="profile-description">{adLine1 ? address : ' '}</div>
                                            </div>
                                        )}{' '}
                                </div>
                                <div className=" col-md-4">

                                    <h6>Email</h6>
                                    <div className="email-section">
                                        <p className="profile-description">{email ? email : " "}</p>
                                    </div>


                                </div>
                                <div className="col-md-4">
                                    <h6>Contact</h6>
                                    {(isEdit && (
                                        <div>
                                            <div className="phoneNumber">
                                                <input
                                                    type="number"
                                                    name="mobile"
                                                    className="px-3"
                                                    placeholder="Phone*"
                                                    value={mobile}
                                                    onChange={this.handleChangeProfile}
                                                    onWheel={ event => (event.target as HTMLElement).blur() }
                                                    maxLength={10}
                                                    onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                                                    
                                                />
                                                {errors.mobile.length > 0 && (
                                                    <p style={{ color: 'red' }}>{errors.mobile}</p>
                                                )}
                                            </div>
                                        </div>
                                    )) || (
                                            <div>
                                                <div className="profile-description">{mobile ? mobile : " "}</div>
                                            </div>
                                        )}
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <h6 className="top-buffer">Gender</h6>
                                    {isEdit &&
                                        <div>
                                            <select name="gender" id="gender" className="px-3 py-1" onChange={this.handleChangeProfile} required value={gender}>
                                                <option value="">Select</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                        ||
                                        <p className="profile-description">{gender ? gender : " "}</p>}
                                </div>
                                <div className="col-md-4">
                                    <h6 className="top-buffer">Household Income*</h6>
                                    {(isEdit && (
                                        <div>
                                            <input
                                                type="number"
                                                name="houseHoldIncome"
                                                className="px-3"
                                                placeholder="Income in $"
                                                value={houseHoldIncome}
                                                onChange={this.handleChangeProfile}
                                                onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                                                onWheel={ event => (event.target as HTMLElement).blur() }
                                            />
                                            {errors.houseHoldIncome.length > 0 && (
                                                <p style={{ color: 'red' }}>{errors.houseHoldIncome}</p>
                                            )}
                                        </div>
                                    )) || (
                                            <div>
                                                <div className="profile-description">{houseHoldIncome}</div>
                                            </div>
                                        )}{' '}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div>
                            <div className="container profile-about">
                                <h4 className="title">Account Preferences</h4>
                                <h6>Newsletters</h6>

                                <ToggleSwitch
                                    id="newsletter"
                                    checked={newsletter}
                                    onChange={(event: any) => this.onNewsletterChange(event)}
                                />

                                <div className="my-4 password-section">
                                    <form>
                                        <div className="password py-1">
                                            <h6>Change Password</h6>
                                            <div className="password search-bar" id="searchBar">
                                                <input
                                                    className="mt-2 password-input search-icon"
                                                    type={showOldPassword ? "text" : "password"}
                                                    name="oldPassword"
                                                    placeholder="Old Password"
                                                    onChange={this.handleChange}
                                                    required
                                                    value={oldPassword}
                                                />
                                                {showOldPassword ? <img className="search-bar-profile__icon" src={require("../../../../assets/images/eyeOpen.svg")} onClick={()=>{this.clickShowPassword("showOldPassword")}}></img>
                                                    :
                                                    <img className="search-bar-profile__icon" src={require("../../../../assets/images/eyeclose.svg")} onClick={()=>{this.clickShowPassword("showOldPassword")}}></img>
                                                }

                                                {errors.oldPassword.length > 0 && (
                                                    <span style={{ color: 'red' }}>{errors.oldPassword}</span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="password py-1 search-bar" id="searchBar">
                                            <input
                                                className="password-input search-icon"
                                                type={showNewPassword ? "text" : "password"}
                                                name="newPassword"
                                                placeholder="New Password"
                                                onChange={this.handleChange}
                                                required
                                                value={newPassword}
                                                onFocus={() => { errors.newPassword = true }}
                                                onBlur={() => { errors.newPassword = false }}
                                            />
                                           {showNewPassword ? <img className="search-bar-profile__icon" src={require("../../../../assets/images/eyeOpen.svg")} onClick={()=>{this.clickShowPassword("showNewPassword")}}></img>
                                                    :
                                                    <img className="search-bar-profile__icon" src={require("../../../../assets/images/eyeclose.svg")} onClick={()=>{this.clickShowPassword("showNewPassword")}}></img>
                                                }
                                            {(errors.newPassword == true) ?
                                                <div className='error-message mt-2 border p-3 '>
                                                    <p className='invalid'><img style={{ marginRight: "10px" }} src={passwordLock} /><b>Your password needs to:</b></p>
                                                    <div className='p-1'>
                                                        <p className={(errors.newupperCase == true ? 'valid' : 'invalid')}>
                                                            <img className={(errors.newupperCase == true ? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Uppercase
                                                        </p>
                                                        <p className={(errors.newlowerCase == true ? 'valid' : 'invalid')}>
                                                            <img className={(errors.newlowerCase == true ? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Lowercase
                                                        </p>
                                                        <p className={(errors.newdigit == true ? 'valid' : 'invalid')}>
                                                            <img className={(errors.newdigit == true ? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one digit
                                                        </p>
                                                        <p className={(errors.newspecialChar == true ? 'valid' : 'invalid')}>
                                                            <img className={(errors.newspecialChar == true ? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Special Characters
                                                        </p>
                                                        <p className={(errors.newminLength == true ? 'valid' : 'invalid')}>
                                                            <img className={(errors.newminLength == true ? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least minimum 8 characters</p>
                                                    </div>
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>

                                        <div className="password py-1 search-bar" id="searchBar">
                                            <input
                                                className="password-input search-icon"
                                                type={showConfirmPassword ? "text" : "password"}
                                                name="confirmNewPassword"
                                                placeholder=" Confirm Password"
                                                onChange={this.handleChange}
                                                required
                                                value={confirmNewPassword}
                                            />
                                             {showConfirmPassword ? <img className="search-bar-profile__icon" src={require("../../../../assets/images/eyeOpen.svg")} onClick={()=>{this.clickShowPassword("showConfirmPassword")}}></img>
                                                    :
                                                    <img className="search-bar-profile__icon" src={require("../../../../assets/images/eyeclose.svg")} onClick={()=>{this.clickShowPassword("showConfirmPassword")}}></img>
                                                }
                                            <br />
                                            {errors.confirmNewPassword.length > 0 && (
                                                <span style={{ color: 'red' }}>{errors.confirmNewPassword}</span>
                                            )}
                                        </div>
                                        <br />
                                        <button className={isEnablePassword ? "change-password-button-disable" : "change-password-button"} disabled={isEnablePassword} onClick={(event: any) => this.changePassword(event)}>Change Password</button>
                                        <br />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs><br /><br />
                <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any) => this.onHideUpload(event)} />
                <Footer />
            </>
        );
    }
}
