import React from 'react';
import './PageNotFound.scss';
import {Link} from "react-router-dom";
 
class PageNotFound extends React.Component {
    state = {  }
    render() { 
        return ( 
            <div className={"main-text"}>
                 {/* <Header/> */}
                 <div className={"mllg--5 p-lg-5 box-section"}>
                <div className="">
                <p className={"not-found-header"}> 
                    404
                </p>
                </div>
               <div className="">
               <p className={"not-found-content"}>
                   <h2 className={"oops"}> Oops! Page not found</h2>
                The page you were looking for couldn't be found. The page could be removed or you misspelled the word while searching for it. Maybe try a search?
                </p>
               </div>
               <div className="button-sctn">
               {/* <div >
                    <input type="text" className={"searchinput"}/>
                    <button className="btn btn-link serch_btn"> <i className="fa fa-search " aria-hidden="true"></i></button>
                </div> */}
                <br></br>
                <Link to="/" className={"mt-2 nav-home-btn button"}>Back to Home</Link>
               </div>
                </div>
            </div>
         );
    }
}
 
export default PageNotFound;