import React from 'react';
import Footer from '../../../common-components/Footer/Footer';
import Header from '../../../common-components/Header/Header';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FBwithround from '../../../../assets/images/FBwithround.svg';
import Linkedinwithround from '../../../../assets/images/Linkedinwithround.svg';
import Twitterwithround from '../../../../assets/images/Twitterwithround.svg';
import Loader from '../../../common-components/Loader';
import Edit from '../../../../assets/images/edit.svg';
import './Profile.scss';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import Image from "../../../common-components/Image";
import Delete from "../../../../assets/images/delete.svg"
import { blobService } from '../../../common-components/Blob';
import Upload from "../../../../assets/images/upload.svg"
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import ToggleSwitch from '../../../common-components/ToggleSwitch/ToggleSwitch';
import {Link} from 'react-router-dom'
interface ProfileProps {
    name?: any;
    value?: any;
}
interface ProfileState {
    checked: boolean;
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    isLoading: boolean;
    isEdit: boolean;
    isPublic: boolean;
    projectName: string;
    type: string;
    project: any;
    measurementUnit: string,
    adLine1: string;
    adLine2: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    errors: {
        oldPassword: string,
        newPassword: string,
        confirmNewPassword: string
    };
    stateList: any,
    pincodeList: any,
    cityList:any,
    twitterLink: string,
    facebookLink: string,
    instagramLink: string,
    youtubeLink: string,
    townplanner: any;
    townPlannerName: string;
    about: string;
    address: string;
    mobile: string;
    email: string;
    profileImage: string,
    showUpload: boolean,
    header: string,
    body: string,
    newsletter: boolean,
    id: string,
    alternativeMobile: string,
    alternativeEmail: string,
    pageNo: any,
    user: any
}

export default class Profile extends React.Component<ProfileProps, ProfileState> {

    constructor(props: ProfileProps) {
        super(props);

        const initialState = {
            checked: true,
            profileTab: true,
            settingTab: false,
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            isLoading: true,
            isEdit: false,
            isPublic: false,
            projectName:'',
            type: 'text',
            project: {},
            projectModal: false,
            projectHeader: '',
            projectBody: '',
            measurementUnit:'',
            adLine1: '',
            adLine2: '',
            city: '',
            state: '',
            country: '',
            pincode: '',
            errors: {
                oldPassword:'',
                newPassword:'',
                confirmNewPassword:''
            },
            twitterLink: '',
            facebookLink: '',
            instagramLink: '',
            youtubeLink: '',
            townplanner:{},
            townPlannerName:'',
            about:'',
            stateList:[],
            pincodeList:[],
            cityList:[],
            address: '',
            mobile: '',
            email: '',
            profileImage:'',
            showUpload: false,
            header: '',
            body: '',
            newsletter: false,
            id: '',
            alternativeMobile:'',
            alternativeEmail:'',
            pageNo: 0,
            user: {}
        };

        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;

        let errors = this.state.errors;
        switch (name) {
            case 'oldPassword':
                errors.oldPassword = value.length < 8 ? 'Password must be 8 characters long' : '';
                break;
            case 'newPassword':
                errors.newPassword = value.length < 8 ? 'Password must be 8 characters long ' : '';
                break;
            case 'confirmNewPassword':
                errors.confirmNewPassword = this.state.newPassword != value ? 'Make Sure Your Password is Same as New Password' : '';
                break;
            case 'state':
                this.fetchCityList(value);
                break;
            case 'city':
                this.fetchPicodeList(value);
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    };

    componentDidMount() {
        this.init();
    }

    init() {
        let userId = localStorage.getItem("id");
        this.viewProfile(userId);
        this.viewUser(userId);
        this.fetchStateList();
    }
    async viewUser(id: any): Promise<any> {
        const options = {
            url: constants.fetchUserAPI + id,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let u = response.data;
                this.setState({ 
                    user: u, 
                    email: u.detail.email,
                    mobile: u.detail.phone,
                });
            })
            .catch((error) => {

            });
    }
    async fetchStateList(): Promise<any> {
        const options = {
            url: constants.fetchStatesListAPI,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({stateList: response.data})
                
            })
            .catch((error) => {

            });
    }
    async fetchCityList(stateNm: any): Promise<any> {
        if (stateNm) {
            const options = {
                url: constants.fetchCitiesListAPI + stateNm,
            };
            userService
                .getApiRequest(options)
                .then((response) => {
                    let dataArr: any = [];
                    response.data.map((item: any) => (
                        dataArr.push(item.city)
                    
                   ),
                   )
                      
                    const dataArrWithSet = Array.from(new Set(dataArr));
                    const resultArr = [... dataArrWithSet];
                    this.setState({ cityList: resultArr })
                })
        } else {
            this.setState({ cityList: [] })
        }

    }
    async fetchPicodeList(cityNm:any): Promise<any> {
        const options = {
            url: constants.fetchPincodesListAPI+cityNm,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({pincodeList: response.data})
                
            })
            .catch((error) => {
            });
    }
    
    
    async viewProfile(id: any): Promise<any> {
        const options = {
            url: constants.fetchTownPlannerAPI + id,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let b = response.data;
                this.setState({ 
                    townplanner: b, 
                    isLoading: false,
                    townPlannerName: b.townPlannerName,
                    about: b.about,
                    adLine1: b.address.addressLine1,
                    adLine2: b.address.addressLine2,
                    city: b.address.city,
                    state: b.address.state,
                    country: b.address.country,
                    pincode: b.address.pincode,
                    alternativeEmail: b.alternativeEmail,
                    alternativeMobile: b.alternativeMobile,
                    twitterLink: b.socialLinks.twitterLink,
                    facebookLink: b.socialLinks.facebookLink,
                    instagramLink: b.socialLinks.instagramLink,
                    youtubeLink: b.socialLinks.youtubeLink,
                    profileImage: b.profileImage
                });
                if(b.address.state !== null && b.address.city !== null){
                    this.fetchCityList(b.address.state);
                    this.fetchPicodeList(b.address.city);
                }
               
                
                
            })
            .catch((error) => {
            });
    }
    updateProfile = async () => {
        let validity = true;
        Object.values(this.state.errors).forEach((val: any) => val.length > 0 && (validity = false));
        if (validity == true) {
            var b = this.state.townplanner;
            var u = this.state.user;
            b.townPlannerName = this.state.townPlannerName;
            const options = {
                url: constants.updateTownPlannerAPI,
                data : {
                    "userId": b.userId,
                    "townPlannerId": b.id,
                    "townPlannerName": this.state.townPlannerName || b.townPlannerName,
                    "address": {
                      "id": b.address.id,
                      "addressLine1": this.state.adLine1 || b.address.addressLine1,
                      "addressLine2": this.state.adLine2|| b.address.addressLine2,
                      "city": this.state.city || b.address.city,
                      "state": this.state.state || b.address.state,
                      "country": this.state.country || b.address.country,
                      "pincode": this.state.pincode || b.address.pincode,
                      "latitude": b.address.latitude,
                      "longitude": b.address.longitude,
                      "active": b.address.active
                    },
                    "profileImage": this.state.profileImage || b.profileImage,
                    "about": this.state.about || b.about,
                    "alternativeMobile": this.state.alternativeMobile || b.alternativeMobile,
                    "alternativeEmail": this.state.alternativeEmail || b.alternativeEmail,
                    "website": b.website,
                    "socialLinks": {
                        "id": b.socialLinks.id,
                        "facebookLink": this.state.facebookLink || b.socialLinks.facebookLink,
                        "instagramLink": this.state.instagramLink || b.socialLinks.instagramLink,
                        "twitterLink": this.state.twitterLink || b.socialLinks.twitterLink,
                        "youtubeLink": this.state.youtubeLink || b.socialLinks.youtubeLink,
                    },
                    "active": b.active
                  }
            };
            var response = await userService.updateApiRequest(options);
            if (response) {
                const options = {
                    url: constants.updateUserAPI+b.userId,
                    data: {
                        "fullName": u.fullName,
                        "userName": u.userName,
                        "email": this.state.email || u.email,
                        "phone": this.state.mobile || u.phone,
                        "emailModified": (this.state.email === u.email) ? false : true,
                        "phoneModified": (this.state.mobile === u.phone) ? false : true,
                      }
                };
                var response = await userService.postApiRequest(options);
                if (response) {
                    this.openUpload('Success', 'Profile Updated Successfully');
                    this.init();
                    this.edit();
                }else {
                    this.openUpload('Failed', 'Failed to Update Profile');
                }

            } else {
                this.openUpload('Failed', 'Failed to Update Profile');
            }
        } else {
        }
    };

    cancel() {
        this.init();
        this.edit();
    }
    edit = () => {
        this.setState({
            isEdit: !this.state.isEdit,
        });
    };

    handleChangeProfile = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'name':
                //  errors.name = value.length == 0 ? 'Please enter valid Name': '';
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    };
    removeImage = async(event:any) => {
        let id = event?.target.id;
        this.setState({profileImage:''})
    }

    openUpload = async(header:any, body: any) => {
        this.setState({showUpload:true, header: header, body: body});
    }
    onHideUpload = async(event:any) => {
        this.setState({showUpload:false});
    }

    onFileChange = async (event: any) => {
        let userId = localStorage.getItem("id");
        let id = event?.target.id;
        let file = event.target.files[0];
        const formData = new FormData();
        
        if(file){
            formData.append('id', userId?userId:'');
            formData.append('type', "Profile");
            formData.append('file', file);   
            const options = {
                url: constants.uploadFilesAPI,
                data: formData
            }
            const response = await userService.postFileApiRequest(options);
                if(response){
                    this.setState({profileImage: response});
                    this.openUpload("Success", "File Uploaded SuccessFully");
                }else{
                    this.setState({profileImage: ''});
                    this.openUpload("Failed", "Failed to Upload File");
                }
        }else{
            // let errors = this.state.errors;
            // errors.file1 = 'Please Select File to Upload';
            // this.setState({errors: errors});
        }
    };
    onNewsletterChange(event:any){
        this.setState({newsletter: !this.state.newsletter});
    }

    async changePassword(event:any){
        event.preventDefault();
        let userId = localStorage.getItem("id");
        let validity = true;
        Object.values(this.state.errors).forEach((val: any) => val.length > 0 && (validity = false));

        if(!this.state.oldPassword || !this.state.newPassword){
            return;
        }
        if (validity == true) {
            const options = {
                url: constants.resetPasswordAPI + userId,
                data: {
                    "oldPassword": this.state.oldPassword,
                    "newPassword": this.state.newPassword,
                }
            };
            await userService.updateApiRequest(options)
                .then(response=>{
                    this.clearValues();
                    this.openUpload('Success', 'Password Changed Successfully');
                    this.init();
                })
                .catch(error=>{
                    this.openUpload('Failed to Change Password', error.message);
                });
        }
    }

    clearValues(){
        this.setState({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        });
    }

    changeTab(index:any){
        this.setState({
            pageNo: index
        })
    }

    render() {
        let {
            errors,
            isEdit,
            isLoading,
            about,
            adLine1,
            adLine2,
            state,
            city,
            country,
            pincode,
            twitterLink,
            facebookLink,
            instagramLink,
            youtubeLink,
            townplanner,
            townPlannerName,
            address,
            mobile,
            email,
            profileImage,
            showUpload,
            header,
            body,
            newsletter,
            alternativeEmail,
            alternativeMobile,
            stateList,
            pincodeList,
            cityList,
            pageNo,
            oldPassword,
            newPassword,
            confirmNewPassword,
        } = this.state;
        address = adLine1+", "+adLine2+", "+city+", "+state+", "+country+", "+pincode;

        return isLoading ? (
            <Loader />
        ) : (
            <>
                <Header />  
                <div className="bg-img">                   
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-2 col-12">
                                    <h1>Profile</h1>
                                </div>
                                {pageNo == 0 &&
                                <>
                       
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    {!isEdit ? (
                                    <div className="col-md">
                                        <button
                                            className="button3 contact"
                                            onClick={() => {
                                                this.edit();
                                            }}
                                        >
                                            Edit Profile
                                            <img src={Edit} className="ml-1" />
                                        </button>
                                    </div>
                                ) : (
                                    <div className="col-md">
                                        <button
                                            className="button3 contact save-btn"
                                            onClick={() => {
                                                this.updateProfile();
                                            }}>
                                            Save
                                        </button>
                                        <button
                                            className="button3 contact cancel-btn"
                                            onClick={() => {
                                                this.cancel();
                                            }}>
                                            Cancel
                                        </button>
                                    </div>
                                )}
                </div>

                <Tabs defaultIndex={0} onSelect={(index:any) => this.changeTab(index)} selectedTabClassName="TabProfileTrue">
                    <TabList>
                        <div className="container">
                            <div className="row">
                                <div className="heading">
                                    <Tab
                                        id="profile"
                                        className="title tab-select"
                                    >
                                        Profile
                                    </Tab>
                                    <Tab
                                        id="setting"
                                        className="title left-tab tab-select"
                                    >
                                        Settings
                                    </Tab>
                                </div>
                            </div>
                        </div>
                    </TabList>

                    <TabPanel>
                        <div className="aboutProject">
                            <div className="container ">
                             
                                <div className="row ">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                 {/* */}
                                                 {isEdit &&
                                                    <>
                                                    {profileImage && 
                                                        <>
                                                        <div className="builder-logo">
                                                            <span>
                                                                <Image id="logo" src={blobService.createUrl(profileImage)} errorImg={Upload} alt="" width="100%" height="300px"  />
                                                                <Image className="close x-icon tab-select" id="logo" src={Delete} onClick={(event)=>{this.removeImage(event)}}  width="100%" height="20px"/>
                                                            </span>
                                                        </div>
                                                        </>
                                                        || 
                                                        <>
                                                         <div className="builder-logo content-center">
                                                         <span>
                                                            <label htmlFor="logo">
                                                                <span><h6><img src={Upload}/>Logo</h6></span>
                                                            </label>
                                                            <input id="logo" type="file" style={{display:"none"}}  onChange={(event)=>this.onFileChange(event)} width="100%" height="300px"/>
                                                            </span>
                                                         </div>
                                                        </>
                                                        }
                                                    </>
                                                 ||
                                                <div className="profilecard">
                                                    <span>
                                                        <Image id="logo" src={blobService.createUrl(profileImage)} errorImg={Upload} alt="" width="100%" height="300px" />
                                                    </span>
                                                </div>
                                                 }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="projectInfo">
                                            {(isEdit === true && (
                                                <input
                                                    name="townPlannerName"
                                                    type={this.state.type}
                                                    value={townPlannerName}
                                                    autoFocus
                                                    placeholder="Name"
                                                    onChange={this.handleChangeProfile}
                                                />
                                            )) || (
                                                <span>
                                                    <h4>{townPlannerName}</h4>
                                                </span>
                                            )}
                                            {(isEdit === true && (
                                                 <div>
                                                    <input
                                                        className="mt-2"
                                                        name="city"
                                                        type={this.state.type}
                                                        value={city}
                                                        placeholder="City"
                                                        onChange={this.handleChangeProfile}
                                                    />
                                                 </div>
                                            )) || (
                                                <p className="city-name">{city}</p>
                                            )}
                                            

                                            <div>
                                                {isEdit ? (
                                                    <div className="aboutProject">
                                                        <textarea
                                                            id="about"
                                                            name="about"
                                                            className="px-3"
                                                            placeholder="About Me"
                                                            rows={8}
                                                            cols={50}
                                                            onChange={this.handleChangeProfile}
                                                            value={about}
                                                            required
                                                        />
                                                    </div>
                                                ) : (
                                                    <p className="light-content" style={{ lineHeight: '30px' }}>
                                                        {about}
                                                    </p>
                                                )}
                                            </div>
                                            {isEdit===true &&
                                        <div>
                                            <br/>
                                        <div>
                                            <img src={Twitterwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="twitterLink"
                                                type={this.state.type}
                                                value={twitterLink}
                                                placeholder="Twitter Link"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <br/>
                                        <div>
                                            <img  src={FBwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="facebookLink"
                                                type={this.state.type}
                                                value={facebookLink}
                                                placeholder="Facebook Link"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <br/>
                                        <div>
                                            <img  src={Linkedinwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="instagramLink"
                                                type={this.state.type}
                                                value={instagramLink}
                                                placeholder="LinkedIn Link"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <br/>
                                        </div>
                                        ||
                                        <div>
                                            {(twitterLink == null || twitterLink == "") ?
                                                <a  target="_blank">
                                                    <img  src={Twitterwithround} alt="" />
                                                </a>
                                            :
                                                <a href={townplanner.socialLinks.twitterLink} target="_blank">
                                                     <img  src={Twitterwithround} alt="" />
                                                </a>
                                            }
                                            {(facebookLink == null || facebookLink == "") ?
                                                <a  target="_blank" className="left-buffer">
                                                    <img  src={FBwithround} alt="" />
                                                </a>
                                            :
                                                <a href={townplanner.socialLinks.facebookLink} target="_blank" className="left-buffer">
                                                    <img  src={FBwithround} alt="" />
                                                </a>
                                            }
                                            {(instagramLink == null || instagramLink == "") ?
                                                <a  target="_blank" className="left-buffer">
                                                    <img  src={Linkedinwithround} alt="" />
                                                </a>
                                            :
                                                <a href={townplanner.socialLinks.instagramLink} target="_blank" className="left-buffer">
                                                        <img  src={Linkedinwithround} alt="" />
                                                </a>
                                            }
                                        </div>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><br/>
                        <div className="container">
                            <h4 className="title">About</h4>
                            <div className="row">
                                <div className="col-md-4" >
                                    <h6>Address</h6>
                                    {(isEdit && (
                                        <div>
                                            <div className="address" style={{display:'flex'}}>
                                                <input
                                                    type="text"
                                                    name="adLine1"
                                                    className="px-3"
                                                    placeholder="Door No"
                                                    onChange={this.handleChangeProfile}
                                                    value={adLine1}
                                                    required
                                                />
                                                
                                            </div>
                                            <br/>
                                            <div className="adLine2" style={{display:'flex'}}>
                                            <input
                                                    type="text"
                                                    name="adLine2"
                                                    className="px-3"
                                                    placeholder="Street"
                                                    onChange={this.handleChangeProfile}
                                                    value={adLine2}
                                                    required
                                                />
                                            </div>
                                            <br />
                                            <div className="state" style={{display:'flex'}}>
                                                <select name="state" id="state"  className="py-1" onChange={this.handleChange} value={state} required>
                                                        <option value="">State</option>
                                                        {stateList.map((item:any) => (
                                                            <option value={item.name} key={item.name}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                            <br/>
                                            <div className="city" style={{display:'flex'}}>
                                                {/* <input
                                                    type="text"
                                                    name="city"
                                                    className="px-3"
                                                    placeholder="City"
                                                    onChange={this.handleChangeProfile}
                                                    value={city}
                                                    required
                                                /> */}
                                               <select name="city" id="city" className="py-1" onChange={this.handleChange} value={city} required style = {{width: "60%"}}>
                                                    <option value="">City</option>
                                                    {/* {cityList.map((item: any) => (
                                                        <option value={item.city} key={item.city}>
                                                            {item.city}
                                                        </option>
                                                    ))} */}
                                                    {cityList.map((item: any) => (
                                                        <option value={item} key={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <br />
                                            <div className="pincode" style={{display:'flex'}}>
                                                <select name="country" id="country"  className="py-1" onChange={this.handleChange} value={country} required>
                                                    <option value="">Country</option>
                                                    <option value="Australia">Australia</option>
                                                </select>
                                                <span className="left-buffer" />
                                                <select name="pincode" id="pincode"  className="py-1" onChange={this.handleChange} value={pincode} required>
                                                    <option value="">Postcode</option>
                                                    {pincodeList.map((item:any) => (
                                                        <option value={item.pincode} key={item.pincode}>
                                                        {item.pincode}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <br />
                                        </div>
                                    )) || (
                                        <div>
                                            <div className="light-content">{adLine1 ? address: '-'}</div>
                                        </div>
                                    )}{' '}
                                    <br />
                                </div>
                                <div className="col-md-3">
                                <h6>Email</h6>
                                    <p className="light-content">{email?email:"-"}</p>
                                    <br/>
                                    <h6>Alternate Email</h6>
                                    {(isEdit && (
                                            <div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="alternativeEmail"
                                                        className="px-3"
                                                        placeholder="Alternate Email"
                                                        value={alternativeEmail}
                                                        onChange={this.handleChangeProfile}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        )) || (
                                            <div>
                                                <div className="light-content">{alternativeEmail ? alternativeEmail :"-"}</div>
                                            </div>
                                        )}{' '}
                                </div>

                 
                                <div className="col-md-4">
                                        <h6>Contact</h6>
                                        {(isEdit && (
                                            <div>
                                                <div className="phoneNumber">
                                                    <input
                                                        type="number"
                                                        name="mobile"
                                                        className="px-3"
                                                        placeholder="Phone"
                                                        value={mobile}
                                                        onChange={this.handleChangeProfile}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        )) || (
                                            <div>
                                                <div className="light-content">{mobile ? mobile :"-"}</div>
                                            </div>
                                        )}{' '}
                                        <br />
                                        <h6>Alternate Contact</h6>
                                        {(isEdit && (
                                            <div>
                                                <div className="phoneNumber">
                                                    <input
                                                        type="number"
                                                        name="alternativeMobile"
                                                        className="px-3"
                                                        placeholder="Phone"
                                                        value={alternativeMobile}
                                                        onChange={this.handleChangeProfile}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        )) || (
                                            <div>
                                                <div className="light-content">{alternativeMobile ? alternativeMobile :"-"}</div>
                                            </div>
                                        )}{' '}
                                    </div>                           
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div>
                            <div className="container">
                                <h4 className="title">Account Preferences</h4>
                                <h6>Newsletters</h6>

                                <ToggleSwitch
                                        id="newsletter"
                                        checked={newsletter}
                                        onChange={(event:any)=>this.onNewsletterChange(event)}
                                        />
                                       
                                <div className="my-4">
                                    <form>
                                        <div className="password py-1">
                                            <h6>Change Password</h6>
                                            <input
                                                className="mt-2"
                                                type="password"
                                                name="oldPassword"
                                                style={{ width: '350px', fontStyle: 'italic', fontSize: '15px' }}
                                                placeholder="Old Password"
                                                onChange={this.handleChange}
                                                required
                                                value = {oldPassword}
                                            />
                                            <br />
                                            {errors.oldPassword.length > 0 && (
                                                <span style={{ color: 'red' }}>{errors.oldPassword}</span>
                                            )}
                                        </div>
                                        
                                        <div className="password py-1">
                                            <input
                                                type="password"
                                                name="newPassword"
                                                placeholder="New Password"
                                                onChange={this.handleChange}
                                                required
                                                style={{ width: '350px', fontStyle: 'italic', fontSize: '15px' }}
                                                value = {newPassword}
                                            />
                                            <br />
                                            {errors.newPassword.length > 0 && (
                                                <span style={{ color: 'red' }}>{errors.newPassword}</span>
                                            )}
                                        </div>

                                        <div className="password py-1">
                                            <input
                                                type="password"
                                                name="confirmNewPassword"
                                                style={{ width: '350px', fontStyle: 'italic' }}
                                                placeholder=" Confirm Password"
                                                onChange={this.handleChange}
                                                required
                                                value = {confirmNewPassword}
                                            />
                                            <br />
                                            {errors.confirmNewPassword.length > 0 && (
                                                <span style={{ color: 'red' }}>{errors.confirmNewPassword}</span>
                                            )}
                                        </div>
                                        <br/><br/>
                                        <button className="hire" onClick={(event:any)=>this.changePassword(event)}>Change Password</button>
                                        <br />
                                        <br />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs><br/><br/>
                
                {/* <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-12">
                    <h1 className="para-text">Plan</h1>
                    <div style={{display:"flex"}} >
                    <div className="button-one first-section">
                        <h2 className="para-text mt-5">Free</h2>
                        <h2 className="para-text"><CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h2>
                        <p className="month-text">per month</p>
                        <button className="plan-button">Current Plan</button>
                    </div>
                    <div className="button-one second-section">
                    <h2 className="para-text font mt-5">Free</h2>
                    <h2 className="para-text font"><CurrencyFormat value={50} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h2>
                        <p className="month-text font">per month</p>
                        <button className="plan-button-two  font">Choose Plan</button>
                    </div>
                    </div>
                </div></div></div>
                <br/><br/> */}

                <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any)=>this.onHideUpload(event)}/>
                <Footer />
            </>
        );
    }
}
