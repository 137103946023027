import React, { Component } from 'react';
import Header from '../../../../common-components/Header/Header';
import Footer from '../../../../common-components/Footer/Footer';
import OwnaHouse from '../../../../../assets/images/OwnaHouse.png';
import { Link } from 'react-router-dom';

export default class OwningAHouse extends React.Component {

    render() {
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-12 ">
                                <h1  className="ourMissionHead">Blog</h1>
                                <p style = {{fontSize: "14px"}} className="my-3"><Link to="/Blog" className="link-text">Blogs</Link> / OWNING A DREAM HOME OVER A RENT HOUSE</p>
                            </div>             
                            </div>
                        </div>
                    </div>
                </div>
            <br/><br/>
            <div className='container blog-container p-3'>     
                   <div className='blog-box  '>
                        <h3 style={{color: "#033b64 "}}>OWNING A DREAM HOME OVER A RENT HOUSE </h3>
                        <img style = {{width: "50%" , height: "40%"}} src={OwnaHouse}/>
                        <div  className=' mt-3'>
                            <p>
                                It is everybody’s vision to own a house.
                                We all keep a part of our savings that will be invested on building a
                                dream home soon.
                                But this creates a question whether we really need to a build a home
                                of our own or simply rent one?
                                Which one is beneficial? A home how big or small it elicits in us a
                                variety of emotions and experiences.
                                A sense of security, comfort, and success are all provided by owning
                                a home.
                            </p>
                            <h4>
                                Let's briefly understand the advantages of constructing an own home.
                            </h4>
                            <ul>
                                <li>
                                    1. Security Although investing in your home is a commitment,
                                    the present market shows that investing in a property always produces
                                    financially attractive rewards.
                                    As a result, your house acts as security for you and your loved ones in
                                    the future. 
                                </li>
                                <li>
                                    2. Savings When you embark on the commitment of home ownership,
                                    you realise how crucial saving money is. This forces us to start saving to cover expenses and plan.
                                </li>
                                <li>
                                    Accomplishment Every time we do something, we are happy with ourselves.
                                    Purchasing a home is no different because you are ensuring the
                                    security of your family.
                                    Additionally, it shows that you have created something on your own,
                                    which gives you a sense of pride. 
                                </li>
                                <li>
                                    4. Tax You must go via a home-loan system that offers numerous
                                    advantages and tax benefits to purchase a home.
                                    There are numerous tax benefits associated with these house loan
                                    repayments, which can be claimed when filing your taxes.
                                </li>
                                <li>
                                    5. Privacy Without anybody else interfering with your routine
                                    housework and upkeep, you may lead a hassle-free existence.
                                    You don't have to follow any landlord's rules because you are your
                                    own owner, and you are free to participate in social interactions. 
                                </li>
                            </ul>
                            <h4>
                                 Now let’s cover the advantages of renting a house. 
                            </h4>
                            <ul>
                                <li>
                                    1. No maintenance cost Renting a house is budget friendly
                                    wherein you are not required to spend on the expenses required to
                                    construct and maintain the house.
                                </li>
                                <li>
                                    2. Provision of easy relocation Rentals can be easily cancelled up to
                                    60 days in advance if you need
                                    to move for work or personal reasons. This is far less labour intensive
                                    than selling your own home,
                                    and renting allows you to pursue career opportunities more easily.
                                </li>
                                <li>
                                    3. Multiple options Within the budget available we can choose from
                                    different types of houses, like single bedroom to multiple
                                    bedrooms,backyard facilities, parking etc.
                                </li>
                                <li>
                                    4. Community The advantage of renting in communities such as
                                    apartments where they have social events in their community rooms
                                    or shared spaces also, encouraging people to socialize and get to
                                    know each other, which helps us to have a sense of security
                                    especially if you are a non-local. 
                                </li>
                                <li>
                                    5. Flexibility in choosing the right location Often the renting of a
                                    house nearer to our workplace or accessible to a metro or public
                                    transport station will reduce the travel time and provides connectivity
                                    to essential areas like hospitals, malls, parks etc. Especially the
                                    crowded cities with heavy traffic. 
                                </li>
                            </ul>
                            <p>
                                So, the conclusion here is completely dependent on individual on or
                                whether he/she is ready have an own house built and have the sense of
                                pride and security for them as well as their future generation or stick
                                to renting a house giving preference to their work and career.
                            </p>
                            <p>
                                With the assistance of the top Australian real estate professionals who are featured on our platform, 
                                you can still achieve the lifestyle inclusions of your dreams regardless of the size of your land.
                            </p>
                            <p>
                                Keeping in mind the cost of real-estate that is growing exponentially Aashray
                                Homes is encouraging everyone to have their own house built from
                                the scratch by giving the clients onboard an exclusive access to top
                                builders, architects and other professionals who can build the house at
                                an affordable price.
                            </p>
                            <p>
                                Our superior customer service and distinctive approach to meeting our clients' needs have elevated Aashray Homes to the top spot in Melbourne's real estate market.
                                We believe that being a reliable name in the housing industry is insufficient,
                                 therefore we have added extra benefits to make your experience even more enjoyable.
                            </p>    
                        </div>
                       
                    </div>
			</div>
            <Footer/>
            </>
         );
    }
}
