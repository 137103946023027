import React, { Component } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';
import Project1 from '../../../../assets/images/projects1.jpg'
import Project3 from '../../../../assets/images/project3.jpg'
import Project4 from '../../../../assets/images/project4.jpg'
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import Loader from '../../../common-components/Loader';
import Footer from '../../../common-components/Footer/Footer';
import Header from '../../../common-components/Header/Header';
import { blobService } from '../../../common-components/Blob';
import Carousel from "react-bootstrap/Carousel";
import Image from "../../../common-components/Image";
import Delete from "../../../../assets/images/delete.svg"
import Upload from "../../../../assets/images/upload.svg"
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import { RouteComponentProps } from 'react-router';
import Check from '../../../Services/Check';
import './NewBuilderProject.scss';
import ToggleSwitch from '../../../common-components/ToggleSwitch/ToggleSwitch';
import PadLock from "../../../../assets/images/padlock.svg";


interface NewBuilderProjectProps {
    name?: any;
    value?: any;
    closeModal?: any;
}
interface NewBuilderProjectState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    Images: any[],
    errors: {
        description: string,
        projectName: string,
        adLine1: string,
        adLine2: string,
        landSize: string,
        mobile : string,
        cost: string
    };
    project: any,

    type: string,
    projectName: string,
    description: string,
    adLine1: string,
    adLine2: string,
    city: string,
    state: string,
    country: string,
    pincode: string,
    mobile: string,
    landSize: string,
    measurementUnit: string,
    projectImages: any[],
    nextIcon: any,
    prevIcon: any,
    showUpload: boolean,
    header: string,
    body: string,
    id: string,
    cost: string,
    stateList: any,
    pincodeList: any,
    cityList: any,
    nextDisabledIcon: any,
    prevDisabledIcon: any,
    isPublic: boolean,
    membership: boolean,
    subProjectType: string,
    projectType: string,
    projectTypeId: string,
    
}
const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
export default class NewBuilderProject extends React.Component<NewBuilderProjectProps & RouteComponentProps, NewBuilderProjectState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            isLoading: false,
            Images: [
                Project1, Project3, Project4, Project1
            ],
            errors: {
                description: '',
                projectName: '',
                adLine1: '',
                adLine2: '',
                landSize: '',
                mobile : '',
                cost: ''
            },
            project: {},

            type: 'text',
            projectName: "",
            description: "",
            adLine1: "",
            adLine2: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
            mobile: "",
            landSize: "",
            measurementUnit: "Square Meter",
            projectImages: [],
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            nextDisabledIcon: <span className="icon-button-disabled "><img src={RightArrow}></img></span>,
            prevDisabledIcon: <span className="icon-button-disabled "><img src={LeftArrow}></img></span>,
            showUpload: false,
            header: '',
            body: '',
            id: '',
            cost: '',
            stateList: [],
            pincodeList: [],
            cityList:[],
            error:false,
            ErrMsg:"",
            isPublic: true,
            membership: true,
            subProjectType: '',
            projectType: "",
            projectTypeId: "",
        };
        this.state = initialState;
    }
    componentDidMount() {
        this.props.location.state.data.map((items:any)=>{
            this.setState({     
                subProjectType:  items.projectSubtype,
                projectType: items.projectType,
                projectTypeId: items.id,
            })
        })
        this.setState({
            isPublic:  this.props.location.state.publicaData
        })
        this.fetchStateList();
        // this.checkMemberShip();
    }
    async fetchStateList(): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchStatesListAPI,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ stateList: response.data })

            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        }
        )
    }
    async fetchCityList(stateNm: any): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        if (stateNm) {
            const options = {
                url: constants.fetchCitiesListAPI + stateNm,
            };
            userService
                .getApiRequest(options)
                .then((response) => {
                    let dataArr: any = [];
                    response.data.map((item: any) => (
                        dataArr.push(item.city)
                    
                   ),
                   )
                      
                    const dataArrWithSet = Array.from(new Set(dataArr));
                    const resultArr = [... dataArrWithSet];
                    this.setState({ cityList: resultArr })
                })
                .catch((error) => {
                    this.setState({ cityList: [] })
                    this.setState({ ErrMsg: error, error:true });
                });
        } else {
            this.setState({ cityList: [] })
        }

    }
        )

    }
    async fetchPicodeList(cityNm: any): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        if (cityNm) {
            const options = {
                url: constants.fetchPincodesListAPI + cityNm,
            };
            userService
                .getApiRequest(options)
                .then((response) => {
                    this.setState({ pincodeList: response.data })
                })
                .catch((error) => {
                    this.setState({ pincodeList: [] })
                    this.setState({ ErrMsg: error, error:true });
                });
        } else {
            this.setState({ pincodeList: [] })
        }
    }
    )
    }
    

    cancelProject() {
        this.props.history.push('/MyBuilderProjects')
       // this.props.closeModal(this.state);
    }

    saveProject = async () => {
        const { projectName, description, adLine1, adLine2, city, state, pincode, mobile, landSize, cost}:any = this.state;

        if(projectName!==""&&description!==""&&adLine1!==""&&adLine2!==""&&city!==""&&state!=="" && pincode!=="" && mobile!== ""&& landSize!== "" &&cost!== ""){
        Check.checkToken()
        .then(
            async() => {
        let userId = localStorage.getItem("id");
        let userType = localStorage.getItem("userType");
        let validity = true;
        Object.values(this.state.errors).forEach(
            (val: any) => val.length > 0 && (validity = false)
        );
        if (validity == true) {
            var p = this.state.project;
            p.name = this.state.projectName;

            const options = {
                url: constants.updateProjectAPI,
                data: {
                    "name": this.state.projectName,
                    "description": this.state.description,
                    "address": {
                        "addressLine1": this.state.adLine1,
                        "addressLine2": this.state.adLine2,
                        "city": this.state.city,
                        "state": this.state.state,
                        "country": "Australia",
                        "pincode": this.state.pincode,
                        "latitude": 0,
                        "longitute": 0
                    },
                    "mobile": this.state.mobile,
                    "projectDetails": {
                        "builderType": "Custom Builder", //Check
                        "landSize": this.state.landSize,
                        "measurementUnit": this.state.measurementUnit,
                        "projectDocs": {
                            "landSaleContract": "",
                            "architectDrawings": "",
                            "engineeringPlans": "",
                            "landscapingDesigns": ""
                        },
                        "projectImages": this.state.projectImages
                    },
                    "projectType": {
                        "id": this.state.projectTypeId, 
                        "projectType": this.state.projectType, 
                        "projectSubtype": this.state.subProjectType
                        },
                    "cost": this.state.cost,
                    "minBudget": 0,
                    "maxBudget": 0,
                    "durationInMonths": 0,
                    "publicProject": this.state.isPublic,
                    "createdBy": userId,
                    "typeOfProject": "Sale"
                }
            }
            var response = await userService.postApiRequest(options);

            if (response) {
                this.openUpload("Success", "Project Created Successfully And Went For Verification");
                //this.props.closeModal(this.state);
            }
            else {
                this.openUpload("Failed", "Failed to Create Project");
            }

        } else {
            }
        }
        )
    }
    }
    removeImage = async (event: any, path: string) => {
        let pImages = this.state.projectImages;
        let id = event?.target.id;
        const i = pImages.indexOf(path);
        if (i > -1) {
            pImages.splice(i, 1);
        }
        this.setState({
            projectImages: pImages,
        })
    }
    openUpload = async (header: any, body: any) => {
        this.setState({ showUpload: true, header: header, body: body });
    }
    onHideUpload = async (event: any) => {
        this.setState({ showUpload: false });
        this.props.history.push('/MyBuilderProjects')
    }

    onFileChange = async (event: any) => {
        let userId = localStorage.getItem("id");
        let id = event?.target.id;
        let file = event.target.files[0];
        const formData = new FormData();
        formData.append('id', userId ? userId : '');
        formData.append('type', "Project");
        //Check
        //  formData.append('projectId', "28");
        formData.append('file', file);

        if (file) {
            if (id === "projectImages") {
                const options = {
                    url: constants.uploadFilesAPI,
                    data: formData
                }
                Check.checkToken();
                const response = await userService.postFileApiRequest(options);
                if (response) {
                    this.state.projectImages.push(response);
                    this.setState({ projectImages: this.state.projectImages });
                    // this.openUpload("Success", "File Uploaded SuccessFully");
                } else {
                    // this.openUpload("Failed", "Failed to Upload File");
                }

            } else {
            }

        } else {
            // let errors = this.state.errors;
            // errors.file1 = 'Please Select File to Upload';
            // this.setState({errors: errors});
        }

    };
    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'projectName':
                    errors.projectName = value.length == 255 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
                break;
            case 'description':
                    errors.description = value.length == 255 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'adLine1':
                    errors.adLine1 = value.length == 255 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'adLine2':
                    errors.adLine2 = value.length == 255 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'landSize':
                    errors.landSize = value.length > 255 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'cost':
                    errors.cost = value.length > 10 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'mobile':
                    errors.mobile = Regex.test(value) ? '' :  'Please Enter A Valid Number' ;
                break;
            case 'password':
                //  errors.password = value.length < 8 ? 'Password must be 8 characters long': '';
                break;
            case 'usertype':
            //  errors.usertype = value.length == 0 ? 'Please select valid option': '';
            case 'state':
                this.fetchCityList(value);
                break;
            case 'city':
                this.fetchPicodeList(value);
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    }

    // setPublic =() =>{
    //     this.setState({ isPublic: !this.state.isPublic });
    // }

    checkMemberShip = ()=>{
        let userId = localStorage.getItem("id");
        let userType = localStorage.getItem("userType");
         Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.checkEnrolledPlan+ "?userType="+ userType +"&userId="+ userId+"&planPermission=Create Project",
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({
                    membership: response.data, 
                })
               
            })
            .catch((error) => {
            });
        })
      
    }

    render() {
        const { error, ErrMsg, errors, Images, cost, pincodeList,cityList, stateList, isLoading, project, nextIcon, prevIcon, projectImages, projectName, adLine1, adLine2, city, state, pincode, mobile, description, landSize, measurementUnit, showUpload, header, body, prevDisabledIcon, nextDisabledIcon, isPublic, membership  } = this.state;
        var addressObj;
        var address;
        var builder;
        var architect;
        var items: any = [];
        var subItems: any = [];

        if (projectImages.length > 0) {
            items = projectImages.map(
                (item: any) => (
                    (<Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={blobService.createUrl(item)}
                            width="100%" height="350px"
                        />
                    </Carousel.Item>)
                ),
            );
        }
        for (let i = 0; i < 4; i++) {
            if (projectImages[i]) {
                subItems.push(
                    <div className="col-md-3 bottom-buffer compyLogo builderproject-img-wrap">
                        <span>
                            <img className="close" id={"projectImage" + i} src={Delete} onClick={(event) => { this.removeImage(event, projectImages[i]) }} />
                            <Image id={"projectImage" + i} src={blobService.createUrl(projectImages[i])} errorImg={Upload} alt="" width="100%" height="100px" />
                        </span>
                    </div>
                )
            } else {
                subItems.push(
                    <div className="bottom-buffer compyLogo builderproject-img-wrap">
                        <div>
                            <label htmlFor={"projectImages"}>
                                <span className='cursor'><h6 className="left-tab"><img id="Upload" src= {Upload}/>Add images</h6></span>
                            </label>
                            <input id={"projectImages"} type="file" style={{ display: "none" }} onChange={(event) => this.onFileChange(event)} />
                        </div>
                    </div>
                )
            }
        }
        return isLoading ? (<Loader />) :
            membership == true &&
            (
                <>
                 <Header />
               <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <h1 style={{fontWeight:600}}>Add New Project</h1>
                                <p className="my-3"><Link to="/MyBuilderProjects" className="link-text">My Projects</Link>/ Add New Project</p>
                            </div>                          
                        </div>
                        </div>
                    </div>
                </div>
                    <div className="aboutProject">
                        <div className="container py-5">
                            <div >
                                {/*  */}
                                    <div className="projectInfo-form">
                                        <h4 className="newProject">Start a New Project</h4>
                                        {/* <div style = {{marginBottom : "10px"}}>
                                            <ToggleSwitch
                                                id="public"
                                                small
                                                checked={isPublic}
                                                onChange={this.setPublic}
                                            />
                                            <label className="toggletext" htmlFor="daily">Public Project</label>
                                        </div>
                                        <br/> */}
                                        <h5 className="details">Basic Details </h5>
                                        <div className="col-md-6">
                                                {projectImages.length > 0 &&
                                                        <span>
                                                            {(projectImages.length>1)?
                                                                    <Carousel  nextIcon={nextIcon} prevIcon={prevIcon} >
                                                                        {items}
                                                                    </Carousel>
                                                                    :
                                                                    <Carousel className = "disabled-arrow" nextIcon={nextDisabledIcon} prevIcon={prevDisabledIcon} >
                                                                        {items}
                                                                    </Carousel>
                                                                }
                                                            <br />
                                                        </span>
                                                        || <div />
                                                }
                                                <div className="upload-container">
                                                    {subItems}
                                                </div>
                                        </div>
                                        <div className="builder-project">
                                            <div className="mt-3">
                                                <input
                                                    name="projectName"
                                                    type={this.state.type}
                                                    value={projectName}
                                                    placeholder="* Project Name"
                                                    className="px-3 w-50 py-1"
                                                    autoFocus
                                                    onChange={this.handleChange}
                                                    maxLength = {255}
                                                />
                                                    {errors.projectName.length > 0 &&  <p style={{color: "red"}}>{errors.projectName}</p>}
                                            </div>  
                                            <div className="mt-3">
                                                <div className='aboutProject'>
                                                    <textarea id="about" name="description" className="px-3 w-50 py-1" placeholder="* About the Project" rows={5} onChange={this.handleChange} value={description} required maxLength = {255} />
                                                    {errors.description.length > 0 &&  <p style={{color: "red"}}>{errors.description}</p>}
                                                </div>
                                             </div>
                                        </div>
                                        <div>
                                            <div className='address  builder-project'>
                                                <div>
                                                    <input type="text" name="adLine1" className="px-3 mb-3  w-50 py-1" placeholder="* Door No" onChange={this.handleChange} value={adLine1} required maxLength = {255} />
                                                    {errors.adLine1.length > 0 &&  <p  style={{color: "red"}}>{errors.adLine1}</p>}
                                                </div>
                                                <div>
                                                    <input type="text" name="adLine2" className="px-3  w-50 py-1" placeholder="* Street" onChange={this.handleChange} value={adLine2} required maxLength = {255}/>
                                                    {errors.adLine2.length > 0 &&  <p  style={{color: "red"}}>{errors.adLine2}</p>}
                                                </div>
                                            </div>
                                            <br />
                                            <div className='row'>
                                                <div className="col-md-7">
                                                    <select name="state" id="state" className="py-1" onChange={this.handleChange} style = {{width: "43%"}} required>
                                                        <option value="">* State</option>
                                                        {stateList.map((item: any) => (
                                                            <option value={item.name} key={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="left-buffer" />
                                                    <select name="city" id="city" className="py-1" onChange={this.handleChange} required style = {{width: "43%"}}>
                                                        <option value="">* City</option>
                                                        {cityList.map((item: any) => (
                                                            <option value={item} key={item}>
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-5">  
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-md-7 d-flex">                
                                                    <div style = {{width: "43%"}}>
                                                        <select name="pincode" id="pincode" className="py-1" onChange={this.handleChange} required  style = {{width: "100%"}}>
                                                            <option value="">* Postcode</option>
                                                            {pincodeList.map((item: any) => (
                                                                <option value={item.pincode} key={item.pincode}>
                                                                    {item.pincode}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <span className="left-buffer" />
                                                    <div style = {{width: "43%"}}>
                                                        <input type="number" name="mobile" className="px-3 py-1" placeholder="* Phone" onWheel={ event => (event.target as HTMLElement).blur() } value={mobile} onChange={this.handleChange} required maxLength = {10} style = {{width: "100%"}}/>
                                                        {errors.mobile.length > 0 &&  <p style={{color: "red"}}>{errors.mobile}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-5">  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-7">
                                                <div className='landSize'>
                                                    <input type="number" name="landSize" className="px-3 py-1" placeholder="* Land Size" onChange={this.handleChange} onWheel={ event => (event.target as HTMLElement).blur() } value={landSize} required max= '255' style = {{width: "43%"}}/>
                                                    {errors.landSize.length > 0 &&  <p  style={{color: "red", width: "50%"}}>{errors.landSize}</p>}
                                                    <span className="left-buffer">in</span>
                                                    <select name="measurementUnit" id="measurementUnit" className="px-3 py-1 no-border" onChange={this.handleChange} value={measurementUnit} required>
                                                        <option value="Square Meter">Sq Meters</option>
                                                        <option value="Square Feet">Sq Ft</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-7">
                                                <div className='cost'>
                                                    <input type="number" name="cost" className="px-3 py-1" placeholder="* Cost in $" onChange={this.handleChange} value={cost} required style = {{width: "43%"}} onWheel={ event => (event.target as HTMLElement).blur() }/>
                                                    {errors.cost.length > 0 &&  <p  style={{color: "red", width: "50%"}}>{errors.cost}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="mt-4">
                                <button
                                    className="button2 hire"
                                    onClick={() => {this.saveProject();}}>
                                    Publish Project 
                                    <img src={RightArrow} className="contactbut" />
                                </button>   
                            </div>                                   
                        </div>
                    </div>
                    <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any) => this.onHideUpload(event)} />
                <Footer />
                </>
                
            )
            ||
        (
            <>
            <Header/>
                {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
                <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="col-md-6 offset-md-2 col-12">
                                <h1>My Projects</h1>
                                <p className="my-3">My Projects</p>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="container py-5">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <img src={PadLock} width="50" height="50" alt="" />
                                        <h4>Get Aashray Plus</h4>
                                        <p>Get Aashray Plus and Create projects to work on</p>
                                        <div>
                                            <button className="button1 contact"><Link className="text-white" to={{ pathname: "/Pricing" }}>Continue<img src={RightArrow} className="ml-3" /></Link></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                            </>
        )

    }
}
