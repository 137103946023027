import React, { Component } from 'react'
import Footer from '../../../common-components/Footer/Footer'
import Header from '../../../common-components/Header/Header'
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg'; 
import './ViewPlan.scss';
import { RouteComponentProps } from "react-router-dom";
import Check from '../../../Services/Check';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import { Link } from 'react-router-dom';
import CustomModal from '../../../common-components/CustomModal/CustomModal';


interface ViewPlanProps {
    name?: any;
    value?: any;
}
interface ViePlanState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    errors: {};
    planName: string,
    planId: string,
    planAmount: string,
    planDescription: string,
    planDurationIndays: string,
    isPlanEnrolled: boolean,
    showModal: boolean,
    header: string,
    body: any,
}
 class ViewPlan extends React.Component<ViewPlanProps & RouteComponentProps, ViePlanState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            error:false,
            ErrMsg:"",
            isLoading: false,
            errors: {},
            planName: '',
            planId:'',
            planAmount: '',
            planDescription: '',
            planDurationIndays: '',
            isPlanEnrolled: false,
            showModal: false,
            header: '',
            body:{}
        };
        this.state = initialState;
    }

    componentDidMount() {
        this.init();
    }

    init (){
        let planId = this.props.location.state.data;
        this.setState({
            planId: this.props.location.state.data
        })
        this.viewSubcriptionPlan(planId);
    }

    viewSubcriptionPlan = (planId: any)=>{
        let userId = localStorage.getItem("id");
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.viewSubscriptionPlanAPI + planId +"?userId="+ userId,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ 
                    planName: response.data.planName,
                    planDescription: response.data.planDescription,
                    planAmount: response.data.planAmount,
                    planDurationIndays: response.data.planDurationIndays,
                    isPlanEnrolled: response.data.enroll,
                });
               
            })
            .catch((error) => {
            });
        })
    }

    enrollPlan = ()=>{
        const {planName, planId, planAmount}: any = this.state;
        let userId = localStorage.getItem("id");
        let userType = localStorage.getItem("userType");
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.enrollSubscriptionPlan,
            data: {
                "planId": planId,
                "userId": userId,
                "userType": userType,
                // "permissionName": planName,
                "paymentAmount": planAmount,
                "paymentMode": ""
            }
        };
        userService
            .postApiRequest(options)
            .then((response) => {
                this.openUpload("Success", "Plan Enrolled Successfully");
            })
            .catch((error) => {
            });
        })
    }

    openUpload = async (header: any, body: any) => {
        this.setState({ showModal: true, header: header, body: body });
    }
    onHideUpload = async (event: any) => {
        this.setState({ showModal: false });
        this.props.history.push('/Pricing');
    }

    render() {
        const {planName, planAmount, planDescription, planDurationIndays , isPlanEnrolled, showModal, header, body}: any = this.state;
        // let planData = subscriptionList.map((items: any)=>{
        //      return(
        //         <>
        //             <tr>
        //             <td style={{width: "62%"}} className="font-weight-bold">{items.planName}</td>
        //             <td  className="text-center"><i className="fa fa-check-circle" aria-hidden="true"></i></td>
        //             <td  className="text-center"><Link to={{ pathname: "/viewPlan", state:{data: items.id}}}>View Plan</Link></td>
        //         </tr>
        //          </>
        //      )

        // });
     return(
        <>
        <Header/>
            <div className="bg-img">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                                <div className="col-md-6 profile-offset-md-2 col-12">
                                    <h1>View Subscription</h1>
                                </div>
                        </div>
                    </div>
                </div>
                {(isPlanEnrolled == false)?
                    <div className="col-md">
                        <button onClick={() => {this.enrollPlan();}} className="button3 contact profile-button">
                            Enroll Plan
                        </button>
                    </div>
                    :
                    ""
                }
               
            </div>
             <div className="container">
                <div className="aboutProject">
                         <div className="row mt-4">
                             <div className="col-sm-6">
                             <h2 className="font-weight-bold">Plan</h2>
                                 <p style={{fontSize:"18px"}} >enroll the plan which is most suitable for you and your team.You can switch back anytime.</p>
                             </div>
                         </div>
                        
                            <div className="row mt-4 projectInfo profileInfo">
                                <div className="col-md-4 profile-description">
                                    <h6 >Plan Name</h6>
                                    <p >{planName? planName: ""}</p>
                                </div>
                                <div className="col-md-4 profile-description">
                                    <h6 >Plan Description</h6>
                                    <p >{planDescription? planDescription: ""}</p>
                                </div>
                                <div className="col-md-4 profile-description">
                                    <h6 >Plan Amount</h6>
                                    <p >{planAmount? planAmount: ""}</p>
                                </div>
                            </div>
                            <div className="row mt-4 projectInfo profileInfo">
                                <div className="col-md-4 profile-description">
                                    <h6 >Plan Duration</h6>
                                    <p >{planDurationIndays? planDurationIndays: ""}</p>
                                </div>
                            </div>
                </div> 
            </div>
            <CustomModal isAlert={true} show={showModal} header={header} body={body} onHide={(event: any) => this.onHideUpload(event)} />
            <Footer/>
        </>
)

    }

}

export default ViewPlan; 