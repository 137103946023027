import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";

interface CustomModalProps {
    name?: any;
    value?: any;
    show?: boolean;
    onHide?:any;
    header?: any;
    body?: any;
    isAlert?: boolean
}
interface CustomModalState {
    errors: {};
}

export default class CustomModal extends React.Component<CustomModalProps, CustomModalState> {
    constructor(props: CustomModalProps) {
        super(props);
        const initialState = {
            errors: {},
        };
        this.state = initialState;
    }

    render() {
        const { errors } = this.state;
        let {isAlert, onHide, header, body, show} = this.props;
        return (
            <div>
                { isAlert &&
            <Modal show={show} onHide={onHide} >
                <Modal.Header>
                <Modal.Title className="text-center">{header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                <button className="ok-button" onClick={onHide}> 
                    OK
                </button>
                </Modal.Footer>
            </Modal>
            ||
            <Modal
                show={show}
                onHide={onHide}
                // backdrop="static"
                keyboard={false}>
                {body}
                {/* <button className="ok-button" onClick={onHide}> 
                    OK
                </button> */}
            </Modal>}
            </div>
        )
    }
}
