import React, { Component, Fragment } from 'react';
import './PurchaseOrderPage.scss';
import Check from '../../Services/Check';
import { constants } from '../../../constants/constants';
import { userService } from '../../Services/CommonService';
import whiteCloseIcon from "../../../assets/images/whiteCloseIcon.svg";
import companylogo from "../../../assets/images/companylogo.svg";
import Loader from '../Loader';


interface purchaseOrderPageprops {
    id: any;
    subPlanId: any;
    planAmount: any;
    result: any
}

interface purchaseOrderPagestate {
    subscriptionDetails: any;
    // planId: string;
    isLoading: boolean;
    openPurchaseDetails: boolean;
    purchaseDetails: any;
    closePurchaseDetailsModal: boolean
}

class PurchaseOrderPage extends React.Component<purchaseOrderPageprops & any, purchaseOrderPagestate>{
    constructor(props: any) {
        super(props);
        const initialState = {
            subscriptionDetails: {},
            // planId: "",
            isLoading: false,
            openPurchaseDetails: false,
            purchaseDetails: {},
            closePurchaseDetailsModal: true
        };
        this.state = initialState;

    }

    componentDidMount() {
        this.init();
    }

    init() {
        // const planId = this.props.history.location.state?.data
        const planId = this.props.id;
        console.log("planId" + planId)
        this.fetchSubscriptionDetails(planId);

        // this.calculateBill();

    }

    async fetchSubscriptionDetails(planId: any): Promise<any> {
        Check.checkToken()
            .then(
                () => {
                    const options = {
                        url: constants.fetchSubscriptionDetailsAPI + "7",
                    };
                    userService
                        .getApiRequest(options)
                        .then((response) => {
                            this.setState({
                                subscriptionDetails: response.data,

                            });
                            this.calculateBill();
                        })
                        .catch((error) => {
                        });
                }
            )
    }

    async calculateBill(): Promise<any> {
        console.log("call")
        let userId = localStorage.getItem("id");
        Check.checkToken()
            .then(
                () => {
                    const options = {
                        url: constants.calculateBillAPI,
                        data: {
                            "discountAmount": "",
                            "userId": userId,
                            "createdBy": "",
                            "orderDate": "",
                            "status": "",
                            "subscriptionStartDate": this.state.subscriptionDetails.subscriptionStartDate,
                            "subscriptionEndDate": this.state.subscriptionDetails.subscriptionEndDate,
                            "name": this.state.subscriptionDetails.planName,
                            "description": this.state.subscriptionDetails.planDescription,
                            "taxCategoryCode": "",
                            "planId": this.props.id,
                            "subPlanId": this.props.subPlanId,
                            "region": this.state.subscriptionDetails.countryName,
                            "price": this.props.planAmount,
                            "createdDate": "",
                            "updatedDate": "",
                            "gst": "",
                            "totalAmount": "",
                            "totalTaxableAmount": "",
                            "netTotalTaxableAmount": "",
                            "netTotalAmount": ""
                        }
                    };
                    userService
                        .postApiRequest(options)
                        .then((response) => {
                            console.log(response + "ghdshjdfjnmfdjn")
                            this.setState({
                                purchaseDetails: response,
                                isLoading: false,
                            })
                        })
                        .catch((error) => {
                        });
                }
            )
    }

    handleCheckBox = (event: any) => {
        this.saveOrder();
        // let isChecked = event.target.checked;
        // let item = event.target.value;
        // if (isChecked) {
           
        // }
    };

    async saveOrder(): Promise<any> {
        console.log("call")
        let userId = localStorage.getItem("id");
        Check.checkToken()
            .then(
                () => {
                    const options = {
                        url: constants.saveOrderAPI,
                        data: 
                        // {



                        //     "orderNumber": "string",
                            
                        //     "discountAmount": 40,
                            
                        //     "userId": 80,
                            
                        //     "createdBy": "string",
                            
                        //     "orderDate": "2024-01-05T05:59:24.639+00:00",
                            
                        //     "status": "string",
                            
                        //     "subscriptionStartDate": "2024-01-05T05:59:24.639+00:00",
                            
                        //     "subscriptionEndDate": "2024-01-05T05:59:24.639+00:00",
                            
                        //     "name": "new plan",
                            
                        //     "description": "hgjkikj",
                            
                        //     "taxCategoryCode": "12121",
                            
                        //     "planId": 10,
                            
                        //     "subPlanId": 10,
                            
                        //     "region": "India",
                            
                        //     "price": 100,
                            
                        //     "createdDate": "2024-01-03T15:46:26.42",
                            
                        //     "updatedDate": "2024-01-03T15:46:26.42",
                            
                        //     "gst": 6,
                            
                        //     "totalAmount": 100,
                            
                        //     "totalTaxableAmount": 110,
                            
                        //     "netTotalTaxableAmount": 60,
                            
                        //     "netTotalAmount": 66
                        //     }
                        
                        {
                            
                            "orderNumber": this.state.purchaseDetails.orderNumber,
                            "discountAmount": 0.0,
                            "userId": 704,
                            "createdBy": "",
                            "orderDate": null,
                            "status": "",
                            "subscriptionStartDate": this.state.subscriptionDetails.subscriptionStartDate,
                            "subscriptionEndDate": this.state.subscriptionDetails.subscriptionEndDate,
                            "name": this.state.subscriptionDetails.planName,
                            "description": this.state.subscriptionDetails.planDescription,
                            "taxCategoryCode": "",
                            "planId": this.props.id,
                            "subPlanId":this.props.subPlanId,
                            "region": this.state.subscriptionDetails.countryName,
                            "price": this.props.planAmount,
                            "createdDate": this.state.purchaseDetails.createdDate,
                            "updatedDate": this.state.purchaseDetails.updatedDate,
                            "gst": this.state.purchaseDetails.gst,
                            "totalAmount": 0.0,
                            "totalTaxableAmount": 0.0,
                            "netTotalTaxableAmount": this.state.purchaseDetails.netTotalTaxableAmount,
                            "netTotalAmount": this.state.purchaseDetails.netTotalAmount
                        }
           
                    };
                    userService
                        .postApiRequest(options)
                        .then((response) => {
                            console.log(response + "save")
                            this.props.result(this.state.closePurchaseDetailsModal);
                        })
                        .catch((error) => {
                        });
                }
            )
    }
    closePurchaseOrder=()=>{
        this.props.result(this.state.closePurchaseDetailsModal);
    }

    render() {
        const { isLoading, purchaseDetails, subscriptionDetails }: any = this.state
        let purchaseData;
        if (purchaseDetails != null) {
            purchaseData = purchaseDetails
        }
        return isLoading ? (<Loader />) : (
            <>  
            <div style={{backgroundColor: "#fff"}}>
                <div className='  purchaseOrderPage_background '>
                    <div className='p-4 purchaseOrder_header_container'>
                        <div className="d-flex purchaseOrder_header">
                            <h3>Complete your purchase</h3>
                            <img onClick={this.closePurchaseOrder} src={whiteCloseIcon} />
                        </div>
                        <div className='d-flex purchaseOrder_header mt-4'>
                            <div className='d-flex' style={{ alignItems: "center" }}>
                                <img src={companylogo}></img>
                                <p>{purchaseData.name}</p>
                            </div>
                            <p>{purchaseData.netTotalAmount}</p>
                        </div>
                    </div>
                    <div className='purchaseOrder_banner d-flex p-4'>
                        <div>
                            {/* <p>{purchaseData.name}</p> */}
                            <p>Access ends: </p>
                            {/* <p>Access ends: {purchaseData.subscriptionEndDate}</p> */}
                        </div>
                        <div>
                            <p>Sub Total {purchaseData.price}</p>
                            <p>GST {purchaseData.gst}</p>
                            <p>Total Amount {purchaseData.netTotalAmount}</p>
                        </div>
                    </div>
                    <div className='p-4 purchaseOrder_container'>
                        <h3>Payment Method</h3>
                       
                    </div>
                    <div className='d-flex purchaseOrder_container_box'>
                            <input
                                type="radio"
                                checked={true}
                            // className='mr-2'
                             onClick={this.handleCheckBox}
                            // value={item.id}
                            // className = "radio-custom"

                            />
                            <p>Add credit or debit card</p>
                        </div>
                        <div className='d-flex purchaseOrder_container_box mt-1 mb-5'>
                            <input
                                type="radio"
                                checked={false}
                            // className='mr-2'
                            // onChange={this.handleCheckBox}
                            // value={item.id}
                            // className = "radio-custom"

                            />
                            <p>Add bank account with BHIM UPI ID</p>
                        </div>
                </div>
                </div>
            </>
        );
    }
}
export default PurchaseOrderPage;