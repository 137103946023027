import React, { Component, Fragment } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import './Builders.scss';
import Rating from '@material-ui/lab/Rating';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import { Link } from 'react-router-dom';
import Loader from '../../../common-components/Loader';
import { blobService } from '../../../common-components/Blob';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import SearchIcon from '../../../../assets/images/search.svg';
import axios from 'axios';
import Buildershape from '../../../../assets/images/Buildershape.svg';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RightArrow from '../../../../assets/images/rightarrow1.svg';
import Modal from 'react-bootstrap/Modal';
import SendInquiryModal from '../../Builder/SendInquiryModal/SendInquiryModal';
import Vector from "../../../../assets/images/Vector.svg";
import ProjectDetailsModal from '../../Builder/ProjectCreationModal/ProjectDetailsModal/ProjectDetailsModal';
import Newprojecttype from "../../../../assets/images/Newprojecttype.svg";
import Upgradeproperty from "../../../../assets/images/Upgradeproperty.svg";
import Landbuilding1 from "../../../../assets/images/Landbuilding1.svg";
import LandBuilding2 from "../../../../assets/images/LandBuilding2.svg";
import Buildingonly from "../../../../assets/images/Buildingonly.svg";
import Unit from "../../../../assets/images/Unit.svg";
import TownHouse from "../../../../assets/images/TownHouse.svg";
import CompleteHouse from "../../../../assets/images/CompleteHouse.svg";
import ToggleSwitch from '../../../common-components/ToggleSwitch/ToggleSwitch';
import Check from '../../../Services/Check';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import BuilderBanner from "../../../../assets/images/BuilderBanner.svg";
import builderBannerContent from "../../../../assets/images/builderBannerContent.svg";

interface BuildersProps {
    name?: any;
    value?: any;
}
interface BuildersState {
    error: boolean;
    ErrMsg: any;
    isLoading: boolean,
    builders: any,
    searchTxt: string,
    errors: {},
    locationList: any,
    searchLocationText: string,
    stateCode: any,
    isBuilderModal: boolean,
    isChecked: boolean,
    selectedBuilder: any,
    isSendInquiryModal: boolean,
    isAddProject: boolean,
    isProjectDetailsModal: boolean,
    selectAllBuilders: boolean,
    showSuccessModal: boolean,
    projectType: string,
    projectData: any,
    isProjectType: boolean,
    projectAdded: boolean,
    subProjectType: string,
    isPublic: boolean,
    openProjectListModal: boolean,
    projectList: any,
    selectedValue: any,
    header: string,
    body: string,
    projectTypeId: string,
    inquiryData: any,
    selectedBuilderData: any,
    detailsProjectData: any,
}

export default class Builders extends React.Component<BuildersProps, BuildersState> {
    constructor(props: BuildersProps) {
        super(props);
        const initialState = {
            isLoading: true,
            builders: [],
            searchTxt: '',
            errors: {},
            error: false,
            ErrMsg: "",
            locationList: [],
            searchLocationText: '',
            stateCode: [],
            isBuilderModal: false,
            isChecked: false,
            selectedBuilder: [],
            isSendInquiryModal: false,
            isAddProject: false,
            isProjectDetailsModal: false,
            selectAllBuilders: false,
            showSuccessModal: false,
            projectType: 'New Property',
            projectData: [],
            isProjectType: false,
            projectAdded: false,
            subProjectType: '',
            isPublic: true,
            openProjectListModal: false,
            projectList: [],
            selectedValue: [],
            header: "",
            body: "",
            projectTypeId: '',
            inquiryData: {},
            selectedBuilderData: [],
            detailsProjectData: [],
        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.init();
    }
    init() {
        this.fetchBuilders();
        this.fetchStateList();
        this.fetchCityList();
    }

    async fetchBuilders(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        const key = constants.subscriptionKey;
        const url = constants.getBuilderListAPI + "?cityName=&stateName=";
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response: any) => {
                this.setState({ builders: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error: true });
            });
    }
    searchBuilder(event: any) {
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        const key = constants.subscriptionKey;
        let value = this.state.searchTxt ? this.state.searchTxt : "";
        const url = constants.searchBuilderAPI + "?customQuery= &builderName=" + value;
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                this.setState({ builders: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error: true });
            });
    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case "searchTxt":
                if (value == "") { this.fetchBuilders() };
            default:
                break;

        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    };

    clearSearchField = () => {
        this.setState({
            searchTxt: ''
        })
        this.fetchBuilders();
    }

    // viewBuilder = (id: string) => {
    //     this.props.history.push('/ViewProject')
    // }

    async fetchStateList(): Promise<any> {
        const key = constants.subscriptionKey;
        const url = constants.fetchStatesListAPI;
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                if (response.status == 200) {
                    let stateList = response.data;
                    stateList.map((item: any) => {
                        this.state.locationList.push(item.name);
                        this.state.stateCode.push(item.name);
                    })

                    this.setState({
                        locationList: this.state.locationList,
                        stateCode: this.state.stateCode,

                    });
                }

            })
            .catch((error) => {
            });

    }
    async fetchCityList(): Promise<any> {
        const key = constants.subscriptionKey;
        const url = constants.fetchAllCity;
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                if (response.status == 200) {
                    let cityList = response.data.list;
                    cityList.map((item: any) => {
                        this.state.locationList.push(item);
                    })
                    this.setState({ locationList: this.state.locationList });
                }

            })
            .catch((error) => {
            });

    }
    searchBuilderByLocation = (value: any) => {
        let selectedValue;
        if (value) {
            selectedValue = value
        } else {
            selectedValue = ""
        }
        let statelist = this.state.stateCode;
        const isFound = statelist.includes(selectedValue);
        let url;
        if (isFound == true) {
            url = constants.getBuilderListAPI + "?cityName=&stateName=" + selectedValue;
        } else {
            url = constants.getBuilderListAPI + "?cityName=" + selectedValue + "&stateName=";
        }
        const key = constants.subscriptionKey;
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                if (response.status == 200) {
                    this.setState({ builders: response.data, isLoading: false });
                }

            })
            .catch((error) => {
            });
    }

    openBuilderModal = () => {
        this.setState({
            isBuilderModal: true
        })
    }



    handleCheckBox = (event: any) => {
        let isChecked = event.target.checked;
        let item = event.target.value;
        if (isChecked) {
            this.setState({
                selectAllBuilders: false,
                selectedBuilder: [...this.state.selectedBuilder, event.target.value],
            })
        } else {
            const index = this.state.selectedBuilder.indexOf(event.target.value);
            this.state.selectedBuilder.splice(index, 1);
            this.setState({
                selectAllBuilders: false,
                selectedBuilder: this.state.selectedBuilder,
            })
        }
    };

    handleAllCheckBox = (event: any) => {
        let isChecked = event;
        if (isChecked) {

            this.state.builders.map(
                (item: any) => {
                    this.state.selectedBuilder.push(JSON.stringify(item.userId));
                    this.setState({
                        selectedBuilder: this.state.selectedBuilder,
                        selectAllBuilders: true,

                    })
                })

        }
    }
    openSendInquiryModal = () => {
        let data: any;
        this.state.builders.map(
            (item: any) => {
                if (this.state.selectedBuilder.includes(JSON.stringify(item.userId))) {
                    data = {
                        "userId": item.userId,
                        "builderId": 0,
                        "email": item.email,
                    }
                    this.state.selectedBuilderData.push(data);
                }


            })

        this.setState({
            selectedBuilderData: this.state.selectedBuilderData
        })
        this.setState({
            isSendInquiryModal: true,
            isBuilderModal: false,
        })
    }

    closeSendInquiryModal = (event: any) => {
        this.setState({
            isSendInquiryModal: false,
            selectAllBuilders: false,
            inquiryData: {},
            selectedBuilder: [],
        })
    }

    openProjectTypeModal = (event: any) => {
        this.setState({
            inquiryData: event
        })

    }
    toogleProject = (event: any) => {
        if (event == false) {
            if (localStorage.getItem("id")) {
                this.setState({
                    openProjectListModal: true,
                    isSendInquiryModal: false,
                    projectData: [],
                    detailsProjectData: []
                })
                this.fetchProjectList();
            } else {
                this.setState({
                    isSendInquiryModal: false,
                    isAddProject: true,
                    projectData: [],
                    detailsProjectData: []
                })
            }
        } else {
            if (localStorage.getItem("id")) {
                this.setState({
                    openProjectListModal: false,
                    isSendInquiryModal: true,
                    projectData: [],
                    detailsProjectData: []
                })
                this.fetchProjectList();
            } else {
                this.setState({
                    isSendInquiryModal: true,
                    isAddProject: false,
                    projectData: [],
                    detailsProjectData: []
                })
            }
        }
    }
    closeProjectTypeModal = (event: any) => {
        this.setState({
            isAddProject: false,
            projectAdded: false,
            isSendInquiryModal: true,
        })
    }

    fetchProjectList = () => {
        Check.checkToken()
            .then(() => {
                let userId = localStorage.getItem("id");
                const options = {
                    url: constants.getBuilderProtfolioProjectsAPI + userId + "?status=Pending&builderUserId="
                }
                userService.getApiRequest(options)
                    .then((response) => {
                        if (response.data) {
                            this.setState({
                                projectList: response.data,
                                isLoading: false
                            })
                        }
                    })
                    .catch((error) => {

                    });
            })
    }
    // open projecttype modal after login
    projectTypeModal = () => {
        this.setState({
            isAddProject: true,
            openProjectListModal: false

        })
    }

    openProjectDetailsModal = (event: any) => {
        //    if(event.length > 1){
        //         this.setState({
        //             selectedValue: []
        //         })
        //    }else{
        //         this.setState({
        //             isAddProject : false,
        //             isProjectDetailsModal: true,
        //             selectedValue: []
        //         })
        //     }

        this.setState({
            isAddProject: false,
            isProjectDetailsModal: true,
            selectedValue: []
        })
    }

    removeProject = (event: any) => {
        const index = this.state.selectedValue.indexOf(JSON.stringify(event));
        this.state.selectedValue.splice(index, 1);
        this.setState({
            detailsProjectData: this.state.selectedValue,
        })

        // this.state.projectData.map((items: any)=>{

        // })
        //   for (let i = 0; i < this.state.projectData.length; i++){
        //     this.state.detailsProjectData.map((items: any)=>{
        //         if(items.name !== this.state.projectData[i].name){
        //             console.log("items" + this.state.projectData[i].name);
        //             this.state.projectData.splice(this.state.projectData[i]);
        //         }
        //         this.setState({
        //             projectData: this.state.projectData[i]
        //         })
        //     })
        //   }     
    }

    closeProjectDetailsModal = (event: any) => {
        this.setState({
            isProjectDetailsModal: false,
        })
    }
    submitProjectdetails(event: any) {
        let data = {
            "name": "",
            "projectType": {
                "id": "",
                "projectType": "",
                "projectSubtype": ""
            },
        }
        event.map((items: any) => {
            data = {
                "name": items.name,
                "projectType": {
                    "id": items.projectType.id,
                    "projectType": items.projectType.projectType,
                    "projectSubtype": items.projectType.projectSubtype
                },
            }
            this.state.projectData.push(data);
        })
        this.setState({
            projectData: this.state.projectData,
            detailsProjectData: event
        })
        // this.state.projectData.push(JSON.parse(event));
        this.setState({
            isProjectDetailsModal: false,
            openProjectListModal: false,
            isProjectType: false,
            isSendInquiryModal: true,
            //projectData:  this.state.projectData,
            projectAdded: true
        })
    }

    selectProjectType = (projectType: any) => {
        this.setState({
            projectType: projectType
        })
    }
    selectSubProjectType = (subProjectType: any, id: any) => {
        this.setState({
            subProjectType: subProjectType,
            projectTypeId: id
        })
    }
    createProject = () => {
        let projectType = {
            "id": 1,
            "subProjectType": this.state.subProjectType,
            "projectType": this.state.projectType,
        }
        this.setState({
            isProjectType: true
        })
    }

    closeConfirmProjectType = () => {
        this.setState({
            isProjectType: false,
            isAddProject: true
        })
    }

    setPublic = () => {
        this.setState({ isPublic: !this.state.isPublic });
    }

    handleProjects = (event: any) => {
        let isChecked = event.target.checked;
        let item = event.target.value;
        if (isChecked) {
            this.setState({
                selectedValue: [...this.state.selectedValue, JSON.parse(event.target.value)],
            })
        } else {
            const index = this.state.selectedValue.indexOf(event.target.value);
            this.state.selectedValue.splice(index, 1);
            this.setState({
                selectedValue: this.state.selectedValue,
            })


        }
    };

    closeProjectListModal = () => {
        this.setState({
            openProjectListModal: false,
            projectAdded: false,
            isAddProject: false,
            isSendInquiryModal: true
        })
    }

    sendInquirySuccess(event: any) {
        this.setState({
            isSendInquiryModal: false,
            showSuccessModal: true,
            header: "Successful",
            body: event
        })
    }
    onHideSuccessModal = (event: any) => {
        this.setState({ showSuccessModal: false });
    }

    closeBuilderModal = () => {
        this.setState({
            isBuilderModal: false,
            selectedBuilder: [],
            selectAllBuilders: false
        });
    }

    render() {
        const { errors, isLoading, builders, locationList, isBuilderModal, isChecked, selectedBuilder, isSendInquiryModal, isAddProject, selectAllBuilders, showSuccessModal, isProjectDetailsModal, projectType, projectData, subProjectType, isProjectType, projectAdded, isPublic, projectList, selectedValue, openProjectListModal, body, header, projectTypeId, searchTxt } = this.state;
        let projectNames = projectList.map((list: any) => {
            let projectId = list.id;
            let isChecked;
            for (let i = 0; i < selectedValue.length; i++) {
                if (projectId == selectedValue[i]) {
                    isChecked = true
                }
            }
            return isLoading ? (<Loader />) : (
                <>
                    <div className="d-flex">
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={this.handleProjects}
                            value={JSON.stringify(list)}
                        // className = "radio-custom"

                        />
                        <p> {list.name}</p>
                    </div>
                    <hr />
                </>
            )

        })
        const listItems = builders.map(
            (item: any) => (
                <li className="col-md-4">
                    <div className="buildercard">
                        {/* <div className="buildercard" onClick={(event:any)=>this.viewBuilder(item.id)}> */}
                        <Link to={{ pathname: "/ViewBuilder", state: { id: item.userId } }}>
                            {item.logoPath &&
                                <img src={blobService.createUrl(item.logoPath)} alt="" width="100%" height="200px" />
                                ||
                                <img src={ProjectPlaceholder} alt="" width="100%" height="200px" />
                            }
                        </Link>
                    </div>
                    <div className="buildername"><p>{item.builderName}</p></div>
                    <div className="location">{item.address ? item.address.city : ""}</div>
                    {/* <Rating name="read-only" value={item.rating} readOnly className="rating" /> */}
                </li>
            )
        );

        const listModalItems = builders.map(
            (item: any) => {
                let BuilderId = item.userId;
                let isBuilderChecked;
                for (let i = 0; i < selectedBuilder.length; i++) {
                    if (BuilderId == selectedBuilder[i]) {
                        isBuilderChecked = true
                    }
                }
                return (
                    <li className="col-md-4 ">
                        <div className="buildercard">
                            <input
                                style={{ display: "flex", alignItems: "flex-start" }}
                                // className='check-box'
                                type='checkbox'
                                checked={isBuilderChecked}
                                onChange={this.handleCheckBox}
                                value={JSON.stringify(item.userId)}
                            />
                            {item.logoPath &&
                                <img src={blobService.createUrl(item.logoPath)} alt="" width="100%" height="130px" />
                                ||
                                <img src={ProjectPlaceholder} alt="" width="100%" height="130px" />
                            }
                        </div>
                        <div className="buildername"><p>{item.builderName}</p></div>
                        <div className="location">{item.address ? item.address.city : ""}</div>
                    </li>
                )
            }
        );

        let isEnabled = true;
        if (selectedBuilder.length !== 0 || selectAllBuilders == true) {
            isEnabled = false
        }
        return isLoading ? (<Loader />) :
            (
                <>
                    <Header />
                    <div className="Builders">
                        <div className="">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 col-12 mt-5 bannerContent">
                                        <img className='w-100' src={builderBannerContent} />
                                        <Link to="/builderContentPage">
                                            <button className='px-4 py-2 mt-5'>
                                                Why Hire our builders?
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="col-md-6 col-12 mt-5 d-flex justify-content-center">
                                        <img className='w-75' src={BuilderBanner} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buildersBlock py-3">
                        <div className="container">
                            <div className='content'>
                                <p>Are you looking for builders in Australia?</p>
                                <p>Aashray Homes:- The Right platform to find the most recommended commercial builders who can build custom home.
                                    You find professional builders in Sydney, Perth, Melbourne and all across Australia who have years expertise in house constructions. We understand that bringing outside professionals to your home can sometimes be a stressful experience. So, Aashray Homes has hand-picked the genuine real estate professionals from your locality.
                                    If you have a new construction project on your hands, choose the top builders in Australia and sit back and relax while they transform your property.</p>
                                    <Link to="/builderContentPage">
                                            <button className='px-4 py-2 mt-5'>
                                                Learn More
                                            </button>
                                    </Link>
                            </div>
                            <div className="row mt-5">

                                <div className="col-md-3">
                                    <h4>Filters</h4>
                                    <h5 className="my-3">Sort by Location</h5>
                                    <div className="search-bar-section">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            onChange={(event, newValue) => {
                                                this.searchBuilderByLocation(newValue);
                                            }}
                                            style={{ width: "100%" }}
                                            options={locationList}
                                            getOptionLabel={(option: any) => option}
                                            renderInput={(params) => <TextField {...params} placeholder="Search" variant="outlined" name="searchLocationText" />}
                                        />

                                        {/* <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#filterItems">...</button> */}
                                    </div>
                                    {/* <h5 className="mt-5">Sort by Rating</h5> */}
                                    <ul className="RatingList">
                                        {/* <li>
                                        <Rating name="read-only" value={5} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={4} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={3} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={2} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={1} readOnly className="rating" /> & Up
                                    </li> */}
                                    </ul>
                                </div>
                                <div className="offset-md-1 col-md-8">
                                    {builders.length > 0 &&
                                        <>
                                            <ul className="builderscard row " >
                                                <div className="input-cntr mb-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        value={searchTxt}
                                                        name="searchTxt"
                                                        onChange={this.handleChange}
                                                        className="px-3 py-2 search-bar w-100 search-icon"
                                                        onKeyPress={event => {
                                                            if (event.key === 'Enter') {
                                                                this.searchBuilder(event)
                                                            }
                                                        }}
                                                    />
                                                    {(searchTxt.length) > 0 ?
                                                        <img className="close-search" onClick={this.clearSearchField} src={Vector} />
                                                        :
                                                        ""
                                                    }
                                                </div>
                                                <div className=' p-4 w-100 send-inquiry'>
                                                    <div className='row send-inquiry-container' style={{ alignItems: "center" }}>
                                                        <div className='col-md-9'>
                                                            <h4>Send Inquiry</h4>
                                                            <p>Notification will send to the builders to contact you about your projects and other information</p>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <button className="button2  p-3" onClick={this.openBuilderModal}>
                                                                Send Inquiry
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {listItems}
                                            </ul>
                                        </>
                                        ||
                                        <div className="buildersBlock py-5 empty-Message-Container">
                                            <div className="input-cntr mb-3">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    value={searchTxt}
                                                    name="searchTxt"
                                                    onChange={this.handleChange}
                                                    className="px-3 py-2 search-bar w-100 search-icon"
                                                    onKeyPress={event => {
                                                        if (event.key === 'Enter') {
                                                            this.searchBuilder(event)
                                                        }
                                                    }}
                                                />
                                                {(searchTxt.length) > 0 ?
                                                    <img className="close-search" onClick={this.clearSearchField} src={Vector} />
                                                    :
                                                    ""
                                                }
                                            </div>
                                            <div className="container-fluid empty-Message-Content">
                                                <img src={Buildershape} alt="" />
                                                <p className="text-center my-3">There are no builders on the platform yet. We will soon have some for you.</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={isBuilderModal}  >
                        <Fragment>
                            <div className='p-4 container w-100 buildersBlock'>
                                <div className='send-inquiry-container'>
                                    <div className=' d-flex' style={{ justifyContent: "space-between" }}>
                                        <h4>Send Inquiry</h4>
                                        <img className='cursor' src={Vector}
                                            onClick={this.closeBuilderModal}
                                        />
                                    </div>
                                    <p>Notification will send to the builders to contact you about your projects and other information</p>
                                </div>
                                <div className='select-all'>
                                    <p style={{ color: "#2F80ED" }} className='cursor' onClick={() => { this.handleAllCheckBox("true") }}>Select All</p>
                                    <div className="builderscard row" >
                                        {listModalItems}
                                    </div>
                                </div>
                                <div className='send-inquiry-container'>
                                    {/* <div></div> */}
                                    <button
                                        className={isEnabled ? "button2 p-3 disabled-button" : "button2 p-3"}
                                        style={{ float: "right" }}
                                        onClick={this.openSendInquiryModal}
                                        disabled={isEnabled}
                                    >
                                        Send Inquiry
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    </Modal>
                    <Modal show={isSendInquiryModal} onHide={() => this.closeSendInquiryModal}>
                        <SendInquiryModal
                            closeSendInquiryModal={(event: any) => this.closeSendInquiryModal(event)}
                            selectedBuilder={this.state.selectedBuilderData}
                            isAddProject={(event: any) => this.openProjectTypeModal(event)}
                            projectChecked={(event: any) => this.toogleProject(event)}
                            selectAllBuilders={selectAllBuilders}
                            result={(event: any) => this.sendInquirySuccess(event)}
                            projectData={projectData ? projectData : ''}
                            addProject={projectAdded}
                            editProject={(event: any) => this.openProjectDetailsModal(event)}
                            inquiryData={this.state.inquiryData}
                            detailsProjectData={this.state.detailsProjectData ? this.state.detailsProjectData : ""}
                            removeProject={(event: any) => { this.removeProject(event) }}
                        />
                    </Modal>
                    <Modal show={isProjectDetailsModal}>
                        <ProjectDetailsModal
                            projectType={projectType}
                            subProjectType={subProjectType}
                            projectTypeId={projectTypeId}
                            result={(event: any) => this.submitProjectdetails(event)}
                            projectData={this.state.detailsProjectData}
                            isPublic={isPublic}
                            closeProjectDetailsModal={(event: any) => this.closeProjectDetailsModal(event)}
                        />
                    </Modal>
                    <Modal show={openProjectListModal}>
                        {(projectList.length > 0) ?
                            <div className='container p-4 '>
                                <div className='d-flex sendInquiry-header'>
                                    <h4>Select Project</h4>
                                    {(selectedValue.length > 0) ?
                                        <p
                                            className="disabled-button"
                                        >
                                            + Add New Project
                                        </p>
                                        :
                                        <p
                                            onClick={(event: any) => this.projectTypeModal()}
                                            className="cursor"
                                        >
                                            + Add New Project
                                        </p>
                                    }
                                </div>
                                <div className="hire-content-list" >
                                    <hr />
                                    <p>Select a project for which you are seding to the builders</p>
                                    <div className="scrollableContent" style={{ height: "56vh" }}>
                                        {projectNames}
                                    </div>
                                </div>
                                <div className='mt-3 project-container'>
                                    <button
                                        onClick={(event: any) => this.submitProjectdetails(selectedValue)}
                                        className={(selectedValue.length > 0) ? "" : 'disabled-button'}
                                        disabled={selectedValue.length < 1}
                                    >
                                        Confirm
                                    </button>
                                    <button onClick={this.closeProjectListModal}>Skip</button>
                                </div>
                            </div>
                            :
                            <div className='container p-4  w-100'>
                                <div className='d-flex sendInquiry-header'>
                                    <h4>Select Project</h4>
                                    <img
                                        src={Vector}
                                        onClick={this.closeProjectListModal}
                                    />
                                </div>
                                <hr />
                                <div className='project-container py-5'>
                                    <h4>No Project Has been Created yet!</h4>
                                    <p>Please create a project which you need to share with
                                        selected builders
                                    </p>
                                </div>
                                <div className='mt-2 py-5 project-container'>
                                    <button
                                        onClick={(event: any) => this.projectTypeModal()}
                                    >
                                        Create Project
                                    </button>
                                    <button onClick={this.closeProjectListModal}>Skip</button>
                                </div>
                            </div>
                        }
                    </Modal>
                    <Modal show={showSuccessModal} >
                        <h4>Inquiry Sent Successfully</h4>
                        <p>
                            your details sent to the selected builders and you will get email
                            related to your account creation
                        </p>
                    </Modal>
                    <Modal show={isAddProject}>
                        <>
                            <div style={{ height: "100vh" }} className='container p-4 project-type'>
                                <div className="select-type-modal">
                                    <h4>Select Type</h4>
                                    <img src={Vector}
                                        onClick={this.closeProjectTypeModal}
                                    />
                                </div>
                                <p>Choose the type of project you have in mind for us.</p>
                                <div>
                                    <ToggleSwitch
                                        id="public"
                                        small
                                        checked={isPublic}
                                        onChange={this.setPublic}
                                    />
                                    <label htmlFor="daily">Public Project</label>
                                </div>
                                <div className="d-flex property-header" >
                                    <p
                                        onClick={() => { this.selectProjectType("New Property") }}
                                        className={projectType == "New Property" ? "project-type-active " : ""}
                                    >
                                        <img src={Newprojecttype} ></img>
                                        New Property
                                    </p>
                                    <p
                                        onClick={() => { this.selectProjectType("Upgrade Property") }}
                                        className={projectType == "Upgrade Property" ? "project-type-active" : ""}
                                    >
                                        <img style={{ width: "5%" }} src={Upgradeproperty} ></img>
                                        Upgrade Property
                                    </p>
                                </div>
                                {(projectType == "New Property") ?
                                    <div className='row py-2 mt-4 d-flex'>
                                        <div className='col-md-6 project-type-container'>
                                            <div
                                                className={subProjectType == "Building only" ? "project-type-box-active" : "project-type-box"}
                                                onClick={() => { this.selectSubProjectType("Building only", "1") }}
                                            >
                                                <img src={Buildingonly} ></img>
                                            </div>
                                            <p>Building only</p>
                                        </div>
                                        <div className='col-md-6 project-type-container'>
                                            <div
                                                className={subProjectType == "Land + Building" ? "project-type-box-active d-flex" : "project-type-box d-flex"}
                                                onClick={() => { this.selectSubProjectType("Land + Building", "2") }}
                                            >
                                                <img style={{ marginRight: "10px" }} src={Landbuilding1}></img>
                                                <img src={LandBuilding2}></img>
                                                <img style={{ marginLeft: "10px" }} src={Buildingonly}></img>
                                            </div>
                                            <p>Land + Building</p>
                                        </div>
                                    </div>
                                    :
                                    <div className='row py-2 mt-4 d-flex'>
                                        <div className='col-md-6 project-type-container'>
                                            <div
                                                className={subProjectType == "Unit" ? "project-type-box-active" : "project-type-box"}
                                                onClick={() => { this.selectSubProjectType("Unit", "3") }}
                                            >
                                                <img src={Unit} ></img>
                                            </div>
                                            <p>Unit</p>
                                        </div>
                                        <div className='col-md-6 project-type-container'>
                                            <div
                                                className={subProjectType == " Complete House" ? "project-type-box-active d-flex" : "project-type-box d-flex"}
                                                onClick={() => { this.selectSubProjectType("Complete House", "4") }}
                                            >
                                                <img src={CompleteHouse}></img>
                                            </div>
                                            <p> Complete House</p>
                                        </div>
                                        <div className='col-md-6 project-type-container'>
                                            <div
                                                className={subProjectType == "Town House" ? "project-type-box-active d-flex" : "project-type-box d-flex"}
                                                onClick={() => { this.selectSubProjectType("Town House", "5") }}
                                            >
                                                <img src={TownHouse}></img>
                                            </div>
                                            <p> Town House</p>
                                        </div>
                                    </div>
                                }

                                <button
                                    disabled={subProjectType == ""}
                                    onClick={this.createProject}
                                    className={subProjectType == "" ? "button2 p-3 disabled-button" : "button2 p-3"}
                                >
                                    Create a Project
                                </button>
                            </div>
                        </>
                    </Modal>
                    <Modal show={isProjectType}>
                        <div style={{ height: "100vh" }} className='p-4 container w-100 sendInquiry-modal'>
                            <div className='d-flex sendInquiry-header'>
                                <h4>Enter Project Details!</h4>
                                <img
                                    src={Vector}
                                    onClick={this.closeConfirmProjectType}
                                />
                            </div>
                            <div className="d-flex property-header" >
                                <p

                                    className={projectType == "New Property" ? "project-type-active " : "project-type-disabled"}
                                >
                                    <img src={Newprojecttype} ></img>
                                    New Property
                                </p>
                                <p

                                    className={projectType == "Upgrade Property" ? "project-type-active" : "project-type-disabled"}
                                >
                                    <img style={{ width: "5%" }} src={Upgradeproperty} ></img>
                                    Upgrade Property
                                </p>
                            </div>
                            <div className="d-flex mt-4 select-type-modal" >
                                <p>Property Type <span onClick={this.closeConfirmProjectType}>Edit</span></p>
                                <p>Step 01/03</p>
                            </div>
                            <div className="sendInquiry-container mt-3">
                                {(projectType == "New Property") ?
                                    <div className='row py-2 mt-4 d-flex'>
                                        {(subProjectType == "Building only") ?
                                            <div className='col-md-6 project-type-container'>
                                                <div
                                                    className={subProjectType == "Building only" ? "project-type-box-active" : "project-type-box"}
                                                //  onClick={()=>{this.selectSubProjectType("Building only")}}
                                                >
                                                    <img src={Buildingonly} ></img>
                                                </div>
                                                <p>Building only</p>
                                            </div>
                                            :
                                            <div className='col-md-6 project-type-container'>
                                                <div
                                                    className={subProjectType == "Land + Building" ? "project-type-box-active d-flex" : "project-type-box d-flex"}
                                                // onClick={()=>{this.selectSubProjectType("Land + Building")}}  
                                                >
                                                    <img style={{ marginRight: "10px" }} src={Landbuilding1}></img>
                                                    <img src={LandBuilding2}></img>
                                                    <img style={{ marginLeft: "10px" }} src={Buildingonly}></img>
                                                </div>
                                                <p>Land + Building</p>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <>
                                        {(subProjectType == "Unit") &&

                                            <div className='col-md-6 project-type-container'>
                                                <div
                                                    className={subProjectType == "Unit" ? "project-type-box-active" : "project-type-box"}

                                                >
                                                    <img src={Unit} ></img>
                                                </div>
                                                <p>Unit</p>
                                            </div>
                                            ||
                                            (subProjectType == "Complete House") &&
                                            <div className='col-md-6 project-type-container'>
                                                <div
                                                    className={subProjectType == "Complete House" ? "project-type-box-active d-flex" : "project-type-box d-flex"}

                                                >
                                                    <img src={CompleteHouse}></img>
                                                </div>
                                                <p> Complete House</p>
                                            </div>
                                            ||
                                            (subProjectType == "Town House") &&
                                            <div className='col-md-6 project-type-container'>
                                                <div
                                                    className={subProjectType == "Town House" ? "project-type-box-active d-flex" : "project-type-box d-flex"}

                                                >
                                                    <img src={TownHouse}></img>
                                                </div>
                                                <p> Town House</p>
                                            </div>
                                        }
                                    </>
                                }
                            </div>

                            <div className='mt-6'>
                                <button
                                    className="button2 p-3"
                                    onClick={() => { this.openProjectDetailsModal("") }}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </Modal>
                    <CustomModal isAlert={true} show={showSuccessModal} header={header} body={body} onHide={(event: any) => this.onHideSuccessModal(event)} />
                    <Footer />
                </>
            );
    }
}
