import React, { Component, Fragment } from 'react';
import Footer from '../../../common-components/Footer/Footer';
import Header from '../../../common-components/Header/Header';
import MembershipModal from '../../../feature-components/Builder/MembershipModal/MembershipModal';
import Project1 from '../../../../assets/images/projects1.jpg';
import { Link } from 'react-router-dom';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import Loader from '../../../common-components/Loader';
import Add from '../../../../assets/images/add.svg';
import Check from '../../../Services/Check';
import longarrowaltright from '../../../../assets/images/longarrowaltright.svg';
import ruler from '../../../../assets/images/ruler.svg';
import Image from "../../../common-components/Image";
import { blobService } from '../../../common-components/Blob';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import emptyQuote from '../../../../assets/images/emptyQuote.svg';
import { RouteComponentProps } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Vector from "../../../../assets/images/Vector.svg";
import ProjectTypeModal from '../../Builder/ProjectCreationModal/ProjectTypeModal/ProjectTypeModal';

interface MyProjectsProps {
    name?: any;
    value?: any;

}
interface MyProjectsState {
    error: boolean;
    ErrMsg: any;
    isLoading: boolean,
    projects: any,
    errors: {};
    activeli: any,
    status: string,
    myQuotes: any
    builderQuotes: any,
    projectDetails: any,
    isRequestStatus: boolean,
    requestChange: string,
    showProjectType: boolean,
}

export default class MyProjects extends React.Component<MyProjectsProps & RouteComponentProps, MyProjectsState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            isLoading: true,
            projects: [],
            errors: {},
            error: false,
            ErrMsg: "",
            activeli: [true, false, false, false],
            status: "",
            myQuotes: [],
            builderQuotes: [],
            projectDetails: [],
            isRequestStatus: false,
            requestChange: '',
            showProjectType: false,

        };
        this.state = initialState;
    }
    componentDidMount() {
        let userId = localStorage.getItem("id");
        this.fetchProjects(userId);
    }

    async fetchProjects(id: any): Promise<any> {
        Check.checkToken()
            .then(
                () => {
                    const options = {
                        url: constants.getBuilderProtfolioProjectsAPI + id + "?status=",
                    };
                    userService
                        .getApiRequest(options)
                        .then((response) => {
                            this.setState({ projects: response.data, isLoading: false });
                        })
                        .catch((error) => {
                            this.setState({ ErrMsg: error, error: true });
                        });
                }
            )
    };

    changeActive = (e: any) => {
        const { activeli }: any = this.state

        let newArrey = []
        for (let index = 0; index < activeli.length; index++) {
            if (index === e) {
                newArrey.push(true)
            } else {
                newArrey.push(false)
            }

        }
        this.setState({
            activeli: newArrey
        })
        if (e == 1 || e == 2) {
            this.fetchBuilderQuotes(e);
        }

    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;

        let errors = this.state.errors;
        switch (name) {
            case 'status':
                this.searchProjectByStatus(value);
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { [name]: value }));
    };

    // Search builder's projects by status
    searchProjectByStatus = (status: any) => {
        let userId = localStorage.getItem("id");
        Check.checkToken()
            .then(
                () => {
                    const options = {
                        url: constants.getBuilderProtfolioProjectsAPI + userId + "?status=" + status,
                    };
                    userService
                        .getApiRequest(options)
                        .then((response) => {
                            this.setState({ projects: response.data, isLoading: false });
                        })
                        .catch((error) => {
                            this.setState({ ErrMsg: error, error: true });
                        });
                }
            )
    }

    async fetchBuilderQuotes(activeli: any): Promise<any> {
        let userId = localStorage.getItem("id");
        let createdBy: any;
        if (activeli == 1) {
            createdBy = "Builder"

        } else if (activeli == 2) {
            createdBy = "Customer"
        }
        Check.checkToken()
            .then(
                () => {
                    const options = {
                        url: constants.fetchBuilderQuotes + userId + "?createdBy=" + createdBy,
                    };
                    userService
                        .getApiRequest(options)
                        .then((response) => {
                            if (response.status == 200) {
                                if (activeli == 1) {
                                    this.setState({
                                        myQuotes: response.data.projects,
                                        isLoading: false
                                    })

                                } else {
                                    this.setState({
                                        builderQuotes: response.data.projects,
                                        isLoading: false
                                    })
                                }
                            }

                        })
                        .catch((error) => {
                            this.setState({ ErrMsg: error, error: true });
                        });
                }
            )
    };

    openRequestStatus = (requestChange: string) => {
        this.setState({
            isRequestStatus: true,
            requestChange: requestChange
        })
    }
    closeRequestStatus = () => {
        this.setState({
            isRequestStatus: false,
        })
    }

    openProjectTypeModal = () => {
        this.setState({
            showProjectType: true
        })
    }
    closeProjectTypeModal = (event: any) => {
        this.setState({
            showProjectType: event,
            // projectType: ""
        })
    }

    render() {
        var pList = [];
        const { error, ErrMsg, errors, projects, isLoading, activeli, status, myQuotes, builderQuotes, projectDetails, isRequestStatus, requestChange, showProjectType } = this.state;
        let quotes;
        if (activeli[1] == true) {
            quotes = myQuotes
        } else {
            quotes = builderQuotes
        }
        let projectLists = quotes.map((list: any) => {
            return isLoading ? (<Loader />) :
                (
                    <>
                        <li className="no-gutters" style={{ display: "flex", width: "100%" }}>
                            <div className="col-sm-3">
                                <Image src={list.projectImages[0] ? blobService.createUrl(list.projectImages[0]) : ProjectPlaceholder} alt="" width="100%" height="auto" errorImg={ProjectPlaceholder} />
                            </div>
                            <div className="col-sm-7 p-4 quote-page-content">
                                <h5>{list.projectName}</h5>
                                {/* <h6>by {list.ownername}</h6> */}
                                <h6>Builder : {list.builder && list.builder.builderName || "None"}</h6>
                                <h6>Location : {list.cityName ? list.cityName : "NA"}</h6>
                                <p>{list.description}</p>
                                <p className='cursor link-type' onClick={() => { this.openRequestStatus(list.requestChange) }}>{(list.requestChange) ? "View Request Status" : ""}</p>
                            </div>
                            <div className="col-sm-2 quote-page-buttons" >
                                {(list.quoteStatus == "Pending") &&
                                    <p>
                                        <span>
                                            Pending
                                        </span>
                                    </p>

                                    ||
                                    (list.quoteStatus == "accept") &&
                                    <p>
                                        <span style={{ color: "#00A824" }}>
                                            Accepted
                                        </span>
                                    </p>
                                    ||
                                    (list.quoteStatus == "reject") &&
                                    <p>
                                        <span style={{ color: "#FF4949" }}>
                                            Rejected
                                        </span>
                                    </p>
                                }
                                <Link className="text-center my-3" to={{ pathname: "/viewProject", state: { data: list, projectCreatedBy: "CUSTOMER" } }}><h6 className="text-center">View Project <img className="view-Project-Arrow" src={longarrowaltright} alt="" /></h6></Link>
                            </div>
                        </li>
                    </>
                )

        })
        for (let i = 0; i < projects.length; i++) {
            var p = projects[i];
            pList.push(
                <li className="row no-gutters">
                    <div className="col-md-3">
                        {/* <img src={Project1} alt="" /> */}
                        <Image src={p.projectDetails.projectImages[0] ? blobService.createUrl(p.projectDetails.projectImages[0]) : ProjectPlaceholder} alt="" width="100%" height="auto" errorImg={ProjectPlaceholder} />
                    </div>
                    <div className="col-md-7 p-4">
                        <h4>{p.name}</h4>
                        <h6>Location : {p.address ? p.address.city : "NA"}</h6>
                        <h6>Type of Project: {p.typeOfProject}</h6>
                        <p>{p.description}</p>
                    </div>
                    <div className="col-md-2 row">
                        {p.projectStatus == null ? ""
                            :
                            <span className="ml-auto my-3">
                                {/* <div className="card btn-text pending-btn">
                            In Progress
                        </div>    */}

                                {(p.projectStatus.projectStatus === "In Progress") &&
                                    <div className=" btn-text pending-btn">
                                        In Progress
                                    </div>
                                }
                                {(p.projectStatus.projectStatus === "Completed") &&
                                    <div className=" btn-text completed-btn">
                                        Completed
                                    </div>
                                }
                                {(p.projectStatus.projectStatus === "Cancelled") &&
                                    <div className=" btn-text cancel-btn">
                                        Cancelled
                                    </div>
                                }
                                {(p.projectStatus.projectStatus === "InitiateContract") &&
                                    <div className=" btn-text pending-btn">
                                        Initiate Contract
                                    </div>
                                }
                                {(p.projectStatus.projectStatus === "Pending") &&
                                    <div className=" btn-text pending-btn">
                                        Yet to Assign
                                    </div>
                                }
                            </span>
                        }

                        <span className="align-self-end ml-auto mb-3">
                            <Link className="text-center my-3" to={{ pathname: "/ViewCustomerProject", state: { data: p } }}><h6 className="text-center">View Project <img className="view-Project-Arrow" src={longarrowaltright} alt="" /></h6></Link>
                        </span>
                    </div>
                </li>
            )
        }
        return isLoading ? (<Loader />) :
            (
                <>
                    <Header />
                    {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
                    <div className="bg-img">
                        <div className="overlay">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        {activeli[0] === true ?
                                            <>
                                                <h1 style={{ fontWeight: 600 }}>My Projects</h1>
                                                <p className="my-3">My Projects /Projects</p>
                                            </>
                                            :
                                            ""
                                        }
                                        {activeli[1] === true ?
                                            <>
                                                <h1>Quotes</h1>
                                                <p className="my-3">
                                                    <Link to="#" className="link-text" onClick={() => { this.changeActive(0) }}>
                                                        My Projects
                                                    </Link>
                                                    /Quotes/ My Quotes</p>
                                            </>
                                            : ""}
                                        {activeli[2] === true ?
                                            <>
                                                <h1>Quotes</h1>
                                                <p className="my-3">
                                                    <Link to="#" className="link-text" onClick={() => { this.changeActive(0) }}>
                                                        My Projects
                                                    </Link>
                                                    /Quotes/ Customer Quotes
                                                </p>
                                            </>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md">
                            {/* <Link className="button2 contact" to="/NewBuilderProject">Start New Project<img src={Add} className="ml-1"/></Link> */}
                            <button className="button2 contact" onClick={this.openProjectTypeModal}>Start New Project<img src={Add} className="ml-1" /></button>
                        </div>
                    </div>

                    <div className="Projects">
                        <div className="container">
                            <div className="my-5">
                                <div className="row align-items-center top-navbar">
                                    <h4 className={activeli[0] === true ? 'active' : ''} onClick={() => { this.changeActive(0) }}>Projects</h4>
                                    <h4 className={activeli[1] === true ? 'active' : ''} onClick={() => { this.changeActive(1) }} style={{ marginLeft: "40px", marginRight: "40px" }}>Quotes</h4>
                                    <MembershipModal />
                                    {(activeli[0] === true) ?
                                        <div className="ml-auto">
                                            <span className="mx-3"> Filter by:</span>
                                            {/* <select className="px-3 py-1">
                                            <option value="">Builder Associated</option>
                                            <option value="">Yes</option>
                                            <option value="">No</option>
                                        </select> */}
                                            <select name="status" className="px-3 py-1 mx-3" onChange={this.handleChange} value={status}>
                                                <option value="">Status</option>
                                                <option value="Pending">Yet to Assign</option>
                                                <option value="InitiateContract">Initiate Contract</option>
                                                <option value="In Progress">In Progress</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Closed">Cancelled</option>
                                            </select>
                                        </div>
                                        :
                                        ""
                                    }

                                </div>
                                {projects.length > 0 &&
                                    <ul style={{ display: activeli[0] === true ? "" : "none" }}>
                                        {pList}
                                    </ul>
                                    ||
                                    <div style={{ display: activeli[0] === true ? "" : "none" }} className="buildersBlock py-5 empty-Findproject-Container">
                                        <div className="container-fluid empty-Findproject-Content">
                                            <img src={ruler} alt="" />
                                            <p className="text-center my-3">There are no live projects. We will soon have some for you.</p>
                                        </div>
                                    </div>
                                }
                                <ul style={{ display: activeli[1] === true || activeli[2] === true ? "" : "none" }}>
                                    <div className="row align-items-center quotes-heading">
                                        <h6 className={activeli[1] === true ? 'active-quote' : ''} onClick={() => { this.changeActive(1) }}>My Quotes</h6>
                                        <h6 className={activeli[2] === true ? 'active-quote' : ''} onClick={() => { this.changeActive(2) }}>Customer Quotes</h6>
                                    </div>
                                    <div className="Projects" style={{ display: activeli[1] === true ? "" : "none" }}>
                                        {(myQuotes.length) > 0 &&
                                            <ul >{projectLists}</ul>
                                            ||
                                            <div className="buildersBlock py-5 empty-Message-Container">
                                                <div className="container-fluid empty-Message-Content">
                                                    <img src={emptyQuote} alt="" />
                                                    <p className="text-center my-3">There are no quotes on your project yet. You will be able to see them once you sends a quote.</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="Projects" style={{ display: activeli[2] === true ? "" : "none" }}>
                                        {(builderQuotes.length) > 0 &&
                                            <ul >{projectLists}</ul>
                                            ||
                                            <div className="buildersBlock py-5 empty-Message-Container">
                                                <div className="container-fluid empty-Message-Content">
                                                    <img src={emptyQuote} alt="" />
                                                    <p className="text-center my-3">There are no quotes on your project yet. You will be able to see them once any customer sends you a quote.</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </ul>
                                {/* {projects.length < 1 ? <h6 className="text-center my-3">No Projects</h6> :  <ul style={{display:activeli[0]===true?"":"none"}}>{pList}</ul>}
                            {projects.length > 2 ? <h6 className="text-center my-3">See More</h6>:""} */}


                            </div>
                        </div>
                    </div>
                    <Modal onHide={this.closeRequestStatus} show={isRequestStatus}>
                        <Fragment>
                            <div className="container-fluid quote-container">
                                <div className="quote-header">
                                    <p>Request Change</p>
                                    <img src={Vector} onClick={this.closeRequestStatus} />
                                </div>
                                <div className="quote-confirmation-content">
                                    <p>Message</p>
                                    <textarea
                                        name="changeRequest"
                                        value={requestChange}
                                        readOnly
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </Fragment>
                    </Modal>
                    <Modal show={this.state.showProjectType} >
                        <ProjectTypeModal
                            closeProjectTypeModal={(event: any) => { this.closeProjectTypeModal(event) }}
                        />
                    </Modal>
                    <Footer />
                </>
            );
    }
}
