import React, { Component } from "react";
import './SendInquiryModal.scss';
import Vector from  "../../../../assets/images/Vector.svg";
import ToggleSwitch from '../../../common-components/ToggleSwitch/ToggleSwitch';
import Check from '../../../Services/Check';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import editProject from '../../../../assets/images/editProject.svg';
import remove from '../../../../assets/images/remove.svg';
import axios from 'axios';

interface sendInquiryModalprops {
    closeSendInquiryModal: any;
    id?: any;
    selectedBuilder? : any;
    isAddProject? : any;
    projectData?: any;
    addProject? : any;
    selectAllBuilders? : any;
    result? : any;
    editProject? : any;
    inquiryData? : any;
    detailsProjectData? : any;
    removeProject? : any;
    projectChecked?: any;
}

interface sendInquiryModalstate {
    isAddProject: boolean;
    closeSendInquiryModal: boolean;
    fullName: string;
    emailId: string;
    phoneNumber: string;
    errors: {
        fullName: string;
        emailId: string;
        phoneNumber: string;
        terms: string,
    };
    isSubmitted: boolean;
    checked: boolean;
    onboardingChecked: boolean;
    projectDetails: any
}

const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
const RegexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class SendInquiryModal extends React.Component<sendInquiryModalprops , any & sendInquiryModalstate>{
    constructor(props:any){
        super(props);
        const initialState = {
            isAddProject: this.props.addProject? this.props.addProject :false,
            closeSendInquiryModal: true,
            fullName: '',
            emailId: '',
            phoneNumber: '',
            errors: {
                fullName: '',
                emailId: '',
                phoneNumber: '',
                terms: '',
            },
            isSubmitted: true,
            checked: false,
            onboardingChecked: false,
            projectDetails: []
        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);

    }
    componentDidMount () {   
         this.init();
    }

    init(){
        if(this.props.detailsProjectData.length > 0){
            this.setState({
                projectDetails: this.props.detailsProjectData
            })
        }
        if(this.props.inquiryData.length !== 0){
            this.setState({
                fullName: this.props.inquiryData.fullName,
                emailId: this.props.inquiryData.emailId,
                phoneNumber: this.props.inquiryData.phoneNumber,
            })
        }
        if(localStorage.getItem("id")){
            this.fetchUserData(localStorage.getItem("id"));
        }
    }
    fetchUserData = (userId: any)=>{
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchUserAPI + userId,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let u = response.data;
                this.setState({ 
                    fullName: u.detail.fullName, 
                    emailId: u.detail.email,
                    phoneNumber: u.detail.phone,
                });
            })
            .catch((error) => {
            });
        }
        )
    }

    closeSendInquiryModal = ()=>{
        this.props.closeSendInquiryModal(this.state.closeSendInquiryModal);
    }

    addProject = ()=>{
        this.setState({ isAddProject: !this.state.isAddProject });
        let data = {
            "fullName": this.state.fullName,
            "emailId": this.state.emailId,
            "phoneNumber": this.state.phoneNumber
        }
        let projectChecked = this.state.isAddProject;
        this.props.isAddProject(data, projectChecked);
        this.props.projectChecked(projectChecked);
        
    }

    handleChange = (event: any)=>{
        event.preventDefault();
        const {name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'fullName':
                errors.fullName = value.length < 70 ? '' : 'You Have Reached Your maximum Limit Of Characters Allowed';
                break;
            case 'emailId':
                errors.emailId = RegexEmail.test(value) ? '' : 'Please Enter Valid Email';
                break;
            case 'phoneNumber':
                errors.phoneNumber = Regex.test(value) ? '' :  'Please Enter A Valid Number' ;
                break;
            default:
                break;
            }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    }

    sendInquiry = ()=>{
        const{fullName, emailId, phoneNumber, onboardingChecked, isAddProject} = this.state;
        this.state.errors.terms = !this.state.checked ? 'Please click on the check box' : '';
        this.setState({ errors: this.state.errors });
        let validity = true;
        Object.values(this.state.errors).forEach((val:any) => val.length > 0 && (validity = false));
        if (validity == true ) {
            let builderUserIds = [];
            let projectDetailsData = [];
            if(isAddProject == true){
                projectDetailsData =  this.props.projectData? this.props.projectData: ''
            }else{
                projectDetailsData = []
            }
            
            if(this.props.selectAllBuilders == true){
                builderUserIds = []
            }else{
                builderUserIds = this.props.selectedBuilder;
            }
        let data = {
            "projectDtoList": projectDetailsData,
            "builderDtoList": builderUserIds,
            "userDetailsDto": {
            //   "id": 0,
              "fullName": fullName,
              "userName": emailId,
              "email": emailId,
              "phone": phoneNumber
            },
            "createUserAccount": onboardingChecked,
            "sendToAllBuilders": this.props.selectAllBuilders? this.props.selectAllBuilders: false
          }
        const key = constants.subscriptionKey;           
        const url =  constants.sendEnquiryForBuilders;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.post(url, data, { headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                    this.closeSendInquiryModal();
                    if(localStorage.userType == null && onboardingChecked == true){
                        this.props.result("your details sent to the selected builders and you will get email related to your account creation ");
                    }else{
                        this.props.result("your details sent to the selected builders. ");
                    }
                    
            }
                
            })
            .catch((error) => {
                this.props.result("Failed to sent details to builder ");
            });
        } else {
            
        }
    }

    // closeProjectTypeModal = (event: any)=>{
    //     this.setState({
    //         isAddProject : false,
    //     })
    // }

    handleCheckBox = () => {
        this.setState({ 
            checked: !this.state.checked,
         });
    };

    handleOnboardingCheckBox = () => {
        this.setState({ 
            onboardingChecked: !this.state.onboardingChecked,

         });
    };

    editProject = (value: any)=>{
            this.props.editProject(value);
    }
    removeProject = (value: any)=>{
    //    this.props.removeProject(value);
    const index = this.state.projectDetails.indexOf( JSON.stringify(value));
    this.state.projectDetails.splice(index,1);
    this.setState({
        detailsProjectData: this.state.projectDetails,
    })
    if(this.state.projectDetails.length == 0){
        this.setState({
            isAddProject: false
        })
    }
    }

    render(){
        const {isAddProject, fullName, emailId, phoneNumber, errors, checked, onboardingChecked, projectDetails} = this.state;
        let projectName;
        let name; 
        if(isAddProject == true){
            projectName =  projectDetails.map((items:any)=>{
                name = items.name? items.name: ""
               return(
                    <>
                       
                            <input
                                value = {items.name}
                                className= "mt-3 w-75 projectname-field"
                                disabled= {true}
                                
                            />
                            <img 
                                className="edit-image cursor" 
                                src={editProject}
                                onClick = {()=>{this.editProject({items})}}
                            />
                            <img 
                                className="edit-image ml-1 cursor" 
                                src={remove}
                                onClick = {()=>{this.removeProject(items)}}
                            />
                       
                    </>
                )
            })
        }else{
            projectName = ""
        }
       
        let isSubmitted = true;
        if(fullName !== "" && emailId !== "" && phoneNumber !== "" 
        && errors.fullName == "" && errors.emailId == "" && errors.phoneNumber == ""){
            isSubmitted= false
        };
      return(
        <>
            <div className='p-4 container  sendInquiry-modal'>
                    {(localStorage.getItem("id"))?
                             <>
                             <div className='d-flex sendInquiry-header'>
                                 <h4>Please Confirm</h4>
                                 <img 
                                     src = {Vector}  
                                     onClick={this.closeSendInquiryModal}
                                 />
                             </div>
                             <div className="sendInquiry-container mt-3">
                                 <p>Full Name</p>
                                 <input
                                     type="text"
                                     name = "fullName"
                                     value= {fullName}
                                     placeholder="full name"
                                     className="mb-3 w-75 px-3 py-1"
                                     onChange={this.handleChange}
                                     required
                                 />
                                 {errors.fullName.length > 0 && (
                                         <p style={{ color: 'red' }}>{errors.fullName}</p>
                                 )}
                                 <p>Email Address</p>
                                 <input
                                     type="text"
                                     name = "emailId"
                                     value= {emailId}
                                     placeholder="email address"
                                     className="mb-3 w-75 px-3 py-1"
                                     onChange={this.handleChange}
                                     required
                                 />
                                 {errors.emailId.length > 0 && (
                                         <p style={{ color: 'red' }}>{errors.emailId}</p>
                                 )}
                                 <p>Phone Number</p>
                                 <input
                                     type="number"
                                     name = "phoneNumber"
                                     value= {phoneNumber}
                                     placeholder="Phone number"
                                     className="mb-3 w-75 px-3 py-1"
                                     onChange={this.handleChange}
                                     maxLength = {10}
                                     onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }
                                     onWheel={ event => (event.target as HTMLElement).blur() }
                                     required
                                 />
                                 {errors.phoneNumber.length > 0 && (
                                         <p style={{ color: 'red' }}>{errors.phoneNumber}</p>
                                 )}
                                         
                                         {isAddProject  ?
                                             <>
                                                 <p>Project details</p>
                                                 {projectName}
                                                
                                             </>
                                                 :
                                                 <div className="mt-2">
                                                 <ToggleSwitch
                                                     id="addProject"
                                                     small
                                                     checked={isAddProject}
                                                     onChange={this.addProject} 
                                                 />
                                                 <span className="ml-1">Add Project Details(Optional)</span>
                                             </div>
                                             }
                         
                             </div>
                             <div className="mt-2">
                                 <input
                                     type= "checkbox"
                                     onChange={this.handleCheckBox}
                                     checked={checked}
                                 />
                                 <span className="ml-2">I accept the privacy and terms</span>
                             </div>
                             {errors.terms.length > 0 && <p style={{ color: 'red' }}>{errors.terms}</p>}
                             {/* <div className="mt-2">
                                 <input
                                     type='checkbox'
                                     onChange={this.handleOnboardingCheckBox}
                                     checked={onboardingChecked}
                                 />
                                 <span className="ml-2">By clicking on it you agree to create your account automatically</span>
                             </div> */}
                             <div className='mt-4'>
                                 <button  
                                     className= {isSubmitted == true? "button2 p-3 disabled-button" : "button2 p-3"}
                                     disabled = {isSubmitted}
                                     // style={{float: "right"}}
                                     onClick = {this.sendInquiry}
                                 > 
                                     Send Inquiry
                                 </button>
                             </div>
                         </>
                            :
                            <>
                                <div className='d-flex sendInquiry-header'>
                                    <h4>Enter Details!</h4>
                                    <img 
                                        src = {Vector}  
                                        onClick={this.closeSendInquiryModal}
                                    />
                                </div>
                                <div className="sendInquiry-container mt-3">
                                    <p>Full Name</p>
                                    <input
                                        type="text"
                                        name = "fullName"
                                        value= {fullName}
                                        placeholder="full name"
                                        className="mb-3 w-75 px-3 py-1"
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {errors.fullName.length > 0 && (
                                            <p style={{ color: 'red' }}>{errors.fullName}</p>
                                    )}
                                    <p>Email Address</p>
                                    <input
                                        type="text"
                                        name = "emailId"
                                        value= {emailId}
                                        placeholder="email address"
                                        className="mb-3 w-75 px-3 py-1"
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {errors.emailId.length > 0 && (
                                            <p style={{ color: 'red' }}>{errors.emailId}</p>
                                    )}
                                    <p>Phone Number</p>
                                    <input
                                        type="number"
                                        name = "phoneNumber"
                                        value= {phoneNumber}
                                        placeholder="Phone number"
                                        className="mb-3 w-75 px-3 py-1"
                                        onChange={this.handleChange}
                                        maxLength = {10}
                                        onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }
                                        onWheel={ event => (event.target as HTMLElement).blur() }
                                        required
                                    />
                                    {errors.phoneNumber.length > 0 && (
                                            <p style={{ color: 'red' }}>{errors.phoneNumber}</p>
                                    )}
                                            <div className="mt-2">
                                                <ToggleSwitch
                                                    id="addProject"
                                                    small
                                                    checked={isAddProject}
                                                    onChange={this.addProject} 
                                                />
                                                <span className="ml-1">Add Project Details(Optional)</span>
                                            </div>
                                            
                                            {isAddProject ?
                                                <>
                                                    <p>Project details</p>
                                                    <input
                                                        value = {name}
                                                        className= "mt-3 w-75 projectname-field"
                                                        disabled= {true}
                                                    />
                                                    <img 
                                                        className="edit-image cursor" 
                                                        src={editProject}
                                                        onClick = {this.editProject}
                                                    />
                                                </>
                                                    :
                                                    ""
                                                }
                            
                                </div>
                                <div className="mt-2">
                                    <input
                                        type= "checkbox"
                                        onChange={this.handleCheckBox}
                                        checked={checked}
                                    />
                                    <span className="ml-2">I accept the privacy and terms</span>
                                </div>
                                {errors.terms.length > 0 && <p style={{ color: 'red' }}>{errors.terms}</p>}
                                <div className="mt-2">
                                    <input
                                        type='checkbox'
                                        onChange={this.handleOnboardingCheckBox}
                                        checked={onboardingChecked}
                                    />
                                    <span className="ml-2">By clicking on it you agree to create your account automatically</span>
                                </div>
                                <div className='mt-4'>
                                    <button  
                                        className= {isSubmitted == true? "button2 p-3 disabled-button" : "button2 p-3"}
                                        disabled = {isSubmitted}
                                        // style={{float: "right"}}
                                        onClick = {this.sendInquiry}
                                    > 
                                        Send Inquiry
                                    </button>
                                </div>
                    </>
                    }
            </div>
            
        </>
      )   
    

}
}

export default SendInquiryModal;