import React, { Component } from 'react'
import Footer from '../../../common-components/Footer/Footer'
import Header from '../../../common-components/Header/Header'
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg'; 
import "./Pricing.scss"
import { RouteComponentProps } from "react-router-dom";
import Check from '../../../Services/Check';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import { Link } from 'react-router-dom';


interface PricingProps {
    name?: any;
    value?: any;
}
interface PricingState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    errors: {};
    subscriptionList: any,
    planName: string,
    nextIcon: any,
    prevIcon: any,
    showModal: boolean,
    header: string,
    body: any,
}
export default class Pricing extends React.Component<PricingProps & RouteComponentProps, PricingState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            error:false,
            ErrMsg:"",
            isLoading: false,
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            errors: {},
            subscriptionList:[],
            planName: '',
            showModal: false,
            header: '',
            body:{}
        };
        this.state = initialState;
    }

    componentDidMount() {
        this.init();
    }

    init (){
        let userType = localStorage.getItem("userType")
        this.fetchSubscriptionList(userType);
    }

    fetchSubscriptionList = (userType: any)=>{
        let userId = localStorage.getItem("id");
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchSubscriptionListAPI +"?userType="+ userType +"&userId="+ userId,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ 
                    subscriptionList: response.data,
                    planName: response.data.planName,
                });
               
            })
            .catch((error) => {
            });
        })
    }

    render() {
        const {planName, subscriptionList}: any = this.state;
        let planData = subscriptionList.map((items: any)=>{
             return(
                <>
                    <tr>
                    <td style={{width: "62%"}} className="font-weight-bold">{items.planName}</td>
                    <td  className="text-center">
                        {(items.enroll == true)?
                             <i className="fa fa-check-circle" aria-hidden="true"></i>
                             :
                             ""
                        }
                       </td>
                    <td  className="text-center"><Link to={{ pathname: "/viewPlan", state:{data: items.planId}}}>View Subscription</Link></td>
                </tr>
                 </>
             )

        });
     return(
        <>
        <Header/>
        {
                 
               }
        <div className="bg-img">
            <div className="overlay">
            <div className="container">
                <h1 className="font-weight-bold">Pricing</h1>
                <p className="my-3">Select Subscription</p>
            </div>
            </div>
            </div>
             <div className="container">
                         <div className="row mt-4">
                             <div className="col-sm-4">
                             <h2 className="font-weight-bold">Pricing</h2>
                                 <p style={{fontSize:"18px"}} >select the plan which is most suitable for you and your team.You can switch back anytime.</p>
                             </div>
                             <div className="col-sm-3"></div>
                             <div className="col-sm-5">
                             {/* <div style={{display:"flex",marginLeft:"14px"}} >
                                <div className="button-one first-section">
                                    <h2 className="para-text mt-5">Free</h2>
                                    <h1 className="font-weight-bold">$0</h1>
                                    <p className="month-text">per month</p>
                                    <button className="plan-button">Current Plan</button>
                                </div>
                                <div className="button-one second-section">
                                <h2 className="para-text font mt-5">Plus</h2>
                                <h1 className="font-weight-bold">$50</h1>
                                    <p className="month-text font">per month</p>
                                    <button className="plan-button-two  font">Choose Plan</button>
                                </div>
                            </div>  */}
                             </div>
                         </div>
                        <table className="table table-striped" style={{marginBottom:"150px"}}>
                            <tbody>
                                {planData}
                            </tbody>
                        </table>
            </div>
            <Footer/>
        </>
)

    }

}
