import React, { Component } from 'react';
import Footer from '../../../common-components/Footer/Footer';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import Header from '../../../common-components/Header/Header';
import { Link } from 'react-router-dom';
import { constants } from '../../../../constants/constants';
import { blobService } from '../../../common-components/Blob';
import Loader from '../../../common-components/Loader';
import { RouteComponentProps } from "react-router-dom";
import PadLock from "../../../../assets/images/padlock.svg"
import RightArrow from '../../../../assets/images/right-arrow.svg';
import axios from 'axios';
import longarrowaltright from '../../../../assets/images/longarrowaltright.svg';
import ruler from '../../../../assets/images/ruler.svg';
import './FindProjects.scss';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface FindProjectsProps {
    name?: any;
    value?: any;
}
interface FindProjectsState {
    error:boolean;
    ErrMsg:any;
    isLoading: boolean,
    projects: any,
    errors: {},
    membership: any,
    stateList: any,
    state: string,
    cityList: any,
    city: string,
    CreatedBy: string,
    locationList: any,
    stateCode: any,
    selectedCity: string,
    selectedState: string,
    selectedLocation: string
}

export default class FindProjects extends React.Component<FindProjectsProps & RouteComponentProps, FindProjectsState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            error:false,
            ErrMsg:"",
            isLoading: true,
            projects:[],
            errors: {},
            membership:0,
            stateList: [],
            state: "",
            cityList: [],
            city: "",
            CreatedBy: "",
            locationList: [],
            stateCode: [],
            selectedCity: '',
            selectedState: '',
            selectedLocation: ''
        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.init();
    }
    init(){
      
        if(localStorage.id){
        this.fetchBuilder(localStorage.id);
        
        }
        this.fetchStateList();
        this.fetchCityList();
        let CreatedBy = this.props.location.state? this.props.location.state.user: '';
        let location = this.props.location.state ? this.props.location.state.data: "";
        
        if (location.length > 0){
            this.searchProjectByLocation(location);
            this.setState({
                selectedLocation: location
            })
        } else if(CreatedBy.length > 0){
            this.searchProjects(CreatedBy);
            this.setState({
                CreatedBy: CreatedBy
            })
        }else{
            this.fetchPublicProjectList(); 
        }
       
    }

    async fetchBuilder(id: any): Promise<any> {
        const key = constants.subscriptionKey;
        const url =  constants.fetchBuilderAPI + id;
            const user = JSON.parse(localStorage.getItem('user')!); 
            let token;
             if(user){
                     token = 'Bearer ' + user;
                   
                }
            axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                //this.setState({membership: response.data.membership})
                this.setState({membership: 0})
                //this.premium = response.data.membership == 1 ? true : false;
                // let b = response.data;
                // this.setState({ builder: b, builderLogo: b.logoPath, builderName: b.builderName, city: b.address ? b.address.city : '', twitterLink: b.socialLinks ? b.socialLinks.twitterLink :"", facebookLink: b.socialLinks ?  b.socialLinks.facebookLink : "" , about:b.description,
                //     bannerImages: b.bannerImages
                // });
                // this.fetchProjects(id);
                // this.fetchPortfolio(id);
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    };

    async fetchPublicProjectList(): Promise<any> {
            const key = constants.subscriptionKey;           
            const url =  constants.fetchPublicProjectListAPI;
            const user = JSON.parse(localStorage.getItem('user')!); 
            let token;
             if(user){
                     token = 'Bearer ' + user;
                   
                }
            axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                this.setState({ projects: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    };

    async fetchStateList(): Promise<any> {
        const key = constants.subscriptionKey;           
        const url =  constants.fetchStatesListAPI;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                    let stateList = response.data;
                    stateList.map((item:any)=>{
                        this.state.locationList.push(item.name);
                        this.state.stateCode.push(item.name);
                    })
                    
                    this.setState({
                        locationList: this.state.locationList,
                        stateCode: this.state.stateCode,
    
                    });
                   
                }
                
            })
            .catch((error) => {
            });
      
    }
    async fetchCityList(): Promise<any> {
        const key = constants.subscriptionKey;           
        const url =  constants.fetchAllCity;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                    let cityList = response.data.list;
                    cityList.map((item:any)=>{
                        this.state.locationList.push(item);
                    })
                    this.setState({locationList: this.state.locationList});
                }
                
            })
            .catch((error) => {
            });
      
    }
    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;

        let errors = this.state.errors;
        switch (name) {
            case 'CreatedBy':
                this.searchProjects(value);
                break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { [name]: value }));
    };

    searchProjects = (value: any)=>{
        let location = this.state.selectedLocation? this.state.selectedLocation : ""; 
        const key = constants.subscriptionKey;
        const url =  constants.fetchPublicProjectListAPI +"?userType="+ value + "&location="+ location ;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                this.setState({projects: response.data, isLoading: false})
                
            })
            .catch((error) => {
            });
    }

    searchProjectByLocation = (value:any)=>{
        let userType = this.state.CreatedBy? this.state.CreatedBy: "";
        let selectedValue;
        if(value){
            selectedValue = value
        }else{
            selectedValue = ""
        }
        this.setState({
            selectedLocation: value
        })
        let url = constants.fetchPublicProjectListAPI +"?userType="+ userType+ "&location="+ selectedValue;
        const key = constants.subscriptionKey;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                    this.setState({ projects: response.data, isLoading: false });
                }
                
            })
            .catch((error) => {
            });
    }

    render() {
        var pList=[];
        const { errors, projects, isLoading, error, ErrMsg, membership,stateList, state, cityList, city, CreatedBy, locationList, selectedLocation } = this.state;
        for(let i=0; i<projects.length; i++){
            var p = projects[i];
            pList.push(
                <li className="row no-gutters">
                    <div className="col-md-3">
                        {p.projectImages[0] && 
                        <img src={blobService.createUrl(p.projectImages[0])} alt=""  width="100%" height="100px"/>
                        ||
                        <img src={ProjectPlaceholder} alt=""  width="100%" height="100px"/>
                        }
                    </div>
                    <div className="col-md-7 p-4">
                    <h4 className="projectname">{p.projectName}</h4>
                    <h6>Location : {p.cityName ? p.cityName : "NA"}</h6>
                    <p>{p.description}</p>
                    </div>
                    <div className="col-md-2 row">
                        <span className="ml-auto my-3">
                            {/* {(p.projectStatus.projectStatus === "In Progress") && 
                                <div className="card btn-text pending-btn">
                                    In Progress
                                </div>
                            }
                            {(p.projectStatus.projectStatus === "Completed") && 
                                <div className="card btn-text completed-btn">
                                    Completed
                                </div>
                            }
                            {(p.projectStatus.projectStatus === "Cancelled") && 
                                <div className="card btn-text cancel-btn">
                                    Cancelled
                                </div>
                            } */}
                        </span>
                        <span className="align-self-end ml-auto mb-3">
                            <Link className="text-center my-3" to={{ pathname: "/viewProject", state:{data: p}}}><h6 className="text-center">View Project <img className = "view-Project-Arrow" src={longarrowaltright} alt="" /></h6></Link>
                        </span>
                    </div>
                </li>               
            )
        }
        return isLoading ? (<Loader/>): 
        membership == 0 &&
        (
            <>
                <Header/>
                {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
                <div className="bg-img ">
                    <div className="overlay">
                        <div className="container">
                            <div className="col-md-6 col-12">
                                <h1 style={{fontWeight:600}}>Find Projects</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Projects">
                    <div className="container">
                        <div className="my-5 filterby-section">
                            <div className="row row1 align-items-center ">
                                <div className="col-md-4">
                                    <h4>Projects</h4>
                                </div>
                                <div className="col-md-8">
                                    <div className="row align-items-center">
                                        <span > Filter by:</span>
                                        <span className="left-buffer"></span>
                                        <select className="px-3 py-1  w-25" onChange={this.handleChange} name = "CreatedBy" value={CreatedBy} style = {{ marginLeft: "10px"}}>
                                            <option value="">Select</option>
                                            <option value="Builder">Builder</option>
                                            <option value="Customer">Customer</option>
                                        </select>
                                        <span className="left-buffer"></span>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            onChange={(event, newValue) => {
                                                this.searchProjectByLocation(newValue);
                                            }}
                                            className="w-25"
                                            value={selectedLocation}
                                            options={locationList}
                                            getOptionLabel={(option:any) => option}
                                            renderInput={(params) => <TextField {...params} placeholder="Location" variant="outlined" name = "searchLocationText" />}
                                        />
                                    </div>
                                </div>
                            </div>
                            {projects.length < 1 ? 
                                <div className="buildersBlock py-5 empty-Findproject-Container">
                                    <div className="container-fluid empty-Findproject-Content">
                                            <img src={ruler} alt="" />
                                        <p className="text-center my-3">There are no live projects. We will soon have some for you.</p>
                                    </div>
                                </div>
                            : 
                                <ul>{pList}</ul>
                            }
                            {/* {projects.length > 2 ? <h6 className="text-center my-3">See More</h6>:""} */}
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        ) ||
        (
            <>
            <Header/>
                {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
                <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="col-md-6 offset-md-2 col-12">
                                <h1>Find Projects</h1>
                                <p className="my-3">Find Projects</p>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="container py-5">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <img src={PadLock} width="50" height="50" alt="" />
                                        <h4>Get Aashray Plus</h4>
                                        <p>Get Aashray Plus and find projects to work on</p>
                                        <div>
                                            <button className="button1 contact"><Link className="text-white" to={{ pathname: "/Pricing" }}>Continue<img src={RightArrow} className="ml-3" /></Link></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                            </>
        )
    }
}
