import React, { Component } from "react";
import Newprojecttype from "../../../../../assets/images/Newprojecttype.svg";
import Upgradeproperty from "../../../../../assets/images/Upgradeproperty.svg";
import Vector from  "../../../../../assets/images/Vector.svg";
import asterisk from '../../../../../assets/images/asterisk.svg';


interface projectDetailsModalProps {
  projectType: any,
  result: any,
  projectData: any,
  subProjectType? : any,
  isPublic: any,
  projectTypeId: any,
  closeProjectDetailsModal: any;
}

interface projectDetailsModalState {
  projectName: string,
  description: string,
  address: string,
  phoneNumber: string,
  errors: {
    projectName: string,
    description: string,
    address: string,
    phoneNumber: string,
    aboutUpgradation: string,
    budget: string,
    landSize: string,
    timeLine: string,
    terms: string
  },
  isSubmitted: boolean,
  isProjectDetails: boolean,
  projectType: string,
  aboutUpgradation: string,
  budget: string,
  landSize: string,
  timeLine: string,
  isProjectSubmitted: boolean,
  measurement: string,
  builderType: string,
  checked: boolean,
  projectTypes: any
}

const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
class ProjectDetailsModal extends React.Component<projectDetailsModalProps , any & projectDetailsModalState>{
    constructor(props:any){
        super(props);
        const initialState = {
            projectName: '',
            description: '',
            address: '',
            phoneNumber: '',
            errors: {
              projectName: '',
              description: '',
              address: '',
              phoneNumber: '',
              aboutUpgradation: '',
              budget: '',
              landSize: '',
              timeLine: '',
              terms: ''
            },
            isSubmitted: true, 
            isProjectDetails: false,
            projectType: '',
            aboutUpgradation: '',
            budget: '',
            landSize: '',
            timeLine: '',
            isProjectSubmitted: true,
            measurement: 'Square Meter',
            builderType: 'Project Home Builder',
            checked: false,
            projectTypes: []
        };
        this.state = initialState;

    }
    componentDidMount () {   
      let projectDetails;
      let address;
        if(this.props.projectData.length !== 0){
          this.props.projectData.map((items:any)=>{
            projectDetails = items.projectDetails? items.projectDetails.landSize : "";
            address = items.address? items.address : "";
            this.setState({
                projectName : items.name? items.name: '',
                description: items.description? items.description: '',
                phoneNumber: items.mobile? items.mobile: '',
                budget: items.minBudget? items.minBudget: '',
                landSize: items.projectDetails? items.projectDetails.landSize : "",
                measurement: items.projectDetails? items.projectDetails.measurement : "",
                builderType: items.projectDetails? items.projectDetails.builderType : "",
                timeLine:  items.timeLine? items.timeLine: '',
                aboutUpgradation: items.aboutUpgradation? items.aboutUpgradation: '',
                address: address.addressLine1? address.addressLine1 : '',
            })
          })
        }
         this.init();
    }

    init(){
        this.setState({
          projectType: this.props.projectType
        })
    }

    handleChange = (event: any)=>{
      event.preventDefault();
      const {name, value } = event.target;
      let errors = this.state.errors;
      switch (name) {
          case 'projectName':
              errors.projectName = value.length > 255 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
              break;
          case 'description':
              errors.description = value.length > 250 ? 'You have reached your maximum limit of characters allowed': '';
              break;
          case 'phoneNumber':
              errors.phoneNumber = Regex.test(value) ? '' :  'Please Enter A Valid Number' ;
              break;
          case 'address': 
              errors.address = value.length > 250 ? 'You have reached your maximum limit of characters allowed': '';
              break;  
          case 'landSize': 
              errors.landSize = value.length > 4 ? 'You have reached your maximum limit of characters allowed': '';
              break; 
          case 'budget': 
              errors.budget = value.length > 10 ? 'Please Enter A Valid Number' :  '' ;
              break; 
          case 'aboutUpgradation': 
              errors.aboutUpgradation = value.length > 250 ? 'You have reached your maximum limit of characters allowed': '';
              break; 
          case 'timeLine': 
              errors.timeLine = value.length < 8 ? '' :  'Please Enter A Valid Number' ;
              break;
          case 'builderType':
                break;
          default:
              break;
          }
      this.setState(Object.assign(this.state, { errors, [name]: value }));

  }

  openProjectDetails = ()=>{
      this.setState({
          isProjectDetails: true
      })
  }

  closeProjectDetails = ()=>{
      this.setState({
        isProjectDetails: false,
        checked: false 
      })
  }

  submitProjectDetails = ()=>{
    const{projectName, description, phoneNumber, address , errors, isSubmitted,isProjectDetails, projectType, aboutUpgradation,landSize, budget, timeLine, builderType, measurement, projectTypes } = this.state;
    // let projectSubType = this.props.subProjectType;
    // let projectId: any;
    // projectTypes.map((items: any)=>{
    //   if(items.projectSubType == projectSubType){
    //     projectId = items.id
    //   }
    //   else{
    //     projectId = ''
    //   }
    // }) 
    this.state.errors.terms = !this.state.checked ? 'Please click on the check box' : '';
    this.setState({ errors: this.state.errors });
    let validity = true;
    Object.values(this.state.errors).forEach((val:any) => val.length > 0 && (validity = false));
    if (validity == true ) {
    let data = [{
      "name": projectName,
      "description": description,
      "address": {
          "addressLine1": address,
          "addressLine2": "",
          "city": "",
          "state": "",
          "country": "Australia",
          "pincode": "",
          "latitude": 0,
          "longitute": 0
      },
      "mobile": phoneNumber,
      "projectDetails": {
          "builderType": builderType,
          "landSize": landSize,
          "measurementUnit": measurement,
          "projectDocs": {
              "landSaleContract": this.state.landSaleContract,
              "architectDrawings": this.state.architectDrawings,
              "engineeringPlans": this.state.engineeringPlans,
              "landscapingDesigns": this.state.landscapingDesigns
          }
      },
      "projectType": {
              "id": this.props.projectTypeId, 
              "projectType": this.props.projectType, 
              "projectSubtype": this.props.subProjectType
            },
      "minBudget": budget,
      "maxBudget": 0,
      "durationInMonths": timeLine,
      "publicProject": this.props.isPublic,
      "active": true,
      "createdBy": "",
      "typeOfProject": "Contract"
    }]
      this.props.result(data);
    } else {
            
    }
  }

    handleCheckBox = () => {
      this.setState({ 
          checked: !this.state.checked,
      });
    };

    closeProjectDetailsModal = ()=>{
        this.props.closeProjectDetailsModal(false);
    }
   
    render(){
      const{projectName, description, phoneNumber, address, errors,isProjectDetails, projectType, aboutUpgradation,landSize, budget, timeLine, measurement, checked, builderType } = this.state;
      let isSubmitted = true;
      let isProjectSubmitted = true;
      if(projectName !== "" && description !== "" && phoneNumber !== ""  && address !== ""
          && errors.projectName == "" && errors.description == "" && errors.phoneNumber == ""){
                isSubmitted = false
        }
        if(projectType == "New Property"){
          if( budget !== "" && landSize !== "" && builderType !== ""){
              isProjectSubmitted= false
          }
        }else{
          if( budget !== "" && landSize !== "" && aboutUpgradation !== "" && timeLine !== ""){
              isProjectSubmitted= false
          }
        }
      return(
        <>
          <div className='p-4 container w-100 sendInquiry-modal'>
              <div className='d-flex sendInquiry-header'>
                  <h4>Enter Project Details!</h4>
                  <img 
                      src = {Vector}  
                      onClick={this.closeProjectDetailsModal}
                  />
              </div>
              <div className="d-flex property-header mt-3" >
                  <p  
                      className = {projectType == "New Property" ? "project-type-active ": "project-type-disabled" }
                  > 
                      <img src= {Newprojecttype} />
                          New Property 
                  </p>
                  <p 
                      className = {projectType == "Upgrade Property" ? "project-type-active": "project-type-disabled" }
                  > 
                      <img style={{width: "5%"}} src= {Upgradeproperty} />
                           Upgrade Property
                  </p>
              </div>
              <div className="d-flex mt-4 select-type-modal projectDetails-subheading" >
                {(isProjectDetails == false)?
                    <>
                      <p>Basic Details </p>
                      <p>Step 02/03</p>
                    </>  
                    :
                    <>
                      <p>Project Details </p>
                      <p>Step 03/03</p>
                    </>  
                }
              </div>
            {(isProjectDetails == false)?  
                <>
                  <div className="sendInquiry-container asterisk-symbol mt-3">
                      <p>
                        Project Name
                        <img src= {asterisk}/>
                      </p>
                      <input
                        type = "text"
                        name = "projectName"
                        value={ projectName}
                        placeholder="Project Name"
                        className="w-100 mb-3 px-3 py-1"
                        onChange={this.handleChange}
                      />
                      {errors.projectName.length > 0 && (
                                <p style={{ color: 'red' }}>{errors.projectName}</p>
                        )}
                      <p>
                          About
                          <img src= {asterisk}/>
                      </p>
                      <input
                        type = "text"
                        name = "description"
                        value={description}
                        placeholder="About the Project"
                        className="w-100 mb-3 px-3 py-1"
                        onChange={this.handleChange}
                      />
                      {errors.description.length > 0 && (
                                <p style={{ color: 'red' }}>{errors.description}</p>
                        )}
                      <p>
                          Address
                          <img src= {asterisk}/>
                      </p>
                      <input
                        type = "text"
                        name = "address"
                        value={address}
                        placeholder="Address of the Land"
                        className="w-100 mb-3 px-3 py-1"
                        onChange={this.handleChange}
                      />
                      {errors.address.length > 0 && (
                                <p style={{ color: 'red' }}>{errors.address}</p>
                        )}
                      <p>
                          Phone Number
                          <img src= {asterisk}/>
                      </p>
                      <input
                        type = "number"
                        name = "phoneNumber"
                        value={phoneNumber}
                        placeholder="Phone"
                        className="w-100 mb-3 px-3 py-1"
                        onChange={this.handleChange}
                        maxLength = {10}
                        onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }
                        required
                      />
                      {errors.phoneNumber.length > 0 && (
                                <p style={{ color: 'red' }}>{errors.phoneNumber}</p>
                        )}
                  </div>  
                  <div className='mt-4'> 
                      <button
                          className= {isSubmitted == true ? "button2 p-2 disabled-button" : "button2 p-2"}
                          disabled= {isSubmitted}
                          onClick = {this.openProjectDetails}
                      >
                        Next
                      </button> 
                  </div>
                </>  
              :
                <>
                {(projectType == "New Property")?
                      <>
                        <div className="sendInquiry-container asterisk-symbol mt-3">
                          {/* <p>
                              Purpose of Property 
                              <img src= {asterisk}/>
                          </p>
                          <select  className="w-80 mb-3 px-3 py-2">
                              <option value="" disabled selected>Why are you looking for?</option>
                              <option>Commercial</option>
                              <option>Residential</option>
                          </select> */}
                          <p>
                              Builder Type
                              <img src= {asterisk}/>
                          </p>
                          <select name="builderType" id="builderType" onChange={this.handleChange} className="w-50 mb-3 px-3 py-2">
                              <option value="Custom Builder">Custom Build</option>
                              <option value="Project Home Builder">Project Home Builder</option>
                          </select>
                          <p>This type of builders has limitation on designs and materials in construction.</p>
                          <p>
                              Land Size
                              <img src= {asterisk}/>
                          </p>
                          <div className="land-size">
                          <input
                            type = "number"
                            name = "landSize"
                            value={landSize}
                            placeholder="Land Size"
                            className="w-50 mb-3 px-3 py-1"
                            onChange={this.handleChange}
                          />
                          
                            <span>in</span>
                            <select  name="measurement"  onChange={this.handleChange} value = {measurement}>
                                  <option value="Square Meter">Sq Meters</option>
                                  <option value="Square Feet">Sq Ft</option>
                            </select>
                          </div>
                          {errors.landSize.length > 0 && (
                                <p style={{ color: 'red' }}>{errors.landSize}</p>
                        )}
                          <p>
                              Budget
                              <img src= {asterisk}/>
                          </p>
                          <input
                            type = "number"
                            name = "budget"
                            value={budget}
                            placeholder="Enter the budget"
                            className="w-50 mb-3 px-3 py-1"
                            onChange={this.handleChange}
                            required
                          />
                          {errors.budget.length > 0 && (
                                <p style={{ color: 'red' }}>{errors.budget}</p>
                          )}
                      </div>  
                      <div className="mt-2">
                            <input
                                type= "checkbox"
                                onChange={this.handleCheckBox}
                                checked={checked}
                            />
                            <span className="ml-2">I accept the privacy and terms</span>
                        </div>
                        {errors.terms.length > 0 && <p style={{ color: 'red' }}>{errors.terms}</p>}
                      <div className='mt-4 d-flex'> 
                          <button
                                  className=  "button2 p-2"
                                  onClick = {this.closeProjectDetails}
                              >
                            Previous
                          </button> 
                          <button
                               className= {isProjectSubmitted == true ? " disabled-button button2 p-2 ml-2" : "button2 p-2 ml-2"} 
                               disabled= {isProjectSubmitted}
                              onClick = {this.submitProjectDetails}
                          >
                            Create Project
                          </button> 
                      </div>
                     
                      </>
                      :
                      <>
                        <div className="sendInquiry-container mt-3">
                          <p>
                              Timeline
                              <img src= {asterisk}/>
                          </p>
                          <input
                            type="text"
                            name= "timeLine"
                            value = {timeLine}
                            placeholder="Enter Timeline"
                            onChange={this.handleChange}
                            className="w-50 mb-3 px-3 py-1"
                          />
                          {errors.timeLine.length > 0 && (
                                <p style={{ color: 'red' }}>{errors.timeLine}</p>
                          )}
                          <p>
                              Budget
                              <img src= {asterisk}/>
                          </p>
                          <input
                            type = "number"
                            name = "budget"
                            value={budget}
                            placeholder="Enter the budget"
                            className="w-50 mb-3 px-3 py-1"
                            onChange={this.handleChange}
                            required
                          />
                          {errors.budget.length > 0 && (
                                <p style={{ color: 'red' }}>{errors.budget}</p>
                          )}
                          <p>
                              Land Size
                              <img src= {asterisk}/>
                          </p>
                          <div className="land-size">
                            <input
                              type = "number"
                              name = "landSize"
                              value={landSize}
                              placeholder="Land Size"
                              className="w-50 mb-3 px-3 py-1"
                              onChange={this.handleChange}
                            />
                            <span>in</span>
                            <select  name="measurement"  onChange={this.handleChange} value = {measurement}>
                                <option value="Square Meter">Sq Meters</option>
                                <option value="Square Feet">Sq Ft</option>
                            </select>
                          </div>
                          {errors.landSize.length > 0 && (
                                <p style={{ color: 'red' }}>{errors.landSize}</p>
                          )}
                          <p>
                              Tell Us More About
                          </p>
                          <textarea
                            placeholder="Tell us more about the upgradation"
                            value={aboutUpgradation}
                            name= "aboutUpgradation"
                            onChange={this.handleChange}
                          />  
                          {errors.aboutUpgradation.length > 0 && (
                                <p style={{ color: 'red' }}>{errors.aboutUpgradation}</p>
                          )}   
                      </div>  
                      <div className="mt-2">
                            <input
                                type= "checkbox"
                                onChange={this.handleCheckBox}
                                checked={checked}
                            />
                            <span className="ml-2">I accept the privacy and terms</span>
                        </div>
                        {errors.terms.length > 0 && <p style={{ color: 'red' }}>{errors.terms}</p>}
                      <div className='mt-4 d-flex'> 
                          <button
                                  className=  "button2 p-2"
                                  onClick = {this.closeProjectDetails}
                              >
                            Previous
                          </button> 
                          <button
                              className= {isProjectSubmitted == true ? " disabled-button button2 p-2 ml-2" : "button2 p-2 ml-2"} 
                              disabled= {isProjectSubmitted}
                              onClick = {this.submitProjectDetails}
                          >
                            Create Project
                          </button> 
                      </div>
                      </>

                }
                
              </>  
            }
          </div>  
                  
        </>
      )   
    

}
}

export default ProjectDetailsModal;