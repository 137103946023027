import React from "react";
import "./Register.scss"
import RightArrow from "../../../assets/images/rightarrow1.svg"
import {constants} from "../../../constants/constants"
import axios from 'axios';
import passwordLock from '../../../assets/images/passwordLock.svg';

interface RegisterProps {
    name?: any;
    value?: any;
    result: any;
    closeModel?: any;
 }
 interface RegisterState {
    name : string,
    email : string,
    password : string,
    usertype: string,
    agentId : string,
    checked : boolean,
    errors : {
       name :  string,
       email : string,
       password : boolean,
       usertype : string,
       terms : string,
       registrationNumber: string,
       agentid : string,
       upperCase: boolean,
       lowerCase: boolean,
       digit: boolean,
       specialChar: boolean,
       minLength: boolean,
    },
    registerErr: string
    show: boolean;
    sweetAlert: any;
    registrationNumber: string;
    isSubmitted: boolean,
    //error:boolean
 }
//const Regex = RegExp(/^\s?[A-Z0–9]+[A-Z0–9._+-]{0,}@[A-Z0–9._+-]+\.[A-Z0–9]{2,4}\s?$/i);
const Regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const restrictedCharacters = ['!', '@', '+', '-', '$', '#', '%', '^', '&', '*', '_', '(', ')', '?', '<', '>', '{', '}', '~', '`', '[', ']', '?', ':', ';', '|', '/', '"', ".", ",", "'", '='];
const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;


export default class Register extends React.Component<RegisterProps, RegisterState>{ 

   constructor(props: RegisterProps) {
      super(props);
      const initialState = {
         name : '',
         sweetAlert : "",
         email : '',
         password : '',
         usertype: '',
         agentId : '',
         checked: false,
         errors : {
           name : '',
           email : '',
           password : false,
           usertype : '',
           terms : '',
           registrationNumber: '',
           agentid : '',
           upperCase: false,
           lowerCase: false,
           digit: false,
           specialChar: false,
           minLength: false,
         },
         show: false,
         registerErr: '',
         registrationNumber: '',
         isSubmitted: true,
         //error:false
       }
       this.state = initialState;
       this.handleChange = this.handleChange.bind(this)
       this.onClickHandler = this.onClickHandler.bind(this);
       
 }

    handleSubmit = (event : any) => {
        this.state.errors.terms = !this.state.checked ? 'Please accept Terms & Conditions': '';
        this.setState({errors: this.state.errors});
        event.preventDefault();
        let validity = true;
        Object.values(this.state.errors).forEach(
          (val: any) => val.length > 0 && (validity = false)
        );
        if(validity == true){
           let data = {
              "fullName": this.state.name,
              "userName": this.state.email,
              "password": this.state.password,
              "userType":this.state.usertype,
              "agentId" :this.state.agentId,
              "email": this.state.email,
              "registrationNumber": this.state.registrationNumber,
              "socialUser": false
            }
          
         //  userService
         //        .postApiRequest(options)
         const key = constants.subscriptionKey;
         const url =  constants.registrationAPI;
            axios.post(url, data, { headers: {"Ocp-Apim-Subscription-Key" :key} })
                .then((response: any) => {
                     this.clearValues();
                     this.props.result(response.data.message);
                     
                })
                .catch((error) => {
                     this.setState({ registerErr: error.response.data.message  });
                });
        }else{
           
        }
     }

  
     
   public onClickHandler (evt: React.MouseEvent) {
      evt.preventDefault();
      this.setState(prev => ({
        show: !prev.show
      }));
    }

    clearValues(){
      this.setState({
         name:'', 
         email:'', 
         password:'', 
         checked: false, 
         usertype:'', 
         registrationNumber: '',
         agentId:'',
         registerErr:  '',
      });
    }

    
   handleChange = (event : any) => {
    event.preventDefault();
    const { name, value } = event.target; 
    let errors = this.state.errors;
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{8,}/;
    switch (name) {
      case 'name':
         errors.name = value.length == 0 ? 'Please enter valid Name': '';
         break;
      case 'email':
         errors.email = Regex.test(value)? '': 'Please enter valid Email';
         break;
      case 'password':
         errors.upperCase = uppercaseRegExp.test(value)? true : false;
         errors.lowerCase = lowercaseRegExp.test(value)? true : false;
         errors.digit = digitsRegExp.test(value)? true : false;
         errors.specialChar = specialCharRegExp.test(value)? true : false;
         errors.minLength = minLengthRegExp.test(value)? true : false;
         break;
      case 'usertype':
         errors.usertype = value.length == 0 ? 'Please select valid option': '';
         break;
         case 'registrationNumber':
            errors.registrationNumber = regex.test(value) && (value.length) < 20 ? '' : 'Please enter valid Registration Number which should contains alphanumeric';
            break;
         case 'agentId':
            errors.agentid = regex.test(value) && (value.length) < 20 ? '' : 'Please enter valid Agent Id';
            break;
      default:
        break;
    }
   this.setState(Object.assign(this.state, { errors,[name]: value }));
   const {  email, password, usertype, agentId, registrationNumber} = this.state 
   if(usertype=="FinanceBrokers"){  
      if ( this.state.name != "" && email != "" && password != "" && agentId != "" && (errors.upperCase
          && errors.lowerCase && errors.digit && errors.minLength && errors.specialChar) == true) {
         this.setState({
            isSubmitted: false,
            
         })
      
      }else{
            this.setState({
               isSubmitted: true,
               
            })
         }
        
      }else if(usertype=="Raw Material Supplier"){  
         if ( this.state.name != "" && email != "" && password != "" && registrationNumber != "" && (errors.upperCase
             && errors.lowerCase && errors.digit && errors.minLength && errors.specialChar) == true) {
            this.setState({
               isSubmitted: false,
               
            })
         
         }else{
               this.setState({
                  isSubmitted: true,
                  
               })
            }
           
         } else{
         if ( this.state.name != "" && email != "" && password != ""&& usertype != ""  && (errors.upperCase
            && errors.lowerCase && errors.digit && errors.minLength && errors.specialChar) == true) {
            this.setState({
               isSubmitted: false,
               
            })
         
      }else{
            this.setState({
               isSubmitted: true,
               
            })
         }
      }
   }

   handleCheckBox = () => {
      this.setState({checked: !this.state.checked});
   }
   
   closeSignupModal = ()=>{
      this.clearValues();
      this.props.closeModel(false);
   }

    render() {
        const {errors, checked, name, email, password, usertype, agentId,registrationNumber , registerErr, isSubmitted} = this.state   
        const {show} = this.state;
        const styles = show ? "modal display" : "modal";
        return (
           <>
          <div className='register'>
               {/* {
                   error ?  <ApiErrorHandling error={registerErr}/> : ""
               } */}
            <div className='container px-5 py-3'>
               <div>
                  <p className="loginhead">LOGIN/SIGNUP</p>
                  <h1 >Signup</h1>
                  <div className="my-4">
                  <div className='fullName'>
                     <label className="mt-2">Name</label>
                     <input type="text" name="name" className="w-100 px-3" placeholder="Name" onChange={this.handleChange} value={name} />
                     {errors.name.length > 0 &&  <span style={{color: "red"}}>{errors.name}</span>}
                  </div>
                  <div className='email'>
                     <label className="mt-2">Email</label>
                     <input type='email' name='email' className="w-100 px-3" placeholder="Email" onChange={this.handleChange} value={email} />
                     {errors.email.length > 0 &&  <span style={{color: "red"}}>{errors.email}</span>}
                  </div> 
                  <div className='password'>
                     <label className="mt-2">Password</label>
                     <input 
                        type='password' 
                        name='password' 
                        className="w-100 px-3" 
                        placeholder="Password" 
                        onChange={this.handleChange}
                        value={password}  
                        onFocus = {()=>{errors.password = true}}
                        onBlur = {()=>{errors.password = false}}
                         />
                     {(errors.password == true)?
                           <div className='error-message mt-2 border p-3 w-100'>
                                 <p className='invalid'><img style = {{marginRight: "10px"}} src= {passwordLock}/><b>Your password needs to:</b></p>
                                 <div className='p-1'>   
                                       <p  className= {(errors.upperCase == true? 'valid' : 'invalid')}>
                                          <img className= {(errors.upperCase == true? ' valid-icon' : ' invalid-icon')}></img>
                                          At least one Uppercase
                                       </p>
                                       <p  className= {(errors.lowerCase == true? 'valid' : 'invalid')}>
                                             <img className= {(errors.lowerCase == true? ' valid-icon' : ' invalid-icon')}></img>
                                             At least one Lowercase
                                       </p>
                                       <p  className= {(errors.digit == true? 'valid' : 'invalid')}>
                                             <img className= {(errors.digit == true? ' valid-icon' : ' invalid-icon')}></img>
                                             At least one digit
                                       </p>
                                       <p  className= {(errors.specialChar == true? 'valid' : 'invalid')}>
                                             <img className= {(errors.specialChar == true? ' valid-icon' : ' invalid-icon')}></img>
                                             At least one Special Characters
                                       </p>
                                       <p  className= {(errors.minLength == true? 'valid' : 'invalid')}>
                                             <img className= {(errors.minLength == true? ' valid-icon' : ' invalid-icon')}></img>
                                             At least minimum 8 characters
                                       </p>
                                                    </div>
                                             </div>
                                             :
                                             ""
                                            }
                  </div> 
                  
                  <div className='usertype'>
                     <label  className="mt-2">What are you?</label>
                     <select name="usertype" id="usertype"  className="w-100 px-3 py-1" onChange={this.handleChange} value={usertype} required>
                           <option value="">--SELECT--</option>
                           <option value="Customer">Customer</option>
                           <option value="Builder">Builder</option>
                           <option value="Architect">Architect</option>
                           <option value="Raw Material Supplier">Raw Material Supplier</option>
                           <option value="Town Planner">Town Planner</option>
                           <option value="FinanceBrokers">Financial Broker</option>
                     </select>
                     {errors.usertype.length > 0 &&  <span style={{color: "red"}}>{errors.usertype}</span>}
                  </div>
                  {usertype == 'Raw Material Supplier' ?
                           <div >
                              <label className="mt-2">Registration Number</label>
                              <input
                                 type="text"
                                 name="registrationNumber"
                                 className="w-100 px-3"
                                 placeholder="Enter Registration Number"
                                 onChange={this.handleChange}
                                 value={registrationNumber}
                                 onKeyDown={(evt) => restrictedCharacters.includes(evt.key) && evt.preventDefault()}
                              />
                              {errors.registrationNumber.length > 0 && <span style={{ color: "red" }}>{errors.registrationNumber}</span>}
                           </div> : ""}
                  { usertype=='FinanceBrokers' ?
                  <div className='agentId'>
                     <label className="mt-2">Agent Id</label>
                     <input type="text" name="agentId" className="w-100 px-3" placeholder="Enter Agent Id" onChange={this.handleChange} value={agentId} required/>
                     {errors.agentid.length > 0 &&  <span style={{color: "red"}}>{errors.agentid}</span>}
                  </div> : "" }


                  <div className='terms'>
                     <input
                        type="checkbox"
                        className="mt-3"
                        checked={checked}
                        onChange={this.handleCheckBox}
                     />
                     <span className="mx-2">I accept the privacy and terms</span>
                  </div>
                  {errors.terms.length > 0 &&  <span style={{color: "red"}}>{errors.terms}</span>}
                  {registerErr.length > 0 && <span style={{ color: 'red' }}>{registerErr}</span>}
                  <br/><br/>
                  <div className="login-cancel d-flex">
                         <button onClick={this.handleSubmit} className= {isSubmitted ? "mr-3 disabled-button"  : "mr-3" } disabled = {isSubmitted}>Signup<img src={RightArrow}  className="ml-2 arrow1"/></button>
                  <button onClick={this.closeSignupModal}>Cancel<img src={RightArrow} className="ml-2 arrow2" />
                                </button>
                  </div>
               <p className="mt-2">Already  signed up? <span className="signup" onClick={this.props.value}>Login</span></p>
                  </div>
               </div>
         </div>
      </div>
      </>
     );
    }
}