import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import map from '../../../../assets/images/location.svg';
import './ContactUs.scss';
import phone from '../../../../assets/images/phone.svg';
import message from '../../../../assets/images/message.svg';
import dot from '../../../../assets/images/dot.svg';
import {withRouter} from 'react-router-dom';
import { constants } from '../../../../constants/constants';
import axios from 'axios';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
interface ContactUsProps {
    name?: any;
    value?: any;
}
interface ContactUsState {
    name:string;
    toEmail:string;
    description:string;
    data:{};
    header: string,
    body: string,
    showAlert:boolean,
    validate:boolean;
    errors: {
        name: string,
        toEmail:string,
        description: string
    };
}
const Regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default class ContactUs extends React.Component<ContactUsProps, ContactUsState> {
    constructor(props: ContactUsProps) {
        super(props);
        const initialState = {
            name:"",
            toEmail:"",
            description:"",
            showAlert:false,
            validate:false,
            header: "",
            body:"",
            data:{},
            errors: {
            name: '',
            toEmail:'',
            
            description: ''
        },
        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
      }
      postMessage=()=>{
        let validity = true;
        Object.values(this.state.errors).forEach(
           (val: any) => val.length > 0 && (validity = false)
       );
      let data={
        name:this.state.name,
        toEmail:this.state.toEmail,
        description:this.state.description
    }
       if(validity === true){
        const key = constants.subscriptionKey;           
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        const url=constants.contactUs;
        axios.post(url, data, { headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} }).then((response)=>{
            this.openUpload("Success", "Message sent SuccessFully");
        }).catch((error)=>
        {    
            this.openUpload("Error", "Message send failed");
        })
       
    }
      }
      handleChange(event:any){
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name){
                case 'name':
                    errors.name = value.length == 0 ? 'Name can not be empty': '';
              break;    
              case 'toEmail':
                    errors.toEmail =Regex.test(value)? '': 'Please enter valid Email';
              break; 
              case 'description':
                errors.description =value.length==0 ? "Description can not be empty" : ""  ;
              break; 
            }
            this.setState(Object.assign(this.state, { errors,[name]: value }));
      }
      validData=()=>{
        const { name, toEmail,description } = this.state;
       if(name==="" || toEmail==="" || description===""){
           return true
       }
       else{
           return false
       }
      }
      openUpload = async(header:any, body: any) => {
        this.setState({showAlert:true, header: header, body: body});
    }
    onHideUpload = async(event:any) => {
        this.setState({showAlert:false});
        this.clearFields()
    }
    clearFields=()=>{
        this.setState({name:"",description:"",toEmail:""})
        let errors=this.state.errors;
        errors.description="";
        errors.name="";
        errors.toEmail="";
    }
    render() {
        const {name,toEmail,errors,validate,header,body,description,showAlert}=this.state;
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-12">
                                <h1 style={{fontWeight:600}}>Contact Us</h1>
                            </div>             
                            </div>
                        </div>
                    </div>
        
                </div>

            <div className="">
            <div className="column">
                <div className='newmap-sctn'>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.6756682571126!2d144.9602543758857!3d-37.8210651719738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b296c31e25%3A0x59345d979cfd4ac6!2sLevel%202%2F1%20Southbank%20Blvd%2C%20Southbank%20VIC%203006%2C%20Australia!5e0!3m2!1sen!2sin!4v1722427753683!5m2!1sen!2sin"  style={{width: '100%', border:'0px'}} loading="lazy"></iframe>
				</div>
                </div>
                </div>
                
				<div className='container' style = {{marginTop: "-3%"}}>
                    <div className='row'>
                    <div className='col-md-4'></div>
                        <div className='col-md-4'>
                                <div className='address-container new-address'>
                                    <div className='address-image-sctn'>
                                    </div>
                                    <div className = 'add-text-container address-textbox'>
                                        <h5>COMPANY ADDRESS</h5>
                                        <p> 
                                            Level 2 1 Southbank Boulevard,<br/>
                                            Southbank, Victoria<br/>
                                            3006, Australia
                                        </p>
                                    </div>		
                                </div>
                                <div className='phone-sctn d-flex mt-4'>
                                    <div className='phn-image-sctn'>
                                        <img src={phone} alt='phone' />
                                    </div>
                                    <div className='phn-text-sctn'>
                                        <p className='newphn'>COMPANY PHONES</p>
                                        <p className='newnum'>+61 3 7036 5746</p>
                                        <p className='num'></p>
                                    </div>
                                </div>
                                <div className='email-sctn d-flex mt-4'>
                                    <div className='email-image-sctn'>
                                        <img src={message} alt='message' />
                                        <div className='maildots ml-2'>
                                            <img  className="dot-image mb-100" src={dot} alt='dot' />
                                            <img className="dot-image" src={dot} alt='dot' />
                                            <img className="dot-image" src={dot} alt='dot' />
                                        </div>
                                    </div>
                                    
                                    
                                    <div className='email-text-sctn'>
                                        <p className='newphn'>Dustro Emails</p>
                                        <p className='num'>info@ashrayhome.com.au</p>
                                        <p className='num'></p>
                                    </div>
                                </div>
                        </div>
                        <div className='col-md-4'></div>
                    </div>
                </div>
            <br/><br/>
            <CustomModal show={showAlert}
            isAlert={true}
            header={header}
            body={body}
            onHide={(event:any)=>this.onHideUpload(event)}
            ></CustomModal>
            <Footer/>
            </>
         );
    }
}
