import React from 'react';

class PrivacyandTerms extends React.Component {
    constructor(props: any){
        super(props);
        const initialState = {
        };
        this.state = initialState;
    }

    render(){
        return(
            <>
            <div className = "container p-5" >
                <h4 >Privacy Policy for Aashray Homes</h4>
                <h4>How Aashray Homes uses and collects the information?</h4>
                <p >
                    We don't accumulate any information or data to plug and we assemble the information when you use our Services. 
                    Any information that you offer or use for making the record remains with us yet we don't share it. 
                    We can use the information to improve your experience of being at <b>Aashray Homes</b> with redone content imagined from your tendencies, 
                    for engaging any spam or sorts of abuse. 
                    In case you affirm your <b>Aashray Homes</b> account with any third-party, 
                    we save the alternative to revive, collect,and store the information of yours related with that specific third-party without your unequivocal consent.
                </p>
                <h4 >The declaration and revelation of the information collected</h4>
                <p >
                    AASHRAY HOMES will never offer your own information to any of the third-party, 
                    with the objective that they can elevate themselves to you. 
                    We will simply trade or offer your information just in the conditions of 
                    1) Your consent 2) if the expert centre fulfils our data security rules 3) with any non-advantage researchers 
                    4) as or required by the law and region. If we share your information for legal purposes, 
                    you will be educated for it so you can challenge it with the exception of in the event that it is blocked by the law.
                </p>
                <h4 >The storage of the collected information</h4>
                <p >
                    We use vendors and hosts related with third-party frameworks for organization, accumulating, development, and gear. 
                    By using our organizations, you give us the approval to move and store your information.
                </p>
                <h4 >The information related to third-parties</h4>
                <p >
                    AASHRAY HOMES can show any notices which are presented to third-parties and we don't hold any obligation regarding
                     the substance being appeared or for the information that you share with them and its usage. 
                    A part of the third-party inserts may demand your information and AASHRAY HOMES 
                    doesn't hold any commitment with respect to what comes to pass for that information or the substance for which it is shared.
                </p>
                <h4 >Cookies and Tracking Policy</h4>
                <p >
                    We use cookies to know when you return to our organizations and we can use them to remember about your tendencies, 
                    send you messages for admonitions, and personalization of the substance. We don't follow your off 
                    AASHRAY HOMES practices with the exception of if any URL is saved to <b>AASHRAY HOMES</b>. We simply spread techniques of our treats and not of any outsiders.
                </p>
                <h4 >Changes to the information provided</h4>
                <p >
                    You can without a very remarkable stretch change, alter, or eradicate your own information after you have made a record. 
                    Be that as it may, we spare residual copies of your information for a specific time span, you should hold the obligation of your information.
                </p>
                <h4 >Protection of your data</h4>
                <p >
                    AASHRAY HOMES uses encryption for any data that is taken care of or communicated by us yet no data which is needed upon the web is totally secure. 
                    In this way, there is no affirmation of security and you are at risk for using our organizations.
                </p>
                <h4 >In case of business transfers</h4>
                <p >
                    If <b>AASHRAY HOMES</b> gets in the solace of merger improvement, 
                    where your information can be reliant upon any third-party, you will be prompted priorly so you can stop our organizations and delete your record
                </p>
                <h4 >Notifications from AASHRAY HOMES</h4>
                <p >
                    You may get messages from <b>AASHRAY HOMES</b> which can be as to changes in organizations or approaches. 
                    You can stop from any of the worth based sends yet sends related to releases or buildup should be conceivable by 
                    encountering your record's settings.
                    We will never send you an email that demands your mystery word or some other individual information.
                </p>
                <h4>Any changes done to the privacy policy</h4>
                <p>
                  AASHRAY HOMES has the benefit to change the Privacy Policy with no previous 
                  notice yet any movements that are immense will be made known to you.
                </p>
                <h4>Disclaimer of AASHRAY HOMES</h4>
                <p>
                    This site is intended for blogging purposes, content updating, and providing IT services. 
                    AASHRAY HOMES doesn't hold any commitment for the possibility that any substance that is moved on the website. 
                    <b>AASHRAY HOMES</b> isn't proposed to hurt the morals and respectability of any human. 
                    It is simply to educate people and spread data about varying fields.
                </p>
                <h4 >Contact Us</h4>
                <p >If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:info@ashrayhome.com.au">info@ashrayhome.com.au</a>.</p>

                <h4 >Terms and Conditions for Aashray Homes</h4>
                <h4>Your Account</h4>
                <p >
                    You will be at risk to keep up the secrecy of your record which is made by AASHRAY HOMES and 
                    you should take all the potential measures to protect your accreditations.
                </p>
                <h4 >The Privacy</h4>
                <p >
                    At the point when you decide to utilize our administrations, 
                    you consent to the mix of both our terms and conditions and protection arrangements 
                    which are encircled by AASHRAY HOMES under the law and locale.
                </p>
                <h4 >Openness of AASHRAY HOMES</h4>
                <p >
                    It may happen that because of some upkeep going on the site, the openness of AASHRAY HOMES can be obstructed yet we will do 
                    our absolute best to convey you with an issue free encounter of being on our site.
                </p>
                <h4 >Your Conduct</h4>
                <p >
                   Any abuse of the data present on the site, any altering, pirating, or copyright 
                   infringement is carefully restricted and won't be engaged under any conditions.
                </p>
                <h4 >Shocking conditions and claims</h4>
                <p >
                    With extravagant merchants, vendors, and clients, it turns into an extreme errand to 
                    monitor every one of the substances being shown, so in the event that you go over any shocking 
                    substance on the site, we demand you to report it to us at the earliest opportunity.
                </p>
                <h4 >Youngsters' Policy</h4>
                <p >
                   On the off chance that you are a minor or under 18 years old, at that point you are disallowed from utilizing this site. 
                   By utilizing our administrations, you concur that you are over 18 years old and any fabrication in this data will prompt the end of your record
                </p>
                <h4 >Changes</h4>
                <p >
                    AASHRAY HOMES holds all the rights to change/adjust/modify any of the terms and conditions with no earlier data.
                </p>
                <h4 >Contact Us</h4>
                <p >If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at <a href="mailto:info@ashrayhome.com.au">info@ashrayhome.com.au</a>.</p>
            </div>
           
    
            </>
        )
    }

}
export default PrivacyandTerms;

