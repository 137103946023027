import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../common-components/Footer/Footer';
import Error from '../../common-components/Error/Error';
import './HomePage.scss';
import RightArrow from '../../../assets/images/rightarrow1.svg';
import Header from '../../common-components/Header/Header';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { constants } from '../../../constants/constants';
import phone from '../../../assets/images/phone.svg';
import message from '../../../assets/images/message.svg';
import dot from '../../../assets/images/dot.svg';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Carousel from 'react-bootstrap/Carousel';
import img1 from '../../../assets/images/img1.svg';
import axios from 'axios';
import yellowRightArrow from '../../../assets/images/yellowRightArrow.svg';
import testimonialImage from '../../../assets/images/testimonialImage.png';
import homeBuilders from '../../../assets/images/homeBuilders.svg';
import homeBroker from '../../../assets/images/homeBroker.svg';
import homeTwonplanner from '../../../assets/images/homeTwonplanner.png';
import homeArchitect from '../../../assets/images/homeArchitect.svg';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { blobService } from '../../common-components/Blob';
import ProjectPlaceholder from '../../../assets/images/project-placeholder.jpg';
import dummybuilder from '../../../assets/images/dummybuilder.png';
import ReactPlayer from 'react-player';
import testimonialone from '../../../assets/images/testimonialone.png';
import testimonialContentone from '../../../assets/images/testimonialContentone.png';
import Modal from 'react-awesome-modal';
import LoginModel from '../../common-components/Login/Login';
import RegisterModal from '../../common-components/Register/Register';
import CustomModal from '../../common-components/CustomModal/CustomModal';
import homeVideo from '../../../assets/images/homeVideo.svg';
import homeVideo1 from '../../../assets/images/homeVideo1.png';
import builderBackground from '../../../assets/images/builderBackground.png';
import checkcirclecenter from '../../../assets/images/checkcirclecenter.svg';
import checkcircle from '../../../assets/images/checkcircle.svg';
import SubscriptionDetails from '../../common-components/SubscriptionDetails/SubscriptionDetails';
interface HomeProps {
	name?: any;
	value?: any;
}
interface HomeState {
	activeIndex: string;
	isVisible: boolean;
	userType: string;
	count: string;
	CreatedBy: string;
	locationList: any;
	stateCode: any;
	selectedCity: string;
	selectedState: string;
	selectedLoaction: any;
	builders: any;
	loginModel: boolean,
	RegisterModel: boolean,
	show: boolean,
	body: string,
	isHidden: any,
	subscriptionList: any;
	openSubscriptionDetails: boolean;
	planId: string
}

class Home extends React.Component<RouteComponentProps, HomeState> {
	constructor(props: any) {
		super(props);
		const initialState = {
			activeIndex: '',
			userType: '',
			isVisible: false,
			CreatedBy: "",
			locationList: [],
			selectedLoaction: [],
			stateCode: [],
			selectedCity: '',
			selectedState: '',
			count: '',
			builders: [],
			loginModel: false,
			RegisterModel: false,
			show: false,
			body: '',
			isHidden: [false, false, false, false],
			subscriptionList: [],
			openSubscriptionDetails: false,
			planId: ''
		};
		this.state = initialState;
	}
	componentDidMount() {
		var scrollComponent = this;
		document.addEventListener("scroll", function (e) {
			scrollComponent.toggleVisibility();
		});
		let userType = localStorage.getItem('userType');
		let activeIndex = localStorage.getItem('tab');
		this.setState({
			userType: userType || '',
			activeIndex: activeIndex || '0'
		});
		this.init();
		localStorage.setItem("tab", '0');
	}

	init() {

		this.fetchProjectCount();
		this.fetchStateList();
		this.fetchCityList();
		this.fetchBuilders();
		this.fetchSubscriptionList();

	}
	toggleVisibility() {
		if (window.pageYOffset > 300) {
			this.setState({
				isVisible: true
			});
		} else {
			this.setState({
				isVisible: false
			});
		}
	}
	async fetchProjectCount(): Promise<any> {
		const user = JSON.parse(localStorage.getItem('user')!);
		let token;
		if (user) {
			token = 'Bearer ' + user;

		}
		const key = constants.subscriptionKey;
		const url = constants.fetchProjectCountAPI;
		axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
			.then((response) => {
				this.setState({ count: response.data });
			})
			.catch((error) => {
				//this.setState({ ErrMsg: error, error: true });
			});
	}
	async fetchBuilders(): Promise<any> {
		const user = JSON.parse(localStorage.getItem('user')!);
		let token;
		if (user) {
			token = 'Bearer ' + user;

		}
		const key = constants.subscriptionKey;
		const url = constants.getBuilderListAPI + "?cityName=&stateName=";
		axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
			.then((response: any) => {
				this.setState({ builders: response.data });
			})
			.catch((error) => {
				// this.setState({ ErrMsg: error, error:true });
			});
	}
	navigate() {
		// history.push("/CustomerHome");
		// this.props.history.push({
		//     pathname: '/CustomerHome',
		//     data: "adasd" // your data array of objects
		//   })
	}
	handleClick = (event: any) => {
		alert('calling');
		this.props.history.push('/View Builder');
		localStorage.setItem('tab', event.target.id);
		this.setState({ activeIndex: event.target.id });
	};
	contactUsPage = () => {
		this.props.history.push('/ContactUs')
	}
	onClickBuilder = (event: any) => {
		localStorage.setItem("tab", '2');
	}
	onClickArchitect = (event: any) => {
		localStorage.setItem("tab", '3');
	}
	onClickTPlanner = (event: any) => {
		localStorage.setItem("tab", '4');
	}

	handleChange = (event: any) => {
		event.preventDefault();
		const { name, value } = event.target;
		// switch (name) {
		//     case 'CreatedBy':
		//         //this.searchProjects(value);
		//         break;
		//     default:
		//         break;
		// }
		this.setState(Object.assign(this.state, { [name]: value }));
	};

	selectLocation = (value: any) => {
		if (value) {
			this.setState({
				selectedLoaction: value,

			})
		} else {
			this.setState({
				selectedLoaction: ''
			})
		}
	}
	async fetchStateList(): Promise<any> {
		const key = constants.subscriptionKey;
		const url = constants.fetchStatesListAPI;
		const user = JSON.parse(localStorage.getItem('user')!);
		let token;
		if (user) {
			token = 'Bearer ' + user;

		}
		axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
			.then((response) => {
				if (response.status == 200) {
					let stateList = response.data;
					stateList.map((item: any) => {
						this.state.locationList.push(item.name);
						this.state.stateCode.push(item.name);
					})
					this.setState({
						locationList: this.state.locationList,
						stateCode: this.state.stateCode,
					});
				}
			})
			.catch((error) => {
			});

	}
	async fetchCityList(): Promise<any> {
		const key = constants.subscriptionKey;
		const url = constants.fetchAllCity;
		const user = JSON.parse(localStorage.getItem('user')!);
		let token;
		if (user) {
			token = 'Bearer ' + user;

		}
		axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
			.then((response) => {
				if (response.status == 200) {
					let cityList = response.data.list;
					cityList.map((item: any) => {
						this.state.locationList.push(item);
					})
					this.setState({ locationList: this.state.locationList });
				}

			})
			.catch((error) => {
			});

	}

	setActiveLink = (e: any) => {
		if (e === "login") {
			this.setState({
				loginModel: true
			});
		} else {
			this.setState({
				RegisterModel: true
			});
		}
	}
	closeLoginModal(event: any) {
		this.setState({
			loginModel: false,
			RegisterModel: false
		});
	}

	closeModal(event: any) {
		this.setState({
			loginModel: event,
			RegisterModel: event
		});


	}
	signup = () => {
		this.setState({
			loginModel: false,
			RegisterModel: true
		})
	}
	closeRegisterModal = (event: any) => {
		this.setState({
			RegisterModel: false,

		});
	}

	success(event: any) {
		this.setState({ RegisterModel: false, show: !this.state.show, body: event })
	}

	login = (event: any) => {
		this.setState({
			loginModel: false,
			RegisterModel: false,
			show: false
		})
	}

	async fetchSubscriptionList(): Promise<any> {
		const user = JSON.parse(localStorage.getItem('user')!);
		let token;
		if (user) {
			token = 'Bearer ' + user;

		}
		const key = constants.adminSubscriptionKey;
		const url = constants.fetchSubscriptionsListAPI + "?regionName=" + "Australia";
		axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
			.then((response: any) => {
				this.setState({ subscriptionList: response.data });
				console.log("response.data" + this.state.subscriptionList[0].planName);
			})
			.catch((error) => {
				// this.setState({ ErrMsg: error, error:true });
			});
	}
	onClickBuyNowBtn = (planId: any) => {
		if (localStorage.id) {
			this.setState({
				planId: planId,
				openSubscriptionDetails: true,

			})
			// this.props.history.push({
			// 	pathname: '/subscriptionDetails',
			// 	state: {
			// 	  data: planId,
			// 	},
			//   });
		} else {
			this.setState({
				planId: planId,

			})
			this.setActiveLink("login");
			localStorage.setItem('openPlanPopup', "true");
		}
	}
	closeSubscriptionDetailsModal(event: any) {
        if(event == true) {
            this.setState({ openSubscriptionDetails: false  })
        }
        
      }
	render() {
		const { isVisible, CreatedBy, locationList, selectedLoaction, builders, show, body, subscriptionList, openSubscriptionDetails }: any = this.state;
		let list = [];
		list.push(
			<>
				<div className='d-flex row'>
					<div className='col-md-7 builder-box '>
						{builders.length != 0 &&
							<>
								{builders[0].bannerImages.length > 0 &&
									<img className="mt-5" src={blobService.createUrl(builders[0].bannerImages[0])} alt="" style={{ width: "35%", height: "20vh" }} />
									||
									<img src={dummybuilder} alt="" style={{ width: "35%", height: "20vh" }} />
								}
								<p>{builders[0].builderName}</p>
								<p>{builders[0].address ? builders[0].address.city : ""}</p>
							</>

							||
							<>
								<img src={dummybuilder} alt="" style={{ width: "35%", height: "20vh" }} />
								<p>Not Available</p>
								<p>We Design, Build, Transform </p>
							</>
						}
					</div>
					<div className='col-md-5'>
						<div className='builder-box   mb-2 py-3 '>
							{builders.length > 1 &&
								<>
									{builders[1].bannerImages.length > 0 &&
										<img src={blobService.createUrl(builders[1].bannerImages[0])} alt="" style={{ width: "20%", height: "10vh" }} />
										||
										<img src={dummybuilder} alt="" style={{ width: "20%", height: "10vh" }} />
									}
									<p>{builders[1].builderName}</p>
									<p>{builders[1].address ? builders[1].address.city : ""}</p>
								</>

								||
								<>
									<img src={dummybuilder} alt="" style={{ width: "20%", height: "10vh" }} />
									<p>Not Available</p>
									<p>We Design, Build, Transform </p>
								</>
							}
						</div>
						<div className='builder-box py-3'>
							{builders.length > 2 &&
								<>
									{builders[2].bannerImages.length > 0 &&
										<img src={blobService.createUrl(builders[2].bannerImages[0])} alt="" style={{ width: "20%", height: "10vh" }} />
										||
										<img src={dummybuilder} alt="" style={{ width: "20%", height: "10vh" }} />
									}
									<p>{builders[2].builderName}</p>
									<p>{builders[2].address ? builders[2].address.city : ""}</p>
								</>

								||
								<>
									<img src={dummybuilder} alt="" style={{ width: "20%", height: "10vh" }} />
									<p>Not Available</p>
									<p>We Design, Build, Transform </p>
								</>
							}
						</div>
					</div>
				</div>
				<div className=' d-flex  mt-3 mb-3'>
					<div className='col-md-6 mr-2  no-gutters builder-box py-5'>
						{builders.length > 3 &&
							<>
								{builders[3].bannerImages.length > 0 &&
									<img src={blobService.createUrl(builders[3].bannerImages[0])} alt="" style={{ width: "20%", height: "10vh" }} />
									||
									<img src={dummybuilder} alt="" style={{ width: "20%", height: "10vh" }} />
								}
								<p>{builders[3].builderName}</p>
								<p>{builders[3].address ? builders[3].address.city : ""}</p>
							</>

							||
							<>
								<img src={dummybuilder} alt="" style={{ width: "20%", height: "10vh" }} />
								<p>Not Available</p>
								<p>We Design, Build, Transform </p>
							</>
						}
					</div>
					<div className='col-md-6 builder-box py-5'>
						{builders.length > 4 &&
							<>
								{builders[4].bannerImages.length > 0 &&
									<img src={blobService.createUrl(builders[4].bannerImages[0])} alt="" style={{ width: "20%", height: "10vh" }} />
									||
									<img src={dummybuilder} alt="" style={{ width: "20%", height: "10vh" }} />
								}
								<p >{builders[4].builderName}</p>
								<p>{builders[4].address ? builders[4].address.city : ""}</p>
							</>

							||
							<>
								<img src={dummybuilder} alt="" style={{ width: "20%", height: "10vh" }} />
								<p>Not Available</p>
								<p>We Design, Build, Transform </p>
							</>
						}
					</div>
				</div>
			</>
		);
		let subscriptionLOneData = [];
		{
			subscriptionList.length != 0 &&
				subscriptionLOneData.push(
					<>
						<div className='p-5 subscription_box_center' >
							<h4 className='mt-2'>{this.state.subscriptionList[0].planName} </h4>
							<div className='mt-3'>
								{subscriptionList[0].permissions.map((item: any) => {
									return <p><img src={checkcirclecenter} />{item.permissionName}</p>
								})}
							</div>
							<button onClick={() => { this.onClickBuyNowBtn(subscriptionList[0].planId) }} className='w-75'>Buy Now</button>
						</div>
					</>
				);
		}
		var newVal;
		if (true) {
			newVal = <Error />;
		}
		return (
			<>
				<Header />
				<div style={{ backgroundColor: "#F5F5F7" }} >
					<a id='top' />
					<div className="outer-container" >
						<div className='homepage d-block w-100 new-banner'>
						</div>
					</div>
					<div className='container find-project-container'>
						<div className=' '>
							<div className='w-100'>
								<div className='find-project-container-box py-3'>
									<div className='subBox1 mx-5'>
										<h4>Find Project</h4>
										<div className='row'>
											<div className='col-md-7'>
												<div className='row'>
													<div className='col-md-6'>
														<select className=" py-1  w-100 mb-2" onChange={this.handleChange} name="CreatedBy" value={CreatedBy} >
															<option value="">Select type</option>
															<option value="Builder">Builder</option>
															<option value="Customer">Customer</option>
														</select>
														<p>Filter bY</p>
													</div>
													<div className='col-md-6'>
														<Autocomplete
															id="disable-close-on-select"
															onChange={(event, newValue) => {
																this.selectLocation(newValue);
															}}
															className="mb-2"
															options={locationList}
															value={selectedLoaction}
															getOptionLabel={(option: any) => option}
															renderInput={(params) => <TextField {...params} placeholder="Location" variant="standard" name="searchLocationText" />}
														/>
														<p>Location</p>
													</div>
												</div>
											</div>
											<div className='col-md-5'>
												<Link to={{ pathname: '/FindProjects', state: { data: selectedLoaction, user: CreatedBy } }}>
													<button className='py-2 px-2'>
														Search
													</button>
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{isVisible == true && (
						<div className='fab-icon sticky' >
							<a href='#top' title="Go to top" >
								{' '}
								<ArrowUpwardIcon />
							</a>
						</div>)}
					<div className='py-5 mb-2'>
						<div className=' my-5 d-flex home-video'>
							<h4>Highlights of Aashray Homes</h4>
							<div className='home-video-background'>
								<img src={homeVideo1}></img>
								<ReactPlayer
									url='https://web.dev.ashrayhome.com.au/videos/Aashrayhomesfinal.mp4'
									controls
									style={{ height: "unset" }}
									className="w-100 video"
								/>
							</div>
						</div>
					</div>
					<div className=' thirdFold w-100'>
						<div className='background-opacity'>
							<div >
								<div className=' no-gutters  p-4 thirdFold-container mt-3'>
									<div className='mt-3'>
										<p>
											Our Top Real Estate
											Professionals
										</p>
										<p >
											Aashray Homes started as an online medium for the realestate service providers like builders, developers,
											<br />
											raw material suppliers, architects etc., and service seekers or clients who <br />
											are having their dream homes from <br />
											scratch
										</p>
									</div>
								</div>
								<div className='d-flex  thirdFold-smallBox-container p-5' >
									<div className='thirdFold-smallBox  p-4 py-2'>
										<img className="mb-3" src={homeBuilders} />
										<p>Builders</p>
										<div className='base'>
											<p>The Right platform to find the most recommended commercial builders who can build custom home.You find professional builders in Sydney, Perth, Melbourne and all across Australia who have years expertise in house constructions.</p>
											<div className='hide-content' >
												<Link to="/Builders"><button className=' p-3'>Learn more</button></Link>
											</div>
										</div>
									</div>
									<div className='thirdFold-smallBox  p-4 py-2'>
										<img className="mb-3" src={homeArchitect} />
										<p>Architects</p>
										<div className='base'>
											<p>
												Aashray Homes Australia has listed a team of skilled and energetic professionals who are passionate about home design and believe in creating unique architectural and interior solutions keeping in mind the clients’ needs and tastes.
											</p>
											<div className='hide-content' >
												<Link to="/Architects">	<button className=' p-3'>Learn more</button></Link>
											</div>
										</div>
									</div>
									<div className='thirdFold-smallBox  p-4 py-2'>
										<img className="mb-3" src={homeArchitect} />
										<p>Raw Materials Supplier</p>
										<div className='base'>
											<p>
											Aashray Homes filtered the 
														best construction materials 
														supplier from your locality 
														who can...
											</p>
											<div className='hide-content' >
												<Link to="/RawMaterialSuppliers">	<button className=' p-3'>Learn more</button></Link>
											</div>
										</div>
									</div>
									<div className='thirdFold-smallBox  p-4 py-2'>
										<img className="mb-3" src={homeTwonplanner} />
										<p>Town Planner</p>
										<div className='base'>
											<p>
												Real estate development is a complicated process that requires a creative vision and robust knowledge and experience to bring it to life.With Aashray Homes you can have the organization of many interrelated tasks performed by many highly specialized professionals.
											</p>
											<div className='hide-content' >
												<Link to="/TownPlanners"><button className=' p-3'>Learn more</button></Link>
											</div>
										</div>
									</div>
									<div className='thirdFold-smallBox  p-4  py-2'>
										<img className="mb-3" src={homeBroker} />
										<p>Financial Broker</p>
										<div className='base'>
											<p>
												Contact our finance experts to learn your loan eligibility and secure Online Loan to construct your dream home!We will make sure to get you ready for the process
												of constructing a new home.
											</p>
											<div className='hide-content' >
												<Link to="/FinancialBrokers"><button className=' p-3'>Learn more</button></Link>
											</div>
										</div>
									</div>
								</div>
								{/* </div> */}
							</div>
						</div>
					</div>
					<div className='fourthFold w-100 '>
						<div className='py-5'>
							<div className='d-flex fourthFold-header mr-5 ml-5 p-5'>
								<h4>Our Featured Builders</h4>
								<Link to="/Builders">
									<button className='p-3'>View More</button>
								</Link>
							</div>
							<div >
								<img className='fourthFold-image' src={builderBackground} />
								<div className='container'>
									{list}
								</div>
							</div>
						</div>
					</div>
					{subscriptionList.length == 0 ?
						<div></div>
						:
						<div className='subscription_Fold w-100 '>
							<div className='py-5'>
								<h4>Simple, Transparent Pricing</h4>
								<p>No contracts. No surprise fees.</p>
								<div >
									<div className='container mt-5 d-flex'>
										{subscriptionList.length > 1 ?
											<div className='p-5 mt-4 subscription_box'>
												<h4 className='mt-2'>{subscriptionList[1].planName}</h4>
												<div className='mt-3'>
													{subscriptionList[1].permissions.map((item: any) => {
														return <p><img src={checkcircle} />{item.permissionName}</p>
													})}
												</div>
												<button className='w-75' >Buy Now</button>
											</div>
											:
											<div className='p-5 mt-4 subscription_box'>
												<h4 className='mt-2'>FREE PACK</h4>
												<div className='mt-3'>
													<p><img src={checkcircle} />Access to Projects</p>
													<p><img src={checkcircle} />Create Project </p>
													<p><img src={checkcircle} />Access to Send Inquiry/Quote</p>
													<p><img src={checkcircle} />Access to Professional list</p>
												</div>
												<button disabled className='w-75'>Coming Soon</button>
											</div>
										}
										{subscriptionLOneData}
										{subscriptionList.length > 2 ?
											<div className='p-5 mt-4 subscription_box' >
												<h4 className='mt-2'>{subscriptionList[2].planName}</h4>
												<div className='mt-3'>
													{subscriptionList[2].permissions.map((item: any) => {
														return <p><img src={checkcircle} />{item.permissionName}</p>
													})}
												</div>
												<button className='w-75' >Buy Now</button>
											</div>
											:
											<div className='p-5 mt-4 subscription_box' >
												<h4 className='mt-2'>PREMIUM PACK</h4>
												<div className='mt-3'>
													<p><img src={checkcircle} />Access to Projects <span>Unlimited</span></p>
													<p><img src={checkcircle} />Create Project <span>Unlimited</span></p>
													<p><img src={checkcircle} />Access to Send Inquiry/Quote <span>Unlimited</span></p>
													<p><img src={checkcircle} />Access to Professional list <span>Unlimited</span></p>
												</div>

												<button disabled className='w-75'>Coming Soon</button>

											</div>
										}
									</div>
								</div>
							</div>
						</div>
					}
					<div className='testimonial-container w-100 mb-3 '>
						<div className='  py-5' style={{ textAlign: "center" }}>
							<h4>Clients Testimonials</h4>
						</div>
						<div style={{ textAlign: 'center' }} className='container p-4 testimonial-container-box'>
							<div className='testimonals-cards  p-4'>
								<div className='p-4 d-flex' style={{ alignItems: "center" }}>
									<img className='mr-2' src={testimonialImage}></img>
									<div>
										<p>Carlton Trapp</p>
										<p>Architect</p>
									</div>
								</div>
								<p>
									Aashray Homes has helped me to create the perfect team of highly skilled real estate professionals required to build our first ever dream home.I am happy with their services.
								</p>
							</div>
						</div>
					</div>
					{/* For map */}
					<div style={{ backgroundColor: "#F5F5F7" }}>
						<div className='d-flex  container' style={{ justifyContent: "flex-end" }}>
							<div className='w-50 iframe-container' >
								<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.6756682571126!2d144.9602543758857!3d-37.8210651719738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b296c31e25%3A0x59345d979cfd4ac6!2sLevel%202%2F1%20Southbank%20Blvd%2C%20Southbank%20VIC%203006%2C%20Australia!5e0!3m2!1sen!2sin!4v1722427753683!5m2!1sen!2sin" className='map-sctn' style={{ width: '100%', border: '0px', height: "70vh" }} loading="lazy"></iframe>
							</div>
							<div className=' address-container-background'>
								<div>
									<div className='address-text-container p-5 mt-4 mb-5'>
										<p>COMPANY ADDRESS</p>
										<p>Level 2 1 Southbank Boulevard,</p>
										<p>Southbank, Victoria - 3006, Australia</p>
									</div>
									<div className='py-5 mt-5'>
										<div className=' ml-5 phone-sctn d-flex mb-3'>
											<div className='phn-image-sctn'>
												<img src={phone} alt='phone' />
											</div>
											<div className='phn-text-sctn'>
												<p className='phn'>COMPANY PHONES</p>
												<p className='num'>+61 3 7036 5746</p>
											</div>
										</div>
										<div className=' email-sctn d-flex ml-5'>
											<div className='email-image-sctn'>
												<img src={message} alt='message' />
											</div>
											<div className='dots'>
												<img src={dot} alt='dot' />
												<img src={dot} alt='dot' />
												<img src={dot} alt='dot' />
											</div>
											<div className='email-text-sctn'>
												<p className='phn'>Dustro Emails</p>
												<p className='num'>info@ashrayhome.com.au</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='keyword-container py-2'>
						<div className='container py-2 keyword-container-box'>
							<h4>Popular Keywords</h4>
							<div className='row mt-3'>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a target="_blank" href='https://www.youtube.com/watch?v=CQ8oNFafcHw'>
											#realestate
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.youtube.com/watch?v=sP42CqOpcFM' target='_blank'>
											#realtor
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.youtube.com/watch?v=sP42CqOpcFM' target='_blank'>
											#home
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.youtube.com/watch?v=qsfz-AdIV0o' target='_blank'>
											#property
										</a>
									</p>
								</div>
								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.ashrayhome.com.au/dreamHomeBlog' target='_blank'>
											#investment
										</a>
									</p>
								</div>
							</div>
							<div className='row mt-2'>
								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.ashrayhome.com.au/owningAHouse' target='_blank'>
											#dreamhome
										</a>
									</p>
								</div>

								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.youtube.com/channel/UCMdLYbIHwo3UKVrCx5d2nfg/featured' target='_blank'>
											#homesweethome
										</a>
									</p>
								</div>
								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.ashrayhome.com.au/dreamHomeBlog' target='_blank'>
											#realestateinvesting
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/165477912742942/' target='_blank'>
											#design
										</a>
									</p>
								</div>
							</div>
							<div className='row mt-2'>
								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/165266769430723/' >
											#realestateinvestor
										</a>
									</p>
								</div>
								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.ashrayhome.com.au/homeLoanBlog'>
											#homeloan
										</a>
									</p>
								</div>
								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/159455350011865' target='_blank'>
											#quickhomeloan
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/158026930154707' target='_blank'>
											#mortgage
										</a>
									</p>
								</div>
							</div>
							<div className='row mt-2'>
								{/* <div  className='col-md-2 keyword-box p-2 mr-1'>
							<p>#justlisted</p>
						</div> */}
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/163644232926310/' target='_blank'>
											#broker
										</a>
									</p>
								</div>
								{/* <div className='col-md-2 keyword-box p-2 mr-1'>
							<p>#homesforsale</p>
						</div> */}
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/' target='_blank'>
											#electricals
										</a>
									</p>
								</div>
								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/158835093407224/' target='_blank'>
											#homeconstruction
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/164056046218462/' target='_blank'>
											#bricks
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/163854412905292/' target='_blank'>
											#sand
										</a>
									</p>
								</div>
							</div>
							<div className='row mt-2'>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/156603276963739/' target='_blank'>
											#carpenters
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/156603276963739/' target='_blank'>
											#electricians
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/159049866719080/' target='_blank'>
											#builders
										</a>
									</p>
								</div>
								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/160104306613636/' target='_blank'>
											#architects
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box mr-1 p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/160709233219810/' target='_blank'>
											#hardware
										</a>
									</p>
								</div>
							</div>
							<div className='row mt-2'>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162525799704820/' target='_blank'>
											#marbles
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/156603276963739/' target='_blank'>
											#plumbers
										</a>
									</p>
								</div>
								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/163644232926310/' target='_blank'>
											#financialbrokers
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162079053082828/' target='_blank'>
											#steel
										</a>
									</p>
								</div>
								<div className='col-md-2 keyword-box p-2 mr-3 '>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162525799704820/' target='_blank'>
											#cement
										</a>
									</p>
								</div>
							</div>
							<div className='row mt-2 mb-2'>
								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/160709233219810/' target='_blank'>
											#rawmaterialsuppliers
										</a>
									</p>
								</div>
								<div className='col-md-3 keyword-box p-2 mr-3'>
									<p>
										<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162301219727278/' target='_blank'>
											#realestateprofessional
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
					<Footer />
					<Modal visible={this.state.loginModel} width="400" effect="fadeInUp" onClickAway={(event: any) => this.closeLoginModal(event)} >
						<LoginModel value={this.signup} closeModel={(event: any) => this.closeModal(event)} />
					</Modal>

					<Modal visible={this.state.RegisterModel} width="400" effect="fadeInUp" onClickAway={(event: any) => this.closeRegisterModal(event)}>
						<RegisterModal closeModel={(event: any) => this.closeModal(event)} value={this.login} result={(event: any) => this.success(event)} />
					</Modal>
					<CustomModal isAlert={true} show={show} header="Success" body={body} onHide={(event: any) => this.login(event)} />
					{(localStorage.id && this.state.planId != "") ?
						<Modal visible={openSubscriptionDetails} >  
							<SubscriptionDetails
								id={this.state.planId}
								result={(event: any) => this.closeSubscriptionDetailsModal(event)}
							/>
						</Modal>
						:
						""
					}

				</div>
			</>
		);
	}
}

export default withRouter(Home);