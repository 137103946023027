import React, { Component, useEffect } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import './AboutCompany.scss'
interface AboutCompanyProps {
    name?: any;
    value?: any;
}
interface AboutCompanyState {
    errors: {};
}


export default class AboutCompany extends React.Component<AboutCompanyProps, AboutCompanyState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            errors: {},
        };
        this.state = initialState;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
      }
    render() {
        return (
            <>
                <Header />
                <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 d-flex align-items-center">
                                    <h1 style={{fontWeight:600}}>About Us</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="input-cntr">
                         <input type="text" placeholder="Search" name="searchTxt" onChange={this.handleChange} className="px-3 py-2"/><img className="search-icon" onClick={(event:any)=>this.searchBuilder(event)}src={SearchIcon} alt=""/>
                     </div> */}
                </div>
                <br /><br />
                <div className="container aboutuspara mb-3">
                            {/* <div className="row"> */}
                                {/* <div className="offset-md-2 col-md-6 align-items-center"> */}
                <p className = "">
                    Aashray Homes started as an online medium for the real-estate service providers like builders, developers, raw material suppliers, architects etc., and service seekers or clients who are having their dream homes from scratch.
                </p>
                <p>
                    We include the service providers who have expertise in their field ranging from basic to complex requirements in house construction.
                </p>
                <p>
                    Our mission is to simplify the process of house construction, right from assisting our clients from home loan applications to provide the access to top real estate professionals who will construct the house in no time.
                </p>
                <p>
                    With over 5 years’ experience, our online platform is solely designed for connecting real estate professionals and clients. Our pride itself is on integrity, transparency, and overall great customer service. Our business values, knowledge and experience and most importantly affordable, practical high-quality homes is what sets us apart from others.
                </p>
                <p>
                     Our experience as an integral part of real-estate allows us to effectively enhance the process of constructing your dream home. Thereby increasing the value of your investment and produce solid long-term results.
                </p>
                <p>
                    Considering the direct linkages to real estate development activities of Aashray Homes, the company has developed and kept up linkages with such sites for property and facilities management from conception and construction to completion and beyond.
                </p>
                <p>
                    Our team and our customers
                    <br/>
                    In order to provide clients with worldwide market insights and transaction support, Aashray Homes works with clients throughout Australia and India and has built a global network of specialized real estate specialists in both countries.
                    We can guide you through the complexity of the real estate market and help you increase the value of your assets thanks to our unmatched understanding. We can advise you on a wide range of asset classes, from industrial and commercial to retail and residential, thanks to our extensive industry experience.
                    We collaborate with: Prominent worldwide and Australian businesses Investors and banks Companies in the public sector that are developers, architects, or raw material suppliers.
                </p>
                <p>
                    To ensure they can counsel important stakeholders throughout the property lifetime, we have put together an advisory team with a variety of expertise in real estate and depth of experience consisting of managers of development for listed firms, suppliers of senior and structured financing civil engineers and town planners.
                    Regardless of whether real estate is their primary line of business, we can help customers through the decision-making process thanks to the breadth and depth of our knowledge.
                </p>
                <p>
                    We offer comprehensive market intelligence to inform real estate initiatives from the planning stages of projects to their completion and eventual sale or use.
                    Our advisory team offers specific advice on real estate matters on an individual basis, based on our experience in development management and advising on hundreds of projects nationally.
                </p>
                <br /><br />
                </div>
                <Footer />
            </>
        );
    }
}
function componentDidMount() {
    throw new Error('Function not implemented.');
}

