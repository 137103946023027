import React, { Fragment } from 'react';
import Footer from '../../../common-components/Footer/Footer';
import Header from '../../../common-components/Header/Header';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FBwithround from '../../../../assets/images/FBwithround.svg';
import Linkedinwithround from '../../../../assets/images/Linkedinwithround.svg';
import Twitterwithround from '../../../../assets/images/Twitterwithround.svg';
import Loader from '../../../common-components/Loader';
import Edit from '../../../../assets/images/edit.svg';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import Image from "../../../common-components/Image";
import Delete from "../../../../assets/images/delete.svg"
import { blobService } from '../../../common-components/Blob';
import Upload from "../../../../assets/images/upload.svg"
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import ToggleSwitch from '../../../common-components/ToggleSwitch/ToggleSwitch';
import CurrencyFormat from 'react-currency-format';
import Check from '../../../Services/Check';
import Modal from "react-bootstrap/Modal";
import Vector from  "../../../../assets/images/Vector.svg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import passwordLock from '../../../../assets/images/passwordLock.svg';


interface ProfileProps {
    name?: any;
    value?: any;
}
interface ProfileState {
    checked: boolean;
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    isLoading: boolean;
    isEdit: boolean;
    type: string;
    adLine1: string;
    adLine2: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    errors: {
        oldPassword: string,
        newPassword: boolean,
        confirmNewPassword: string,
        description: string,
        mobile: string,
        adLine1: string,
        adLine2: string,
        supplierName: string,
        twitterLink: string,
        facebookLink: string,
        instagramLink: string,
        alternateEmail: string,
        alternateMobile: string,
        pincode: string,
        newupperCase: boolean,
        newlowerCase: boolean,
        newdigit: boolean,
        newspecialChar: boolean,
        newminLength: boolean,
        materialPrice: string,
        materialBrand: string,
        materialDescription: string,
        file: string,
    };
    stateList: any,
    pincodeList: any,
    cityList: any,
    twitterLink: string,
    facebookLink: string,
    instagramLink: string,
    youtubeLink: string,
    rawMaterialSupplier: any;
    supplierName: string;
    description: string;
    address: string;
    mobile: string;
    email: string;
    profileImage: string,
    showUpload: boolean,
    header: string,
    body: string,
    newsletter: boolean,
    id: string,
    alternateMobile: string,
    alternateEmail: string,
    pageNo: any,
    user: any,
    ErrMsg:any,
    error:boolean,
    formAddressData: any,
    formOtherData: any,
    registrationNumber: string,
    rawMaterialList: any,
    isMaterialListOpen: boolean,
    materialPrice: string,
    materialBrand: string,
    materialDescription: string,
    materialType: string,
    selectedMaterials: any,
    materialName: string,
    locationList: any,
    stateCode: any,
    selectedState: any,
    selectedCity: any,
    selectedLocation: any,
    isEnablePassword: boolean,
    isMaterialSubmit: boolean,
    isMaterialAdded: boolean
}

const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
const RegexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const restrictedCharacters = ['!', '@', '+', '-', '$', '#', '%', '^', '&', '*', '_', '(',')','?','<','>','{','}','~','`','[',']','?',':',';','|','/','"',".",",","'",'='];

export default class Profile extends React.Component<ProfileProps, ProfileState> {

    constructor(props: ProfileProps) {
        super(props);

        const initialState = {
            checked: true,
            profileTab: true,
            settingTab: false,
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            isLoading: true,
            isEdit: false,
            type: 'text',
            adLine1: '',
            adLine2: '',
            city: '',
            state: '',
            country: '',
            pincode: '',
            errors: {
                oldPassword: '',
                newPassword: false,
                confirmNewPassword:'',
                description: '',
                mobile: '',
                adLine1: '',
                adLine2: '',
                supplierName: '',
                twitterLink: '',
                facebookLink: '',
                instagramLink: '',
                alternateEmail: '',
                alternateMobile: '',
                pincode: '',
                newupperCase: false,
                newlowerCase: false,
                newdigit: false,
                newspecialChar: false,
                newminLength: false,
                materialPrice: '',
                materialBrand: '',
                materialDescription: '',
                file: '',
            },
            twitterLink: '',
            facebookLink: '',
            instagramLink: '',
            youtubeLink: '',
            rawMaterialSupplier:{},
            supplierName:'',
            description:'',
            stateList:[],
            pincodeList:[],
            cityList:[],
            address: '',
            mobile: '',
            email: '',
            profileImage:'',
            showUpload: false,
            header: '',
            body: '',
            newsletter: false,
            id: '',
            alternateMobile:'',
            alternateEmail:'',
            pageNo: 0,
            user: {},
            ErrMsg:"",
            error:false,
            formAddressData: [],
            formOtherData: [],
            registrationNumber: '',
            rawMaterialList: [],
            isMaterialListOpen: false,
            materialPrice: "",
            materialBrand: "",
            materialDescription: "",
            materialType: "",
            selectedMaterials: [],
            materialName: "",
            locationList: [],
            stateCode: [],
            selectedState: [],
            selectedCity: [],
            selectedLocation: [],
            isEnablePassword: true,
            isMaterialSubmit: true,
            isMaterialAdded: true,
        };

        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
    }

   
    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        const urlRegEx =  RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        let errors = this.state.errors;
        const uppercaseRegExp   = /(?=.*?[A-Z])/;
        const lowercaseRegExp   = /(?=.*?[a-z])/;
        const digitsRegExp      = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp   = /.{8,}/;
        switch (name) {
            case 'oldPassword':
                errors.oldPassword  = value.length > 0 ? '' :  "Please Enter Password";
                break;
            case 'newPassword':
                errors.newupperCase = uppercaseRegExp.test(value) ? true : false;
                errors.newlowerCase = lowercaseRegExp.test(value)? true : false;
                errors.newdigit = digitsRegExp.test(value) ? true : false;
                errors.newspecialChar = specialCharRegExp.test(value) ? true : false;
                errors.newminLength = minLengthRegExp.test(value) ? true : false;
                break;
            case 'confirmNewPassword':
                errors.confirmNewPassword = this.state.newPassword != value ? 'Make Sure Your Password is Same as New Password' : '';
                break;
            case 'pincode':
                errors.pincode = (value.length < 4 || value.length > 4) ? 'Pincode must be 4 digit long' : '';
                break;
            case 'twitterLink':
                    errors.twitterLink = (urlRegEx.test(value) || value.length == 0 ) ? '' :  'Please Enter A Valid Link' ;
                break;
            case 'instagramLink':
                    errors.instagramLink = (urlRegEx.test(value)|| value.length == 0) ? '' :  'Please Enter A Valid Link' ;
                break;
            case 'facebookLink':
                    errors.facebookLink = (urlRegEx.test(value)|| value.length == 0) ? '' :  'Please Enter A Valid Link' ;
                 break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
        if((errors.newupperCase && 
            errors.newlowerCase && errors.newdigit && 
            errors.newspecialChar && errors.newminLength &&
            (this.state.confirmNewPassword == this.state.newPassword)
            ) == true && this.state.oldPassword !== ''){
                    this.setState({
                        isEnablePassword: false
                    })
        }else{
            this.setState({
                isEnablePassword: true
            })
        }
    };

    componentDidMount() {
        this.init();
    }

    init() {
        let userId = localStorage.getItem("id");
        this.viewProfile(userId);
        this.viewUser(userId);
        this.fetchStateList();
        this.fetchAllCityList();
        this.fetchRawMaterialList();
    }
    async viewUser(id: any): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchUserAPI + id,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let u = response.data;
                this.setState({ 
                    user: u, 
                    email: u.detail.email,
                    mobile: u.detail.phone,
                });
            })
            .catch((error) => {
            });
        }
        )
    }
    
    async fetchStateList(): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchStatesListAPI,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let stateList = response.data;
                    stateList.map((item:any)=>{
                        this.state.locationList.push(item.name);
                        this.state.stateCode.push(item.name);
                    })
                    
                    this.setState({
                        locationList: this.state.locationList,
                        stateCode: this.state.stateCode,
    
                    });
                
                this.setState({stateList: response.data})
                
            })
            .catch((error) => {
            });
        })
    }


    async fetchAllCityList(): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchAllCity,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                if(response.status == 200 ){
                let cityList = response.data.list;
                cityList.map((item:any)=>{
                    this.state.locationList.push(item);
                    this.state.cityList.push(item);
                })
                this.setState({locationList: this.state.locationList});
                this.setState({cityList: this.state.cityList});
            }
                
            })
            .catch((error) => {
            });
        })
      
    }
    async viewProfile(id: any): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchRawMaterialSupplierAPI + id,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let b = response.data;
                this.setState({ 
                    rawMaterialSupplier: b, 
                    isLoading: false,
                    supplierName: b.supplierName,
                    description: b.description,
                    adLine1: b.address.addressLine1,
                    adLine2: b.address.addressLine2,
                    city: b.address.city,
                    state: b.address.state,
                    country: b.address.country,
                    pincode: b.address.pincode,
                    alternateEmail: b.alternateEmail,
                    alternateMobile: b.alternateMobile,
                    twitterLink: b.socialLinks.twitterLink,
                    facebookLink: b.socialLinks.facebookLink,
                    instagramLink: b.socialLinks.instagramLink,
                    youtubeLink: b.socialLinks.youtubeLink,
                    profileImage: b.profileImage,
                    formAddressData: b.address,
                    formOtherData: b,
                    registrationNumber: b.registrationNumber,
                    selectedMaterials: b.suppplierRawMaterialMapping,
                });
               
            })
            .catch((error) => {
            });
        })
    }

    fetchRawMaterialList = ()=>{
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchRawMaterialList,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                if(response){
                    this.setState({
                        rawMaterialList: response.data
                    })
                }
            })
            .catch((error)=>{

            });
        })
    }

    updateProfile = async () => {
        Check.checkToken()
        .then(
          async () => {
        let validity = true;
        Object.values(this.state.errors).forEach((val: any) => val.length > 0 && (validity = false));
        if (validity == true) {
           
            var b = this.state.rawMaterialSupplier;
            var u = this.state.user;
            b.supplierName = this.state.supplierName;
            const options = {
                url: constants.updateRawMaterialAPI,
                data: {
                    "userId":  b.userId,
                    "rawMaterialSupplierId":  b.rawMaterialSupplierId,
                    "supplierName": this.state.supplierName,
                    "address": {
                        "id": b.address.id,
                        "addressLine1": this.state.adLine1,
                        "addressLine2": this.state.adLine2,
                        "city": this.state.city,
                        "state": this.state.state,
                        "country": "Australia",
                        "pincode": this.state.pincode,
                        "latitude": b.address.latitude,
                        "longitude":  b.address.longitude,
                        "active": b.address.active
                    },
                    "profileImage": this.state.profileImage,
                    "description": this.state.description,
                    // "mobile": "string",
                    "alternateMobile": this.state.alternateMobile,
                    "alternateEmail":  this.state.alternateEmail,
                    // "email": "string",
                    "socialLinks": {
                        "id": b.socialLinks.id,
                        "facebookLink": this.state.facebookLink,
                        "instagramLink":  this.state.instagramLink,
                        "twitterLink": this.state.twitterLink,
                        "youtubeLink": this.state.youtubeLink
                    },
                    "registrationNumber": this.state.registrationNumber,
                    "suppplierRawMaterialMapping": this.state.selectedMaterials,
                    "active": b.active
                  }
            };
            var response = await userService.updateApiRequest(options);
            if (response) {
                const options = {
                    url: constants.updateUserAPI+b.userId,
                    data: {
                        "fullName": u.fullName,
                        "userName": u.userName,
                        "email": this.state.email || u.email,
                        "phone": this.state.mobile || u.phone,
                        "emailModified": (this.state.email === u.email) ? false : true,
                        "phoneModified": (this.state.mobile === u.phone) ? false : true,
                      }
                };
                var response = await userService.postApiRequest(options);

                if (response) {
                    this.openUpload('Success', 'Profile Updated Successfully');
                    this.init();
                    this.edit();
                }else {
                    this.openUpload('Failed', 'Failed to Update Profile');
                }

            } else {
                this.openUpload('Failed', 'Failed to Update Profile');
            }
        } else {
        }
    })
    };

    cancel() {
        this.clearErrorMsg();
        this.init();
        this.edit();
    }
    edit = () => {
        this.setState({
            isEdit: !this.state.isEdit,
            isMaterialAdded: true
        });
    };
    clearErrorMsg = () =>{
        let errors = this.state.errors;
        errors.description = '';
        errors.mobile= '';
        errors.adLine1= '';
        errors.adLine2= '';
        errors.supplierName= '';
        errors.twitterLink= '';
        errors.facebookLink= '';
        errors.instagramLink= '';
        errors.alternateEmail= '';
        errors.alternateMobile= '';

    }

    hasChangedSinceInitialState = () => {
        const { formAddressData, formOtherData } = this.state;
        return (formAddressData.city === this.state.city && 
            formAddressData.state === this.state.state &&
            formAddressData.pincode == this.state.pincode &&
            formAddressData.addressLine1 === this.state.adLine1 &&
            formAddressData.addressLine2 === this.state.adLine2 &&
            formOtherData.supplierName === this.state.supplierName &&
            formOtherData.description === this.state.description &&
            formOtherData.mobile == this.state.mobile &&
            formOtherData.alternateEmail === this.state.alternateEmail &&
            formOtherData.alternateMobile == this.state.alternateMobile &&
            formOtherData.socialLinks.twitterLink === this.state.twitterLink &&
            formOtherData.socialLinks.facebookLink === this.state.facebookLink &&
            formOtherData.socialLinks.instagramLink === this.state.instagramLink &&
            formOtherData.profileImage == this.state.profileImage &&
            this.state.isMaterialAdded
            );
      }
    handleChangeProfile = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'name':
                //  errors.name = value.length == 0 ? 'Please enter valid Name': '';
                break;
            case 'description':
                     errors.description =  (value.length < 251 || value.length == 0) ? '': 'You Have Reached Your maximum Limit Of Characters Allowed';
                break;
            case 'mobile':
                     errors.mobile = (Regex.test(value) || value.length == 0) ? '' :  'Please Enter A Valid Number' ;
                break;
            case 'adLine1':
                     errors.adLine1 = value.length > 100 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
                break;
            case 'adLine2':
                     errors.adLine2 = value.length > 150 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
                break;
            case 'supplierName':
                    errors.supplierName = value.length > 70 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
               break;
            case 'alternateEmail':
                    errors.alternateEmail = (RegexEmail.test(value) || value.length == 0) ? '': 'Please enter valid Email';
                break;
            case 'alternateMobile':
                    errors.alternateMobile = (Regex.test(value) || value.length == 0) ? '' :  'Please Enter A Valid Number' ;
               break;
            case 'materialPrice':
                     errors.materialPrice = value.length > 8 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
                break;
            case 'materialBrand':
                    errors.materialBrand = value.length > 80 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
               break;
            case 'materialDescription':
                errors.materialDescription = value.length > 250 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
           break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
        const {materialBrand, materialDescription, materialType, materialPrice} = this.state;
        if(materialBrand !== '' && materialDescription !== '' && materialType !== ''
            && materialPrice !== '' && errors.materialDescription == '' && errors.materialPrice == ''
            && errors.materialBrand == ''
        ){
                this.setState({
                    isMaterialSubmit: false
                })
        }else{
                this.setState({
                    isMaterialSubmit: true
                })
        }

      
    };


  
    removeImage = async(event:any) => {
        let id = event?.target.id;
        this.setState({profileImage:''})
    }

    openUpload = async(header:any, body: any) => {
        this.setState({showUpload:true, header: header, body: body});
    }
    onHideUpload = async(event:any) => {

        this.setState({
            showUpload:false,
            isMaterialAdded: true
        });
    }

    onFileChange = async (event: any) => {
        let userId = localStorage.getItem("id");
        let id = event?.target.id;
        let file = event.target.files[0];
        const formData = new FormData();
        let errors = this.state.errors;
        if(file){
            formData.append('id', userId?userId:'');
            formData.append('type', "Profile");
            if (file && file.type != 'image/gif' && file && file.type !=  'image/jpeg' && file && file.type !=  'image/png') {
                
                errors.file = 'Please upload only proper file type';
                this.setState({ errors: errors });
            } else if (file && file.size > 2000000) {
                errors.file = 'Please upload file below 2MB';
                this.setState({ errors: errors });
            } else {
                errors.file = '';
                formData.append('file', file); 
            }  
            const options = {
                url: constants.uploadFilesAPI,
                data: formData
            }
            Check.checkToken();
            const response = await userService.postFileApiRequest(options);
                if(response){
                    this.setState({profileImage: response});
                    this.openUpload("Success", "File Uploaded SuccessFully");
                }else{
                    this.setState({profileImage: ''});
                    this.openUpload("Failed", "Failed to Upload File");
                }
        }else{
            // let errors = this.state.errors;
            // errors.file1 = 'Please Select File to Upload';
            // this.setState({errors: errors});
        }
    };
    onNewsletterChange(event:any){
        this.setState({newsletter: !this.state.newsletter});
    }

    changePassword(event:any){
        event.preventDefault();
        Check.checkToken()
        .then( () => {
        let userId = localStorage.getItem("id");
        let validity = true;
        Object.values(this.state.errors).forEach((val: any) => val.length > 0 && (validity = false));

        if(!this.state.oldPassword || !this.state.newPassword){
            return;
        }
        if (validity == true) {
            
            const options = {
                url: constants.resetPasswordAPI + userId,
                data: {
                    "oldPassword": this.state.oldPassword,
                    "newPassword": this.state.newPassword,
                }
            };
            userService.updateApiRequest(options)
            .then(response=>{
                    this.clearValues();
                    this.openUpload('Success', 'Password Changed Successfully');  
                    this.init();
                })
                .catch(error=>{
                    this.openUpload('Failed to Change Password', error.message);
                });
        }
        })
    }

    clearValues(){
        this.setState({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        });
    }


    changeTab(index:any){
        this.setState({
            pageNo: index
        })
    }

    openMaterialListModal = ()=>{
            this.setState({
                isMaterialListOpen: true,
                materialType: "",
                materialBrand: "",
                materialPrice: "",
                materialDescription: "",
                selectedState: [],
                selectedCity: [],
            })
    }
    closeListModal = ()=>{
        this.setState({
            isMaterialListOpen: false,
            isMaterialSubmit: true
           
        })
    }
    saveMaterialType = ()=>{   
        let statelist = this.state.stateCode;
        let isFound: any; 
        this.state.selectedLocation.map((items: any)=>{
            if( statelist.includes(items)){
                if(this.state.selectedState !== items ){
                    this.state.selectedState.push(items);
                    this.setState({
                        selectedState: this.state.selectedState
                    })
                }       

            }else{
                this.state.selectedCity.push(items);
                this.setState({
                    selectedCity: this.state.selectedCity
                })
            }
        })
        let name : any;
        this.state.rawMaterialList.map((item:any)=>{
            if(this.state.materialType == item.id){
                name = item.materialName
            }

        })
            let list = {
                "materialId": this.state.materialType,                              
                "brand": this.state.materialBrand,
                "price": this.state.materialPrice,
                "description": this.state.materialDescription,
                "state": this.state.selectedState,
                "city": this.state.selectedCity,
                "materialName": name
            }
            this.state.selectedMaterials.push(list);
            
            this.setState({
                selectedMaterials: this.state.selectedMaterials,
                isMaterialListOpen: false,
                isMaterialSubmit: true,
                isMaterialAdded: false
                    });
                
    }

    removeMaterial =(materialId: any)=>{
            let materials = this.state.selectedMaterials;
            const i = materials.indexOf(materialId);
            if (i > -1) {
                materials.splice(i, 1);
             }
             this.setState({
                selectedMaterials: materials,    
                selectedCity: [],
                selectedState: [],
                isMaterialAdded: false,
            })
    }

    searchMaterialByLocation = (value:any)=>{
       
        if(value){
            this.setState({
                selectedLocation: value
            })
        }else{
            this.setState({
                selectedLocation: []
            })
        }
    }

    selectCity = (value: any)=>{
        if(value){
            this.setState({
                city: value,
               
            })
        }else{
            this.setState({
                city: ''
            })
        }
    }

    render() {
        let {
            errors,
            isEdit,
            isLoading,
            description,
            adLine1,
            adLine2,
            state,
            city,
            country,
            pincode,
            twitterLink,
            facebookLink,
            instagramLink,
            rawMaterialSupplier,
            supplierName,
            address,
            mobile,
            email,
            profileImage,
            showUpload,
            header,
            body,
            newsletter,
            alternateEmail,
            alternateMobile,
            stateList,
            cityList,
            pageNo,
            oldPassword,
            newPassword,
            confirmNewPassword,
            registrationNumber,
            isMaterialListOpen,
            rawMaterialList,
            materialPrice,
            materialBrand,
            materialDescription,
            materialType,
            selectedMaterials,
            locationList,
            isEnablePassword,
            isMaterialSubmit,
            selectedLocation,
        } = this.state;
        address = adLine1+", "+adLine2+", "+city+", "+state+", "+country +"-"+ pincode;
        let isEnabled = true;
        let isSubmitted = true;
        
        if(isMaterialSubmit == false && selectedLocation.length > 0){
            isEnabled = false
        };
        if( this.hasChangedSinceInitialState() == false && supplierName !== "" && 
            city !== "" && state !== "" && adLine1 !== "" && adLine2 !== "" && selectedMaterials.length >  0
           && mobile !== "" && pincode !== "" && description !== "" && alternateMobile !== ""
        ){
            isSubmitted = false
        }

        let materialList = rawMaterialList.map((items:any)=>{
                    return(  
                        <option value = {items.id}>{items.materialName}</option>
                    ) 
        })
        
        let selectedValues = selectedMaterials.map((items: any)=>{
                let cityData = items.city.map((data: any)=>{
                    return(
                        <p style = {{margin:"0px"}}>{data? data + "," : "" }</p>
                    )
                });
                let stateData = items.state.map((data: any)=>{
                    return(
                        <p style = {{margin:"0px"}}>{data? data + "," : ""}</p>
                    )
                });
                
               
                return(
                    <>
                    {(isEdit == true)?

                        <>
                            <div className="container ">
                                <div className="row ">
                                    <div className="col-md-2 ">
                                        <p>{items.materialName? items.materialName : ""}</p>
                                    </div>
                                    <div className="col-md-2 "> 
                                        <p>{items.price? items.price : ""}</p>
                                    </div>
                                    <div className="col-md-2 ">
                                        <p>{items.brand? items.brand : ""}</p>
                                    </div>
                                    <div className="col-md-2">
                                        <p>{items.description? items.description : ""}</p>
                                    </div>
                                    <div  className="col-md-2">
                                          <p>{cityData ? cityData: ""}{stateData? stateData: ""}</p>  
                                    </div>
                                    <div className="col-md-2 ">
                                        <p style= {{color: "red", cursor: "pointer"}} onClick= {()=>{this.removeMaterial(items)}}>Remove</p>
                                    </div>
                                   
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="container ">
                                <div className="row ">
                                    <div className="col-md-2 ">
                                        <p>{items.materialName? items.materialName : "" }</p>
                                    </div>
                                    <div className="col-md-2 "> 
                                        <p>{items.price? items.price : ""}</p>
                                    </div>
                                    <div className="col-md-2 ">
                                        <p>{items.brand? items.brand : ""}</p>
                                    </div>
                                    <div className="col-md-2">
                                        <p>{items.description? items.description : ""}</p>
                                    </div>
                                    <div  className="col-md-2">
                                          <p>{cityData ? cityData: ""}{stateData? stateData: ""}</p>  
                                    </div>
                                   
                                </div>
                            </div>
                        </>
                    }
                    </>
                )
        })
        
        return isLoading ? (
            <Loader />
        ) : (
            <>
                <Header />  
                <div className="bg-img">                   
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 profile-offset-md-2 col-12">
                                    <h1>Profile</h1>
                                </div>
                                {pageNo == 0 &&
                                <>
                       
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    {pageNo == 0 &&
                    <>
                    {!isEdit ? (
                                    <div className="col-md">
                                        <button
                                            className="button3 contact profile-button"
                                            onClick={() => {
                                                this.edit();
                                            }}
                                        >
                                            Edit Profile
                                            <img src={Edit} className="ml-1" />
                                        </button>
                                    </div>
                                ) : (
                                    <div className="col-md">
                                        <button
                                            className= {isSubmitted == true ? "button3 contact save-btn disabled-button" :  "button3 contact save-btn"} 
                                            onClick={() => {
                                                this.updateProfile();
                                            }} disabled={isSubmitted}> 
                                            Save
                                        </button>
                                        <button
                                            className="button3 contact cancel-btn"
                                            onClick={() => {
                                                this.cancel();
                                            }}>
                                            Cancel
                                        </button>
                                    </div>
                                )}
                        </>
                        }        
                </div>

                <Tabs className="profile-container"  defaultIndex={0} onSelect={(index:any) => this.changeTab(index)} selectedTabClassName="TabProfileTrue">
                    <TabList>
                        <div className="container">
                            <div className="row">
                                <div className="heading">
                                    <Tab
                                        id="profile"
                                        className="title tab-select"
                                    >
                                        Profile
                                    </Tab>
                                    <Tab
                                        id="setting"
                                        className="title left-tab tab-select"
                                    >
                                        Settings
                                    </Tab>
                                </div>
                            </div>
                        </div>
                    </TabList>

                    <TabPanel>
                        <div className="aboutProject">
                            <div className="container ">
                             
                                <div className="row ">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                 {/* */}
                                                 {isEdit &&
                                                    <>
                                                    {profileImage && 
                                                        <>
                                                        <div className="profilecard">
                                                            <span>
                                                                <Image id="logo" src={blobService.createUrl(profileImage)} errorImg={Upload} alt="" width="100%" height="300px"  />
                                                                <Image className="close x-icon tab-select" id="logo" src={Delete} onClick={(event)=>{this.removeImage(event)}}  width="100%" height="20px"/>
                                                            </span>
                                                        </div>
                                                        </>
                                                        || 
                                                        <>
                                                         <div className="profilecard content-center">
                                                         <span>
                                                            <label htmlFor="logo"  style={{cursor: "pointer"}}>
                                                                <span><h6 data-toggle="tooltip" data-html="true" title="Image must be less than 2MB and file types must be png, jpeg,jpg or gif."><img src={Upload}/>Photo</h6></span>
                                                            </label>
                                                            <input accept="image/gif, image/jpeg, image/png," id="logo" type="file" style={{display:"none"}}  onChange={(event)=>this.onFileChange(event)} width="100%" height="300px"/>
                                                            </span>
                                                         </div>
                                                        </>
                                                        }
                                                    </>
                                                 ||
                                                <div className="profilecard">
                                                    <span>
                                                        <Image id="logo" src={blobService.createUrl(profileImage)} errorImg={Upload} alt="" width="100%" height="300px" />
                                                    </span>
                                                </div>
                                                 }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="projectInfo profileInfo">
                                            {(isEdit === true && (
                                                <>
                                                    <input
                                                        name="supplierName"
                                                        type={this.state.type}
                                                        value={supplierName}
                                                        autoFocus
                                                        placeholder="Name*"
                                                        onChange={this.handleChangeProfile}
                                                    />
                                                     {errors.supplierName.length > 0 && (
                                                        <p style={{ color: 'red' }}>{errors.supplierName}</p>
                                                    )}
                                                </>
                                            )) || (
                                                <span>
                                                    <h4 className = "profile-name">{supplierName}</h4>
                                                </span>
                                            )}
                                            <div style= {{marginTop: "15px"}}>
                                                <p className="city-name">{city}</p>
                                            </div>
                                            <div>
                                                {isEdit ? (
                                                    <div className="aboutProject">
                                                        <textarea
                                                            id="about"
                                                            name="description"
                                                            className="px-3"
                                                            placeholder="About Me*"
                                                            rows={8}
                                                            cols={50}
                                                            onChange={this.handleChangeProfile}
                                                            value={description}
                                                            required
                                                        />
                                                        {errors.description.length > 0 && (
                                                        <span style={{ color: 'red' }}>{errors.description}</span>
                                                    )}
                                                    </div>
                                                ) : (
                                                    <p className="profile-description" >
                                                        {description}
                                                    </p>
                                                )}
                                            </div>
                                            {isEdit===true &&
                                        <div>
                                            <br/>
                                        <div>
                                            <img src={Twitterwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="twitterLink"
                                                type={this.state.type}
                                                value={twitterLink}
                                                placeholder="Twitter Link"
                                                onChange={this.handleChange}
                                            />
                                             {errors.twitterLink.length > 0 && (
                                                        <p style={{ color: 'red', marginLeft: '50px' }}>{errors.twitterLink}</p>
                                                    )}
                                        </div>
                                        <br/>
                                        <div>
                                            <img  src={FBwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="facebookLink"
                                                type={this.state.type}
                                                value={facebookLink}
                                                placeholder="Facebook Link"
                                                onChange={this.handleChange}
                                            />
                                             {errors.facebookLink.length > 0 && (
                                                <p style={{ color: 'red', marginLeft: '50px' }}>{errors.facebookLink}</p>
                                            )}
                                        </div>
                                        <br/>
                                        <div>
                                            <img  src={Linkedinwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="instagramLink"
                                                type={this.state.type}
                                                value={instagramLink}
                                                placeholder="LinkedIn Link"
                                                onChange={this.handleChange}
                                            />
                                             {errors.instagramLink.length > 0 && (
                                                <p style={{ color: 'red',  marginLeft: '50px' }}>{errors.instagramLink}</p>
                                            )}
                                        </div>
                                        <br/>
                                        </div>
                                        ||
                                        <div>
                                         {(twitterLink == null || twitterLink == "") ?
                                            <a  target="_blank">
                                                <img  src={Twitterwithround} alt="" />
                                            </a>
                                            :
                                            <a href={ rawMaterialSupplier.socialLinks.twitterLink} target="_blank">
                                                <img  src={Twitterwithround} alt="" />
                                            </a>
                                         }
                                        {(facebookLink == null || facebookLink == "") ?
                                            <a  target="_blank" className="left-buffer">
                                                <img  src={FBwithround} alt="" />
                                            </a>
                                            :
                                            <a href={ rawMaterialSupplier.socialLinks.facebookLink} target="_blank" className="left-buffer">
                                                <img  src={FBwithround} alt="" />
                                            </a>
                                        }
                                        {(instagramLink == null || instagramLink ==  "") ?
                                            <a  target="_blank" className="left-buffer">
                                                <img  src={Linkedinwithround} alt="" />
                                            </a>
                                            :
                                            <a href={ rawMaterialSupplier.socialLinks.instagramLink} target="_blank" className="left-buffer">
                                                <img  src={Linkedinwithround} alt="" />
                                            </a>
                                        }
                                        </div>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><br/>
                        <div className="container profile-about">
                            <h4>About</h4>
                            <div className="row">
                                <div className="col-md-4" >
                                    <h6>Address</h6>
                                    {(isEdit && (
                                        <div>
                                            <div className="address" >
                                                <input
                                                    type="text"
                                                    name="adLine1"
                                                    className="px-3"
                                                    placeholder="Door No*"
                                                    onChange={this.handleChangeProfile}
                                                    value={adLine1}
                                                    required
                                                    
                                                />
                                                {errors.adLine1.length > 0 && (
                                                     <p style={{ color: 'red', width: '56%'}}>{errors.adLine1}</p>
                                                 )}
                                                
                                            </div>
                                            <br/>
                                            <div >
                                            <input
                                                    type="text"
                                                    name="adLine2"
                                                    className="px-3"
                                                    placeholder="Street*"
                                                    onChange={this.handleChangeProfile}
                                                    value={adLine2}
                                                    required
                                                />
                                                {errors.adLine2.length > 0 && (
                                                    <p style={{ color: 'red', width: '56%'}}>{errors.adLine2}</p>
                                                  )}
                                            </div>
                                            <br />
                                            <div >
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    onChange={(event, newValue) => {
                                                        this.selectCity(newValue);
                                                    }}
                                                    style = {{width : "68%"}}
                                                    options={cityList}
                                                    getOptionLabel={(option:any) => option}
                                                    value = {city}
                                                    renderInput={(params) => <TextField {...params} placeholder="Search" variant="outlined" name = "city"/>}
                                                />
                                            </div>           
                                            <br/>
                                            <div className="state">
                                                <select  name="state" id="state"  className="py-1 px-3" onChange={this.handleChange} value={state} required >
                                                        <option value="">State</option>
                                                        {stateList.map((item:any) => (
                                                            <option value={item.name} key={item.name}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                            <br />
                                            <div className="py-1 ">
                                                <input type = "number" name="pincode" id="pincode"  className="px-3" onChange={this.handleChange} value={pincode} placeholder = "Postcode*" required  onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() } ></input>
                                                {errors.pincode.length > 0 && (
                                                    <p style={{ color: 'red'}}>{errors.pincode}</p>
                                                )}
                                            </div>
                                        </div>
                                    )) || (
                                        <div>
                                            <div className="profile-description">{adLine1 ? address: ' '}</div>
                                        </div>
                                    )}{' '}
                                    <br />
                                </div>
                                <div className="col-md-4">
                                    <h6>Email</h6>
                                    <p className="profile-description">{email?email:" "}</p>
                                    <br/>
                                    <h6>Alternate Email</h6>
                                    {(isEdit && (
                                            <div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="alternateEmail"
                                                        className="px-3"
                                                        placeholder="Alternate Email"
                                                        value={alternateEmail}
                                                        onChange={this.handleChangeProfile}
                                                        required
                                                    />
                                                    {errors.alternateEmail.length > 0 &&  <p style={{color: "red"}}>{errors.alternateEmail}</p>}
                                                </div>
                                            </div>
                                        )) || (
                                            <div>
                                                <div className="profile-description">{alternateEmail ? alternateEmail :" "}</div>
                                            </div>
                                        )}{' '}
                                        <br/>
                                        <h6>Registration Number</h6>
                                        <div>
                                                <div className="profile-description">{registrationNumber ? registrationNumber :" "}</div>
                                        </div>
                                </div>

                 
                                <div className="col-md-4">
                                        <h6>Contact*</h6>
                                        {(isEdit && (
                                            <div>
                                                <div className="phoneNumber">
                                                    <input
                                                        type="number"
                                                        name="mobile"
                                                        className="px-3"
                                                        placeholder="Phone"
                                                        value={mobile}
                                                        onChange={this.handleChangeProfile}
                                                        required
                                                        maxLength = {10}
                                                        onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }
                                                    />
                                                    {errors.mobile.length > 0 && (
                                                        <p style={{ color: 'red' }}>{errors.mobile}</p>
                                                    )}
                                                </div>
                                            </div>
                                        )) || (
                                                <p className="profile-description">{mobile ? mobile :" "}</p>
                                           
                                        )}{' '}
                                        <br />
                                        <h6>Alternate Contact*</h6>
                                        {(isEdit && (
                                            <div>
                                                <div className="phoneNumber">
                                                    <input
                                                        type="number"
                                                        name="alternateMobile"
                                                        className="px-3"
                                                        placeholder="Phone"
                                                        value={alternateMobile}
                                                        onChange={this.handleChangeProfile}
                                                        required
                                                        maxLength = {10}
                                                        onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }
                                                    />
                                                    {errors.alternateMobile.length > 0 && (
                                                        <p style={{ color: 'red' }}>{errors.alternateMobile}</p>
                                                    )}
                                                </div>
                                            </div>
                                        )) || (
                                            <div>
                                                <div className="profile-description">{alternateMobile ? alternateMobile :" "}</div>
                                            </div>
                                        )}{' '}
                                    </div>                           
                            </div>
                            <div className="row">
                                <div className="col-md-12 material-list" >
                                   
                                    {(isEdit && (
                                        <>
                                             <h6>Raw Materials Available *</h6>
                                            <div className="">
                                            <div>
                                                <div className="row ">
                                                    <div className="col-md-2 ">
                                                        <h6>Name</h6>
                                                    </div>
                                                    <div className="col-md-2 ">
                                                        <h6>Price</h6>  
                                                    </div>
                                                    <div className="col-md-2 ">
                                                        <h6>Brand</h6>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <h6>Description</h6>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <h6>Location</h6>
                                                    </div>
                                                </div>
                                            </div>
                                                {selectedValues}
                                            </div>
                                            <span onClick={this.openMaterialListModal} style={{ cursor: 'pointer' }}>+Add More</span>
                                        </>
                                        )) || (
                                            <>
                                             <h6>Raw Materials Available</h6>
                                            <div> 
                                                 <div className="container ">
                                                    <div className="row ">
                                                        <div className="col-md-2 ">
                                                            <h6>Name</h6>
                                                        </div>
                                                        <div className="col-md-2 ">
                                                            <h6>Price</h6>  
                                                        </div>
                                                        <div className="col-md-2 ">
                                                            <h6>Brand</h6>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <h6>Description</h6>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <h6>Location</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                {selectedValues}
                                            </div>
                                            </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div>
                            <div className="container profile-about">
                                <h4 className="title">Account Preferences</h4>
                                <h6>Newsletters</h6>

                                <ToggleSwitch
                                        id="newsletter"
                                        checked={newsletter}
                                        onChange={(event:any)=>this.onNewsletterChange(event)}
                                        />
                                       
                                <div className="my-4">
                                    <form>
                                        <div className="password py-1">
                                            <h6>Change Password</h6>
                                            <input
                                                className="mt-2 password-input"
                                                type="password"
                                                name="oldPassword"
                                                placeholder="Old Password"
                                                onChange={this.handleChange}
                                                required
                                                value = {oldPassword}
                                                
                                            />
                                            <br />
                                            {errors.oldPassword.length > 0 && (
                                                <span style={{ color: 'red' }}>{errors.oldPassword}</span>
                                            )}
                                        </div>
                                        
                                        <div className="password py-1">
                                            <input
                                                className = "password-input"
                                                type="password"
                                                name="newPassword"
                                                placeholder="New Password"
                                                onChange={this.handleChange}
                                                required
                                                value = {newPassword}
                                                onFocus = {()=>{errors.newPassword = true}}
                                                onBlur = {()=>{errors.newPassword = false}}
                                            />
                                            <br />
                                            {(errors.newPassword == true)?
                                                 <div className='error-message mt-2 border p-3 '>
                                                    <p className='invalid'><img src= {passwordLock} style = {{marginRight: "10px"}}/><b>Your password needs to:</b></p>
                                                    <div className='p-1'>   
                                                        <p  className= {(errors.newupperCase == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newupperCase == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Uppercase
                                                        </p>
                                                        <p  className= {(errors.newlowerCase == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newlowerCase == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Lowercase
                                                        </p>
                                                        <p  className= {(errors.newdigit == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newdigit == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one digit
                                                        </p>
                                                        <p  className= {(errors.newspecialChar == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newspecialChar == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least one Special Characters
                                                        </p>
                                                        <p  className= {(errors.newminLength == true? 'valid' : 'invalid')}>
                                                            <img className= {(errors.newminLength == true? ' valid-icon' : ' invalid-icon')}></img>
                                                            At least minimum 8 characters</p>
                                                    </div>
                                             </div>
                                             :
                                             ""
                                            }
                                        </div>

                                        <div className="password py-1">
                                            <input
                                                className = "password-input"
                                                type="password"
                                                name="confirmNewPassword"
                                                placeholder=" Confirm Password"
                                                onChange={this.handleChange}
                                                required
                                                value = {confirmNewPassword}
                                            />
                                            <br />
                                            {errors.confirmNewPassword.length > 0 && (
                                                <span style={{ color: 'red' }}>{errors.confirmNewPassword}</span>
                                            )}
                                        </div>
                                        <br/>
                                        <button  className= {isEnablePassword ? "change-password-button-disable": "change-password-button"} disabled = {isEnablePassword} onClick={(event:any)=>this.changePassword(event)}>Change Password</button>
                                        <br />
                                        <br />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs><br/><br/>
                {pageNo == 0 &&
                <>
                <div className="container profile-about plan-profile-container">
                            <div className="row">
                                <div className="col-md-6 col-12">
                    <h4 >Plan</h4>
                    <div style={{display:"flex"}} >
                    <div style = {{marginRight: "30px"}} className="button-one first-section button-one-new">
                        <h2 className ="mt-5">Free</h2>
                        <h2><CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h2>
                        <p className="profile-month-text">per month</p>
                        <button className="profile-plan-button plan-button-new">Current Plan</button>
                    </div>
                    <div className="button-one second-section button-one-new ">
                    <h2 className="mt-5">Plus</h2>
                    <h2 ><CurrencyFormat value={50} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h2>
                        <p className="profile-month-text">per month</p>
                        <button className="profile-plan-button-two  plan-button-two-new">Choose Plan</button>
                    </div>
                    </div>
                </div></div></div>
                </>
                }
                <br/><br/>

                <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any)=>this.onHideUpload(event)}/>
                <Modal onHide = {this.closeListModal} show = {isMaterialListOpen}>
                        <Fragment>
                            <div className="container p-4 profile-about">
                                    <div className='d-flex material-list' style={{justifyContent: "space-between"}}>
                                        <h6>Add a Raw Material</h6>
                                        <img src = {Vector} onClick={this.closeListModal}/>
                                    </div>
                                    <p>Choose a material and add it to your profile</p>
                                    <div>
                                         <select onChange={this.handleChangeProfile} name="materialType" value={materialType} className='mt-2 px-3 py-1 '>
                                            <option value="">Name</option>
                                                {materialList}
                                        </select>
                                        <input 
                                            onChange={this.handleChangeProfile} 
                                            className='mt-3 px-3 py-1' 
                                            type= "number" 
                                            placeholder='Price' 
                                            name= "materialPrice" 
                                            value = {materialPrice}
                                            onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }
                                            >
                                        </input>
                                        {errors.materialPrice.length > 0 && (
                                                <p style={{ color: 'red'}}>{errors.materialPrice}</p>
                                            )}
                                        <input 
                                            onChange={this.handleChangeProfile} 
                                            name = "materialBrand" 
                                            value = {materialBrand} 
                                            className='mt-3 px-3 py-1 ' 
                                            type= "text" 
                                            placeholder='Brand/Company'
                                        >
                                        </input>
                                        {errors.materialBrand.length > 0 && (
                                                <p style={{ color: 'red'}}>{errors.materialBrand}</p>
                                            )}
                                        <textarea 
                                            onChange={this.handleChangeProfile} 
                                            name = "materialDescription" 
                                            value = {materialDescription} 
                                            className='mt-3 px-3 py-1'  
                                            placeholder='Description'
                                            >
                                        </textarea>
                                        {errors.materialDescription.length > 0 && (
                                                <p style={{ color: 'red'}}>{errors.materialDescription}</p>
                                            )}
                                        <div className="search-bar-section">
                                            <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                onChange={(event, newValue) => {
                                                    this.searchMaterialByLocation(newValue);
                                                }}
                                                // style = {{width : "100%"}}
                                                options={locationList}
                                                getOptionLabel={(option:any) => option}
                                                renderInput={(params) => <TextField {...params} placeholder="Search Location" variant="outlined" name = "searchLocationText" />}
                                                />
                                        </div>
                                    </div>
                                    <div className='d-flex mt-2'>
                                        <button className= {isEnabled == true ? "hire disabled-button": "hire"}  disabled = {isMaterialSubmit} onClick={this.saveMaterialType}>Save</button>
                                        <button className='ml-4' style = {{border: "none", backgroundColor: "#ffff"}} onClick={this.closeListModal}>Cancel</button>
                                    </div>
                            </div>
                        </Fragment>
                </Modal>
                <Footer />
            </>
        );
    }
}
