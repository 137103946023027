import React, { Component } from 'react';
import Header from '../../../../common-components/Header/Header';
import Footer from '../../../../common-components/Footer/Footer';
import whatisanemi from '../../../../../assets/images/whatisanemi.png';
import { Link } from 'react-router-dom';

export default class WhatIsEmiBlog extends React.Component {

    render() {
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-12 ">
                                <h1  className="ourMissionHead">Blog</h1>
                                <p style = {{fontSize: "14px"}} className="my-3"><Link to="/Blog" className="link-text">Blogs</Link> / EQUATED MONTHLY INSTALLMENT (EMI)</p>
                            </div>             
                            </div>
                        </div>
                    </div>
                </div>
            <br/><br/>
            <div className='container blog-container p-3'>     
                   <div className='blog-box  '>
                        <h3 style={{color: "#033b64 "}}>EQUATED MONTHLY INSTALLMENT (EMI) </h3>
                        <img style = {{width: "50%" , height: "40%"}} src={whatisanemi}/>
                        <div  className=' mt-3'>
                            <p>
                                The Equated Monthly Instalment (EMI), which the borrower will be
                                required to pay, is the main factor in determining the size of the
                                mortgage that the borrower can afford. We now primarily concentrate
                                on EMI related to home loan from the various types of EMI available
                                in the financial sector. 
                            </p>
                            <h4>How is an EMI calculated?</h4>
                            <p>
                                The monthly instalment is calculated based on 3 points: 
                            </p>
                            <ul>
                                <li>1. The loan amount. </li>
                                <li>2. Interest rate.</li>
                                <li>3. Tenure. </li>
                            </ul>
                            <h4>
                                Home Loan Amount Eligibility
                            </h4>
                            <p>
                                For any individual the principal loan amount required for the
                                construction of a house is decided based on certain criteria like his/her
                                total annual income. The other factors that follows are age, financial
                                position, credit history and score, repaying capacity, assets etc. 
                            </p>
                            <h4>Rate Of Interest:</h4>
                            <p>
                                The interest depends on the lender from whom you
                                are applying for a home loan. In general, it may vary from anywhere
                                between 7 – 10% (it may vary for different lenders such as banks and
                                the principal amount).
                            </p>
                            <h4>Tenure:</h4>
                            <p>
                                The tenure – time provided by the lender to repay the
                                complete loan amount along with the interest. In India, the usual
                                repayment time is from minimum of 2 years up to 30 years.
                            </p>
                            <h4>EMI Calculator:</h4>
                            <p>
                                EMI is calculated using the following formula: EMI = P * r * (1 + r) *
                                n/((1 + r) * n - 1)), where P is the loan amount, r is the interest rate,
                                and n is the term in months.
                                The EMI payments increase in proportion to the loan amount or
                                interest rate, as mutually decided by the bank and customer. Even
                                though the total amount of interest that must be paid rises as the loan
                                period lengthens, the EMI payments fall as the loan tenure lengthens.
                            </p>
                            <h4>Who can all apply for the home loan?</h4>
                            <ul>
                                <li>
                                    Salaried professionals working under government and privatesector.
                                </li>
                                <li>
                                    Other professionals like doctors, engineers, company secretary,
                                    management consultants, etc.
                                </li>
                                <li>
                                    A business owner who is self-employed and files income tax
                                    returns. 
                                </li>
                                <li>
                                    Age criteria is from 21 years and not more than 65 years
                                </li>
                            </ul>
                            <h4>Financial Brokers at Aashray Homes</h4>
                            <p>
                                The financial brokers listed on Aashray Homes help your get a quick
                                home loan with the application process being simple and transparent.
                                You can ask your queries related to your home loan eligibility know
                                the estimated EMI. You will be able to opt from the available flexible
                                of interest rates and tenure.
                                Choose the tenure set as per your requirement and convenience; either
                                for a brief term to ensure that your interest payments are as low as
                                possible, or for a period of up to 30 years to enable a low EMI.
                            </p>
                            <p> 
                                From the comfort of your home, you can apply and receive the loan
                                for your new house with the help of our financial brokers.
                            </p>
                        </div>
                       
                    </div>
			</div>
            <Footer/>
            </>
         );
    }
}
