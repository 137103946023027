import React, { Component } from "react";
import "./HireMultipleBuilders.scss";
import Vector from  "../../../../assets/images/Vector.svg";
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import quotearrow from  "../../../../assets/images/quotearrow.svg";
import HireRectangleBlue from  "../../../../assets/images/HireRectangleBlue.svg";
import HireRectangle from  "../../../../assets/images/HireRectangle.svg";
import Loader from '../../../common-components/Loader';
import Check from '../../../Services/Check';

interface hireMultipleBuildersprops {
     result: any;
     id: any;
}

interface hireMultipleBuildersstate {
    builderList: any,
    selectedValue: any[],
    quoteModal: boolean,
    closeHireModal: boolean,
    isSelected: boolean,
    isLoading: boolean,
}

class HireMultipleBuilders extends React.Component<hireMultipleBuildersprops , any & hireMultipleBuildersstate>{
    constructor(props:any){
        super(props);
        const initialState = {
            builderList: [],
            selectedValue: [],
            quoteModal: false,
            closeHireModal: true,
            // isSelected: this.props.checked,
            isLoading: true,

        };
        this.state = initialState;


    }
    componentDidMount () {   
         this.init();
    }

    init(){
         this.getBuilderList(); 
    }
    handleCheckBox = (event: any) => {
        let isChecked = event.target.checked;
        let item = event.target.value;
        if(isChecked){
            this.setState({
                    selectedValue: [...this.state.selectedValue, parseInt(event.target.value)],
                })
        }else{
                const index = this.state.selectedValue.indexOf( event.target.value);
                this.state.selectedValue.splice(index,1);
                this.setState({
                    selectedValue: this.state.selectedValue,
                })


        }
    };

    closeHireModal = ()=>{
        this.setState({
            // isSelected: false,
            selectedValue: []
        })
        this.props.result(this.state.closeHireModal);
    }

    getBuilderList = () =>{
        Check.checkToken()
        .then(() => {
            let projectId = this.props.id;
            const options ={
            url : constants.getBuilderListAPI + "?cityName&stateName&projectId="+ projectId,
            }
            userService.getApiRequest(options)
            .then((response) => {
                if(response.data){
                    this.setState({
                        isLoading: false,
                        builderList: response.data,
                    })
                }
            })
            .catch((error) => {

            });
    })
    }
    openQuoteModal = () =>{
        const {selectedValue} = this.state
        if(selectedValue.length !== 0){
            this.props.result(this.state.selectedValue);
        }else{
        }
    }


    render(){
        const {builderList, selectedValue, isSelected, isLoading} = this.state
        let builderNames = builderList.map((list: any) =>{
            let builderId =  list.userId;
            let isChecked;
            for(let i = 0; i < selectedValue.length; i++ ){
                    if(builderId == selectedValue[i]){
                        isChecked = true
                    }
            }
            return(
                <>
                <div className = "d-flex">
                    <input
                        type = "checkbox"
                         checked ={isChecked}
                        onChange={this.handleCheckBox}
                        value = {list.userId}
                      className = "radio-custom"
                          
                    />
                    <p> {list.builderName}</p>
                </div>
                <hr/>
                </>
            )
          
        })
        return isLoading ? (<Loader/>): 
        (
            <>
            <div className = "container-fluid hire-container ">
                <div className = "hire-header">
                    <p>Ask a Quote</p>
                    <img src = {Vector}  onClick={this.closeHireModal}/>
                </div>
                <div style= {{marginBottom : "10px"}}>
                    <img style = {{marginRight : "5px"}} src = {HireRectangleBlue}/>
                    <img src = {HireRectangle}/>
                </div>
                {(builderList.length > 0 )?
                        <div >
                            <p className = "hire-content">Select builders for your project.</p>
                                <div className = "hire-content-list" >
                                    <p>Builders</p>
                                    <div className = "scrollableContent" style = {{height: "23vh"}}>
                                        {builderNames}
                                    </div>
                                
                                </div>
                        </div>
                        :
                        <div style = {{padding: "50px"}}>
                            <h5>No builders available.</h5>
                        </div>
                }
                <button className = {(selectedValue.length !== 0)? 'enableHireButton' : 'disabledHireButton' } onClick={this.openQuoteModal}>Next</button>
            </div>
                   
            </>
        )
    }

       
    

}

export default HireMultipleBuilders;