import React, {Component} from "react";
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Buildershape from '../../../../assets/images/Buildershape.svg';
import { Link } from 'react-router-dom';
import Loader from '../../../common-components/Loader';
import { blobService } from '../../../common-components/Blob';
import PersonPlaceholder from '../../../../assets/images/person-placeholder.svg';
import Rating from '@material-ui/lab/Rating';
import axios from 'axios';
import { constants } from '../../../../constants/constants';
import './RawMaterialSuppliers.scss';


interface RawMaterialSuppliersProps {
    id?: string;
    location?:any
}

interface RawMaterialSuppliersState{
    rawMaterialSuppliers: any;
    isLoading: boolean,
    locationList: any,
    stateCode: any,
    searchLocationText: string,
    materialName: string

}

class RawMaterialSuppliers extends React.Component<RawMaterialSuppliersProps , any & RawMaterialSuppliersState>{
    constructor(props: RawMaterialSuppliersProps){
        super(props);
        const initialState = {
            rawMaterialSuppliers: [],
            isLoading: false,
            locationList: [],
            stateCode: [],
            searchLocationText: '',
            materialName: ""
        }
        this.state = initialState;
    }

    componentDidMount (){
        this.init();
    }

    init (){
        let materialName = this.props.location.state? this.props.location.state.materialName: "";
        this.setState({
            materialName: materialName
        })
        this.fetchRawMaterialSuppliers(materialName);
        this.fetchStateList();
        this.fetchCityList();
    }

    async fetchRawMaterialSuppliers(materialName:any): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        const key = constants.subscriptionKey;
        const url =  constants.getRawMaterialSupplierListAPI + "?cityName=&stateName=&materialName="+ materialName ;
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                if(response.data){
                    this.setState({ rawMaterialSuppliers: response.data, isLoading: false });
                }
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    }

    async fetchStateList(): Promise<any> {
        const key = constants.subscriptionKey;           
        const url =  constants.fetchStatesListAPI;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                    let stateList = response.data;
                    stateList.map((item:any)=>{
                        this.state.locationList.push(item.name);
                        this.state.stateCode.push(item.name);
                    })
                    
                    this.setState({
                        locationList: this.state.locationList,
                        stateCode: this.state.stateCode,
    
                    });
                }
                   
            })
            .catch((error) => {
            });
      
    }
    async fetchCityList(): Promise<any> {
        const key = constants.subscriptionKey;           
        const url =  constants.fetchAllCity;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                let cityList = response.data.list;
                cityList.map((item:any)=>{
                    this.state.locationList.push(item);
                })
                this.setState({locationList: this.state.locationList});
            }
                
            })
            .catch((error) => {
            });
      
    }

    searchSupplierByLocation = (value:any)=>{
        let selectedValue;
        if(value){
            selectedValue = value
        }else{
            selectedValue = ""
        }
        let statelist = this.state.stateCode;
        const isFound = statelist.includes(selectedValue);
        let url;
        if(isFound == true){
            url =  constants.getRawMaterialSupplierListAPI + "?cityName=&stateName="+ selectedValue.replace('&', '%26')+"&materialName="+ this.state.materialName;
        }else{
            url = constants.getRawMaterialSupplierListAPI + "?cityName=" + selectedValue+"&stateName=&materialName="+ this.state.materialName;
        }
        const key = constants.subscriptionKey;
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) =>{
                if(response.status == 200 ){
                    this.setState({ rawMaterialSuppliers: response.data, isLoading: false });
                }
                
            })
            .catch((error) => {
            });
    }

    render(){
        const {rawMaterialSuppliers, isLoading, locationList, searchLocationText}: any = this.state;
        const listItems = rawMaterialSuppliers.map(
            (item:any) => (
                <li className="col-md-4">
                    <div className="buildercard architectCard">
                    <span>
                        <Link to={{ pathname: "/ViewRawMaterialSupplier",state:{data:item}}} className="disabledCursor">
                            { item.profileImage && 
                                <img src={blobService.createUrl(item.profileImage)} alt="" width="100%" height="200px"/>
                            ||
                                <img src={PersonPlaceholder} alt=""  width="100%" height="200px"/>
                            }
                        </Link>
                        </span>
                    </div>
                    <div className="buildername">{item.supplierName}</div>
                    <div className="location">{item.address ? item.address.city : ""}</div>
                    {/* <div className="location">{item.address ? item.address.state : ""}</div>
                    <div className="location">{item.mobile}</div> */}
                    {/* <Rating name="read-only" value={item.rating} readOnly className="rating" /> */}
                </li>
            )
        );
        return isLoading ? (<Loader/>):
        (
            <>
                <Header />
                     <div className="Supplier d-block w-100">
                        <div className="bannerButtons">
                            <Link to = "/supplierContentPage">
                                <button className='py-2 mr-3'>Why Hire our Raw Material Suppliers?</button>
                            </Link>
                        </div>
                    </div>
                    <div className="buildersBlock py-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <h4>Filters</h4>
                                    <h5 className="my-3">Sort by Location</h5>
                                    <div className="search-bar-section">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            onChange={(event, newValue) => {
                                                this.searchSupplierByLocation(newValue);
                                            }}
                                            style = {{width : "100%"}}
                                            options={locationList}
                                            getOptionLabel={(option:any) => option}
                                            renderInput={(params) => <TextField {...params} placeholder="Search" variant="outlined" name = "searchLocationText"/>}
                                            />
                                    </div>
                                </div>
                                <div className="offset-md-1 col-md-8">
                                    {rawMaterialSuppliers.length > 0 &&
                                        <ul className="builderscard row">{listItems}</ul>
                                        ||
                                        <div className="buildersBlock py-5 empty-Message-Container">
                                            <div className="container-fluid empty-Message-Content">
                                                <img src={Buildershape} alt="" />
                                                <p className="text-center my-3">There are no raw material suppliers on the platform yet. We will soon have some for you.</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer/>
            </>
        )
    }

}
export default RawMaterialSuppliers;
