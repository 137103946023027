import axios from 'axios';
 import {constants} from '../../constants/constants';

class AuthenticationService {

    signin  = (email: any, password: any) =>{  
            const key = constants.subscriptionKey;
           const url= constants.loginAPI;
        return axios.post(url, {email, password}, { headers: { "Ocp-Apim-Subscription-Key" :key} })
        .then((response: any) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data.token));
                //localStorage.setItem("token", JSON.stringify(response.data.token));
                localStorage.setItem('id', response.data.id);
                localStorage.setItem('userType', response.data.userType);
                localStorage.setItem('tab', '0');
                
            
              }
            
                
              return response;       
        })
        .catch(err => {
            throw err;
          });

    }

}

export default new AuthenticationService();



































