import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import { constants } from '../../../../constants/constants';
import { Link } from 'react-router-dom';
import Loader from '../../../common-components/Loader';
import { blobService } from '../../../common-components/Blob';
import PersonPlaceholder from '../../../../assets/images/person-placeholder.svg';
import Rating from '@material-ui/lab/Rating';
import './Architects.scss';
import axios from 'axios';
import Buildershape from '../../../../assets/images/Buildershape.svg';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '../../../../assets/images/search.svg';


interface ArchitectsProps {
    name?: any;
    value?: any;
}
interface ArchitectsState {
    isEdit: boolean;
    profileTab: boolean;
    settingTab: boolean;
    checked: boolean;
    newsletter: boolean;
    id: string;
    showing: boolean;

    architects: any;
    error: boolean;
    ErrMsg: any;
    isLoading: boolean,
    searchTxt: string,
    errors: {};
    locationList: any,
    searchLocationText: string,
    stateCode: any,
}

class Architects extends React.Component<ArchitectsProps, ArchitectsState> {
    constructor(props: ArchitectsProps) {
        super(props);

        const initialState = {
            isEdit: false,
            profileTab: true,
            settingTab: false,
            checked: true,
            newsletter: false,
            id: '',
            showing: true,

            architects: [],
            isLoading: true,
            searchTxt: '',
            errors: {},
            error: false,
            ErrMsg: "",
            locationList: [],
            searchLocationText: '',
            stateCode: [],

        };
        this.state = initialState;
    }

    componentDidMount() {
        this.init();
    }
    init() {
        this.fetchArchitects();
        this.fetchStateList();
        this.fetchCityList();
    }
    async fetchArchitects(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        const key = constants.subscriptionKey;
        const url = constants.getArchitectListAPI + "?cityName=&stateName=";
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                if (response.data) {
                    this.setState({ architects: response.data, isLoading: false });
                }
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error: true });
            });
    }


    cancel() {
        this.edit();
    }
    edit = () => {
        this.setState({
            isEdit: !this.state.isEdit,
        });
    };
    async fetchStateList(): Promise<any> {
        const key = constants.subscriptionKey;
        const url = constants.fetchStatesListAPI;
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                if (response.status == 200) {
                    let stateList = response.data;
                    stateList.map((item: any) => {
                        this.state.locationList.push(item.name);
                        this.state.stateCode.push(item.name);
                    })

                    this.setState({
                        locationList: this.state.locationList,
                        stateCode: this.state.stateCode,

                    });
                }

            })
            .catch((error) => {
            });

    }
    async fetchCityList(): Promise<any> {
        const key = constants.subscriptionKey;
        const url = constants.fetchAllCity;
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                if (response.status == 200) {
                    let cityList = response.data.list;
                    cityList.map((item: any) => {
                        this.state.locationList.push(item);
                    })
                    this.setState({ locationList: this.state.locationList });
                }

            })
            .catch((error) => {
            });

    }
    searchArchitectByLocation = (value: any) => {
        let selectedValue;
        if (value) {
            selectedValue = value
        } else {
            selectedValue = ""
        }
        let statelist = this.state.stateCode;
        const isFound = statelist.includes(selectedValue);
        let url;
        if (isFound == true) {
            url = constants.getArchitectListAPI + "?cityName=&stateName=" + selectedValue;
        } else {
            url = constants.getArchitectListAPI + "?cityName=" + selectedValue + "&stateName=";
        }
        const key = constants.subscriptionKey;
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                if (response.status == 200) {
                    this.setState({ architects: response.data, isLoading: false });
                }

            })
            .catch((error) => {
            });
    }

    render() {
        const { errors, isLoading, architects, error, ErrMsg, locationList, searchLocationText } = this.state;
        const listItems = architects.map(
            (item: any) => (
                <li className="col-md-4">
                    <div className="buildercard architectCard">
                        <span>
                            <Link to={{ pathname: "/ViewArchitect", state: { data: item } }} className="disabledCursor">
                                {item.profileImage &&
                                    <img src={blobService.createUrl(item.profileImage)} alt="" width="100%" height="200px" />
                                    ||
                                    <img src={PersonPlaceholder} alt="" width="100%" height="200px" />
                                }
                            </Link>
                        </span>
                    </div>
                    <div className="buildername"><p>{item.architectName}</p></div>
                    <div className="location">{item.address ? item.address.city : ""}</div>
                    {/* <Rating name="read-only" value={item.rating} readOnly className="rating" /> */}
                </li>
            )
        );
        return isLoading ? (<Loader />) :
            (
                <>
                    <Header />

                    <div className="Architect d-block w-100">
                        <div className="bannerButtons">
                            <Link to="/architectContentPage">
                                <button className='py-2 mr-3'>Why Hire our Architects?</button>
                            </Link>
                        </div>
                    </div>


                    <div className="buildersBlock py-3">
                        <div className="container">
                            <div className='content'>
                                <p>Are you looking for architects in Australia?</p>
                                <p>Aashray Homes Australia has listed a team of skilled and energetic professionals who are passionate about home design and believe in creating unique architectural and interior solutions keeping in mind the clients’ needs and tastes. We try to approach the problem with an open mind by fully considering the client’s home and office ideas without any preconceived notion of how it is supposed to be. We believe this is the first step to creating wonderful floor plan designs.
                                    Our core strength is to understand the client’s needs and aspirations for a particular project be it office or house plans and design the project in the best possible way keeping in mind site conditions, budget constraints and timelines.</p>
                                <Link to="/architectContentPage">
                                    <button className='px-4 py-2 mt-5'>
                                        Learn More
                                    </button>
                                </Link>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-3">
                                    <h4>Filters</h4>
                                    <h5 className="my-3">Sort by Location</h5>
                                    <div className="search-bar-section">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            onChange={(event, newValue) => {
                                                this.searchArchitectByLocation(newValue);
                                            }}
                                            style={{ width: "100%" }}
                                            options={locationList}
                                            getOptionLabel={(option: any) => option}
                                            renderInput={(params) => <TextField {...params} placeholder="Search" variant="outlined" name="searchLocationText" />}
                                        />

                                    </div>


                                </div>
                                <div className="offset-md-1 col-md-8">
                                    {architects.length > 0 &&
                                        <ul className="builderscard row">{listItems}</ul>
                                        ||
                                        <div className="buildersBlock py-5 empty-Message-Container">
                                            <div className="container-fluid empty-Message-Content">
                                                <img src={Buildershape} alt="" />
                                                <p className="text-center my-3">There are no architects on the platform yet. We will soon have some for you.</p>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </>
            );
    }
}

export default Architects;
