
import {constants} from '../../constants/constants';
import { userService } from '../../features/Services/CommonService';


class Check {

    checkToken  = () =>{  
       const options = {
        url: constants.refreshToken,
        };
     
     return userService
      .getApiRequest(options)
        .then((res:any) => {
            if (res.status === 200) {
            
              }
              return res;       
        })
        // .catch(err => {
        //     throw err;
        //   });

  
  }

}

export default new Check();
