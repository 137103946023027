import React from "react";
import RightArrow from "../../../assets/images/rightarrow1.svg";
import { constants } from '../../../constants/constants';
import axios from 'axios';





interface ForgotPasswordProps {
    name?: any;
    value?: any;
    result: any;
    closeModal: any;
   
 }
 interface ForgotPasswordState {
    name : string,
    email : string,
    errors: {
        name: string;
        email: string;
    };
    loginErr: string;
    showUpload: boolean,
    header: string,
    body: string,

 }
 const Regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
class ForgotPassword extends React.Component<ForgotPasswordProps,ForgotPasswordState & any > {
    constructor(props: any) {
        super(props);
        const initialState = {
            name : '',
            email : '',
            errors : {
                email : '',
                name : '',
               
              },
              loginErr: '',
              showUpload: false,
              header: '',
              body: '',
            
            }
            this.state = initialState;
             this.handleChange = this.handleChange.bind(this)
             
        }
     
        handleChange = (event : any) => {
            event.preventDefault();
            const { name, value } = event.target;
            let errors = this.state.errors;
            switch (name) {
                case 'email':
                    errors.email = Regex.test(value) ? '' : 'Please Enter Valid Email';
                    break;
                default:
                    break;
            }
            this.setState(Object.assign(this.state, { errors, [name]: value }));
            // const { name, value } = event.target;
            // this.setState({ [name]: value });
           }

        handleSubmit = (event : any) => {
            this.setState({errors: this.state.errors});
            event.preventDefault();
            let email = this.state.email;
            const key = constants.subscriptionKey;
            const url =  constants.forgotPasswordAPI + '?userEmailId=' +email+"&userName=";
                axios.get(url, { headers: { "Ocp-Apim-Subscription-Key" :key} })
                    .then((response) => {
                        this.clearValues();
                        this.props.result(response.data.message);
                        
                    })
                    .catch((error) => {
                        this.props.result(error.response.data.message);
                        this.setState({loginErr: "Cannot send mail ! Please Check Email Id/UserName"});
                    });
        
         }
           clearValues(){
                this.setState({ email:''});
                }
            closeForgotPasswordModal = ()=>{
                    this.clearValues();
                    this.props.closeModal(false);
                } 
        render() {
            const {errors, name, email, loginErr, showUpload,header, body,} = this.state 
            return (
                <>
                <div className='register'>

                    <div className='container px-5 py-3'>
                        <div>
                           
                            <h1  style = {{ marginTop: '38px'}}>Forgot Password</h1>
                            <div className="my-4" style = {{ height: '61vh'}}> 
                                <div className='email'>
                                        <label className="mt-2">Email id</label>
                                        <input type='email' name='email' className="w-100 px-3" placeholder="Email" value={email} onChange={this.handleChange} required/>
                                        {errors.email.length > 0 &&  <span style={{color: "red"}}>{errors.email}</span>}
                                    </div> 
                                    {/* <div className='fullName'>
                                        <label className="mt-2">User Name</label>
                                        <input type="text" name="name" className="w-100 px-3" placeholder="Name" value={name} onChange={this.handleChange}  required/>
                                        {errors.name.length > 0 &&  <span style={{color: "red"}}>{errors.name}</span>}
                                        {loginErr.length > 0 && <span style={{ color: 'red' }}>{loginErr}</span>}
                                    </div> */}

                                    <br/><br/>
                                    <div className="login-cancel d-flex">
                                        <button onClick={this.handleSubmit}  className="mr-3">Send Link</button>
                                        <button onClick={this.closeForgotPasswordModal}>Cancel
                                            <img src={RightArrow} className="ml-2 arrow2" />
                                        </button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )
          }

    }




export default ForgotPassword;