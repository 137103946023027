import React from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import '../../../feature-components/theme.scss';
import Rating from '@material-ui/lab/Rating';
import FBwithround from '../../../../assets/images/FBwithround.svg';
import Twitterwithround from '../../../../assets/images/Twitterwithround.svg';
import LinkedInwithround from '../../../../assets/images/Linkedinwithround.svg';
import { Link } from 'react-router-dom';
import Edit from '../../../../assets/images/edit.svg';
import Project1 from '../../../../assets/images/projects1.jpg';
import Project3 from '../../../../assets/images/project3.jpg';
import RightArrow from '../../../../assets/images/right-arrow.svg';
import LeftArrow from '../../../../assets/images/left-arrow.svg';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import { blobService } from '../../../common-components/Blob';
import Loader from '../../../common-components/Loader';
import './Portfolio.scss';
import Delete from "../../../../assets/images/delete.svg"
import Carousel from "react-bootstrap/Carousel";
import Image from "../../../common-components/Image";
import Upload from "../../../../assets/images/upload.svg"
import {Card, Button} from "react-bootstrap";
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import Modal from 'react-awesome-modal';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import UpdateBuilderProject from '../../../feature-components/Builder/UpdateBuilderProject/UpdateBuilderProject';
import Check from '../../../Services/Check';
import ruler from '../../../../assets/images/ruler.svg';

interface PortfolioProps {
    name?: any;
    value?: any;
}
interface PortfolioState {
    isLoading: boolean;
    builder: any;
    projects: any;
    images: any[];
    errors: {
        builderName: string,
        twitterLink: string,
        facebookLink: string,
        about: string,
        instagramLink: string,
    };
    nextIcon: any;
    prevIcon: any;
    listItems: any;
    isEdit: boolean;
    type: string;
    builderLogo: string,
    showProject: boolean,
    showUpload: boolean,
    header: string,
    body: string,
    builderName: string,
    city: string,
    twitterLink: string,
    facebookLink: string,
    about: string,
    portfolio: any,
    selectedProject: any,
    action: string,
    error:boolean;
    ErrMsg:any;
    bannerImages: any[],
    builderModal: boolean,
    formOtherData: any,
    formImage: any,
    instagramLink: string,


}

export default class Portfolio extends React.Component<PortfolioProps, PortfolioState> {
    constructor(props: PortfolioProps) {
        super(props);
        const initialState = {
            isLoading: true,
            builder: {},
            projects:[],
            type: 'text',
            images: [],
            errors: {
                builderName: '',
                twitterLink: '',
                facebookLink: '',
                about: '',
                instagramLink:'',
            },
            nextIcon: <span className="icon-button"><img src={RightArrow}></img></span>,
            prevIcon: <span className="icon-button"><img src={LeftArrow}></img></span>,
            listItems: [],
            isEdit: false,
            builderLogo:'',
            showUpload: false,
            showProject: false,
            header: '',
            body: '',
            builderName: '',
            city: '',
            twitterLink: '',
            facebookLink: '',
            about: '',
            portfolio:[],
            selectedProject:{},
            action:'',
            bannerImages: [],
            builderModal: false,
            error:false,
            ErrMsg:"",
            formOtherData: [],
            formImage: [],
            instagramLink:'',

        };
        this.state = initialState;
    }
    componentDidMount() {
        this.init(); 
    }

    init(){
        let userId = localStorage.getItem("id");
        this.fetchBuilder(userId); 
    }

    async fetchBuilder(id: any): Promise<any> {
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.fetchBuilderAPI + id,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let b = response.data;
                let images;
                images = JSON.stringify(b.bannerImages);
                this.setState({ 
                    builder: b, 
                    builderLogo: b.logoPath, 
                    builderName: b.builderName, 
                    city: b.address ? b.address.city : '', 
                    twitterLink: b.socialLinks ? b.socialLinks.twitterLink :"", 
                    facebookLink: b.socialLinks ?  b.socialLinks.facebookLink : "" , 
                    instagramLink: b.socialLinks ? b.socialLinks.instagramLink:"",
                    about:b.description,
                    bannerImages: b.bannerImages,
                    formOtherData: b ,
                });
                localStorage.setItem("images" , images);
                this.fetchProjects(id);
                this.fetchPortfolio(id);
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        })
    };
    async fetchProjects(id: any): Promise<any> {           
        const options = {
            // url: constants.getBuilderProjectsAPI + id,
            url: constants.getBuilderProtfolioProjectsAPI + id  +"?status=In Progress"
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ projects: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });

    };

    async fetchPortfolio(id: any): Promise<any> {
        const options = {
            // url: constants.getBuilderPortfolioAPI + id,
             url: constants.getBuilderProtfolioProjectsAPI + id  +"?status=Completed"
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ portfolio: response.data });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
        
    };

     async loadImages(path:string){
        return await blobService.fetch(path);
    };

    editPortfolio = async() => {
        this.setState({
            isEdit: !this.state.isEdit
         });
    }
    

    savePortfolio = async() => {
        Check.checkToken()
        .then(
            async() => {
        let userId = localStorage.getItem("id");
        let validity = true;
        Object.values(this.state.errors).forEach(
            (val: any) => val.length > 0 && (validity = false)
        );
          if(validity == true){
             var p = this.state.builder;
             const options = {
              url: constants.updateBuilderAPI,
              data: {
                "userId": userId,
                "builderName": this.state.builderName,
                "builderType":  p.builderType.builderType,
                "address": {
                    "id": p.address.id,
                    "addressLine1": p.address.addressLine1,
                    "addressLine2": p.address.addressLine2,
                    "city": this.state.city,
                    "state": p.address.state,
                    "country": p.address.country,
                    "pincode": p.address.pincode,
                    "latitude": p.latitude,
                    "longitude": p.longitude,
                    "active": p.address.active
                },
                "logoPath": this.state.builderLogo,
                "portfolioImages": p.portfolioImages,
                "bannerImages": this.state.bannerImages,
                "registrationNo": p.registrationNo,
                "registeredOn": p.registrationOn,
                "apn": p.apn,
                "description": this.state.about,
                "executiveName": p.executiveName,
                "executiveProfile": p.executiveProfile,
                "executiveRole": p.executiveRole,
                "mobile": p.mobile,
                "office": p.office,
                "email": p.email,
                "website": p.website,
                "socialLinks": {
                    "id": p.socialLinks.id,
                    "facebookLink": this.state.facebookLink,
                    "instagramLink": p.socialLinks.instagramLink,
                    "twitterLink": this.state.twitterLink,
                    "youtubeLink": p.socialLinks.youtubeLink
                },
                "minBudget": p.minBudget,
                "maxBudget": p.maxBudget,
                "staffCount": p.staffCount,
                "membership": p.membership,
                "createdDate": p.createdDate,
                "updatedDate": p.updatedDate,
                "active": p.active
            }
            }
            var response = await userService.updateApiRequest(options);

            if(response){
                this.openBuilderModal("Success", "Portfolio Updated Successfully");
                this.init();
                
            }
            else{
                this.openBuilderModal("Failed", "Failed to Update Portfolio");
            }
            
          }else{
          }
        }
        )

    }
    cancelPortfolio = async() => {
        this.clearErrorMsg();
        this.init();                
        this.editPortfolio();
    }
    clearErrorMsg = () =>{
        let errors = this.state.errors;
        errors.about = '';
        errors.twitterLink= '';
        errors.facebookLink= '';
        errors.builderName= '';
        errors.instagramLink= '';
    }

    removeImage = async(event:any, path:string) => {
        let id = event?.target.id;
        if(id === "logo"){
            this.setState({builderLogo:''})
        }else{
            let bImages = this.state.bannerImages;
            const i = bImages.indexOf(path);
            if (i > -1) {
                bImages.splice(i, 1);
             }
             this.setState({
                bannerImages: bImages,    
            })
        }
    }

    onFileChange = async (event: any) => {
        
        let userId = localStorage.getItem("id");
        let id = event?.target.id;
        let file = event.target.files[0];
        const formData = new FormData();
        if(file){
            if(id === "logo" || id === "bannerImages" ){
                formData.append('id', userId?userId:'');
                formData.append('type', "Profile");
                formData.append('file', file);    
            }

            const options = {
                url: constants.uploadFilesAPI,
                data: formData
            }
            Check.checkToken();
            const response = await userService.postFileApiRequest(options);
                if(response){
                    if(id === "logo"){
                        this.setState({builderLogo: response});
                    }else{
                        this.state.bannerImages.push(response);
                        this.setState({bannerImages: this.state.bannerImages});
                    }
                    this.openUpload("Success", "File Uploaded SuccessFully");
                }else{
                    if(id === "logo"){
                        this.setState({builderLogo: ''});
                    }
                    this.openUpload("Failed", "Failed to Upload File");
                }
        }else{
            // let errors = this.state.errors;
            // errors.file1 = 'Please Select File to Upload';
            // this.setState({errors: errors});
        }
    };

    handleChange = (event : any) => {
        event.preventDefault();
        const { name, value } = event.target;
        const urlRegEx =  RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        let errors = this.state.errors;
        switch (name) {
          case 'name':
            //  errors.name = value.length == 0 ? 'Please enter valid Name': '';
             break;
          case 'builderName':
                errors.builderName = value.length > 70 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
             break;
          case 'about':
                errors.about = (value.length < 251 || value.length == 0)  ? '': 'You Have Reached Your maximum Limit Of Characters Allowed';
             break;
          case 'twitterLink':
                errors.twitterLink = (urlRegEx.test(value) || value.length == 0 ) ? '' :  'Please Enter A Valid Link' ;
             break;
          case 'facebookLink':
                errors.facebookLink = (urlRegEx.test(value) || value.length == 0 ) ? '' :  'Please Enter A Valid Link' ;
             break;
          case 'instagramLink':
                errors.instagramLink = (urlRegEx.test(value) || value.length == 0 ) ? '' :  'Please Enter A Valid Link' ;
             break;
          default:
            break;
        }
       this.setState(Object.assign(this.state, { errors,[name]: value }));
       }

    openProject = async(item:any) => {
        this.setState({showProject:true, selectedProject: item, action: "Update"});
    }
    newProject = async() => {
        this.setState({showProject:true, action: "Create"});
    }
    onHideProject = async(event:any) => {
        this.setState({showProject:false});
        this.init();
    }
    openUpload = async(header:any, body: any) => {
        this.setState({showUpload:true, header: header, body: body});
    }
    onHideUpload = async(event:any) => {
        this.setState({showUpload:false});
    }
    
    openBuilderModal = async(header:any, body: any) => {
        this.setState({builderModal:true, header: header, body: body});
       
    }
    onHideBuilderModal = async(event:any) => {
        this.setState({builderModal:false, isEdit: false});
        //this.init();
        window.location.replace("/Portfolio");
    }
    hasChangedSinceInitialState = () => {
        const {  formOtherData, formImage } = this.state;  
        return (
            formOtherData.builderName === this.state.builderName &&
            formOtherData.description === this.state.about &&
            formOtherData.socialLinks.twitterLink === this.state.twitterLink &&
            formOtherData.socialLinks.facebookLink === this.state.facebookLink &&
            formOtherData.address.city === this.state.city  &&
            formOtherData.logoPath == this.state.builderLogo &&
            JSON.parse(localStorage.images).length == this.state.bannerImages.length ||
            this.state.builderName === ""
            );
      }

    
    render() {
        var items:any=[];
        var pItems=[];
        var subItems:any=[];
        var pList=[];
        const { isLoading, builder, projects, images, bannerImages, nextIcon, prevIcon, isEdit, builderLogo, showProject, showUpload, body, header, builderName, city, twitterLink, facebookLink, about, portfolio, selectedProject, action, builderModal, error, ErrMsg, errors, instagramLink } = this.state;
        if(Object.keys(builder).length){
            if(bannerImages.length>0){
                items = bannerImages.map(
                    (item:any) => (
                        (<Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={blobService.createUrl(item)}
                            width="100%" height="350px"
                            />
                        </Carousel.Item>)          
                    ),
                );
            }else{
                items = (<Carousel.Item>
                <img
                className="d-block w-100"
                src={ProjectPlaceholder}
                width="100%" height="350px"
                />
                </Carousel.Item>)
            }
            for(let i = 1; i<5; i++){
                if(bannerImages[i]){
                    subItems.push(
                        <>
                        {isEdit && 
                        <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                            <span>
                                <img className="close" id={"bannerImage"+i} src={Delete} onClick={(event)=>{this.removeImage(event, bannerImages[i])}}/>
                                <Image id={"bannerImage"+i} src={blobService.createUrl(bannerImages[i])} errorImg={Upload} alt="" width="100%" height="100px"/>
                            </span>    
                        </div>
                        || 
                        <div className="col-md-3 bottom-buffer compyLogo img-wrap">
                            <span> <Image id={"bannerImage"+i} src={blobService.createUrl(bannerImages[i])} errorImg={Upload} alt="" width="100%" height="100px"/></span>
                        </div>
                        }
                        </>
                    )
                }else{
                    subItems.push(
                        <>
                            {isEdit && 
                                <div className="col-md-3 bottom-buffer compyLogo img-wrap"> 
                                 <label htmlFor={"bannerImages"}>
                                    <span><h6 className="left-tab"><img src={Upload}/>Logo</h6></span>
                                    </label>
                                    <input id={"bannerImages"} type="file" style={{display:"none"}}  onChange={(event)=>this.onFileChange(event)}/>
                                    </div>   
                            || <div/> }
                        </>
                    )                       
                }
            }
            if(portfolio.length > 0){
                pItems = portfolio.map(
                    (item: any) => (
                        <Card className="left-buffer bottom-buffer" style={{ width: '16rem' }} onClick={(event:any)=>this.openProject(item)}>
                            {item.projectDetails.projectImages[0] ?
                            <Card.Img variant="top" src={blobService.createUrl(item.projectDetails.projectImages[0])} width="100%" height="150px" />
                            :
                            <Card.Img variant="top" src={blobService.createUrl(builderLogo)} width="100%" height="150px" />
                            }
                            <Card.Body>
                                <Card.Title>{item.name}</Card.Title>
                                <Card.Text>
                                   {item.address? item.address.city:''}
                                </Card.Text>
                            </Card.Body>
                            </Card>
                    )
                );
            }
            
            for(let i=0; i<projects.length; i++){
                var p = projects[i];
                pList.push(
                    <li className="row no-gutters">
                    <div className="col-md-3">
                        <img src={Project1} alt="" />
                    </div>
                    <div className="col-md p-4">
                        <h4>{p.name}</h4>
                        <h6>Location : {p.address ? p.address.city : "NA"}</h6>
                        <h6>Type of Project: {p.typeOfProject}</h6>
                        <p>{p.description}</p>
                        <span className="right-end">
                            {/* Check */}
                            <Link className="text-center my-3" to={{ pathname: "/ViewCustomerProject", state:{data: p}}}><h6 className="text-center">View Project</h6></Link>
                        </span>
                    </div>
                </li>
                )
            }
        }
          
        return isLoading ? (<Loader/>): 
         (
            <>
                <Header />
                {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
                <div className="bg-img">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <h1 style={{fontWeight:600}} className="breadcrumbs">Your Portfolio</h1>
                            </div>
                        </div>
                    </div>
                </div>
                {!isEdit ?
                            <div className="col-md">
                                <button className="button6 ml-5 newcontact" onClick={()=>{this.editPortfolio()}}>Edit Portfolio<img src={Edit} className="ml-1"/></button>
                            </div> :
                            <div className="col-md editbtn-section">
                                <button className={this.hasChangedSinceInitialState() ? "button6 ml-5 newcontact save-btn disbledSaveButton" : "button6 ml-5 newcontact save-btn"} onClick={()=>{this.savePortfolio()}}  disabled={this.hasChangedSinceInitialState()} >Save</button>
                                <button className="button6 ml-5 newcontact cancel-btn" onClick={()=>{this.cancelPortfolio()}}>Cancel</button>
                            </div>}
            </div>

                <div className="aboutBuilderProfile">
                    <div className="container">
                        <div className="row mt-5">
                        <div className="col-md-6">
                        <div className="row">
                        <div className="col-md-12">
                                <span>
                                    {(bannerImages.length>1)?
                                                    <Carousel   >
                                                        {items}
                                                    </Carousel>
                                                    :
                                                    <Carousel className = "disabled-arrow" >
                                                        {items}
                                                    </Carousel>
                                    }
                                <br/>
                                </span>
                                <div className="row">
                                        {subItems}
                                </div>
                            </div>
                        </div>
                            
                        </div>

                            <div className="col-md-6">
                                <div className="projectInfo">
                                  
                                    {this.state.isEdit===true &&
                                    
                                        <div>
                                            <div className="col-md-4 compyLogo img-wrap">
                                                {builderLogo && 
                                                <span>
                                                    <img className="close" id="logo" src={Delete} onClick={(event)=>{this.removeImage(event, "")}}/>
                                                    <Image id="logo" src={blobService.createUrl(builderLogo)} errorImg={Upload} alt="" width="100%" height="100px"/>
                                                </span>
                                                ||
                                                <span>
                                                <label htmlFor="logo">
                                                    <span><h6 className="left-tab"><img src={Upload}/>Logo</h6></span>
                                                </label>
                                                <input id="logo" type="file" style={{display:"none"}}  onChange={(event)=>this.onFileChange(event)}/>
                                                </span>
                                                }
                                              
                                            </div>
                                            <br/>
                                            <div>
                                                <input 
                                                    name="builderName"
                                                    type={this.state.type}
                                                    value={builderName}
                                                    autoFocus
                                                    onChange={this.handleChange}
                                                    maxLength = {255}
                                                />
                                                 {errors.builderName.length > 0 &&  <p style={{color: "red"}}>{errors.builderName}</p>}
                                            </div>
                                            <br/>
                                            <div>
                                                <p>{city}</p>
                                                {/* <input 
                                                    name="city"
                                                    type={this.state.type}
                                                    value={city}
                                                    autoFocus
                                                    onChange={this.handleChange}
                                                /> */}
                                            </div>
                                            <br/>
                                        </div>
                                        ||
                                        
                                        <span>
                                            <div className="col-md-4 compyLogo">
                                                <Image src={blobService.createUrl(builderLogo)} alt="" width="100%" height="auto" />
                                            </div>
                                            <h3 className="mt-3">{builderName}</h3>
                                            <p>{city}</p>
                                        </span>}

                                    
                                    {/* <p className="d-flex align-items-center">
                                        <Rating name="read-only" value={3} readOnly className="rating" />
                                        (3 Reviews)
                                    </p> */}
                                    {this.state.isEdit===true &&
                                        <div>
                                            <br/>
                                        <div>
                                            <img  src={Twitterwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="twitterLink"
                                                type={this.state.type}
                                                value={twitterLink}
                                                autoFocus
                                                onChange={this.handleChange}
                                            />
                                             {errors.twitterLink.length > 0 && (
                                                        <p style={{ color: 'red', marginLeft: '50px' }}>{errors.twitterLink}</p>
                                            )}
                                        </div>
                                        <br/>
                                        <div>
                                            <img  src={FBwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="facebookLink"
                                                type={this.state.type}
                                                value={facebookLink}
                                                autoFocus
                                                onChange={this.handleChange}
                                            />
                                             {errors.facebookLink.length > 0 && (
                                                        <p style={{ color: 'red', marginLeft: '50px' }}>{errors.facebookLink}</p>
                                            )}
                                        </div>
                                        <br/>
                                        <div>
                                            <img  src={LinkedInwithround} alt="" />
                                            <input 
                                                className="left-buffer"
                                                name="instagramLink"
                                                type={this.state.type}
                                                value={instagramLink}
                                                placeholder="LinkedIn Link"
                                                onChange={this.handleChange}
                                            />
                                             {errors.instagramLink.length > 0 && (
                                                <p style={{ color: 'red',  marginLeft: '50px' }}>{errors.instagramLink}</p>
                                            )}
                                        </div>
                                        </div>
                                        ||
                                        <div>
                                        {(twitterLink == null || twitterLink == "") ?
                                            <a target="_blank">
                                                <img  src={Twitterwithround} alt="" />
                                            </a>
                                        :
                                            <a href={ builder.socialLinks ?  builder.socialLinks.twitterLink : ""} target="_blank">
                                                <img  src={Twitterwithround} alt="" />
                                            </a>
                                        }
                                        {(facebookLink == null || facebookLink == "") ?
                                            <a  target="_blank" className="left-buffer">
                                                <img  src={FBwithround} alt="" />
                                            </a>
                                        :
                                            <a href={ builder.socialLinks ?  builder.socialLinks.facebookLink :""} target="_blank" className="left-buffer">
                                                <img  src={FBwithround} alt="" />
                                            </a>
                                        }
                                         {(instagramLink == null || instagramLink ==  "") ?
                                        <a  target="_blank" className="left-buffer">
                                            <img  src={LinkedInwithround} alt="" />
                                        </a>
                                        :
                                        <a href={ builder.socialLinks.instagramLink} target="_blank" className="left-buffer">
                                            <img  src={LinkedInwithround} alt="" />
                                        </a>
                                    }
                                    </div>
                                        }
                                   
                                </div>
                            </div>
                        </div>


                        <div className="my-5">
                            <h4>About</h4>
                            {isEdit ? 
                                <div className='description'>
                                    <textarea id="about" name="about" className="px-3" placeholder="About the Project" rows={5} cols={50} onChange={this.handleChange} value={about} required maxLength = {255}/>
                                    {errors.about.length > 0 &&  <p style={{color: "red"}}>{errors.about}</p>}
                                </div>
                                :
                                <p style={{ lineHeight: '30px' }}>{about}</p>
                            }
                      
                        </div>

                        {pItems.length > 0 &&
                        <div className="my-5">
                            <h4 className="my-3">Portfolio</h4>
                            <div className="row">
                            {/* { isEdit && 
                            <Card className="left-buffer bottom-buffer" style={{ width: '16rem' }} onClick={(event:any)=>this.newProject()}>
                                <Card.Body>
                                    <p className="text-center vertical-center">+ Add Projects</p>
                                </Card.Body>
                            </Card>} */}
                            {pItems}
                            </div>
                        </div>
                        ||
                            <div className="my-5">
                                <h4 className="my-3">Portfolio</h4>
                                <div className="buildersBlock py-5 empty-Findproject-Container">
                                    <div className="container-fluid empty-Findproject-Content">
                                            <img src={ruler} alt="" />
                                        <p className="text-center my-3">There are no portfolio. We will soon have some for you.</p>
                                    </div>
                                </div>
                                {/* { isEdit && 
                                <Card className="left-buffer bottom-buffer" style={{ width: '16rem' }} onClick={(event:any)=>this.newProject()}>
                                    <Card.Body>
                                        <p className="text-center vertical-center">+ Add Projects</p>
                                    </Card.Body>
                                </Card>
                                || */}
                            
                                {/* } */}
                            </div>
                        }

                        <div className="my-5">
                            <h4 className="my-3">Projects</h4>
                            {projects.length < 1 ?
                                <div className="buildersBlock py-5 empty-Findproject-Container">
                                    <div className="container-fluid empty-Findproject-Content">
                                            <img src={ruler} alt="" />
                                        <p className="text-center my-3">There are no live projects. We will soon have some for you.</p>
                                    </div>
                                </div>
                                : 
                                <ul>{pList}</ul>
                             }
                            {projects.length > 2 ? <h6 className="text-center my-3">See More</h6>:""}
                        </div>

                        {/* <div className="my-5">
                            <h4 className="my-3">Reviews</h4>
                            <ul>
                                <li className="row no-gutters">
                                    <div className="col-md-3">
                                        <img src={Project1} alt="" />
                                    </div>
                                    <div className="col-md-7 p-4">
                                        <h4>Johnie Deo</h4>
                                        <Rating name="read-only" value={3} readOnly className="rating" />
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed consetetur
                                            sadipscing elitr,{' '}
                                        </p>
                                    </div>
                                </li>

                                <li className="row no-gutters">
                                    <div className="col-md-3">
                                        <img src={Project3} alt="" />
                                    </div>
                                    <div className="col-md-7 p-4">
                                        <h4>House side villa</h4>
                                        <Rating name="read-only" value={3} readOnly className="rating" />
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed consetetur
                                            sadipscing elitr,{' '}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <h6 className="text-center my-3">See More</h6>
                        </div> */}
                    </div>
                </div>

            
                <CustomModal isAlert={true} show={showUpload} header={header} body={body} onHide={(event: any)=>this.onHideUpload(event)}/>
                <CustomModal isAlert={true} show={builderModal} header={header} body={body} onHide={(event: any)=>this.onHideBuilderModal(event)}/>
                <Modal width ="80%"  class="aboutBuilderProfile" visible={showProject} effect="fadeInUp" onClickAway={(event:any) => this.onHideProject(event)}>
                        {/* {showProject && action==="Create" &&
                         <NewBuilderProject closeModal={(event:any)=>this.onHideProject(event)}/>
                        } */}
                        {showProject && action==="Update" &&
                         <UpdateBuilderProject project={selectedProject}  onHideProject={this.onHideProject}/>
                        }
                </Modal>
                <Footer />
            </>
        );
    }
}
