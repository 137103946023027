import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';

interface PartnersProps {
   
}
interface PartnersState {
   
}

export default class Partners extends React.Component<PartnersProps & PartnersState> {
    constructor(props: any) {
        super(props);
        const initialState = {
           
        };
        this.state = initialState;
    }



      render() {
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 offset-md-2 col-12">
                                <h1>Partners</h1>
                            </div>             
                            </div>
                        </div>
                    </div>
                    {/* <div className="input-cntr">
                         <input type="text" placeholder="Search" name="searchTxt" onChange={this.handleChange} className="px-3 py-2"/><img className="search-icon" onClick={(event:any)=>this.searchBuilder(event)}src={SearchIcon} alt=""/>
                     </div> */}
                </div>
            <br/><br/>
            <p className = "static-content">At Aashray Homes, we strive to maintain develop honest, professional, and long-lasting relationships. 
            If your ideas are the same, join us and partner with us. Reach out to know more.</p>
            <br/><br/>
            <Footer/>
            </>
         );
    }


}