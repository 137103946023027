import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import "./BuilderContentPage.scss";
import { Link } from 'react-router-dom';
import builderContentOne from '../../../../assets/images/builderContentOne.svg';
import builderContentTwo from '../../../../assets/images/builderContentTwo.svg';
import { constants } from '../../../../constants/constants';
import axios from 'axios';
import { blobService } from '../../../common-components/Blob';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';

interface BuildersState {
    builders: any,
}
export default class BuilderContentPage extends React.Component{
    constructor(props: any) {
        super(props);
        const initialState = {
            builders: []
        }
        this.state = initialState;
    }
    componentDidMount(){
        this.fetchBuilders();
    }

    async fetchBuilders(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
            const key = constants.subscriptionKey;
            const url =  constants.getBuilderListAPI + "?cityName=&stateName=" ;
            axios.get(url,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key} })
            .then((response: any) => {
                this.setState({ builders: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    }
    render() {
        const {builders}: any = this.state;
        const listItems = builders.map(
            (item:any) => (
                <div className="col-md-3 builderList">
                    <div className="builderContentcard">
                       
                            { item.logoPath && 
                                <img src={blobService.createUrl(item.logoPath)} alt="" width="100%" height="200px" />
                            ||
                                <img src={ProjectPlaceholder} alt=""  width="100%" height="200px"/>
                            }
                      
                    </div>
                    <div className="buildername">{item.builderName}</div>
                    <div className="location">{item.address ? item.address.city : ""}</div>
                </div>
            )
        );
        return (
                <>
                    <Header/>
                        <div className='BuilderContentHeader py-2'>
                            <div className="container mt-3"> 
                                <p>Builders</p>
                                <p><Link to ="/Builders">Builders</Link> / Why  choose Builders</p>
                            </div>  
                        </div>
                        <div className="container BuilderContent">
                            <div className = "row">
                                    <div className='col-md-6 mt-3'>
                                        <h4>Why to hire a builder at Aashray homes?</h4>
                                        <p>We will support you with options such as:</p>
                                        <ul className='px-0'>
                                            <li>
                                                Live chat with your preferred builder and home developer.
                                            </li>
                                            <li>
                                                Connect and Hire a No-broker home builder for your property.
                                            </li>
                                            <li>
                                                Get free consultation with the best handpicked builders.
                                            </li>
                                            <li>
                                                 Put you in direct touch with the best developers in your city with Quick and hassle-free experience.
                                            </li>
                                            <li>
                                                Convert ideas from paper to real property, Imagine, Create,develop and control the process of
                                                 development from beginning to end.
                                            </li>
                                            <li>
                                                We proffer builders of peace! Get what you want, Project Home Builder, 
                                                Small Home Builder, Custom Home Builder, Hybrid Home Builder any many more under one roof.
                                            </li>
                                            <li>
                                                The skills list like the one below will give you a sense of whether or not you should choose builder as something you might want to embark on.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-md-6'>
                                           <img className='w-100' src = {builderContentOne}/>
                                    </div>
                            </div>
                        </div>
                        <div className='thirdFold mt-3'>
                                <div className='background-opacity'>
                                    <div className='container'>
                                        <div className='row no-gutters  p-4 thirdFold-container mt-3' style={{textAlign: "left"}}>
                                            <div className='col-md-5'>
                                                <p>Aashray Homes Builders Feature</p>
                                                <p>
                                                    Home developers at Aashray will have to abide by the building guidelines 
                                                    to be followed by a builder are documented in National Building Code (NBC).
                                                </p>
                                                <Link to= "/AboutCompany">
                                                    <button className ="p-3">Learn More</button>
                                                </Link>
                                            </div>
                                            <div className='col-md-7 d-flex thirdFold-smallBox-container' >
                                                <div className='mr-2'>
                                                        <div  className='thirdFold-smallBox-contentpage w-100 p-2'>
                                                                <p>Hard Skills</p>
                                                                <ul>
                                                                    <li>
                                                                        Osha compliance
                                                                    </li>
                                                                    <li>
                                                                        Framing
                                                                    </li>
                                                                    <li>
                                                                        Power tools and hand tools
                                                                    </li>
                                                                    <li>
                                                                        Concrete work
                                                                    </li>
                                                                    <li>
                                                                        Flooring
                                                                    </li>
                                                                    <li>
                                                                        Equipment operation
                                                                    </li>
                                                                    <li>
                                                                        Managing work sites
                                                                    </li>
                                                                    <li>
                                                                        Record keeping
                                                                    </li>
                                                                </ul>
                                                        </div>
                                                        <div  className='thirdFold-smallBox-contentpage mt-2 p-2 w-100'>
                                                                <p>Town Planner</p>
                                                                <ul>
                                                                    <li>
                                                                        Teamwork
                                                                    </li>
                                                                    <li>
                                                                        Communication
                                                                    </li>
                                                                    <li>
                                                                        Organisation
                                                                    </li>
                                                                    <li>
                                                                        Efficiency
                                                                    </li>
                                                                    <li>
                                                                        Problem solving
                                                                    </li>
                                                                    <li>
                                                                        Attention to detail
                                                                    </li>
                                                                    <li>
                                                                        Prioritisation
                                                                    </li>
                                                                </ul>
                                                        </div>
                                                </div>
                                            
                                            <div className='thirdFold-smallBox-contentpage p-2' style={{height: "auto"}}>
                                                    <p>Financial Broker</p>
                                                    <ul>
                                                        <li>
                                                            Hand power tools such as drills, forklifts, skid loaders, and several other tools as directed by the management
                                                        </li>
                                                        <li>
                                                            Erecting scaffolding, removing and disposing of waste materials properly, and digging trenches.
                                                        </li>
                                                        <li>
                                                            Providing support for contractors and subcontractors.
                                                        </li>
                                                        <li>
                                                            Digging trenches for drainage, electrical piping, and other utility lines on new construction sites.
                                                        </li>
                                                        <li>
                                                            Operating in compliance with all safety procedures on the job site.
                                                        </li>
                                                    </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                            <div className="container BuilderContent">
                                <div className = "row">
                                        <div className='col-md-6'>
                                            <img className='w-100' src = {builderContentTwo}/>
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <h4>What is the job role of a builder at Aashray homes?</h4>
                                            <ul className='px-0'>
                                                <li>
                                                    Home developers at Aashray will have to abide by the building guidelines to be followed by a builder are documented in National Building Code (NBC).
                                                </li>
                                                <li>
                                                    Based on your input Builder will oversee, coordinate and work on the construction, repair and renovation of homes and other buildings. 
                                                </li>
                                                <li>
                                                    The Home Builders will know his way through the permitting labyrinth for every city and town in the area and be familiar with all the zoning laws and Contracts will be prepared and executed pursuant to specific consultation such as construction, maintenance, repair, renovation and remodelling work in the residential, commercial, institutional and industrial sectors
                                                </li>
                                                <li>
                                                    Meet all your needs for the design and installation of electrical circuits and appliances, air conditioning and heaters and will be carried out as laid down by the building regulations 
                                                </li>
                                                <li>
                                                    Adhere to all industry standards in development
                                                </li>
                                                <li>
                                                    Obtaining the NOC and completion certificate from the authorities.
                                                </li>
                                                <li>
                                                    Will subcontract bricklayers, carpenters, electricians, plumbers, etc while constructing a house and make sure that those subcontracted are qualified for their job.
                                                </li>
                                            </ul>
                                        </div>      
                                </div>
                            </div>
                            <div className="container">
                                <div style={{textAlign: "center"}}>
                                    <h4  className='mt-2'>Our Top Builders</h4>
                                   
                                </div>
                                <Link to = "/Builders">
                                     <button className='py-2 mb-3' style={{float: "right"}}>View All</button>
                                </Link>
                               
                                <div className='row scrollableContent' style={{height: "50vh", width: "100%", overflow: "scroll", flexWrap: "unset" }}>
                                        {listItems}
                                </div>
                            </div>
                    <Footer/>
                </>
         );
    }
}  