import React, { Component } from 'react';
import Header from '../../../../common-components/Header/Header';
import { Link } from 'react-router-dom';
import Footer from '../../../../common-components/Footer/Footer';
import dreamhomeblog from '../../../../../assets/images/dream-home-blog.png';
import HomeLoan from '../../../../../assets/images/HomeLoan.png';
import RealEstatePrices from '../../../../../assets/images/RealEstatePrices.png';
import whatisanemi from '../../../../../assets/images/whatisanemi.png';
import OwnaHouse  from '../../../../../assets/images/OwnaHouse.png';
import './Blog.scss';

export default class Blog extends React.Component {

    render() {
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-12 ">
                                <h1  className="ourMissionHead">Blogs</h1>
                            </div>             
                            </div>
                        </div>
                    </div>
                </div>
            <br/><br/>
            <div className='container blog-keyWords'>
                <h4>Explore more content</h4>
                    <div className='row mt-3'>
						<div  className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a target="_blank" href = 'https://www.youtube.com/watch?v=CQ8oNFafcHw'>
									#realestate
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.youtube.com/watch?v=sP42CqOpcFM' target='_blank'>
									#realtor
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.youtube.com/watch?v=sP42CqOpcFM' target='_blank'>
									#home
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.youtube.com/watch?v=qsfz-AdIV0o' target='_blank'>
									#property
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.ashrayhome.com.au/dreamHomeBlog' target='_blank'>
									#investment
								</a>
							</p>
						</div>
					</div>
                    <div className='row mt-2'>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.ashrayhome.com.au/owningAHouse' target='_blank'>
									#dreamhome
								</a>
							</p>
						</div>
						
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.youtube.com/channel/UCMdLYbIHwo3UKVrCx5d2nfg/featured' target='_blank'>
									#homesweethome
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.ashrayhome.com.au/dreamHomeBlog' target='_blank'>
									#realestateinvesting
								</a>
							</p>
						</div>
						<div  className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/165477912742942/' target='_blank'>
									#design
								</a>
							</p>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/165266769430723/' >
									#realestateinvestor
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.ashrayhome.com.au/homeLoanBlog'>
									#homeloan
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/159455350011865' target='_blank'>
									#quickhomeloan
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/158026930154707' target='_blank'>
									#mortgage
								</a>
							</p>
						</div>
					</div>
					<div className='row mt-2'>
						{/* <div  className='col-md-2 keyword-box p-2 mr-1'>
							<p>#justlisted</p>
						</div> */}
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/163644232926310/' target='_blank'>
									#broker
								</a>
							</p>
						</div>
						{/* <div className='col-md-2 keyword-box p-2 mr-1'>
							<p>#homesforsale</p>
						</div> */}
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/' target='_blank'>
									#electricals
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/158835093407224/' target='_blank'>
									#homeconstruction
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/164056046218462/' target='_blank'>
										#bricks
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/163854412905292/' target='_blank'>
										#sand
								</a>
							</p>
						</div>
					</div>
					<div className='row mt-2'>
					<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/156603276963739/' target='_blank'>
										#carpenters
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/156603276963739/' target='_blank'>
										#electricians
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/159049866719080/' target='_blank'>
										#builders
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/160104306613636/' target='_blank'>
										#architects
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box mr-1 p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/160709233219810/' target='_blank'>
										#hardware
								</a>
							</p>
						</div>
					</div>
					<div className='row mt-2'>
						<div  className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162525799704820/' target='_blank'>
										#marbles
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/156603276963739/' target='_blank'>
										#plumbers
								</a>
							</p>
						</div>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/163644232926310/' target='_blank'>
									#financialbrokers
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162079053082828/' target='_blank'>
										#steel
								</a>
							</p>
						</div>
						<div className='col-md-2 keyword-box p-2 mr-3 '>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162525799704820/' target='_blank'>
										#cement
								</a>
							</p>
						</div>
					</div>
					<div className='row mt-2 mb-2'>
						<div className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/160709233219810/' target='_blank'>
										#rawmaterialsuppliers
								</a>
							</p>
						</div>
						<div  className='col-md-3 keyword-box p-2 mr-3'>
							<p>
								<a href='https://www.facebook.com/AashrayHomes789/photos/a.104416138849120/162301219727278/' target='_blank'>
										#realestateprofessional
								</a>
							</p>
						</div>
					</div>
            </div>
            <div className='container blog-keyWords blog-header mt-3'>
                <h4>What’s new from product</h4>
                <p>
                    The job isn’t finished until the paperwork is done,
                    or in this day and age, until the documents have been filed. 
                </p>
            </div>
            <div className='container blog-container p-3'>     
                   <div className=' row blog-container-box'>
                        <div className= 'col-lg-4 col-sm-4 mt-1' >
                            <div className='blog-card'>
                                <img  src={dreamhomeblog}></img>
                                <p>Dream Home Or Investment Asset Which Can Be The Right Choice? </p>
                                <Link to="/dreamHomeBlog">Read More</Link>
                            </div>
                        </div>
                        <div className= 'col-lg-4 col-sm-4 mt-1'>
                            <div className='blog-card'>
                                <img  src={HomeLoan}></img>
                                <p>Home Loan and Interest</p>
                                <Link to="/homeLoanBlog">Read More</Link>
                            </div>
                        </div>
                        <div className= 'col-lg-4 col-sm-4 real-estate mt-1' >
                            <div className='blog-card'>
                                <img  style = {{height: "40vh"}} src={RealEstatePrices}></img>
                                <p>COVID-19 AND ITS IMPACT ON REAL ESTATE  </p>
                                <Link to="/realEstatePriceBlog" >Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className=' row blog-container-box'>
                        <div className= 'col-lg-4 col-sm-4 mt-4' >
                            <div className='blog-card'>
                                <img  src={whatisanemi}></img>
                                <p>EQUATED MONTHLY INSTALLMENT (EMI)</p>
                                <Link to="/whatIsEmiBlog">Read More</Link>
                            </div>
                        </div>
                        <div className= 'col-lg-4 col-sm-4 mt-4'>
                            <div className='blog-card'>
                                <img  src={OwnaHouse}></img>
                                <p>OWNING A DREAM HOME OVER A RENT HOUSE</p>
                                <Link to="/owningAHouse">Read More</Link>
                            </div>
                        </div>
                    </div>
			</div>
            <Footer/>
            </>
         );
    }
}
