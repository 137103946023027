import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
// import "./BuilderContentPage.scss";
import { Link } from 'react-router-dom';
import { constants } from '../../../../constants/constants';
import axios from 'axios';
import { blobService } from '../../../common-components/Blob';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import brokerContentOne from '../../../../assets/images/brokerContentOne.png';
import brokerContentTwo from '../../../../assets/images/brokerContentTwo.png';


interface BrokersState {
    brokers: any,
}
export default class BrokerContentPage extends React.Component{
    constructor(props: any) {
        super(props);
        const initialState = {
            brokers: []
        }
        this.state = initialState;
    }
    componentDidMount(){
        this.fetchFinancialBroker();
    }

    async fetchFinancialBroker(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
            const key = constants.subscriptionKey;
            const url =  constants.getFinancialBrokerListAPI;
            axios.get(url ,{ headers: {"Authorization" : '' || token, "Ocp-Apim-Subscription-Key" :key}})
            .then((response: any) => {
                this.setState({ brokers: response.data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    }

    render() {
        const {brokers}: any = this.state;
        const listItems = brokers.map(
            (item:any) => (
                <div className="col-md-3 builderList">
                    <div className="builderContentcard">
                        <Link to={{ pathname: "/ViewFinancialBroker", state:{id: item.userId}}}>
                            { item.profileImage && 
                                <img src={blobService.createUrl(item.profileImage)} alt="" width="100%" height="200px" />
                            ||
                                <img src={ProjectPlaceholder} alt=""  width="100%" height="200px"/>
                            }
                        </Link>
                    </div>
                    <div className="buildername">{item.financeBrokersName}</div>
                    <div className="location">{item.address ? item.address.city : ""}</div>
                </div>
            )
        );
        return (
            <>
                <Header/>
                <div className='BuilderContentHeader py-2'>
                            <div className="container mt-3"> 
                                <p>Financial Broker</p>
                                <p><Link to ="/FinancialBrokers">Financial Broker</Link> /Why choose Financial Broker</p>
                            </div>  
                </div>
                <div className="container BuilderContent">
                            <div className = "row">
                                    <div className='col-md-6 mt-3'>
                                        <h4>Looking for a House Loan?</h4>
                                        <p>
                                            Always pay attention to your Financial advisor advising on requesting on house 
                                            loan and investment the same on building a new house.
                                        </p>
                                        <p>
                                            Why to hire a Financial Advisor at Aashray Homes?
                                        </p>
                                        <p>
                                            We will support you with options such as
                                        </p>
                                        <ul className='px-0'>
                                            <li>
                                                Live chat with your preferred investment banker.
                                            </li>
                                            <li>
                                                Connect and hire a financial advisor for your home loan needs.
                                            </li>
                                            <li>
                                                Get free consultation with the best handpicked advisors.
                                            </li>
                                            <li>
                                                Banking specialists will especially support you to construct personalised financial plans that aim to achieve
                                                 your financial goals which includes investment, savings, budget, insurance, and tax strategies.
                                            </li>
                                            <li>
                                                Holistic financial advice, including budgeting, estate planning and investing.
                                            </li>
                                            <li>
                                                We proffer financial advisors of peace! Get what you want!
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-md-6'>
                                           <img className='w-100' src = {brokerContentOne}/>
                                    </div>
                            </div>
                        </div>
                        <div className='thirdFold mt-3'>
                                <div className='background-opacity'>
                                    <div className='container'>
                                        <div className='row no-gutters  p-4 thirdFold-container mt-3' style={{textAlign: "left"}}>
                                            <div className='col-md-5'>
                                                <p>Aashray Homes Financial Broker Feature</p>
                                                <p>
                                                    Home developers at Aashray will have to abide by the building guidelines to be followed by a Financial Broker are documented in National Building Code (NBC).
                                                </p>
                                                <Link to= "/AboutCompany">
                                                    <button className ="p-3">Learn More</button>
                                                </Link>
                                            </div>
                                            <div className='col-md-7 d-flex thirdFold-smallBox-container'>
                                                <div className='mr-2'>
                                                        <div  className='thirdFold-smallBox-contentpage w-100 p-2'>
                                                                <p>Advisor Types</p>
                                                                <ul>
                                                                    <li>
                                                                        Registered Investment Advisors
                                                                    </li>
                                                                    <li>
                                                                        Robo-advisors
                                                                    </li>
                                                                    <li>
                                                                        Financial planning services
                                                                    </li>
                                                                    <li>
                                                                        Traditional financial advisors
                                                                    </li>
                                                                    <li>
                                                                        Research financial advisors
                                                                    </li>
                                                                   
                                                                </ul>
                                                        </div>
                                                        <div  className='thirdFold-smallBox-contentpage mt-2 p-2 w-100'>
                                                                <p>Hard Skills</p>
                                                                <ul>
                                                                    <li>
                                                                        Financial research
                                                                    </li>
                                                                    <li>
                                                                        Financial planning
                                                                    </li>
                                                                    <li>
                                                                        Client education
                                                                    </li>
                                                                    <li>
                                                                        Equity analysis
                                                                    </li>
                                                                </ul>
                                                        </div>
                                                </div>
                                            
                                            <div className='thirdFold-smallBox-contentpage p-2'  style={{height: "60%", justifyContent: "flex-start"}}>
                                                    <p>Soft Skills</p>
                                                    <ul>
                                                        <li>
                                                            Interpersonal skill
                                                        </li>
                                                        <li>
                                                            Customer service
                                                        </li>
                                                        <li>
                                                            Problem solving
                                                        </li>
                                                        <li>
                                                            Time management
                                                        </li>
                                                    </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="container BuilderContent">
                                <div className = "row">
                                        <div className='col-md-6'>
                                            <img className='w-100' src = {brokerContentTwo}/>
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <h4>This how the architect follows a transparent process at Aashray homes</h4>
                                            <ul className='px-0'>
                                                <li>
                                                    Architects at Aashray will support you with bespoke architectural design practice to successfully accomplish the blue-chip projects.
                                                </li>
                                                <li>
                                                    Based on your input architectural designs will be created.
                                                </li>
                                                <li>
                                                    Contracts will be prepared and executed pursuant to specific consultation.
                                                </li>
                                                <li>
                                                    Adhere to all industry standards in design.
                                                </li>
                                                <li>
                                                    Negotiate the alternative solutions with a major stakeholder who originally blocked progress.
                                                </li>
                                                <li>
                                                    Lead architect will maintain strict control over the construction process.
                                                </li>
                                                <li>
                                                    Will subcontract bricklayers, carpenters, electricians, plumbers, etc while constructing a house and make sure that those subcontracted are qualified for their job.
                                                </li>
                                            </ul>
                                        </div>      
                                </div>
                            </div>
                            <div className="container">
                                <div style={{textAlign: "center"}}>
                                    <h4  className='mt-2'>Our Financial Brokers</h4>
                                    <Link to = "/FinancialBrokers">
                                        <button className='py-2 mb-3' style={{float: "right"}}>View All</button>
                                    </Link>
                                </div>
                                <div className='row scrollableContent' style={{height: "50vh", width: "100%", overflow: "scroll", flexWrap: "unset" }}>
                                        {listItems}
                                </div>
                            </div>
                <Footer/>
                </>
         );
    }
}