import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import "./SupplierContentPage.scss";
import { Link } from 'react-router-dom';
import { constants } from '../../../../constants/constants';
import axios from 'axios';
import { blobService } from '../../../common-components/Blob';
import ProjectPlaceholder from '../../../../assets/images/project-placeholder.jpg';
import brokerContentOne from '../../../../assets/images/brokerContentOne.png';
import SteelIcon from '../../../../assets/images/SteelIcon.png';
import TimberIcon from '../../../../assets/images/TimberIcon.png';
import PaintIcon from '../../../../assets/images/PaintIcon.png';
import MarblesIcon from '../../../../assets/images/MarblesIcon.png';
import HardwareIcon from '../../../../assets/images/HardwareIcon.png';
import ElectricalIcon from '../../../../assets/images/ElectricalIcon.png';
import CementIcon from '../../../../assets/images/CementIcon.png';

interface SuppliersState {
    suppliers: any,
}
export default class SupplierContentPage extends React.Component{
    constructor(props: any) {
        super(props);
        const initialState = {
            suppliers: []
        }
        this.state = initialState;
    }
    componentDidMount(){
        this.fetchRawMaterialSuppliers();
    }

    async fetchRawMaterialSuppliers(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!); 
        let token;
         if(user){
                 token = 'Bearer ' + user;
               
            }
        const key = constants.subscriptionKey;
        const url =  constants.getRawMaterialSupplierListAPI + "?cityName=&stateName=&materialName=" ;
        axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
            .then((response) => {
                if(response.data){
                    this.setState({ suppliers: response.data });
                }
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    }

    render() {
        const {suppliers}: any = this.state;
        const listItems = suppliers.map(
            (item:any) => (
                <div className="col-md-3 builderList">
                    <div className="builderContentcard">
                            { item.logoPath && 
                                <img src={blobService.createUrl(item.logoPath)} alt="" width="100%" height="200px" />
                            ||
                                <img src={ProjectPlaceholder} alt=""  width="100%" height="200px"/>
                            }  
                    </div>
                    <div className="buildername">{item.supplierName}</div>
                    <div className="location">{item.address ? item.address.city : ""}</div>
                </div>
            )
        );
        return (
            <>
                <Header/>
                <div className='BuilderContentHeader py-2'>
                            <div className="container mt-3"> 
                                <p>Raw Materials suppliers</p>
                                <p><Link to ="/RawMaterialSuppliers">Raw Materials suppliers</Link> / Why choose RMS</p>
                            </div>  
                </div>
                <div className="container BuilderContent">
                            <div className = "row">
                                    <div className='col-md-6 mt-3'>
                                        <h4>Why to hire a Raw Material Supplier at Aashray homes?</h4>
                                        <ul className='px-0'>
                                            <li>
                                                Live chat with your preferred supplier and Inventory advisor.
                                            </li>
                                            <li>
                                                Connect and Hire a No-broker home supplier for your property raw material.
                                            </li>
                                            <li>
                                                Get free consultation with the best handpicked suppliers.
                                            </li>
                                            <li>
                                                Suppliers specialists will especially support the implementation of the Raw Materials Program which includes preparation of specifications, 
                                                protocol and reports for method and raw material qualification, testing plans and risk assessments for the manufacture of products.
                                            </li>
                                            <li>
                                                Planner will have access to natural resources in order to collect and disseminate raw materials.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-md-6'>
                                           <img className='w-100' src = {brokerContentOne}/>
                                    </div>
                            </div>
                        </div>
                        <div className="container">
                            <div style={{textAlign: "center"}}>
                                <h4  className='mt-2'>We proffer inventory suppliers of peace! </h4>
                                <p>Get what you want!</p>
                                <div className='row mt-2'>
                                {/* <div className = "col-sm-1"></div> */}
                                    <div className = "col-md-2 mr-4 py-5 Townplanner-SecondFold">
                                        <img  src = {SteelIcon} />
                                        Steel supplier
                                    </div>
                                    <div className = "col-md-2 mr-4 py-5 Townplanner-SecondFold">
                                        <img  src = {CementIcon} />
                                        Cement supplier
                                    </div>
                                    <div  className = "col-md-2 mr-4 py-5 Townplanner-SecondFold">    
                                        <img  src = {TimberIcon} />
                                        Bricks supplier
                                    </div>
                                    <div className = "col-md-2 mr-4 py-5 Townplanner-SecondFold">
                                        <img  src = {PaintIcon} />
                                        Sand supplier
                                    </div>
                                    <div className = "col-md-2 py-5 Townplanner-SecondFold">
                                        
                                        <img  src = {HardwareIcon} />
                                        Hardware supplier
                                    </div>
                                    {/* <div className = "col-sm-1"></div> */}
                                </div>
                                <div className='row mt-2'>
                                    <div className = "col-md-2 py-5  mr-4 Townplanner-SecondFold">
                                        
                                        <img  src = {ElectricalIcon} />
                                        Electricals supplier
                                    </div>
                                    <div className = "col-md-2 py-5 Townplanner-SecondFold">
                                        
                                        <img  src = {MarblesIcon} />
                                        Marbles supplier
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                                <div style={{textAlign: "center"}}>
                                    <h4  className='mt-2'>Our Top Raw Materials suppliers</h4>
                                    <Link to = "/RawMaterialSuppliers">
                                        <button className='py-2 mb-3' style={{float: "right"}}>View All</button>
                                    </Link>
                                </div>
                                <div className='row scrollableContent' style={{height: "50vh", width: "100%", overflow: "scroll", flexWrap: "unset" }}>
                                        {listItems}
                                </div>
                        </div>
                <Footer/>
                </>
         );
    }

}