import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';

interface OurProjectsProps {
    name?: any;
    value?: any;
}
interface OurProjectsState {
    errors: {};
}

export default class OurProjects extends React.Component<OurProjectsProps, OurProjectsState> {
    constructor(props: OurProjectsProps) {
        super(props);
        const initialState = {
            errors: {},
        };
        this.state = initialState;
    }
    componentDidMount() {
        window.scrollTo(0, 0);
      }
    render() {
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 offset-md-2 col-12">
                                <h1>Our Projects</h1>
                                <p className="my-3">Our Projects</p>
                            </div>             
                            </div>
                        </div>
                    </div>
                    {/* <div className="input-cntr">
                         <input type="text" placeholder="Search" name="searchTxt" onChange={this.handleChange} className="px-3 py-2"/><img className="search-icon" onClick={(event:any)=>this.searchBuilder(event)}src={SearchIcon} alt=""/>
                     </div> */}
                </div>
            <br/><br/>
            <p className="text-center">Our Projects</p>
            <br/><br/>
            <Footer/>
            </>
         );
    }
}
