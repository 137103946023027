import React from "react";
import { Component } from "react"
import "./Footer.scss";
import Fb from "../../../assets/images/facebook.svg";
import LinkedIn from "../../../assets/images/linkdin.svg";
import Twitter from "../../../assets/images/twitter.svg";
import footerLogo from "../../../assets/images/footerLogo.svg";
import { Link } from 'react-router-dom';
import Youtube from "../../../assets/images/youtube.svg";
import footerIos from "../../../assets/images/footerIos.svg";
import footerAndroid from "../../../assets/images/footerAndroid.svg";

class Footer extends Component<{}, {}> {
  state = {};

  openFindProject = (event: any) => {
    localStorage.setItem("tab", '1');
  }
  render() {
    return (
      <div className="footer">
        <div className="row no-gutters">
          <div className="col-md-6  footerbox1">
            <div className=" row no-gutters justify-content-start">
              <div className="col-md-5">
                <ul>
                    <img src={footerLogo} />
                    <p className="my-4">
                        Helping you build your dream house from your present house.
                    </p>
                    <p className="m-0 callus mb-2">CALL US TODAY</p>
                    <a className="contactnum link-text" href="tel:+61 3 7036 5746">+61 3 7036 5746</a>
                    <div className="row mt-3">

                      <div className="footerImg">
                        <a href="https://twitter.com/AashrayHomes" target="_blank">
                          <img src={Twitter} alt="" />
                        </a>
                      </div>
                      <div className="footerImg">
                        <a href="https://www.facebook.com/Aashray-Homes-102650865692314" target="_blank">
                          <img src={Fb} alt="" />
                        </a>
                      </div>
                      <div className="footerImg">
                        <a href="https://www.linkedin.com/company/79147531/admin/" target="_blank">
                          <img src={LinkedIn} alt="" />
                        </a>
                      </div>
                      <div className="footerImg">
                        <a href="https://www.youtube.com/channel/UCMdLYbIHwo3UKVrCx5d2nfg" target="_blank">
                          <img src={Youtube} alt="" />
                        </a>
                      </div>
                    </div>
                </ul>
              </div>
              <div className="col-md-4">
                <ul>
                  <h4 className="mb-2">
                    For Customer
                  </h4>
                </ul>  
                  <ul className="my-4">
                      <li>
                        Hire Builders
                      </li>
                      <li>
                        Hire an Architects
                      </li>
                      <li>
                      Hire Materials Suppliers
                    </li>
                      <li>
                        Hire Town Planners
                      </li>
                      <li>
                        Hire Financial Brokers
                      </li>
                  </ul>
              </div>
              <div className="col-md-3">
                <ul>
                  <h4 className="mb-2">
                      For Client
                  </h4>
                </ul>
                <ul className="my-4">
                  <li>
                      Builders
                  </li>
                  <li>
                    Architects
                  </li>
                  <li>
                  Raw Material Suppliers
                </li>
                  <li>
                    Town Planners
                  </li>
                  <li>
                    Financial Brokers
                  </li>
                </ul>  
              </div>
            </div>
              <p className="ml-4">
                Aashray Homes. All rights reserved Copyrights 2020
              </p>
          </div>
          <div className="col-md-6  footerbox2">
            <div className="row no-gutters footerbox2-container justify-content-start">
              <div className="col-md-3">
                <ul className="px-0">
                  <h5>Quick Links</h5>
                </ul>
                <ul className="my-4 px-0">
                  <li><Link className="link-text" to="/AboutCompany">About Company</Link></li>
                  <li><Link className="link-text" to="/OurMission">Our Mission</Link></li>
                  <li><Link className="link-text" to="/OurServices">Our Services</Link></li>
                  <li onClick={this.openFindProject}><Link className="link-text" to="/FindProjects">Our Projects</Link></li>
                  {/* <li><Link className="link-text" to="/OurPricing">Our Pricing</Link></li> */}
                  <li><Link className="link-text" to="/ContactUs">Contact Us</Link></li>

                  {/* <li><Link className="link-text" to="/PrivacyPolicy">Privacy Policy</Link></li> */}
                  {/* <li><Link className="link-text" to="/TermsConditions">Terms & Conditions</Link></li>
                  <li><Link className="link-text" to="/ContactUs">Contact Us</Link></li>
                  <li><Link className="link-text" to="/ContactUs">Contact Us</Link></li> */}

                </ul>
              </div>
              <div className="col-md-4">
                  <ul className="px-0">
                    <h5>Our Service</h5>
                  </ul>
                  <ul className="my-4 px-0">
                      <li>
                          <Link className="link-text" to="/Builders">
                            Builders
                          </Link>
                      </li>
                      <li>
                          <Link className="link-text" to="/Architects">
                            Architects
                          </Link>
                      </li>
                      <li>
                        <Link className="link-text" to="/RawMaterialSuppliers">
                          Raw Materials Suppliers
                        </Link>
                    </li>
                      <li>
                          <Link className="link-text" to="/TownPlanners">
                            Town Planners
                          </Link>
                      </li>
                      <li>
                          <Link className="link-text" to="/FinancialBrokers">
                            Financial Brokers
                          </Link>
                      </li>
                      <li>
                          <Link className="link-text" to="/FindProjects">
                            Find Projects
                          </Link>
                      </li>
                  </ul>
              </div>
              <div className="col-md-5">
                  <ul className="px-0">
                    <h5>Download Our App</h5>
                  </ul>
                  <a href= "https://apps.apple.com/app/aashray-homes/id1593356411">
                    <button className="downloadButton py-2 px-3">
                        <img className= "mr-1" src = {footerIos}/>
                        Download for IOS
                    </button>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=au.com.aashrayhomes">
                    <button className="downloadButton py-2 px-3 mt-2">
                        <img src = {footerAndroid} className= "mr-1"/>
                        Download for Android
                    </button>
                  </a>
              </div>
            </div>
              <ul className="d-flex" style={{justifyContent: "space-evenly"}}>
                  <Link className="link-text" to="/PrivacyPolicy"><p>Privacy Policy</p></Link>
                  <Link to= "/terms&conditions" ><p>Terms and Condition</p></Link>
                </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
