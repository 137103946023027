import React, { Component } from 'react';
import Header from '../../../../common-components/Header/Header';
import Footer from '../../../../common-components/Footer/Footer';
import HomeLoan from '../../../../../assets/images/HomeLoan.png';
import { Link } from 'react-router-dom';

export default class HomeLoanBlog extends React.Component {

    render() {
        return ( 
            <>
            <Header/>
            <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-6 col-12 ">
                                <h1  className="ourMissionHead">Blog</h1>
                                <p className="my-3"><Link to="/Blog" className="link-text">Blogs</Link> / Home Loan and Interest</p>
                            </div>             
                            </div>
                        </div>
                    </div>
                </div>
            <br/><br/>
            <div className='container blog-container p-3'>     
                   <div className='blog-box  '>
                        <h3 style={{color: "#033b64 "}}>Home Loan and Interest  </h3>
                        <img style = {{width: "50%" , height: "40%"}} src={HomeLoan}/>
                        <div  className=' mt-3'>
                            <p> Banks offer long tenures to borrowers so they can achieve their dream 
                                of having their own house big or small. Opting for longer duration
                                can lower the equated monthly installment but increase the interest pay-out and cost of the loan. 
                            </p>
                            <p> Our loan amount and the period of repayment has to be in such a way that it 
                                does not have a negative impact on the CIBIL score. So, 
                                choosing the right home loan tenure is important.
                            </p>
                            <p> 
                            <Link to = "/"><b>Aashray Homes</b></Link> takes care of the complicated process and assist you in making the decision. 
                            </p>
                            <p>
                                Let’s take up the basic points that you need to remember while applying for loan. 
                            </p>
                            <p>Now what if we already have our dream home and can we use the next one to act as an asset class. 
                                This brings up the point on how we can get a better return on investment.
                            </p>
                            <p>Let's get into the detail, As the pandemic engulfed the world, the economy became unstable. All the sectors including real-estate took a hit. The prices fell drastically. As time passed the market was quick enough to recover making it a perfect time for investment in real-estate. 
                                If you start constructing a house now which can be rented for tenants it not only secures your hard-earned money but also your home's value (property) will almost certainly increase several times over time.  You also get to decide on how many families can stay in the segregated portions you allot.
                            </p>
                            <p>1. What is your monthly income? </p>
                            <p> 2. What is the property that you are seeking to invest in? </p>
                            <p>3. What is your age at the time of applying for loan? </p>
                            <p> 4. Are you ready to pay certain portion of the property in the beginning?</p> 
                            <p>5. What is the interest being charged for the loan amount? </p> 
                            <p> 6. What is the scenario after your retirement? </p>
                            <p> Now we will explore the above points in detail </p>
                           
                            <h4>1. MONTHLY INCOME </h4>
                            <p>
                                When applying for a home loan, you need to have analysis on your monthly income and expenditure. 
                                Then you can decide how much EMI you are willing to bear. A shorter tenure means higher EMI, 
                                whereas a longer tenure will ease out the burden by reducing the monthly installment amount.
                                 You need to decide as per your needs and after which you need to segregate the amount periodically. 
                            </p>
                            <h4>2.VALUE OF THE PROPERTY </h4>
                            <p> Every person aspires of building a dream home for self and family. 
                                His/her tastes and ideas form other source will be tried to be brought into practical while designing the house.
                                 So, we need to keep in mind the EMI, interest and what are the expenses will we incur to realise our 
                                ideas. Hence the value of property decides the amount for which we can apply the loan.  
                            </p>
                            <h4>3.BORROWER’S AGE  </h4>
                            <p> One of the eligibilities to sanction loan is age. 
                                If you are younger it implies to the lender that you can for long and hence repayment of the loan is assured.
                                Hence you can ask for loan on a higher tenure.
                                If the monthly income is considerable you can 
                                rule over the age and opt for higher EMI on a shorter tenure.   
                            </p>
                            <h4>4. AGE OF RETIREMENT</h4>
                            <p>It is ideal for you to know the number of years left from applying 
                                loan to the date of your retirement as the income might not be 
                                the same as after. The process of repaying the loan should be completed 
                                way before your retirement so your expenses are not bound and burdened to loan itself. 
                            </p>
                            <h4>5. DOWN PAYMENT </h4>
                            <p> Usually lenders prefer to pay 80 – 90% of the total property value as loan.
                                It is for the security and also if borrower fails to pay, 
                                the lender has the option to claim the property over the market value. 
                                So, the remaining of the amount has to be paid by the borrower to own the
                                property is via down payment in addition to other fees. This is loan value ratio.
                            </p>
                            <h4>6. RATE OF INTEREST </h4>
                            <p> Interest is applied on the loan amount on annual basis. 
                                The longer you take to repay the loan the higher interest 
                                you end up paying. So on beforehand you need to completely analyse for a certain loan amount 
                                for a particular period of time how much interest is being 
                                applied by the lender. So, you don’t get exploited. 
                            </p>
                            <p>
                                <Link to = "/"><b>Aashray Homes</b></Link> has been assisting our clients for the past 5+ years in building their new home from scratch.
                                This means our process starts as early as handling the complete process of obtaining loan 
                                at a desired interest rate by our customers.  
                                We have the expertise to connect you to the best lenders such as 
                                all nationalised banks in order to get the amount as quickly as possible 
                                without you indulging in the complicated process of the system. 
                            </p>
                        </div>
                       
                    </div>
			</div>
            <Footer/>
            </>
         );
    }
}
