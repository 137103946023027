import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import FindProjects from './features/feature-components/Builder/FindProjects/FindProjects';
import MyProjects from './features/feature-components/Builder/MyProjects/MyProjects';
import Builders from './features/feature-components/Customer/Builders/Builders';
import BuilderProfile from './features/feature-components/Builder/Profile/Profile';
import Portfolio from './features/feature-components/Builder/Portfolio/Portfolio';
import CustomerProjects from './features/feature-components/Customer/MyProjects/MyProjects';
import Projects from './features/feature-components/Customer/Projects/Projects';
import CustomerProfile from './features/feature-components/Customer/Profile/Profile';
import './App.scss';
import HomePage from './features/feature-components/HomePage/HomePage';
import ViewProject from './features/feature-components/Builder/ViewProject/ViewProject'
import Pricing from './features/feature-components/Builder/Pricing/Pricing'
import NewProject from './features/feature-components/Customer/NewProject/NewProject'
import ViewBuilder from './features/feature-components/Customer/ViewBuilder/ViewBuilder'
import ViewMyProject from './features/feature-components/Customer/ViewMyProject/ViewMyProject'
import NewBuilderProject from './features/feature-components/Builder/NewBuilderProject/NewBuilderProject'
import ViewCustomerProject from './features/feature-components/Builder/ViewCustomerProject/ViewCustomerProject'
import VerifyEmail from './features/feature-components/Customer/VerifyEmail/VerifyEmail'
import ViewBuilderProject from './features/feature-components/Customer/ViewBuilderProject/ViewBuilderProject'
import PageNotFound from "./features/common-components/PageNotFound/PageNotFound"
import Architects from './features/feature-components/Architect/Architects/Architects';
import TownPlanners from './features/feature-components/TownPlanner/TownPlanners/TownPlanners';
import ArchitectProfile from './features/feature-components/Architect/Profile/Profile';
import TPProfile from './features/feature-components/TownPlanner/Profile/Profile';

import AboutCompany from './features/feature-components/Content/AboutCompany/AboutCompany';
import ContactUs from './features/feature-components/Content/ContactUs/ContactUs';
import OurMission from './features/feature-components/Content/OurMission/OurMission';
import OurProjects from './features/feature-components/Content/OurProjects/OurProjects';
import OurServices from './features/feature-components/Content/OurServices/OurServices';
import OurPricing from './features/feature-components/Content/Pricing/Pricing';
import LearnMore from './features/feature-components/Content/LearnMore/LearnMore';
import ResetPassword from './features/common-components/ResetPassword/ResetPassword';
import Support from './features/feature-components/Content/Support/Support';
import Partners from './features/feature-components/Content/Partners/Partners';
import IdelTimerContainer from './IdelTimerContainer';
import ForgotPassword from './features/common-components/ForgotPassword/ForgotPassword';
import ArchitectProjects from './features/feature-components/Architect/MyProjects/MyProject';
import PrivacyPolicy from './features/feature-components/Content/PrivacyPolicy/PrivacyPolicy';
import NewArchitectProject from './features/feature-components/Architect/NewArchitectProject/NewArchitectProject';
import ViewArchitect from './features/feature-components/Architect/ViewArchitect/ViewArchitect';
import MyPortfolio from './features/feature-components/Architect/Portfolio/MyPortfolio';
import ViewArchitectProject from './features/feature-components/Architect/ViewProject/ViewArchitectProject';
import ViewArchitectPortfolio from './features/feature-components/Architect/ViewArchitectPortfolio/ViewArchitectPortfolio';
import FinancialBrokers from './features/feature-components/FinancialBroker/FinancialBrokers/FinancialBrokers';
import ViewFinancialBroker from './features/feature-components/FinancialBroker/ViewFinancialBroker/ViewFinancialBroker';
import FinancialBrokerProfile from './features/feature-components/FinancialBroker/Profile/Profile';
import ViewPlan from './features/feature-components/Builder/ViewPlan/ViewPlan';
import Blog from './features/feature-components/Content/Blog/Blogs/Blog';
import DreamHomeBlog from './features/feature-components/Content/Blog/DreamHomeBlog/DreamHomeBlog' ;
import HomeLoanBlog from './features/feature-components/Content/Blog/HomeLoanBlog/HomeLoanBlog';
import RealEstatePriceBlog from './features/feature-components/Content/Blog/RealEstatePriceBlog/RealEstatePriceBlog';
import WhatIsEmiBlog from './features/feature-components/Content/Blog/WhatIsEmiBlog/WhatIsEmiBlog';
import OwningAHouse from './features/feature-components/Content/Blog/OwningAHouse/OwningAHouse';
import TermsConditions from './features/feature-components/Content/TermsConditions/TermsConditions';
import PrivacyandTerms from './features/feature-components/Content/Privacy-Policy/PrivacyandTerms';
import builderContentPage from './features/feature-components/Builder/BuilderContentPage/BuilderContentPage';
import architectContentPage from './features/feature-components/Architect/ArchitectContentPage/ArchitectContentPage';
import brokerContentPage from './features/feature-components/FinancialBroker/BrokerContentPage/BrokerContentPage';
import townplannerContentPage from './features/feature-components/TownPlanner/TownplannerContentPage/TownplannerContentPage';
import SubscriptionDetails from './features/common-components/SubscriptionDetails/SubscriptionDetails';
import RawMaterialSuppliers from './features/feature-components/RawMaterialSupplier/RawMaterialSuppliers/RawMaterialSuppliers';
import ViewRawMaterialSupplier from './features/feature-components/RawMaterialSupplier/ViewRawMaterialSupplier/ViewRawMaterialSupplier';
import SupplierContentPage from './features/feature-components/RawMaterialSupplier/SupplierContentPage/SupplierContentPage';
import rawMaterialSupplierProfile from './features/feature-components/RawMaterialSupplier/Profile/Profile';



class App extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <>
            <IdelTimerContainer></IdelTimerContainer>
            <BrowserRouter>
                <Switch>
                    {/* Common Pages */}
                    <Route path="/" exact component={HomePage}  />
                    <Route path="/VerifyEmail" exact component={VerifyEmail} />
                    <Route path="/ResetPassword" exact component={ResetPassword} />
                    <Route path="/ForgotPassword" exact component={ForgotPassword} />



                    {/* Customer Portal */}
                    <Route path="/Builders" exact component={Builders} />
                    <Route path="/Projects" exact component={Projects} />
                    <Route path="/MyProjects" exact component={CustomerProjects} />
                    <Route path="/CustomerProfile" exact component={CustomerProfile} />
                    <Route path="/ViewBuilder" exact component={ViewBuilder} />
                    <Route path="/NewProject" exact component={NewProject} />
                    <Route path="/ViewBuilderProject" exact component={ViewBuilderProject} />
                    <Route path="/Pricing" exact component={Pricing} />
                    <Route path="/ViewPlan" exact component={ViewPlan} />



                    {/* Builder Portal */}
                    <Route path="/FindProjects" exact component={FindProjects} />
                    <Route path="/MyBuilderProjects" exact component={MyProjects} />
                    <Route path="/BuilderProfile" exact component={BuilderProfile} />
                    <Route path="/Portfolio" exact component={Portfolio} />
                    <Route path="/ViewProject" exact component={ViewProject} />
                    <Route path="/builderContentPage" exact component={builderContentPage} />
                    




                    {/* <Route path="/ProjectPage" exact component={ProjectPage} /> */}

                    <Route path="/ViewMyProject" exact component={ViewMyProject} />
                    <Route path="/NewBuilderProject" exact component={NewBuilderProject} />
                    <Route path="/ViewCustomerProject" exact component={ViewCustomerProject} />

               

                     {/* Architect Portal*/}
                    <Route path="/Architects" exact component = {Architects} />
                    <Route path="/ArchitectProfile" exact component={ArchitectProfile} />
                    <Route path="/MyArchitectProjects" exact component={ArchitectProjects} />
                    <Route path="/NewArchitectProject" exact component={NewArchitectProject} />
                    <Route path="/ViewArchitect" exact component = {ViewArchitect} />
                    <Route path="/ArchitectPortfolio" exact component={MyPortfolio} />
                    <Route path="/ViewArchitectProject" exact component={ViewArchitectProject} />
                    <Route path="/ViewArchitectPortfolio" exact component={ViewArchitectPortfolio} />
                    <Route path="/architectContentPage" exact component={architectContentPage} />

                    {/* Raw Material suppliers Portal*/}
                    <Route path="/RawMaterialSuppliers" exact component={RawMaterialSuppliers} />
                        <Route path="/ViewRawMaterialSupplier" exact component={ViewRawMaterialSupplier} />
                        <Route path="/rawMaterialSupplierProfile" exact component={rawMaterialSupplierProfile} />
                        <Route path="/supplierContentPage" exact component={SupplierContentPage} />
                        

                    { /*Town Planner*/ }
                    <Route path="/TownPlanners"  exact component = {TownPlanners}/>
                    <Route path="/TPProfile" exact component={TPProfile} />
                    <Route path="/townplannerContentPage" exact component={townplannerContentPage} />
                    
                    { /*Financial Brokers*/ }
                    <Route path="/FinancialBrokers"  exact component = {FinancialBrokers}/>
                    <Route path="/ViewFinancialBroker" exact component = {ViewFinancialBroker} />
                    <Route path="/FinancialBrokerProfile" exact component={FinancialBrokerProfile} />
                    <Route path="/brokerContentPage" exact component={brokerContentPage} />

                    {/* Static Pages */}
                    <Route path="/AboutCompany"  exact component = {AboutCompany}/>
                    <Route path="/ContactUs"  exact component = {ContactUs}/>
                    <Route path="/OurMission"  exact component = {OurMission}/>
                    <Route path="/OurProjects"  exact component = {OurProjects}/>
                    <Route path="/OurServices"  exact component = {OurServices}/>
                    <Route path="/OurPricing"  exact component = {OurPricing}/>
                    <Route path="/LearnMore"  exact component = {LearnMore}/>
                    <Route path="/support"  exact component = {Support}/>
                    <Route path="/partners"  exact component = {Partners}/>
                    <Route path="/privacypolicy"  exact component = {PrivacyPolicy}/>
                    <Route path="/privacy-policy"  exact component = {PrivacyandTerms}/>
                    <Route path="/terms&conditions"  exact component = {TermsConditions}/>
                    <Route path="/blog"  exact component = {Blog}/>
                    <Route path="/dreamHomeBlog"  exact component = {DreamHomeBlog}/>
                    <Route path="/homeLoanBlog"  exact component = {HomeLoanBlog}/>
                    <Route path="/realEstatePriceBlog"  exact component = {RealEstatePriceBlog}/>
                    <Route path="/whatIsEmiBlog"  exact component = {WhatIsEmiBlog}/>
                    <Route path="/owningAHouse"  exact component = {OwningAHouse}/>
                    {/* Subscription Pages */}
                    <Route path="/subscriptionDetails"  exact component = {SubscriptionDetails}/>

                    <Route component={PageNotFound} />
                </Switch>

            </BrowserRouter>
            </>
        );
    }
}

export default App;



























