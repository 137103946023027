import React, { Component, Fragment } from 'react';
import Header from '../../common-components/Header/Header';
import Footer from '../../common-components/Footer/Footer';
import Check from '../../Services/Check';
import { constants } from '../../../constants/constants';
import { userService } from '../../Services/CommonService';
import './SubscriptionDetails.scss';
import Loader from '../Loader';
import checkcirclecenter from '../../../assets/images/checkcirclecenter.svg';
import arrowcircleleft from '../../../assets/images/arrowcircleleft.svg';
import Modal from 'react-awesome-modal';
import PurchaseOrderPage from '../PurchaseOrderPage/PurchaseOrderPage';
import { Link, withRouter, useHistory, NavLink } from 'react-router-dom';

interface subscriptionDetailsprops {
    id: any;
    result: any;
}

interface subscriptionDetailsstate {
    subscriptionDetails: any;
    planId: string;
    isLoading: boolean;
    permissions: any;
    subPlans: any;
    selectedValue: string;
    openPurchaseDetails: boolean;
    closeSubscriptionDetailsModal: boolean;
    planAmount: string
}

class SubscriptionDetails extends React.Component<subscriptionDetailsprops & any, subscriptionDetailsstate>{
    constructor(props: any) {
        super(props);
        const initialState = {
            subscriptionDetails: {},
            planId: "",
            isLoading: true,
            permissions: [],
            subPlans: [],
            selectedValue: '',
            openPurchaseDetails: false,
            closeSubscriptionDetailsModal: true,
            planAmount: ''
        };
        this.state = initialState;

    }

    componentDidMount() {
        this.init();
    }

    init() {
        // const planId = this.props.history.location.state?.data
        const planId = this.props.id;
        console.log("planId" + planId)
        this.fetchSubscriptionDetails(planId);

    }

    async fetchSubscriptionDetails(planId: any): Promise<any> {
        Check.checkToken()
            .then(
                () => {
                    const options = {
                        url: constants.fetchSubscriptionDetailsAPI + planId,
                    };
                    userService
                        .getApiRequest(options)
                        .then((response) => {
                            this.setState({
                                subscriptionDetails: response.data,
                                permissions: response.data.permissions,
                                subPlans: response.data.subPlans,
                                isLoading: false,

                            })
                        })
                        .catch((error) => {
                        });
                }
            )
    }
    handleCheckBox = (event: any) => {
        let isChecked = event.target.checked;
        let item = event.target.value;
        if (isChecked) {
            this.setState({
                selectedValue: event.target.value,

            })
        }
    };
    openPurchaseDetails = (planAmount: any) => {
        this.setState({
            openPurchaseDetails: true,
            planAmount: planAmount,
        })
    }

    closeSubscriptionDetails = () => {
        this.props.result(this.state.closeSubscriptionDetailsModal);
    }
    orderSuccess(event: any) {
        if (event == true) {
            this.setState({ openPurchaseDetails: false })
        } else {
            this.setState({ openPurchaseDetails: false, })
        }

    }

    render() {
        const { subscriptionDetails, isLoading, permissions, subPlans, selectedValue, planAmount, openPurchaseDetails }: any = this.state
        let subscriptionData = [];
        let subPlanData = [];
        let planAmountData: any;
        for (let i = 0; i < subPlans.length; i++) {
            if (selectedValue == subPlans[i].id) {
                planAmountData = subPlans[i].planAmount
            }
        }
        {
            subscriptionDetails != "" &&
                subscriptionData.push(
                    <>
                        <div className='p-5 subscription_box_details_center mt-4' >
                            <h4 className='mt-2'>{subscriptionDetails.planName} </h4>

                            {permissions.length != 0 &&
                                <div className='mt-3'>

                                    {permissions.map((item: any) => {
                                        return <p><img src={checkcirclecenter} />{item.permissionName}</p>
                                    })}
                                </div>
                            }
                        </div>
                    </>
                );
        }

        {
            subscriptionDetails != "" &&
                subPlanData.push(
                    <>
                        <div className='p-4 mt-4 subscription_details_box'>
                            <h4 className='mt-2'>Choose Plan</h4>
                            <p>
                                {subscriptionDetails.planDescription}
                            </p>
                            {subPlans.length != 0 &&
                                <div className='mt-3'>

                                    {subPlans.map((item: any) => {
                                        return <div className='d-flex subplan_container p-2 mt-2'>
                                            <div className='d-flex'>
                                                <input
                                                    type="radio"
                                                    checked={selectedValue == item.id}
                                                    className='mr-2'
                                                    onChange={this.handleCheckBox}
                                                    value={item.id}
                                                // className = "radio-custom"

                                                />
                                                <p>{item.name}</p>
                                            </div>

                                            <p>{item.planAmount}/{item.planDuration}{item.planDurationType}</p>
                                        </div>

                                    })}
                                    <button disabled = {this.state.selectedValue == ""? true : false} className='w-75' onClick={() => { this.openPurchaseDetails(planAmountData) }}>Buy Now</button>
                                </div>
                            }
                        </div>
                    </>
                );
        }


        return isLoading ? (<Loader />) : (
            <>
                <div className='container subscription_background p-5 '>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='row container p-4'>
                                <div className='col-md-3 d-flex' style={{ alignItems: "flex-start", cursor: "pointer" }}>
                                    <img style={{ cursor: "pointer" }} onClick={this.closeSubscriptionDetails} src={arrowcircleleft} />
                                    <p style={{ cursor: "pointer" }} className='ml-1' onClick={this.closeSubscriptionDetails}>Back</p>
                                </div>
                                <div className='col-md-9'>
                                    <p>{subscriptionDetails.planName}</p>
                                    <span style={{ cursor: "pointer" }} onClick={this.closeSubscriptionDetails}> 
                                        Home
                                    </span> <span> {'>'} Subscription Plan</span>
                                    {subscriptionData}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            {subPlanData}
                        </div>

                    </div>
                    {(openPurchaseDetails == true && this.state.selectedValue != "") ?
                        <Modal visible={openPurchaseDetails} >
                            <PurchaseOrderPage
                                planId={this.props.id}
                                subPlanId={this.state.selectedValue}
                                planAmount={this.state.planAmount}
                                result={(event: any) => this.orderSuccess(event)}
                            />
                        </Modal>
                        :
                        ""
                    }

                </div>
            </>
        );
    }
}
export default SubscriptionDetails;