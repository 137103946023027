import React, { Component } from 'react';
import Footer from '../../../common-components/Footer/Footer';
import Header from '../../../common-components/Header/Header';
import Loader from '../../../common-components/Loader';
import Project1 from '../../../../assets/images/projects1.jpg';
import { Link } from 'react-router-dom';
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import './Projects.scss';
import ruler from '../../../../assets/images/ruler.svg';


interface ProjectsProps {
    name?: any;
    value?: any;
}
interface ProjectsState {
    error:boolean;
    ErrMsg:any;
    errors: {};
    isLoading: boolean,
    projects: any,
}

export default class Projects extends React.Component<ProjectsProps, ProjectsState> {
    constructor(props: ProjectsProps) {
        super(props);
        const initialState = {
            errors: {},
            isLoading: false,
            projects:[],
            error:false,
            ErrMsg:"",

        };
        this.state = initialState;
    }
    componentDidMount() {
        this.init();
    }
    init(){
        this.viewProjects(); 
    }
    viewProjects(){
        const options = {
            url: constants.fetchBuilderProjectsAPI,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                let p = response.data;
                this.setState({ projects: p, isLoading: false });
            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error:true });
            });
    }

    // async fetchbuilder(id:any){
    //     const options = {
    //         url: constants.fetchBuilderAPI +id,
    //     };
    //     console.log('options ' + JSON.stringify(options));
    //     await userService
    //         .getApiRequest(options)
    //         .then((response) => {
    //             console.log("123");
    //             console.log(response);
    //             return response.data;
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             return;
    //         });
    // }

    render() {
        const { errors, isLoading, projects, error, ErrMsg, } = this.state;
        var pList=[];
        for(let i=0; i<projects.length; i++){
            var p = projects[i];
            pList.push(
                <li className="row no-gutters">
                    <div className="col-md-3">
                        <img src={Project1} alt="" />
                    </div>
                    <div className="col-md-7 p-4">
                    <h5>{p.name}</h5>
                    <h6>Type of Project : {p.typeOfProject}</h6>
                    <h6>Location : {p.address ? p.address.city : "NA"}</h6>
                    {/* <h6>Builder: {p.builder ? p.builder.builderName:"None"}</h6> */}
                    <p>{p.description}</p>
                    </div>
                    <div className="col-md-2 row projectflex">
                        <span className="ml-auto my-3">  
                            {(p.projectStatus.projectStatus === "In Progress") && 
                                <div className="card btn-text pending-btn">
                                    In Progress
                                </div>
                            }
                            {(p.projectStatus.projectStatus === "Completed") && 
                                <div className="card btn-text completed-btn">
                                    Completed
                                </div>
                            }
                            {(p.projectStatus.projectStatus === "Cancelled") && 
                                <div className="card btn-text cancel-btn">
                                    Cancelled
                                </div>
                            }
                        </span>
                        <span className="align-self-end ml-auto mb-3">
                            <Link className="text-center my-3" to={{ pathname: "/ViewBuilderProject", state:{data: p}}}><h6 className="text-center">View Project</h6></Link>
                        </span>
                    </div>
                </li>               
            )
        }
        return isLoading ? (<Loader/>): 
        (
            <>
                <Header />
                {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
                <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-2 col-12">
                                <h1>Projects</h1>
                                <p className="my-3"><Link to="#" className="link-text">Projects</Link></p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="Projects">
                    <div className="container py-5 section">
                        <div className="my-5">
                            <div className="row align-items-center">
                                <h4>Projects</h4>
                                {/* <div className="ml-auto">
                                    <span className="mx-3"> Filter by:</span>
                                    <select className="px-3 py-1">
                                        <option value="">Builder Associated</option>
                                        <option value="">Yes</option>
                                        <option value="">No</option>
                                    </select>
                                    <select className="px-3 py-1 mx-3">
                                        <option value="">Status</option>
                                        <option value="">Pending</option>
                                        <option value="">In Progress</option>
                                        <option value="">Completed</option>
                                        <option value="">Cancelled</option>
                                    </select>
                                    <select className="px-3 py-1">
                                        <option value="">Location</option>
                                        <option value="">SA</option>
                                        <option value="">TAS</option>
                                        <option value="">VIC</option>
                                    </select>
                                </div> */}
                            </div>
                            {projects.length < 1 ?
                                <div className="buildersBlock py-5 empty-Findproject-Container">
                                    <div className="container-fluid empty-Findproject-Content">
                                            <img src={ruler} alt="" />
                                        <p className="text-center my-3">There are no live projects. We will soon have some for you.</p>
                                    </div>
                                </div>
                                : 
                                <ul>{pList}</ul>
                             }
                            {/* {projects.length > 2 ? <h6 className="text-center my-3">See More</h6>:""} */}
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    }
}
