import React from "react";
import './Login.scss';
import RightArrow from '../../../assets/images/rightarrow1.svg';
import { Link } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import ForgotpasswordModal from '../ForgotPassword/ForgotPassword';
import AuthenticationService from '../../Services/AuthenticationService';
import CustomModal from '../CustomModal/CustomModal';


interface LoginProps {
    name?: any;
    value?: any;
    closeModel?: any;
    
}
interface LoginState {
    email: string;
    password: string;
    checked: boolean;
    errors: {
        email: string;
        password: string;
        terms: string;
    };
    loginErr: string;
    sweetAlert: any;
    forgotPasswordModal: boolean
    error:string,
    showUpload: boolean,
    header: string,
    body: string,
    showPassword: boolean
    
}
// const Regex = RegExp(/^\s?[A-Z0–9]+[A-Z0–9._+-]{0,}@[A-Z0–9._+-]+\.[A-Z0–9]{2,4}\s?$/i);
const Regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export default class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);
        const initialState = {
            sweetAlert: "",
            email: '',
            password: '',
            checked: false,
            errors: {
                email: '',
                password: '',
                terms: '',
            },
            loginErr: '',
            forgotPasswordModal: false,
            error:'',
            showUpload: false,
            header: '',
            body: '',
            showPassword: false
        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.onClickHandler = this.onClickHandler.bind(this);
    }

    componentDidMount(){
        this.clearErrorMessage();
    }

    handleSubmit = async  (event: any) => {
        this.state.errors.terms = !this.state.checked ? 'Please Accept Terms & Conditions' : '';
        this.setState({ errors: this.state.errors });
        event.preventDefault();
        let validity = true;
        Object.values(this.state.errors).forEach((val) => val.length > 0 && (validity = false));
        if (validity == true) {
                
            AuthenticationService.signin(this.state.email,
                                        this.state.password)
                                        .then(
                                            () => {
                                                
                                            //window.location.reload();
                                            window.location.replace("/");
                                            },
                                            error => {
                                              this.setState({loginErr: "Cannot Sign in Successfully ! Please Check Username/Password Again"});
                                            }
                                        );                       
        } else {
        }
    };
   
    

    //    public onClickHandler (evt: React.MouseEvent) {
    //       evt.preventDefault();
    //       this.setState(prev => ({
    //         show: !prev.show
    //       }));
    //     }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'email':
                errors.email = Regex.test(value) ? '' : 'Please Enter Valid Email';
                break;
            // case 'password':
            //    errors.password = value.length < 8 ? 'Password must be 8 characters long': '';
            //    break;
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    };

    handleCheckBox = () => {
        this.setState({ checked: !this.state.checked });
    };

    forgotPassword = () =>{
        this.setState({
            forgotPasswordModal: true
        })
        
    }
    closeForgotPasswordModal = (event: any) =>{  
        this.setState({
            forgotPasswordModal: false
          });
    }

    success(event: any) {
        this.props.closeModel(false);
        this.setState({ forgotPasswordModal: false, showUpload: true,  body: event })
      }

      onHideUpload = async(event:any) => {
        this.setState({showUpload:false});
    }
    
    closeLoginModal =()=>{
        this.clearErrorMessage();
        this.props.closeModel(false);
    } 
    clearErrorMessage = ()=>{
        let errors = this.state.errors;
        errors.email = "";
        errors.password = "";
        errors.terms = "";
        this.setState({
            email: '',
            password: ''
        })
    }
    clickShowPassword = ()=>{
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    render() {
        
        const { errors, checked, loginErr, sweetAlert, showUpload,header, body, email, password ,showPassword} = this.state;
        let isSubmitted = true;
        if(email !== "" && password !== ""){
            isSubmitted = false
        }
        return (
            <div>
            <div className="login">
                <div className="container p-5">
                    <div>
                        <p className="loginhead">LOGIN/SIGNUP</p>
                        <h1>Login</h1>

                        <div className="my-4">
                                <div className="email">
                                    <label className="mt-2">Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        className="w-100 px-3"
                                        placeholder="Email"
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {errors.email.length > 0 && <span style={{ color: 'red' }}>{errors.email}</span>}
                                </div>
                                <div className="password search-bar" id="searchBar">
                                    <label className="mt-2">Password</label>
                                    <input
                                        type= {this.state.showPassword ? "text" : "password"}
                                        name="password"
                                        className="w-100 px-3 search-icon "
                                        placeholder="Password"
                                        onChange={this.handleChange}
                                        required
                                        id="searchBarInput"
                                    />
                                    {showPassword ?     <img className="search-bar__icon" src={require("../../../assets/images/eyeOpen.svg")}  onClick={this.clickShowPassword }></img> 
                                    :
                                        <img className="search-bar__icon" src={require("../../../assets/images/eyeclose.svg")} onClick={this.clickShowPassword}></img>
                                    }
                                    
                                    {errors.password.length > 0 && (
                                        <span style={{ color: 'red' }}>{errors.password}</span>
                                    )}
                                </div>

                                <div className="terms">
                                    <input
                                        type="checkbox"
                                        className="mt-3"
                                        checked={checked}
                                        onChange={this.handleCheckBox}
                                    />
                                    <span className="mx-2">I accept the privacy and terms</span>
                                </div>
                                {errors.terms.length > 0 && <span style={{ color: 'red' }}>{errors.terms}</span>}
                                {loginErr.length > 0 && <span style={{ color: 'red' }}>{loginErr}</span>}
                                <br/><br/>
                                
                             <div className="login-cancel d-flex">
                             <button className= {isSubmitted ? "mr-3 disabled-button"  : "mr-3" } disabled = {isSubmitted}  onClick={this.handleSubmit}>
                                    Login
                                    <img src={RightArrow} className="ml-2 arrow1" />
                                </button>
                               
                                <button onClick={this.closeLoginModal}>
                                    Cancel
                                    <img src={RightArrow} className="ml-2 arrow2" />
                                </button>
                                {/* </div> */}

                             </div>
                            {/* </form> */}
                            <br />
                            <p>
                                New to Aashray Homes?{' '}
                                <span className="signup" onClick={this.props.value}>
                                    Signup
                                </span>
                                <p  className="link-type cursor w-50" onClick ={this.forgotPassword}>
                                    Forgot password?
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Modal visible={this.state.forgotPasswordModal} width="400" effect="fadeInUp" onClickAway={this.closeForgotPasswordModal} >
            <ForgotpasswordModal value={this.forgotPassword} closeModal = {(event: any)=>{this.closeForgotPasswordModal(event)}}  result={(event: any) => this.success(event)}/>
            </Modal>

            <CustomModal isAlert={true} show={showUpload} header= "Success" body={body} onHide={(event: any)=>this.onHideUpload(event)}  /> 
        </div>
        );
    }
}
