import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import { constants } from '../../../../constants/constants';
import { Link } from 'react-router-dom';
import Loader from '../../../common-components/Loader';
import { blobService } from '../../../common-components/Blob';
import PersonPlaceholder from '../../../../assets/images/person-placeholder.svg';
import Rating from '@material-ui/lab/Rating';
import './TownPlanners.scss';
import axios from 'axios';
import Buildershape from '../../../../assets/images/Buildershape.svg';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '../../../../assets/images/search.svg';


interface ArchitectsProps {
    name?: any;
    value?: any;
}
interface ArchitectsState {
    isEdit: boolean;
    profileTab: boolean;
    settingTab: boolean;
    checked: boolean;
    newsletter: boolean;
    id: string;
    showing: boolean;

    townplanners: any;
    error: boolean;
    ErrMsg: any;
    isLoading: boolean,
    searchTxt: string,
    errors: {};
    locationList: any,
    searchLocationText: string,
    stateCode: any,
}

class TownPlanners extends React.Component<ArchitectsProps, ArchitectsState> {
    constructor(props: ArchitectsProps) {
        super(props);

        const initialState = {
            isEdit: false,
            profileTab: true,
            settingTab: false,
            checked: true,
            newsletter: false,
            id: '',
            showing: true,

            townplanners: [],
            isLoading: true,
            searchTxt: '',
            errors: {},
            error: false,
            ErrMsg: "",
            locationList: [],
            searchLocationText: '',
            stateCode: [],

        };
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.init();
    }
    init() {
        this.fetchTownPlanners();
        this.fetchStateList();
        this.fetchCityList();
    }
    async fetchTownPlanners(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        const key = constants.subscriptionKey;
        const url = constants.getTownPlannerListAPI + "?cityName=&stateName=";
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                if (response.data) {
                    this.setState({ townplanners: response.data, isLoading: false });
                }

            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error: true });
            });
    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState(Object.assign(this.state, { errors, [name]: value }));
    };

    cancel() {
        this.edit();
    }
    edit = () => {
        this.setState({
            isEdit: !this.state.isEdit,
        });
    };

    async fetchStateList(): Promise<any> {
        const key = constants.subscriptionKey;
        const url = constants.fetchStatesListAPI;
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                if (response.status == 200) {
                    let stateList = response.data;
                    stateList.map((item: any) => {
                        this.state.locationList.push(item.name);
                        this.state.stateCode.push(item.name);
                    })

                    this.setState({
                        locationList: this.state.locationList,
                        stateCode: this.state.stateCode,

                    });
                }

            })
            .catch((error) => {
            });

    }
    async fetchCityList(): Promise<any> {
        const key = constants.subscriptionKey;
        const url = constants.fetchAllCity;
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                if (response.status == 200) {
                    let cityList = response.data.list;
                    cityList.map((item: any) => {
                        this.state.locationList.push(item);
                    })
                    this.setState({ locationList: this.state.locationList });
                }

            })
            .catch((error) => {
            });

    }
    searchTownplannerByLocation = (value: any) => {
        let selectedValue;
        if (value) {
            selectedValue = value
        } else {
            selectedValue = ""
        }
        let statelist = this.state.stateCode;
        const isFound = statelist.includes(selectedValue);
        let url;
        if (isFound == true) {
            url = constants.getTownPlannerListAPI + "?cityName=&stateName=" + selectedValue;
        } else {
            url = constants.getTownPlannerListAPI + "?cityName=" + selectedValue + "&stateName=";
        }
        const key = constants.subscriptionKey;
        const user = JSON.parse(localStorage.getItem('user')!);
        let token;
        if (user) {
            token = 'Bearer ' + user;

        }
        axios.get(url, { headers: { "Authorization": '' || token, "Ocp-Apim-Subscription-Key": key } })
            .then((response) => {
                if (response.status == 200) {
                    this.setState({ townplanners: response.data, isLoading: false });
                }

            })
            .catch((error) => {
            });
    }

    render() {
        const { errors, isLoading, townplanners, error, ErrMsg, locationList, searchLocationText } = this.state;
        const listItems = townplanners.map(
            (item: any) => (
                <li className="col-md-4">
                    <div className="buildercard">
                        {/* <div className="buildercard" onClick={(event:any)=>this.viewBuilder(item.id)}> */}
                        <Link to={{ state: { id: item.userId } }} className="disabledCursor" onClick={(event) => event.preventDefault()}>
                            {item.profileImage &&
                                <img src={blobService.createUrl(item.profileImage)} alt="" width="100%" height="230px" />
                                ||
                                <img src={PersonPlaceholder} alt="" width="100%" height="200px" />
                            }
                        </Link>
                    </div>
                    <div className="buildername"><p>{item.townPlannerName}</p></div>
                    {/* <div className="location">{item.mobile}</div> */}
                    <div className="location">{item.address ? item.address.city : ""}</div>

                    {/* <Rating name="read-only" value={item.rating} readOnly className="rating" /> */}
                </li>
            )
        );
        return isLoading ? (<Loader />) :
            (
                <>
                    <Header />
                    {/* {
                   error ?  <ApiErrorHandling error={ErrMsg}/> : ""
               } */}
                    <div className="Townplanner d-block w-100">
                        <div className="bannerButtons">
                            <Link to="/townplannerContentPage">
                                <button className='py-2 mr-3'>Why Hire our Town Planners?</button>
                            </Link>
                        </div>
                    </div>



                    <div className="buildersBlock px-3">
                        <div className="container">
                            <div className='content'>
                                <p>Are you looking for Town Planners in Australia?</p>
                                <p>Real estate development is a complicated process that requires a creative vision and robust knowledge and experience to bring it to life. With Aashray Homes you can have the organization of many interrelated tasks performed by many highly specialized professionals. By selecting suitable town planners, you can focus on your core business and outsource your development process to professional town planning consultants. Our team of specialists will serve as your development consultant, providing the leadership needed to move the development process forward in a controlled and coordinated manner.
                                    With the help of competent professionals who oversee each stage of the development process, you can establish, revise, and deliver your real estate project on time and within your projected budget. From new construction to expansions or consolidations, we'll help you determine the viability of, secure funding for, and carry out your next project.</p>
                                <Link to="/townplannerContentPage">
                                    <button className='px-4 py-2 mt-5'>
                                        Learn More
                                    </button>
                                </Link>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-3">
                                    <h4>Filters</h4>
                                    <h5 className="my-3">Sort by Location</h5>
                                    <div className="search-bar-section">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            onChange={(event, newValue) => {
                                                this.searchTownplannerByLocation(newValue);
                                            }}
                                            style={{ width: "100%" }}
                                            options={locationList}
                                            getOptionLabel={(option: any) => option}
                                            renderInput={(params) => <TextField {...params} placeholder="Search" variant="outlined" name="searchLocationText" />}
                                        />

                                        {/* <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#filterItems">...</button> */}
                                    </div>


                                    {/* <h5 className="mt-5">Sort by Location</h5> */}
                                    <ul className="RatingList">
                                        {/* <li>
                                        <Rating name="read-only" value={5} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={4} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={3} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={2} readOnly className="rating" /> & Up
                                    </li>
                                    <li>
                                        <Rating name="read-only" value={1} readOnly className="rating" /> & Up
                                    </li> */}
                                    </ul>
                                </div>
                                <div className="offset-md-1 col-md-8">
                                    {townplanners.length > 0 &&
                                        <ul className="builderscard row">{listItems}</ul>
                                        ||
                                        <div className="buildersBlock py-5 empty-Message-Container">
                                            <div className="container-fluid empty-Message-Content">
                                                <img src={Buildershape} alt="" />
                                                <p className="text-center my-3">There are no townplanners on the platform yet. We will soon have some for you.</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </>
            );
    }
}

export default TownPlanners;
