import React, { Component } from 'react';
import Header from '../../../common-components/Header/Header';
import Footer from '../../../common-components/Footer/Footer';
import RightArrow from "../../../../assets/images/right-arrow.svg"
import Info from "../../../../assets/images/info.svg"
import { userService } from '../../../Services/CommonService';
import { constants } from '../../../../constants/constants';
import Modal from "react-bootstrap/Modal";
import ToggleSwitch from '../../../common-components/ToggleSwitch/ToggleSwitch';
import { Link } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import Check from '../../../Services/Check';
import './NewProject.scss';
import Buildingonly from "../../../../assets/images/Buildingonly.svg";
import Landbuilding1 from "../../../../assets/images/Landbuilding1.svg";
import LandBuilding2 from "../../../../assets/images/LandBuilding2.svg";
import Unit from "../../../../assets/images/Unit.svg";
import TownHouse from "../../../../assets/images/TownHouse.svg";
import CompleteHouse from "../../../../assets/images/CompleteHouse.svg";
import ProjectTypeModal from '../../Builder/ProjectCreationModal/ProjectTypeModal/ProjectTypeModal';
import asterisk from '../../../../assets/images/asterisk.svg';
import step1 from '../../../../assets/images/step1.svg';
import Rectangleactive from '../../../../assets/images/Rectangleactive.svg';
import step2inactive from '../../../../assets/images/step2inactive.svg';
import Rectangleinactive from '../../../../assets/images/Rectangleinactive.svg';
import step3inactive from '../../../../assets/images/step3inactive.svg';
import step2 from '../../../../assets/images/step2.svg';
import step3 from '../../../../assets/images/step3.svg';

interface NewProjectProps {
    name?: any;
    value?: any;
}
interface NewProjectState {
    error: boolean;
    ErrMsg: any;
    name: string,
    description: string,
    adLine1: string,
    adLine2: string,
    city: string,
    state: string,
    country: string,
    pincode: string,
    mobile: string,
    builderType: string,
    landSize: string,
    measurement: string,
    landSaleContract: string,
    architectDrawings: string,
    engineeringPlans: string,
    landscapingDesigns: string,
    selectedFile1: any,
    selectedFile2: any,
    selectedFile3: any,
    selectedFile4: any,
    checked: boolean,
    isCustomBuilder: boolean,
    isPublic: boolean,
    errors: {
        terms: string,
        file1: string,
        file2: string,
        file3: string,
        file4: string,
        description: string,
        name: string,
        adLine1: string,
        adLine2: string,
        landSize: string,
        mobile : string,
        aboutUpgradation: string,
        budget: string,
        timeLine: string,
        address: string,
    },
    projectModal: boolean,
    uploadModal: boolean,
    projectHeader: string,
    projectBody: string,
    uploadHeader: string,
    uploadBody: string,
    stateList: any,
    pincodeList: any,
    cityList: any,
    subProjectType: string,
    showProjectType: boolean,
    isBasicDetails: boolean,
    isProjectDetails: boolean,
    projectType: string,
    isProjectType: boolean,
    aboutUpgradation: string,
    budget: string,
    timeLine: string,
    projectTypeId: string,
    address: string,
}

const Regex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
export default class NewProject extends React.Component<NewProjectProps & any , NewProjectState> {
    constructor(props: any) {
        super(props);
        const initialState = {
            name: '',
            description: '',
            adLine1: '',
            adLine2: '',
            city: '',
            state: '',
            country: '',
            pincode: '',
            mobile: '',
            builderType: 'Project Home Builder',
            landSize: '',
            measurement: 'Square Meter',
            landSaleContract: '',
            architectDrawings: '',
            engineeringPlans: '',
            landscapingDesigns: '',
            selectedFile1: '',
            selectedFile2: '',
            selectedFile3: '',
            selectedFile4: '',
            checked: false,
            errors: {
                terms: '',
                file1: '',
                file2: '',
                file3: '',
                file4: '',
                description: '',
                name: '',
                adLine1: '',
                adLine2: '',
                landSize: '',
                mobile : '',
                aboutUpgradation: '',
                budget: '',
                timeLine: '',
                address: '',
            },
            isPublic: true,
            isCustomBuilder: false,
            projectModal: false,
            uploadModal: false,
            projectHeader: '',
            projectBody: '',
            uploadHeader: '',
            uploadBody: '',
            stateList: [],
            pincodeList: [],
            cityList: [],
            error: false,
            ErrMsg: "",
            subProjectType: '',
            showProjectType: false,
            isBasicDetails: false,
            isProjectDetails: false,
            projectType: "",
            isProjectType: true,
            aboutUpgradation: "",
            budget: "",
            timeLine: "",
            projectTypeId: "",
            address: "",
        };
        this.state = initialState;
        // this.setPublic = this.setPublic.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
            this.props.location.state.data.map((items:any)=>{
                this.setState({     
                    subProjectType:  items.projectSubtype,
                    projectType: items.projectType,
                    projectTypeId: items.id,
                })
            })
            this.setState({
                isPublic:  this.props.location.state.publicaData
            })
           
        this.fetchStateList();
    }
    async fetchStateList(): Promise<any> {
        const options = {
            url: constants.fetchStatesListAPI,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                this.setState({ stateList: response.data })

            })
            .catch((error) => {
                this.setState({ ErrMsg: error, error: true });
            });
    }

    async fetchCityList(stateNm: any): Promise<any> {
        if (stateNm) {
            const options = {
                url: constants.fetchCitiesListAPI + stateNm,
            };
            userService
                .getApiRequest(options)
                .then((response) => {
                    let dataArr: any = [];
                    response.data.map((item: any) => (
                        dataArr.push(item.city)
                    
                   ),
                   )
                      
                    const dataArrWithSet = Array.from(new Set(dataArr));
                    const resultArr = [... dataArrWithSet];
                    this.setState({ cityList: resultArr })
                })
                .catch((error) => {
                    this.setState({ cityList: [] })
                    this.setState({ ErrMsg: error, error: true });
                });
        } else {
            this.setState({ cityList: [] })
        }

    }
    async fetchPicodeList(cityNm: any): Promise<any> {
        if (cityNm) {
            const options = {
                url: constants.fetchPincodesListAPI + cityNm,
            };
            userService
                .getApiRequest(options)
                .then((response) => {
                    this.setState({ pincodeList: response.data })
                })
                .catch((error) => {
                    this.setState({ pincodeList: [] })
                    this.setState({ ErrMsg: error, error: true });
                });
        } else {
            this.setState({ pincodeList: [] })
        }

    }

    // setPublic() {
    //     this.setState({ isPublic: !this.state.isPublic });
    // }
    getBase64(file: any) {
        let document: any = "";
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                document = reader.result;
            };

            reader.onerror = function (error) {
            };
        }
        return document;
    }
    onFileChange = async (event: any) => {
        let file = event.target.files[0];
        let id = event.target.id;
        if (id === "file1") {
            if (file && file.type != 'application/pdf') {
                let errors = this.state.errors;
                errors.file1 = 'Please upload only PDF file';
                this.setState({ errors: errors });
            } else if (file && file.size > 4000000) {
                let errors = this.state.errors;
                errors.file1 = 'Please upload file below 4MB';
                this.setState({ errors: errors });
            } else {
                let errors = this.state.errors;
                errors.file1 = '';
                this.setState({ selectedFile1: file, errors: errors });
            }
        }
        if (id === "file2") {
            if (file && file.type != 'application/pdf') {
                let errors = this.state.errors;
                errors.file2 = 'Please upload only PDF file';
                this.setState({ errors: errors });
            } else if (file && file.size > 4000000) {
                let errors = this.state.errors;
                errors.file1 = 'Please upload file below 4MB';
                this.setState({ errors: errors });
            } else {
                let errors = this.state.errors;
                errors.file2 = '';
                this.setState({ selectedFile2: file, errors: errors });
            }
        }
        if (id === "file3") {
            if (file && file.type != 'application/pdf') {
                let errors = this.state.errors;
                errors.file3 = 'Please upload only PDF file';
                this.setState({ errors: errors });
            } else if (file && file.size > 4000000) {
                let errors = this.state.errors;
                errors.file1 = 'Please upload file below 4MB';
                this.setState({ errors: errors });
            } else {
                let errors = this.state.errors;
                errors.file3 = '';
                this.setState({ selectedFile3: file, errors: errors });
            }
        }
        if (id === "file4") {
            if (file && file.type != 'application/pdf') {
                let errors = this.state.errors;
                errors.file4 = 'Please upload only PDF file';
                this.setState({ errors: errors });
            } else if (file && file.size > 4000000) {
                let errors = this.state.errors;
                errors.file1 = 'Please upload file below 4MB';
                this.setState({ errors: errors });
            } else {
                let errors = this.state.errors;
                errors.file4 = '';
                this.setState({ selectedFile4: file, errors: errors });
            }
        }

    };

    onFileUpload = async (e: any) => {
        let userId = localStorage.getItem("id");
        let id = e.target.id;
        if (id === "file1") {
            let errors = this.state.errors;
            if (this.state.selectedFile1) {
                errors.file1 = '';
                this.setState({ errors: errors });
                const fileData = this.state.selectedFile1;
                const formData = new FormData();
                formData.append('id', userId ? userId : '');
                formData.append('type', "ProjectDocument");
                // formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data: formData
                }
                const response = await userService.postFileApiRequest(options);
                if (response) {
                    this.setState({ landSaleContract: response });
                    this.showUploadModal("Success", "File Uplaoded Successfully");
                } else {
                    this.showUploadModal("Failed", "Failed to Upload File");
                    this.setState({ landSaleContract: '' });
                }
            } else {
                let errors = this.state.errors;
                errors.file1 = 'Please Select File to Upload';
                this.setState({ errors: errors });
            }
        }
        if (id === "file2") {
            let errors = this.state.errors;
            if (this.state.selectedFile2) {
                errors.file2 = '';
                this.setState({ errors: errors });
                const fileData = this.state.selectedFile2;
                const formData = new FormData();
                formData.append('id', userId ? userId : '');
                formData.append('type', "ProjectDocument");
                // formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data: formData
                }
                const response = await userService.postFileApiRequest(options);
                if (response) {
                    this.showUploadModal("Success", "File Uplaoded Successfully");
                    this.setState({ architectDrawings: response });
                } else {
                    this.showUploadModal("Failed", "Failed to Upload File");
                    this.setState({ architectDrawings: '' });
                }
            } else {
                let errors = this.state.errors;
                errors.file2 = 'Please Select File to Upload';
                this.setState({ errors: errors });
            }
        }
        if (id === "file3") {

            let errors = this.state.errors;
            if (this.state.selectedFile3) {
                errors.file3 = '';
                this.setState({ errors: errors });
                const fileData = this.state.selectedFile3;
                const formData = new FormData();
                formData.append('id', userId ? userId : '');
                formData.append('type', "ProjectDocument");
                // formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data: formData
                }
                const response = await userService.postFileApiRequest(options);
                if (response) {
                    this.showUploadModal("Success", "File Uplaoded Successfully");
                    this.setState({ engineeringPlans: response });
                } else {
                    this.showUploadModal("Failed", "Failed to Upload File");
                    this.setState({ engineeringPlans: '' });
                }
            } else {
                let errors = this.state.errors;
                errors.file3 = 'Please Select File to Upload';
                this.setState({ errors: errors });
            }


        }
        if (id === "file4") {

            let errors = this.state.errors;
            if (this.state.selectedFile4) {
                errors.file4 = '';
                this.setState({ errors: errors });
                const fileData = this.state.selectedFile4;
                const formData = new FormData();
                formData.append('id', userId ? userId : '');
                formData.append('type', "ProjectDocument");
                // formData.append('projectId', "1");
                formData.append('file', fileData);

                const options = {
                    url: constants.uploadFilesAPI,
                    data: formData
                }
                const response = await userService.postFileApiRequest(options);
                if (response) {
                    this.showUploadModal("Success", "File Uplaoded Successfully");
                    this.setState({ landscapingDesigns: response });
                } else {
                    this.showUploadModal("Failed", "Failed to Upload File");
                    this.setState({ landscapingDesigns: '' });
                }

            } else {
                let errors = this.state.errors;
                errors.file4 = 'Please Select File to Upload';
                this.setState({ errors: errors });
            }


        }
    };
    remove = (fileNm: any) => {
        if (fileNm === "file1") {
            this.setState({ landSaleContract: '', selectedFile1: '' });
        }
        if (fileNm === "file2") {
            this.setState({ architectDrawings: '', selectedFile2: '' });
        }
        if (fileNm === "file3") {
            this.setState({ engineeringPlans: '', selectedFile3: '' });
        }
        if (fileNm === "file5") {
            this.setState({ landscapingDesigns: '', selectedFile4: '' });
        }
    }
    showProjectModal = (head: any, body: any) => {
        this.setState({ projectModal: true, projectHeader: head, projectBody: body });
    };
    hideProjectModal = () => {
        this.setState({ projectModal: false });
        this.props.history.push('/MyProjects')

    };
    showUploadModal = (head: any, body: any) => {
        this.setState({ uploadModal: true, uploadHeader: head, uploadBody: body });
    };
    hideUploadModal = () => {
        this.setState({ uploadModal: false });
    };


    handleSubmit = async (event: any) => {
        const { name, description, adLine1, adLine2, city, state, country, pincode, mobile, landSize, cost, address}:any = this.state;

        if(name!==""&&description!=="" && mobile!== ""&& landSize!== ""){
        let userId = localStorage.getItem("id");
        event.preventDefault();
        let errors = this.state.errors;
        errors.terms = !this.state.checked ? 'Please accept Terms & Conditions' : '';
        this.setState({ errors: errors });
        let validity = true;
        Object.values(this.state.errors).forEach(
            (val: any) => val.length > 0 && (validity = false)
        );
        if (validity == true) {
            Check.checkToken();
            const options = {
                url: constants.createProjectAPI,
                data: {
                    "name": this.state.name,
                    "description": this.state.description,
                    "address": {
                        "addressLine1": address,
                        "addressLine2": "",
                        "city": "",
                        "state": "",
                        "country": "Australia",
                        "pincode": "",
                        "latitude": 0,
                        "longitute": 0
                    },
                    "mobile": this.state.mobile,
                    "projectDetails": {
                        "builderType": this.state.builderType,
                        "landSize": this.state.landSize,
                        "measurementUnit": this.state.measurement,
                        "projectDocs": {
                            "landSaleContract": this.state.landSaleContract,
                            "architectDrawings": this.state.architectDrawings,
                            "engineeringPlans": this.state.engineeringPlans,
                            "landscapingDesigns": this.state.landscapingDesigns
                        }
                    },
                    "projectType": {
                            "id": this.state.projectTypeId, 
                            "projectType": this.state.projectType, 
                            "projectSubtype": this.state.subProjectType
                            },
                    "minBudget": this.state.budget,
                    "maxBudget": 0,
                    "durationInMonths": this.state.timeLine,
                    "publicProject": this.state.isPublic,
                    "active": true,
                    "createdBy": userId,
                    "typeOfProject": "Contract"
                }
            }
            var response = await userService.postApiRequest(options);

            if (response) {
                this.showProjectModal("Success", "Project Created Successfully And Went For Verification");
            }
            else {
                this.showProjectModal("Failed", "Failed to Create Project");
            }
        } else {
        }
    }
    }

    handleChange = (event: any) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'name':
                    errors.name = value.length > 255 ? 'You Have Reached Your maximum Limit Of Characters Allowed': '';
                break;
            case 'description':
                    errors.description = value.length > 250 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'adLine1':
                    errors.adLine1 = value.length == 255 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'adLine2':
                    errors.adLine2 = value.length == 255 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'mobile':
                     errors.mobile = Regex.test(value) ? '' :  'Please Enter A Valid Number' ;
                break;
            case 'landSize':
                    errors.landSize = value.length > 4 ? 'You have reached your maximum limit of characters allowed': '';
                break;
            case 'usertype':
            //  errors.usertype = value.length == 0 ? 'Please select valid option': '';
            case 'builderType':
                break;
            // case 'state':
            //     this.fetchCityList(value);
            //     break;
            // case 'city':
            //     this.fetchPicodeList(value);
            //     break;
            case 'budget': 
                errors.budget = value.length < 8 ? '' :  'Please Enter A Valid Number' ;
                break; 
            case 'aboutUpgradation': 
                errors.aboutUpgradation = value.length > 250 ? 'You have reached your maximum limit of characters allowed': '';
                break; 
            case 'timeLine': 
                errors.timeLine = value.length < 8 ? '' :  'Please Enter A Valid Number' ;
                break;
            case 'address': 
                errors.address = value.length > 250 ? 'You have reached your maximum limit of characters allowed': '';
                break;  
            default:
                break;
        }
        this.setState(Object.assign(this.state, { errors, [name]: value }));
        // if (name === "builderType") {
        //     if (value === "Custom Builder") {
        //         this.setState({ isCustomBuilder: true });
        //     } else {
        //         this.setState({ isCustomBuilder: false });
        //     }
        // }
    }

    handleCheckBox = () => {
        this.setState({ checked: !this.state.checked });
    }

    openProjectTypeModal=()=>{
        this.setState({
            showProjectType: true
        })
    }
    closeProjectTypeModal=(event: any)=>{
        if(this.state.showProjectType == true){
            if(event.length > 0){
                event.map((items:any)=>{
                    this.setState({     
                        subProjectType:  items.projectSubtype,
                        showProjectType: false,
                        projectType: items.projectType,
                        projectTypeId: items.id,
                    })
                })
            }
            else{
                this.setState({     
                    showProjectType: false,
                })
            }
           
        }else{
            this.props.location.state.data.map((items:any)=>{
                this.setState({     
                    subProjectType:  items.projectSubtype,
                    showProjectType: false,
                    projectType: items.projectType,
                    projectTypeId: items.id,
                })
            })
        }
        
    }

    openBasicDetails = ()=>{
            this.setState({
                isBasicDetails: true,
                isProjectType: false
            })
    }

    openProjectDetails = ()=>{
        this.setState({
            isProjectDetails: true,
            isBasicDetails: false
        })
    }
    closeBasicDetails = ()=>{
        this.setState({
            isProjectType: true,
            isBasicDetails: false
        })
    }

    render() {
        const { error, ErrMsg, errors, checked, isCustomBuilder, isPublic, projectModal, uploadModal, landSaleContract, architectDrawings, engineeringPlans, landscapingDesigns, stateList, pincodeList, cityList, city, state, measurement, landSize, subProjectType, showProjectType, isBasicDetails, name, description, mobile, isProjectDetails, projectType, isProjectType,  aboutUpgradation, budget, timeLine, builderType, address } = this.state;
        const uniquePincodes = Array.from(new Set(pincodeList));
        let isSubmitted = true;
        let isProjectSubmitted = true;
        if(name !== "" && description !== "" && mobile !== "" 
            && errors.name == "" && errors.description == "" && errors.mobile == ""){
                  isSubmitted = false
          }
          if(projectType == "New property"){
            if( budget !== "" && landSize !== "" && builderType !== ""){
                isProjectSubmitted= false
            }
          }else{
            if( budget !== "" && landSize !== "" && aboutUpgradation !== "" && timeLine !== ""){
                isProjectSubmitted= false
            }
          }
         
        return (
            <>
                <Header />
                <div className="bg-img">
                    <div className="overlay">
                        <div className="container">
                            <div className="col-md-6 col-12">
                                <h1  style={{fontWeight:600}}>New Project</h1>
                                <p className="my-3"><Link to="/MyProjects" className="link-text">My Projects</Link> / New Project</p>
                            </div>
                        </div>
                    </div>
                    <div>
                    <Link to="/MyProjects" className="link-text">
                        <button  className="button2 contact">Cancel</button>
                    </Link>
                    </div>
                </div>
                <div className="Projects">
                    <div className="container mt-5">
                        <div>
                            { (isProjectType === true)&&
                                <>
                                    <div>
                                        <img src={step1} />
                                        <img className='ml-2 mr-2' src= {Rectangleactive}/>
                                        <img src= {step2inactive} />
                                        <img className='ml-2 mr-2' src = {Rectangleinactive} />
                                        <img src= {step3inactive} />
                                    </div>
                                    <div className='row d-flex step-heading'>
                                        <p className='col-md-4 active-step'>Project Type</p>
                                        <p className='col-md-4'>Basic Details</p>
                                        <p className='col-md-4' >Project Details</p>
                                    </div>
                                </>    
                                ||
                                (isBasicDetails == true)&&
                                    <>
                                        <div>
                                            <img src={step1} />
                                            <img className='ml-2 mr-2' src= {Rectangleactive}/>
                                            <img src= {step2} />
                                            <img className='ml-2 mr-2' src = {Rectangleinactive} />
                                            <img src= {step3inactive} />
                                        </div>
                                        <div className='row d-flex step-heading'>
                                            <p className='col-md-4 active-step'>Project Type</p>
                                            <p className='col-md-4 active-step'>Basic Details</p>
                                            <p className='col-md-4' >Project Details</p>
                                        </div>
                                    </>
                                ||
                                (isProjectDetails == true)&&
                                    <>
                                        <div>
                                            <img src={step1} />
                                            <img className='ml-2 mr-2' src= {Rectangleactive}/>
                                            <img src= {step2} />
                                            <img className='ml-2 mr-2' src = {Rectangleactive} />
                                            <img src= {step3} />
                                        </div>
                                        <div className='row d-flex step-heading'>
                                            <p className='col-md-4 active-step'>Project Type</p>
                                            <p className='col-md-4 active-step'>Basic Details</p>
                                            <p className='col-md-4 active-step' >Project Details</p>
                                        </div>
                                    </>    
                            }

                        </div>
                        {(isBasicDetails == true)&& 
                            <>
                            <div className="sendInquiry-container asterisk-symbol mt-3">
                                <div className='mt-2 mb-4 contailner-heading'>
                                    <p>
                                        Basic Details
                                    </p>
                                </div>
                                <p>
                                  Project Name
                                  <img src= {asterisk}/>
                                </p>
                                <input
                                  type = "text"
                                  name = "name"
                                  value={ name }
                                  placeholder="Project Name"
                                  className="w-50 mb-3 px-3 py-2"
                                  onChange={this.handleChange}
                                />
                                {errors.name.length > 0 && (
                                          <p style={{ color: 'red' }}>{errors.name}</p>
                                  )}
                                <p>
                                    About
                                    <img src= {asterisk}/>
                                </p>
                                <input
                                  type = "text"
                                  name = "description"
                                  value={description}
                                  placeholder="About the Project"
                                  className="w-50 mb-3 px-3 py-2"
                                  onChange={this.handleChange}
                                />
                                {errors.description.length > 0 && (
                                          <p style={{ color: 'red' }}>{errors.description}</p>
                                  )}
                                <p>
                                    Address
                                    <img src= {asterisk}/>
                                </p>
                                <input
                                  type = "text"
                                  name = "address"
                                  value={address}
                                  placeholder="Address of the Land"
                                  className="w-50 mb-3 px-3 py-2"
                                  onChange={this.handleChange}
                                />
                                {errors.address.length > 0 && (
                                          <p style={{ color: 'red' }}>{errors.address}</p>
                                  )}
                                <p>
                                    Phone Number
                                    <img src= {asterisk}/>
                                </p>
                                <input
                                  type = "number"
                                  name = "mobile"
                                  value={ mobile }
                                  placeholder="Phone"
                                  className="w-50 mb-3 px-3 py-2"
                                  onChange={this.handleChange}
                                  maxLength = {10}
                                  onKeyDown={ (evt) =>  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() }
                                  required
                                />
                                {errors.mobile.length > 0 && (
                                          <p style={{ color: 'red' }}>{errors.mobile}</p>
                                  )}
                            </div>  
                            <div className='mt-4 mb-5'> 
                                <button 
                                    onClick={this.closeBasicDetails}
                                    className = " px-5 py-3 Previous"
                                >
                                    Previous
                                </button>
                                <button
                                    className= {isSubmitted == true ? "button2 px-5 py-3 disabled-button ml-3" : "button2 px-5 py-3 ml-3"}
                                    disabled= {isSubmitted}
                                     onClick = {this.openProjectDetails}
                                >
                                  Next
                                </button> 
                            </div>
                          </> 
                           
        
                   
                            ||
                            (isProjectType === true)&&
                            <>
                                <div className='mt-2 contailner-heading'>
                                    <p>
                                        Property Type
                                        <span onClick={this.openProjectTypeModal} className='ml-3'>Edit</span>
                                    </p>
                                </div>
                        <div className='mt-2 mb-5'>
                            {
                                (subProjectType == "Building only")&&
                                    <div className='col-md-3 project-type-container'>
                                        <div 
                                            className =  "project-type-box" 
                                        >
                                            <img   src= {Buildingonly} ></img>
                                        </div> 
                                        <p>Building only</p>
                                    </div>
                                    ||
                                (subProjectType == "Land + Building")&&
                                    <div className='col-md-3 project-type-container'>
                                        <div 
                                            className = "project-type-box d-flex"
                                        >
                                            <img style = {{marginRight: "10px"}} src= {Landbuilding1}></img>
                                            <img src= {LandBuilding2}></img>
                                            <img style = {{ marginLeft: "10px"}} src= {Buildingonly}></img> 
                                        </div> 
                                        <p>Land + Building</p>
                                    </div>
                                    ||
                                (subProjectType == "Unit")&&
                                    <div className='col-md-3 project-type-container'>
                                        <div 
                                            className = "project-type-box"
                                        >
                                            <img   src= {Unit} ></img>
                                        </div> 
                                        <p>Unit</p>
                                    </div>
                                    ||
                                (subProjectType == "Complete House")&&
                                    <div className='col-md-3 project-type-container'>
                                        <div 
                                            className = "project-type-box "
                                        >
                                            <img src= {CompleteHouse}></img>
                                        </div> 
                                        <p> Complete House</p>
                                    </div>
                                    ||
                                (subProjectType == "Town-House")&&
                                    <div className='col-md-3 project-type-container'>
                                        <div 
                                            className = "project-type-box"
                                        >
                                            <img src= {TownHouse}></img>
                                        </div> 
                                        <p>Town-House</p>
                                    </div>
                            }
                        </div>
                        <button className='button2 py-2 px-4 mt-5 mb-5' onClick={this.openBasicDetails}>Next</button>
                            </>
                            ||
                            (isProjectDetails == true)&&
                        <>
                            {(projectType == "New property")?
                            <>
                            <div className="sendInquiry-container asterisk-symbol mt-3">
                                <div className='mt-2 mb-4 contailner-heading'>
                                        <p>
                                            Project Details 
                                        </p>
                                </div>
                                {/* <p>
                                    Purpose of Property 
                                    <img src= {asterisk}/>
                                </p>
                                <select  className="w-25 mb-3 px-3 py-2">
                                    <option value="" disabled selected>Why are you looking for?</option>
                                    <option>Commercial</option>
                                    <option>Residential</option>
                                </select> */}
                                <p>
                                    Builder Type
                                    <img src= {asterisk}/>
                                </p>
                                <select name="builderType" id="builderType" onChange={this.handleChange} className="w-25 mb-3 px-3 py-2">
                                    <option value="Custom Builder">Custom Build</option>
                                    <option value="Project Home Builder">Project Home Builder</option>
                                </select>
                                <p>This type of builders has limitation on designs and materials in construction.</p>
                                <p>
                                    Land Size
                                    <img src= {asterisk}/>
                                </p>
                                <div className="land-size">
                                <input
                                type = "number"
                                name = "landSize"
                                value={landSize}
                                placeholder="Land Size"
                                className="w-25 mb-3 px-3 py-2"
                                onChange={this.handleChange}
                                />
                                
                                <span>in</span>
                                <select  name="measurement"  onChange={this.handleChange} value = {measurement}>
                                        <option value="Square Meter">Sq Meters</option>
                                        <option value="Square Feet">Sq Ft</option>
                                </select>
                                </div>
                                {errors.landSize.length > 0 && (
                                    <p style={{ color: 'red' }}>{errors.landSize}</p>
                            )}
                                <p>
                                    Budget
                                    <img src= {asterisk}/>
                                </p>
                                <input
                                type = "text"
                                name = "budget"
                                value={budget}
                                placeholder="Enter the budget"
                                className="w-25 mb-3 px-3 py-2"
                                onChange={this.handleChange}
                                required
                                />
                                {errors.budget.length > 0 && (
                                    <p style={{ color: 'red' }}>{errors.budget}</p>
                                )}
                            </div>  
                            <div className="mt-2">
                                <input
                                    type= "checkbox"
                                    onChange={this.handleCheckBox}
                                    checked={checked}
                                />
                                <span className="ml-2">I accept the privacy and terms</span>
                            </div>
                            {errors.terms.length > 0 && <p style={{ color: 'red' }}>{errors.terms}</p>}
                            <div className='mt-4 d-flex'> 
                                <button
                                        className=  "button2 py-2 px-4  mt-5 mb-5 Previous"
                                        onClick={this.openBasicDetails}
                                    >
                                Previous
                                </button> 
                                <button
                                    className=  {isProjectSubmitted == true ? " disabled-button button2 py-2 px-4 mt-5 mb-5 ml-2" : "button2 py-2 px-4 mt-5 mb-5 ml-2"} 
                                    disabled= {isProjectSubmitted}
                                     onClick = {this.handleSubmit}
                                >
                               Publish Project
                                </button> 
                            </div>
                        
                            </>
                            :
                            <>
                            <div className="sendInquiry-container mt-3">
                                <p>
                                    Timeline
                                    <img src= {asterisk}/>
                                </p>
                                <input
                                type="text"
                                name= "timeLine"
                                value = {timeLine}
                                placeholder="Enter Timeline"
                                onChange={this.handleChange}
                                className="w-50 mb-3 px-3 py-2"
                                />
                                {errors.timeLine.length > 0 && (
                                    <p style={{ color: 'red' }}>{errors.timeLine}</p>
                                )}
                                <p>
                                    Budget
                                    <img src= {asterisk}/>
                                </p>
                                <input
                                type = "number"
                                name = "budget"
                                value={budget}
                                placeholder="Enter the budget"
                                className="w-50 mb-3 px-3 py-2"
                                onChange={this.handleChange}
                                required
                                />
                                {errors.budget.length > 0 && (
                                    <p style={{ color: 'red' }}>{errors.budget}</p>
                                )}
                                <p>
                                    Land Size
                                    <img src= {asterisk}/>
                                </p>
                                <div className="land-size">
                                <input
                                    type = "number"
                                    name = "landSize"
                                    value={landSize}
                                    placeholder="Land Size"
                                    className="w-50 mb-3 px-3 py-2"
                                    onChange={this.handleChange}
                                />
                                <span>in</span>
                                <select  name="measurement"  onChange={this.handleChange} value = {measurement}>
                                    <option value="Square Meter">Sq Meters</option>
                                    <option value="Square Feet">Sq Ft</option>
                                </select>
                                </div>
                                {errors.landSize.length > 0 && (
                                    <p style={{ color: 'red' }}>{errors.landSize}</p>
                                )}
                                <p>
                                    Tell Us More About
                                </p>
                                <textarea
                                placeholder="Tell us more about the upgradation"
                                value={aboutUpgradation}
                                name= "aboutUpgradation"
                                onChange={this.handleChange}
                                />  
                                {errors.aboutUpgradation.length > 0 && (
                                    <p style={{ color: 'red' }}>{errors.aboutUpgradation}</p>
                                )}   
                            </div>  
                            <div className="mt-2">
                                <input
                                    type= "checkbox"
                                    onChange={this.handleCheckBox}
                                    checked={checked}
                                />
                                <span className="ml-2">I accept the privacy and terms</span>
                            </div>
                            {errors.terms.length > 0 && <p style={{ color: 'red' }}>{errors.terms}</p>}
                            <div className='mt-4 d-flex'> 
                                <button
                                        className=  "button2 py-2 px-4 mt-5 mb-5 Previous"
                                        onClick={this.openBasicDetails}
                                    >
                                Previous
                                </button> 
                                <button
                                    className= {isProjectSubmitted == true ? " disabled-button button2 py-2 px-4 mt-5 mb-5 ml-2" : "button2 py-2 px-4 mt-5 mb-5 ml-2"} 
                                    disabled= {isProjectSubmitted}
                                    onClick = {this.handleSubmit}
                                >
                                Publish Project
                                </button> 
                            </div>
                            </>
                            }
                            </>
                        }
                          
                        
                         </div>
                    </div>
                    <Modal show={this.state.showProjectType} >
                        <ProjectTypeModal
                            closeProjectTypeModal = {(event: any)=>{this.closeProjectTypeModal(event)}}
                            isEdit = {showProjectType}
                    />
                    </Modal>
                    <Modal show={projectModal} onHide={this.hideProjectModal}>
                    <Modal.Header>
                        <Modal.Title className="text-center">{this.state.projectHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.projectBody}</Modal.Body>
                    <Modal.Footer>
                        <button className="ok-button" onClick={this.hideProjectModal}>
                            OK
                </button>
                    </Modal.Footer>
                </Modal>
                <Footer />
            </>
        );
    }
}
