import React, { Component } from "react";
import "./ProjectTypeModal.scss";
import Newprojecttype from "../../../../../assets/images/Newprojecttype.svg";
import Upgradeproperty from "../../../../../assets/images/Upgradeproperty.svg";
import Vector from  "../../../../../assets/images/Vector.svg";
import Buildingonly from "../../../../../assets/images/Buildingonly.svg";
import Unit from "../../../../../assets/images/Unit.svg";
import TownHouse from "../../../../../assets/images/TownHouse.svg";
import CompleteHouse from "../../../../../assets/images/CompleteHouse.svg";
import Landbuilding1 from "../../../../../assets/images/Landbuilding1.svg";
import LandBuilding2 from "../../../../../assets/images/LandBuilding2.svg";
import Modal from "react-bootstrap/Modal";
import { constants } from '../../../../../constants/constants';
import axios from 'axios';
import ToggleSwitch from '../../../../common-components/ToggleSwitch/ToggleSwitch';
import { Link } from 'react-router-dom';
import { userService } from '../../../../Services/CommonService';
import Check from '../../../../Services/Check';

interface projectTypeModalProps {
    closeProjectTypeModal?: any;
    result? : any;
    isEdit? : any;
}

interface projectTypeModalState {
    projectType: string,
    subProjectType: string,
    isPublic: boolean,
    projectType1: any,
    projectTypeData: any,
    isNewProject: boolean
}


class ProjectTypeModal extends React.Component<projectTypeModalProps , any & projectTypeModalState>{
    constructor(props:any){
        super(props);
        const initialState = {
            projectType: 'New Property',
            subProjectType: "",
            isPublic: false,
            projectType1: [],
            projectTypeData: [],
            isNewProject: false
        };
        this.state = initialState;

    }
    componentDidMount () {   
         this.init();
    }

    init(){
        this.fetchProjectType();
    }

    // fetchProjectType = ()=>{
    //     const key = constants.subscriptionKey;           
    //     const url =  constants.projecttypes;
    //     const user = JSON.parse(localStorage.getItem('user')!); 
    //     let token;
    //      if(user){
    //              token = 'Bearer ' + user;
               
    //         }
    //     axios.get(url,{ headers: {"Authorization" : '' || token,  "Ocp-Apim-Subscription-Key" :key} })
    //         .then((response) =>{
    //             if(response.status == 200 ){
    //         }
                
    //         })
    //         .catch((error) => {
    //         });
    // }

    // selectProjectType = (projectType: any )=>{
    //     this.setState ({
    //         projectType: projectType
    //     })
    // }
    // selectSubProjectType = (subProjectType: any )=>{
    //     this.setState ({
    //         subProjectType: subProjectType
    //     })
    // }

    // closeProjectTypeModal = ()=>{
    //     this.props.closeProjectTypeModal(this.state.closeProjectTypeModal);
    // }

    // createProject = ()=>{
        
    //     // if(this.state.subProjectType == "Demolish" || this.state.subProjectType == "Redesign"){
    //     //     this.setState({
    //     //         subUpgrade: true,
    //     //         isDemolish: true
    //     //     })
    //     // }else if(this.state.subProjectType == "Interior Design"){
    //     //     this.setState({
    //     //         subUpgrade: true,
    //     //         isDemolish: false
    //     //     })
    //     // }

    //     if(this.state.projectType  == "New Property"){
    //             this.confirmProjectType();
    //     }
    //     if(this.state.subUpgradeType !== ""){
    //         this.confirmProjectType();
    //     }
       
    // }

    // closesubUpgrade = ()=>{
    //     this.setState({
    //         subUpgrade: false,
           
    //     })
    // }

    // selectsubUpgradeType = (subUpgradeType: any)=>{
    //     this.setState ({
    //         subUpgradeType: subUpgradeType
    //     })
    // }

    // confirmProjectType = ()=>{
    //     this.setState({
    //         isProjectType: true
    //     })
    // }

    // closeConfirmProjectType = ()=>{
    //     this.setState({
    //         isProjectType: false
    //     })
    // }

    // openProjectDetailsModal = ()=>{
    //     this.props.result(this.state.projectType);
    // }

    fetchProjectType = ()=>{
        Check.checkToken()
        .then(
            () => {
        const options = {
            url: constants.projecttypes,
        };
        userService
            .getApiRequest(options)
            .then((response) => {
                if(response.status == 200 ){
                    this.setState({
                                    projectType1:response.data
                                })
                }
            })
            .catch((error) => {
               
            });
        }
        )
    }

    openProjectTypeModal=()=>{
        this.fetchProjectType();
        this.setState({
            showProjectType: true
        })
    }
    closeProjectTypeModal=()=>{
        // this.setState({
        //     showProjectType: false,
        //     projectType: ""
        // })
        this.props.closeProjectTypeModal(false);
    }

        selectProjectType = (projectType: any )=>{
            this.setState ({
                projectType: projectType
            })
        }
    
        setPublic =() =>{
            this.setState({ isPublic: !this.state.isPublic });
        }

        selectSubProjectType = (subProjectType: string )=>{

            this.setState ({
                subProjectType: subProjectType,
                projectTypeData: []
                
            })
        
        }

        createProject = ()=>{
            for(let i = 0; i< this.state.projectType1.length; i++){
            if( this.state.subProjectType === this.state.projectType1[i].projectSubtype){
                this.state.projectTypeData.push(this.state.projectType1[i])
                 if(this.props.isEdit == true){
                    this.setState({
                        projectTypeData: this.state.projectTypeData,
                        isNewProject: true
                                })
                         this.props.closeProjectTypeModal(this.state.projectTypeData);
                }else{
                    this.setState({
                        projectTypeData: this.state.projectTypeData,
                        isNewProject: true
                                })
                }
               
            }
        }
        }

    render(){
        const { showProjectType, projectType, isPublic, projectType1, subProjectType, projectTypeData, isNewProject, isEdit } = this.state;
      return(
        <>
            <div  className='container w-100 p-4 project-type'>
                            <div className = "select-type-modal">
                                <h4>Select Type</h4>
                                <img src = {Vector} 
                                    onClick = {this.closeProjectTypeModal}
                                />
                            </div>
                            <p>Choose the type of project you have in mind for us.</p>
                            <div>
                                    <ToggleSwitch
                                        id="public"
                                        small
                                        checked={isPublic}
                                        onChange={this.setPublic}
                                    />
                                    <label  htmlFor="daily">Public Project</label>
                                </div>
                            <div className="d-flex property-header" >
                                <p  
                                    onClick={()=>{this.selectProjectType("New Property")}}
                                    className = {projectType == "New Property" ? "project-type-active ": "" }
                                > 
                                    <img src= {Newprojecttype} ></img>
                                    New Property 
                                </p>
                                <p 
                                    onClick={()=>{this.selectProjectType("Upgrade Property")}}
                                    className = {projectType == "Upgrade Property" ? "project-type-active": "" }
                                > 
                                    <img style={{width: "5%"}} src= {Upgradeproperty} ></img>
                                    Upgrade Property
                                </p>
                            </div>   
                            {(projectType == "New Property")?
                                <div className='row py-2 mt-4 d-flex'>
                                <div className='col-md-6 project-type-container'>
                                    <div 
                                        className = {subProjectType == "Building only" ? "project-type-box-active": "project-type-box" }
                                        onClick={()=>{this.selectSubProjectType("Building only")}}
                                    >
                                        <img   src= {Buildingonly} ></img>
                                    </div> 
                                    <p>Building only</p>
                                </div>
                                <div className='col-md-6 project-type-container'>
                                    <div 
                                        className = {subProjectType == "Land + Building" ? "project-type-box-active d-flex": "project-type-box d-flex" }
                                        onClick={()=>{this.selectSubProjectType("Land + Building")}}  
                                    >
                                        <img style = {{marginRight: "10px"}} src= {Landbuilding1}></img>
                                        <img src= {LandBuilding2}></img>
                                        <img style = {{ marginLeft: "10px"}} src= {Buildingonly}></img> 
                                    </div> 
                                    <p>Land + Building</p>
                                </div>
                            </div>
                            :
                            <div className='row py-2 mt-4 d-flex'>
                                <div className='col-md-6 project-type-container'>
                                            <div 
                                                className = {subProjectType == "Unit" ? "project-type-box-active": "project-type-box" }
                                                onClick={()=>{this.selectSubProjectType("Unit")}}
                                            >
                                                <img   src= {Unit} ></img>
                                            </div> 
                                            <p>Unit</p>
                                        </div>
                                        <div className='col-md-6 project-type-container'>
                                            <div 
                                                className = {subProjectType == "Complete House" ? "project-type-box-active d-flex": "project-type-box d-flex" }
                                                onClick={()=>{this.selectSubProjectType("Complete House")}}  
                                            >
                                                <img src= {CompleteHouse}></img>
                                            </div> 
                                            <p> Complete House</p>
                                        </div>
                                        <div className='col-md-6 project-type-container'>
                                            <div 
                                                className = {subProjectType == "Town-House" ? "project-type-box-active d-flex": "project-type-box d-flex" }
                                                onClick={()=>{this.selectSubProjectType("Town-House")}}  
                                            >
                                                <img src= {TownHouse}></img>
                                            </div> 
                                            <p>Town-House</p>
                                        </div>
                            </div>
                            }
                            {(localStorage.userType && localStorage.userType == "Customer")&&
                                <>
                                    {(isEdit == true)?
                                        <button 
                                        disabled = {subProjectType == ""}  
                                        onClick={this.createProject}
                                        className= {subProjectType == "" ? "button2 p-3 disabled-button" : "button2 p-3"} 
                                    >
                                        Create a Project
                                        
                                    </button> 
                                    :
                                        <Link to= {{ pathname: "/NewProject", state:{data: projectTypeData, publicaData: isPublic}}}>
                                            <button 
                                                disabled = {subProjectType == ""}  
                                                onClick={this.createProject}
                                                className= {subProjectType == "" ? "button2 p-3 disabled-button" : "button2 p-3"} 
                                            >
                                                Create a Project
                                                
                                            </button>  
                                        </Link> 
                                    }   
                                </>
                                ||
                                (localStorage.userType && localStorage.userType == "Builder")&&
                                    <>
                                        <Link to= {{ pathname: "/NewBuilderProject", state:{data: projectTypeData, publicaData: isPublic}}}>
                                            <button 
                                                disabled = {subProjectType == ""}  
                                                onClick={this.createProject}
                                                className= {subProjectType == "" ? "button2 p-3 disabled-button" : "button2 p-3"} 
                                            >
                                                Create a Project
                                                
                                            </button>  
                                        </Link>
                                    </>
                            }
                             
                        </div>
        </>
      )   
    

}
}

export default ProjectTypeModal;