import React, { Component } from "react";
import "./QuoteConfirmationModal.scss";
import Vector from  "../../../assets/images/Vector.svg";
import { userService } from '../../Services/CommonService';
import { constants } from '../../../constants/constants';
import Check from '../../Services/Check';



interface qutoeConfirmationprops {
    result: any;
    projectName:any;
    projectId: any,
    quoteCreatedBy: any,
    userId: any,
    quoteStatus: any,
    userName: any,
    error: any,
    status: any,
}

interface qutoeConfirmationstate {
    projectName: string,   
    userName: string,

}

class QuoteConfirmationModal extends React.Component<qutoeConfirmationprops & qutoeConfirmationstate , any>{
    constructor(props: any) {
        super(props);
        const initialState = {
            projectName: '',
            projectId: '',
            quoteCreatedBy: '',
            userId: '',
            quoteStatus: '',
            userName: '',
            closeQuoteConfirm: true,

        };
        this.state = initialState;
    }

    componentDidMount(){
        this.setState({
            projectName: this.props.projectName,
            projectId: this.props.projectId,
            quoteCreatedBy: this.props.quoteCreatedBy,
            userId: this.props.userId,
            quoteStatus: this.props.quoteStatus,
            userName: this.props.userName,

        }) 
    }

//Closing confirm modal on click of close icon /cancel button    
    closeQuoteConfirmModal = () =>{
        this.props.result(this.state.closeQuoteConfirm);
    }
//Updating the quote status as Accept/Reject on click of confirm button
    quoteStatusHandler = ()=>{
        let {projectId, quoteCreatedBy, userId, quoteStatus}:any = this.state
         Check.checkToken()
         .then(
            async () => {
              const options = {
               url: constants.manageQuoteStatus,
               data: {
                 "projectId": projectId,
                 "createdBy": quoteCreatedBy,
                 "userId":  userId,
                 "status": quoteStatus,
                 "requestChange": ""
               }
             }
         await userService.postApiRequest(options)
         .then((response)=>{
                this.props.result( "Quote Status updated Successfully");
            
         })
         .catch((error)=>{
            this.props.error("Failed to update Quote Status");
         })      
         })
     }
 

    render(){
        const {projectName, userName, quoteStatus}:any = this.state
        return (
            <div className = "container-fluid quote-container">
                <div className = "quote-header">
                    <p>Confirmation</p>
                    <img src = {Vector}  onClick={this.closeQuoteConfirmModal}/>
                </div>
                <div className = "quote-confirmation-content">
                    {(this.props.status == "InitiateContract")?
                        <p>Are you sure you want to reject the previous quote and  accept the quote from “{userName}” for the project “{projectName}”? </p>
                        :
                        <>
                            {(quoteStatus == "accept")?
                                <p>Are you sure you want to accept the quote from “{userName}” for the project “{projectName}”? </p>
                                :
                                <p>Are you sure you want to reject the quote from “{userName}” for the project “{projectName}”? </p>
                            }
                        </>
                    }
                   
                </div>
                <div className = "d-flex quote-confirmation-button">
                    <button onClick={()=>this.quoteStatusHandler()}>Confirm</button>
                    <button onClick={this.closeQuoteConfirmModal}>Cancel</button>
                </div>
            </div>
        )
        
    }

}

export default QuoteConfirmationModal;